import React, { forwardRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Autocomplete, TextField, Checkbox, Dialog } from '@mui/material';
import { CheckBox, CheckBoxOutlineBlank } from "@mui/icons-material";
import Label from './label/Label';
import CustomValue from './CustomValue';

const TypeAhead = forwardRef(({ name, data, api, idKey, displayTextKey, defaultText, multiple, minInputLength = 1, selectedAsCheckbox, isCloseHide, isReadOnly, disableCloseOnSelect = false, onInputChange, onChange, ...rest }, ref) => {
  idKey = idKey || 'id';
  const isObject = !!displayTextKey;
  const { setValue, watch, trigger, formState: { errors } } = useFormContext();
  const [options, setOptions] = useState(data || []);
  const [loading, setLoading] = useState(false);
  const [optionText, setOptionText] = useState('Start Typing...');
  const [openDialog, setOpenDialog] = useState(false);
  const value = watch(name);

  const fetchData = async (inputValue) => {
    setLoading(true);
    try {
      const response = await fetch(`${api}?q=${inputValue}`);
      const resultData = await response.json();
      if (resultData?.length > 0) {
        setOptions(resultData);
      } else {
        if (!options?.length) setOptionText('No match found.');
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (event, newInputValue) => {
    if (!event) return false;
    if (onInputChange) onInputChange(event, newInputValue);
    if (api && newInputValue?.length >= minInputLength) {
      fetchData(newInputValue);
    } else {
      setOptionText('Start Typing...');
    }
  };

  const handleCustomValue = (_, customValue) => {
    setOpenDialog(false);
    const result = isObject ? { [idKey]: customValue?.toLowerCase(), [displayTextKey]: customValue } : customValue;
    if (onChange) onChange(_, result);
    setValue(name, isObject ? result[displayTextKey] : result, { shouldDirty: true });
    trigger(name);
  };

  return (
    <>
      <Autocomplete
        {...rest}
        multiple={multiple}
        size="small"
        disablePortal
        noOptionsText={optionText}
        disableClearable={isCloseHide || false}
        options={options}
        readOnly={isReadOnly || false}
        getOptionLabel={(option) => typeof option === 'object' && displayTextKey ? option[displayTextKey] : option}
        value={multiple ? value || [] : value}
        disableCloseOnSelect={disableCloseOnSelect}
        onChange={(_, newValue) => {
          if (newValue) {
            if (isObject && typeof newValue === 'object' && displayTextKey ? newValue[displayTextKey] === defaultText : newValue === defaultText) {
              setOpenDialog(true);
            } else {
              if (onChange) onChange(_, newValue);
              setValue(name, typeof newValue === 'object' && displayTextKey ? newValue[displayTextKey] : newValue, { shouldDirty: true });
              trigger(name);
            }
          }
        }}
        filterOptions={defaultText ? (options, state) => {
          if (loading || !state?.inputValue || state?.inputValue?.length < minInputLength) return [];
          const filtered = options?.filter(option => {
            return isObject && typeof option === 'object' ? option[displayTextKey]?.toLowerCase()?.includes(state.inputValue?.toLowerCase()) : option?.toLowerCase().includes(state.inputValue?.toLowerCase())
          });
          // Append "Others" to the filtered list
          return [...filtered, isObject ? { [idKey]: defaultText?.toLowerCase(), [displayTextKey]: defaultText } : defaultText];
        } : undefined}
        loading={loading}
        clearOnBlur={false}
        onInputChange={handleInputChange}
        renderOption={(props, option, { selected }) => {
          return (
            <li {...props} key={props?.id}>
              {selectedAsCheckbox && <Checkbox
                icon={<CheckBoxOutlineBlank fontSize='small' />}
                checkedIcon={<CheckBox fontSize='small' />}
                style={{ marginRight: 8 }}
                checked={selected}
              />}
              {typeof option === 'object' ? option[displayTextKey] : option}
            </li>
          );
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={rest?.label}
            error={!!errors[name]}
            helperText={errors[name]?.message}
            inputRef={ref}
          />
        )}
        isOptionEqualToValue={(option, value) => {
          const isMatching = typeof option === 'object' ? (option[idKey] ? option[idKey] === value[idKey] : (option[displayTextKey] === value[displayTextKey])) : option === value;
          return isMatching;
        }}
      />

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="otp-dialog-wrapper">
        <CustomValue onClose={() => setOpenDialog(false)} title={rest?.placeholder} onCustomValue={handleCustomValue} label={rest?.label} />
      </Dialog>
    </>
  );
});

export default TypeAhead;
