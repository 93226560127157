import React from 'react'
import { Box, Card, CardContent, Chip, Container, Divider, Grid, List, ListItem, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import { changeNameFormat } from 'utils/Utils';
import { useAuth } from 'hooks/useAuthContext';
import dayjs from 'dayjs';
const imgLogo = `${process.env.REACT_APP_HOMEPAGE}assets/default.png`;

const ResumeFormatViewResume = ({ educationData, summaryData, workExperienceData, customSection, certificateData, financeTechologiesData, pdfRef }) => {
    const { user, updateContext } = useAuth();
    const groupedData = workExperienceData?.reduce((acc, data) => {
        if (data?.organization_name !== 'Work Gap') {
            if (!acc[data.organization_name]) {
                acc[data.organization_name] = {
                    industry: data.industry,
                    entries: [],
                };
            }
            acc[data.organization_name].entries.push(data);
        }
        return acc;
    }, {});

    return (
        <Box >
            <Container maxWidth="xl">
                <Box sx={{
                    backgroundColor: "white",
                    position: 'relative',
                    width: '100%',
                    display: 'block',
                    padding: '2rem 1rem',
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    marginTop: '1rem',
                    marginBottom: '1rem',
                    maxHeight: '70vh',
                    '&::-webkit-scrollbar': {
                        display: 'none',
                    },

                }}>

                    <Box ref={pdfRef}>
                        <Box>
                            <Stack direction="row" spacing={1} alignItems="start" mb={1}>
                                <Box sx={{ width: '100%' }}>
                                    <Stack direction="row" spacing={1.5} alignItems="center">
                                        <Box sx={{ display: 'inline-flex', overflow: 'hidden', borderRadius: '4.8px' }}>
                                            <img alt="profile" width="65px" height="auto" src={user?.photo || imgLogo}
                                                onError={(e) => (e.target.src = imgLogo)}
                                            />
                                        </Box>
                                        <Box>
                                            <Typography mb={0.5} sx={{
                                                color: '#002149',
                                                fontFamily: 'Times New Roman',
                                                fontSize: '12px',
                                                fontStyle: 'normal',
                                                fontWeight: '700',
                                                lineHeight: '14px'
                                            }}>{changeNameFormat(user?.name)}</Typography>
                                            <Typography
                                                sx={{
                                                    color: '#002149',
                                                    fontFamily: 'Times New Roman',
                                                    fontSize: '12px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '14px'
                                                }}
                                            >{user?.email} {user?.phone ? `| +91-${user?.phone}` : ''}  {user?.city ? `| ${user?.city}` : ''}</Typography>
                                        </Box>

                                    </Stack>
                                </Box>
                            </Stack>
                        </Box>


                        {summaryData &&
                            <Box>
                                <Divider sx={{ borderStyle: 'solid', borderColor: '#E7EBFD', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} />
                                <Box mb={1.5}>
                                    <Typography mb={0.5} sx={{
                                        color: '#002149',
                                        fontFamily: 'Times New Roman',
                                        fontSize: '12px',
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        lineHeight: '14px'
                                    }}>Professional Summary</Typography>
                                    <Typography sx={{
                                        color: '#002149',
                                        fontFamily: 'Times New Roman',
                                        fontSize: '10px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: '12px'
                                    }}>{summaryData}</Typography>
                                </Box>
                            </Box>
                        }

                        {educationData?.filter(item => item?._id)?.length > 0 && (
                            <Box mb={1.5}>
                                <TableContainer sx={{ border: '1.5px solid #E7EBFD', borderRadius: '8px' }}>
                                    <Table sx={{ minWidth: '100%', overflow: 'hidden', borderRadius: '8px' }} size="small" aria-label="a dense table">
                                        <TableHead sx={{
                                            borderRadius: '8px 8px 0px 0px',
                                            background: '#E7EBFD'
                                        }}>
                                            <TableRow>
                                                <TableCell sx={{ borderBottom: 'none' }}>
                                                    <Typography sx={{
                                                        color: '#002149',
                                                        fontFamily: 'Times New Roman',
                                                        fontSize: '11px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '700',
                                                        lineHeight: '13px'
                                                    }}>Education</Typography>
                                                </TableCell>
                                                <TableCell sx={{ borderBottom: 'none' }}>
                                                    <Typography sx={{
                                                        color: '#002149',
                                                        fontFamily: 'Times New Roman',
                                                        fontSize: '11px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '700',
                                                        lineHeight: '13px'
                                                    }}>Key Skills</Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {educationData?.filter(item => item?._id)?.map((item, index) => (
                                                <TableRow key={index}>
                                                    <TableCell>
                                                        <Stack direction="row" alignItems={'center'} justifyContent={'space-between'} spacing={2} mb={1}>
                                                            <Typography sx={{
                                                                color: '#002149',
                                                                fontFamily: 'Times New Roman',
                                                                fontSize: '8px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '700',
                                                                lineHeight: '10px'
                                                            }}>{item?.degree_received || ''} ( {item?.field_of_study || ''}), {item?.education_institute_name || ''}</Typography>
                                                            <Typography sx={{
                                                                color: '#374151',
                                                                fontFamily: 'Times New Roman',
                                                                fontSize: '8px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                lineHeight: '10px'
                                                            }}>{dayjs(item?.education_from).format('MMM YY')} - {dayjs(item?.education_to).format('MMM YY') || '-'}</Typography>
                                                        </Stack>

                                                        <List sx={{ paddingTop: 0, paddingLeft: '1.5rem', listStyleType: 'disc' }}>
                                                            {item?.key_accomplishments && item?.key_accomplishments?.map((accomplishment, index) => (
                                                                <ListItem key={index} sx={{ display: 'list-item' }} disablePadding>
                                                                    <Typography sx={{
                                                                        color: '#002149',
                                                                        fontFamily: 'Times New Roman',
                                                                        fontSize: '8px',
                                                                        fontStyle: 'normal',
                                                                        fontWeight: '400',
                                                                        lineHeight: '10px'
                                                                    }}>{accomplishment}</Typography>
                                                                </ListItem>
                                                            ))}
                                                        </List>
                                                    </TableCell>
                                                    <TableCell>
                                                        {item?.skills && item?.skills.map((skill, index) => (
                                                            <Typography key={index} sx={{
                                                                color: '#002149',
                                                                fontFamily: 'Times New Roman',
                                                                fontSize: '8px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                lineHeight: '10px'
                                                            }}>{skill}</Typography>
                                                        ))}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        )}

                        {certificateData?.filter(item => item?._id)?.length > 0 && (
                            <Box mb={2}>
                                <Card variant="outlined" sx={{ borderRadius: '12px' }}>
                                    <CardContent sx={{ padding: '0!important' }}>
                                        <Box
                                            sx={{
                                                display: 'flex',
                                                padding: '4px 0px 4px 10px',
                                                alignItems: 'flex-start',
                                                alignSelf: 'stretch',
                                                background: '#E7EBFD'
                                            }}>
                                            <Typography sx={{
                                                color: '#002149',
                                                fontFamily: 'Times New Roman',
                                                fontSize: '12px',
                                                fontStyle: 'normal',
                                                fontWeight: '700',
                                                lineHeight: '14px'
                                            }}>Certification</Typography>
                                        </Box>

                                        <Box sx={{
                                            padding: '1rem 1.5rem'
                                        }}>
                                            <Stack spacing={1}>
                                                {certificateData?.filter(item => item?._id).map((item, index) => (
                                                    <Box key={index}>
                                                        <Stack direction="row" alignItems={'center'} justifyContent={'space-between'} spacing={2}>
                                                            <Typography sx={{
                                                                color: '#002149',
                                                                fontFamily: 'Times New Roman',
                                                                fontSize: '8px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '700',
                                                                lineHeight: '10px'
                                                            }}>{item?.certificate_received || ''}{item?.learning_format && ` (${item?.learning_format})`}</Typography>
                                                            <Typography sx={{
                                                                color: '#374151',
                                                                fontFamily: 'Times New Roman',
                                                                fontSize: '8px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                lineHeight: '10px'
                                                            }}>{dayjs(item?.education_from).format('MMM YY')} - {dayjs(item?.education_to).format('MMM YY') || '-'}</Typography>
                                                        </Stack>
                                                    </Box>
                                                ))}
                                            </Stack>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </Box>
                        )}

                        {workExperienceData?.filter(item => item?._id)?.length > 0 && (
                            <Box mb={2}>
                                <Box mb={2}>
                                    <Typography sx={{
                                        color: '#002149',
                                        fontFamily: 'Times New Roman',
                                        fontSize: '12px',
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        lineHeight: '14px'
                                    }} mb={1}>Work Experience Overview</Typography>

                                    <TableContainer sx={{ border: '1.5px solid #E7EBFD', borderRadius: '8px' }}>
                                        <Table sx={{ minWidth: '100%', overflow: 'hidden', borderRadius: '8px' }} size="small" aria-label="a dense table">
                                            <TableHead sx={{
                                                borderRadius: '8px 8px 0px 0px',
                                                background: '#E7EBFD'
                                            }}>
                                                <TableRow>
                                                    <TableCell sx={{ borderBottom: 'none' }}>
                                                        <Typography sx={{
                                                            color: '#002149',
                                                            fontFamily: 'Times New Roman',
                                                            fontSize: '11px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '700',
                                                            lineHeight: '13px'
                                                        }}>Period</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none' }}>
                                                        <Typography sx={{
                                                            color: '#002149',
                                                            fontFamily: 'Times New Roman',
                                                            fontSize: '11px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '700',
                                                            lineHeight: '13px'
                                                        }}>Company Name</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none' }}>
                                                        <Typography sx={{
                                                            color: '#002149',
                                                            fontFamily: 'Times New Roman',
                                                            fontSize: '11px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '700',
                                                            lineHeight: '13px'
                                                        }}>Industry</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none' }}>
                                                        <Typography sx={{
                                                            color: '#002149',
                                                            fontFamily: 'Times New Roman',
                                                            fontSize: '11px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '700',
                                                            lineHeight: '13px'
                                                        }}>Last Designation</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none' }}>
                                                        <Typography sx={{
                                                            color: '#002149',
                                                            fontFamily: 'Times New Roman',
                                                            fontSize: '11px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '700',
                                                            lineHeight: '13px'
                                                        }}>Team</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {workExperienceData?.filter(item => item?._id).map((data, index) => (
                                                    data.organization_name === 'Work Gap' ? (data.show_in_resume && (
                                                        <TableRow key={index}>
                                                            <TableCell sx={{ borderBottom: 'none' }}>
                                                                <Typography sx={{
                                                                    color: '#002149',
                                                                    fontFamily: 'Times New Roman',
                                                                    fontSize: '8px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '700',
                                                                    lineHeight: '10px'
                                                                }}>{dayjs(data?.work_history_from).format('MMM YY')} - {data?.present ? 'Present' : dayjs(data?.work_history_to).format('MMM YY')}</Typography>
                                                            </TableCell>
                                                            <TableCell sx={{ borderBottom: 'none' }}>
                                                                <Typography sx={{
                                                                    color: '#002149',
                                                                    fontFamily: 'Times New Roman',
                                                                    fontSize: '8px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '700',
                                                                    lineHeight: '10px'
                                                                }}>{data.organization_name || '-'}</Typography>
                                                            </TableCell>
                                                            <TableCell sx={{ borderBottom: 'none' }}>
                                                                <Typography sx={{
                                                                    color: '#002149',
                                                                    fontFamily: 'Times New Roman',
                                                                    fontSize: '8px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '700',
                                                                    lineHeight: '10px'
                                                                }}>{data?.industry || '-'}</Typography>
                                                            </TableCell>
                                                            <TableCell sx={{ borderBottom: 'none' }}>
                                                                <Typography sx={{
                                                                    color: '#002149',
                                                                    fontFamily: 'Times New Roman',
                                                                    fontSize: '8px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '700',
                                                                    lineHeight: '10px'
                                                                }}>{data?.gap_explaination || '-'}</Typography>
                                                            </TableCell>
                                                            <TableCell sx={{ borderBottom: 'none' }}>
                                                                <Typography sx={{
                                                                    color: '#002149',
                                                                    fontFamily: 'Times New Roman',
                                                                    fontSize: '8px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '700',
                                                                    lineHeight: '10px'
                                                                }}>{data?.team_size_managed || '-'}</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    )) : (
                                                        <TableRow key={index}>
                                                            <TableCell sx={{ borderBottom: 'none' }}>
                                                                <Typography sx={{
                                                                    color: '#002149',
                                                                    fontFamily: 'Times New Roman',
                                                                    fontSize: '8px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '700',
                                                                    lineHeight: '10px'
                                                                }}>{dayjs(data?.work_history_from).format('MMM YY')} - {data?.present ? 'Present' : dayjs(data?.work_history_to).format('MMM YY')}</Typography>
                                                            </TableCell>
                                                            <TableCell sx={{ borderBottom: 'none' }}>
                                                                <Typography sx={{
                                                                    color: '#002149',
                                                                    fontFamily: 'Times New Roman',
                                                                    fontSize: '8px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '700',
                                                                    lineHeight: '10px'
                                                                }}>{data.organization_name || '-'}</Typography>
                                                            </TableCell>
                                                            <TableCell sx={{ borderBottom: 'none' }}>
                                                                <Typography sx={{
                                                                    color: '#002149',
                                                                    fontFamily: 'Times New Roman',
                                                                    fontSize: '8px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '700',
                                                                    lineHeight: '10px'
                                                                }}>{data?.industry || '-'}</Typography>
                                                            </TableCell>
                                                            <TableCell sx={{ borderBottom: 'none' }}>
                                                                <Typography sx={{
                                                                    color: '#002149',
                                                                    fontFamily: 'Times New Roman',
                                                                    fontSize: '8px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '700',
                                                                    lineHeight: '10px'
                                                                }}>{data?.highest_designation || '-'}</Typography>
                                                            </TableCell>
                                                            <TableCell sx={{ borderBottom: 'none' }}>
                                                                <Typography sx={{
                                                                    color: '#002149',
                                                                    fontFamily: 'Times New Roman',
                                                                    fontSize: '8px',
                                                                    fontStyle: 'normal',
                                                                    fontWeight: '700',
                                                                    lineHeight: '10px'
                                                                }}>{data?.team_size_managed || '-'}</Typography>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>


                                <TableContainer sx={{ border: '1.5px solid #E7EBFD', borderRadius: '8px' }}>
                                    <Table sx={{ minWidth: '100%', overflow: 'hidden', borderRadius: '8px' }} size="small" aria-label="a dense table">
                                        <TableHead sx={{
                                            borderRadius: '8px 8px 0px 0px',
                                            background: '#E7EBFD'
                                        }}>
                                            <TableRow>
                                                <TableCell sx={{ borderBottom: 'none' }}>
                                                    <Typography sx={{
                                                        color: '#002149',
                                                        fontFamily: 'Times New Roman',
                                                        fontSize: '12px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '700',
                                                        lineHeight: '14px'
                                                    }}>Key Responsibilities and Accomplishments</Typography>
                                                </TableCell>
                                                <TableCell sx={{ borderBottom: 'none' }}>
                                                    <Typography sx={{
                                                        color: '#002149',
                                                        fontFamily: 'Times New Roman',
                                                        fontSize: '12px',
                                                        fontStyle: 'normal',
                                                        fontWeight: '700',
                                                        lineHeight: '14px'
                                                    }}>Key Skills</Typography>
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>

                                        <TableBody>
                                            {Object.keys(groupedData).map((organizationName, orgIndex) => (
                                                <TableRow key={orgIndex}>
                                                    <TableCell>
                                                        {/* Organization Name with Industry */}
                                                        <Typography
                                                            sx={{
                                                                color: '#111827',
                                                                fontFamily: 'Times New Roman',
                                                                fontSize: '8px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                lineHeight: '10px'
                                                            }}
                                                            mb={1}
                                                        >
                                                            <Box component={'span'} sx={{ fontWeight: 700 }}>
                                                                {organizationName}
                                                            </Box>
                                                            {groupedData[organizationName].industry && ` - ${groupedData[organizationName]?.industry}`}
                                                        </Typography>

                                                        {/* Display Designations and Details for each group */}
                                                        {groupedData[organizationName]?.entries?.map((data, dataIndex) => (
                                                            <div key={dataIndex}>
                                                                <Stack direction="row" alignItems={'center'} justifyContent={'space-between'} spacing={2} mb={1}>
                                                                    <Typography
                                                                        sx={{
                                                                            color: '#111827',
                                                                            fontFamily: 'Times New Roman',
                                                                            fontSize: '8px',
                                                                            fontStyle: 'normal',
                                                                            fontWeight: '400',
                                                                            lineHeight: '10px'
                                                                        }}
                                                                    >
                                                                        <Box component={'span'} sx={{ fontWeight: 700 }}>
                                                                            {data?.highest_designation}
                                                                        </Box>
                                                                        {data?.job_type && ` (${data?.job_type})`}
                                                                    </Typography>
                                                                    <Typography
                                                                        sx={{
                                                                            color: '#374151',
                                                                            fontFamily: 'Times New Roman',
                                                                            fontSize: '8px',
                                                                            fontStyle: 'normal',
                                                                            fontWeight: '400',
                                                                            lineHeight: '10px'
                                                                        }}
                                                                    >
                                                                        {dayjs(data?.work_history_from)?.format('MMM YY')} -{' '}
                                                                        {data?.present ? 'Present' : dayjs(data?.work_history_to).format('MMM YY')}
                                                                    </Typography>
                                                                </Stack>

                                                                {/* Accomplishments List */}
                                                                <List sx={{ paddingTop: 0, paddingLeft: '1.5rem', listStyleType: 'disc' }}>
                                                                    {data?.key_accomplishments &&
                                                                        data?.key_accomplishments?.map((accomplishment, keyIndex) => (
                                                                            <ListItem key={keyIndex} sx={{ display: 'list-item' }} disablePadding>
                                                                                <Typography
                                                                                    sx={{
                                                                                        color: '#002149',
                                                                                        fontFamily: 'Times New Roman',
                                                                                        fontSize: '8px',
                                                                                        fontStyle: 'normal',
                                                                                        fontWeight: '400',
                                                                                        lineHeight: '10px'
                                                                                    }}
                                                                                >
                                                                                    {accomplishment}
                                                                                </Typography>
                                                                            </ListItem>
                                                                        ))}
                                                                </List>
                                                            </div>
                                                        ))}
                                                    </TableCell>

                                                    {/* Display Skills */}
                                                    <TableCell>
                                                        {groupedData[organizationName]?.entries?.map((data, dataIndex) => (
                                                            <div key={dataIndex}>
                                                                {data?.skills &&
                                                                    data?.skills?.map((skill, keyIndex) => (
                                                                        <Typography
                                                                            key={keyIndex}
                                                                            sx={{
                                                                                color: '#002149',
                                                                                fontFamily: 'Times New Roman',
                                                                                fontSize: '8px',
                                                                                fontStyle: 'normal',
                                                                                fontWeight: '400',
                                                                                lineHeight: '10px'
                                                                            }}>{skill}</Typography>
                                                                    ))}
                                                            </div>
                                                        ))}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        )}

                        {customSection?.length > 0 && customSection?.map((section, keyIndex) => (
                             section?.key && section?.message && section?.message !== '<p><br></p>' ?(
                            <Box mb={2} key={keyIndex}>
                                <Typography sx={{
                                    color: '#002149',
                                    fontFamily: 'Times New Roman',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '700',
                                    lineHeight: '14px'
                                }}>{section?.key}</Typography>
                                <Typography
                                    sx={{
                                        color: '#002149',
                                        fontFamily: 'Times New Roman',
                                        fontSize: '8px',
                                        fontStyle: 'normal',
                                        fontWeight: '400',
                                        lineHeight: '10px',
                                        '& ul': {
                                            listStyleType: 'disc',
                                            marginLeft: '20px',
                                        },
                                        '& ol': {
                                            listStyleType: 'decimal',
                                            marginLeft: '20px',
                                        },
                                        '& li': {
                                            marginBottom: '1px',
                                        },
                                    }}
                                >
                                    <div dangerouslySetInnerHTML={{ __html: section?.message }} />
                                </Typography>
                            </Box>
                            ) : null
                        ))}

                        {workExperienceData?.filter(item => item?._id)?.length > 0 && (
                            <Box>
                                <Box mb={2}>
                                    <Typography sx={{
                                        color: '#002149',
                                        fontFamily: 'Times New Roman',
                                        fontSize: '12px',
                                        fontStyle: 'normal',
                                        fontWeight: '700',
                                        lineHeight: '14px'
                                    }} mb={1}>Skills Summary</Typography>

                                    <TableContainer sx={{ border: '1.5px solid #E7EBFD', borderRadius: '8px' }}>
                                        <Table sx={{ minWidth: '100%', overflow: 'hidden', borderRadius: '8px' }} size="small" aria-label="a dense table">
                                            <TableHead sx={{
                                                borderRadius: '8px 8px 0px 0px',
                                                background: '#E7EBFD'
                                            }}>
                                                <TableRow>
                                                    <TableCell sx={{ borderBottom: 'none' }}>
                                                        <Typography sx={{
                                                            color: '#002149',
                                                            fontFamily: 'Times New Roman',
                                                            fontSize: '11px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '700',
                                                            lineHeight: '13px'
                                                        }}>Expertise</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none' }}>
                                                        <Typography sx={{
                                                            color: '#002149',
                                                            fontFamily: 'Times New Roman',
                                                            fontSize: '11px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '700',
                                                            lineHeight: '13px'
                                                        }}>Key Skills</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none' }}>
                                                        <Typography sx={{
                                                            color: '#002149',
                                                            fontFamily: 'Times New Roman',
                                                            fontSize: '11px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '700',
                                                            lineHeight: '13px'
                                                        }}>Where learned/ used?</Typography>
                                                    </TableCell>
                                                    <TableCell sx={{ borderBottom: 'none' }}>
                                                        <Typography sx={{
                                                            color: '#002149',
                                                            fontFamily: 'Times New Roman',
                                                            fontSize: '11px',
                                                            fontStyle: 'normal',
                                                            fontWeight: '700',
                                                            lineHeight: '13px'
                                                        }}>Experience</Typography>
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>

                                            <TableBody>
                                                {workExperienceData?.map((data, index) => data.organization_name !== 'Work Gap' && (
                                                    <TableRow key={index}>
                                                        <TableCell sx={{ borderBottom: 'none' }}>
                                                            <Typography sx={{
                                                                color: '#002149',
                                                                fontFamily: 'Times New Roman',
                                                                fontSize: '8px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                lineHeight: '10px'
                                                            }}>{data?.highest_designation}</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ borderBottom: 'none' }}>
                                                            <Typography sx={{
                                                                color: '#002149',
                                                                fontFamily: 'Times New Roman',
                                                                fontSize: '8px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                lineHeight: '10px'
                                                            }}>{data?.skills?.join(',')}</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ borderBottom: 'none' }}>
                                                            <Typography sx={{
                                                                color: '#002149',
                                                                fontFamily: 'Times New Roman',
                                                                fontSize: '8px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                lineHeight: '10px'
                                                            }}>{data?.organization_name}</Typography>
                                                        </TableCell>
                                                        <TableCell sx={{ borderBottom: 'none' }}>
                                                            <Typography sx={{
                                                                color: '#002149',
                                                                fontFamily: 'Times New Roman',
                                                                fontSize: '8px',
                                                                fontStyle: 'normal',
                                                                fontWeight: '400',
                                                                lineHeight: '10px'
                                                            }}>{data?.work_experience || 'Present'}</Typography>
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            </Box>
                        )}

                        {financeTechologiesData?.length > 0 && (
                            <Box >
                                <Typography sx={{
                                    color: '#002149',
                                    fontFamily: 'Times New Roman',
                                    fontSize: '12px',
                                    fontStyle: 'normal',
                                    fontWeight: '700',
                                    lineHeight: '14px'
                                }} mb={1}>Technical Competencies</Typography>

                                {/* <Stack direction="row" spacing={1} alignItems="center">  */}
                                <Stack direction="row" flexWrap="wrap" alignItems="center">
                                    {financeTechologiesData?.map((techSkills, techIndex) => (
                                        <Chip key={techIndex} label={techSkills} variant="outlined"
                                            sx={{
                                                borderRadius: '8px',
                                                border: '0.5px solid var(--Neutral-200, #C5D1DB)',
                                                background: '#F9FAFF',
                                                marginRight: '0.5rem',
                                                marginBottom: '0.5rem',
                                                '& .MuiChip-label': {
                                                    color: '#002149',
                                                    fontFamily: 'Times New Roman',
                                                    fontSize: '8px',
                                                    fontStyle: 'normal',
                                                    fontWeight: '400',
                                                    lineHeight: '10px'
                                                }
                                            }} />
                                    ))}
                                </Stack>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Container>
        </Box>
    )
}

export default ResumeFormatViewResume
