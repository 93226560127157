import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  IconButton,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useForm, register, watch, Controller } from 'react-hook-form';
import TypeAhead from 'components/TypeAhead';
import { endpoints } from 'utils/axios';
import FormProvider from 'hooks/form/form-provider';
import { updateCandidateEducation } from 'api/users';
import { updateCandidateWorkExperience } from 'api/users';
import { useSnackbar } from 'components/snackbar';
import { updateSkills } from 'api/users';

const UpdateReleventSkillsDrawer = ({
  onClose,
  workExperience,
  education,
  certificate,
  skillsUpdate,
  nonMatchingSkills,
  matchingSkills,
  setNonMatchingSkills,
}) => {
  const [matchedSkills, setMatchedChips] = useState(matchingSkills || []);
  const [selectedChips, setSelectedChips] = useState([]);
  const defaultValues = {
    skills: [],
    selectedWorkExperience: [],
    selectedEducation: [],
    selectedCertificate: [],
  };
  const { enqueueSnackbar } = useSnackbar();

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
  });

  const {
    register,
    handleSubmit,
    control,
    getValues,
    setValue,
    formState: { errors, isDirty, isValid, dirtyFields, isSubmitting },
    watch,
  } = methods;

  useEffect(() => {
    setValue('skills', matchedSkills);
  }, [matchedSkills]);

  const onSubmit = handleSubmit(async (formData) => {
    try {
      formData.skills = selectedChips;
      if (
        formData?.selectedEducation?.length > 0 ||
        formData?.selectedWorkExperience?.length > 0 ||
        formData.selectedCertificate?.length > 0
      ) {
        const response = await updateSkills(formData);
        if (response && response?.data && response?.success) {
          setNonMatchingSkills(nonMatchingSkills.filter((chip) => chip !== selectedChips[0]));
          skillsUpdate(matchedSkills);
          setSelectedChips([]);
          onClose();
          enqueueSnackbar('  Skill updated successfully.', { variant: 'success' });
        } else {
          enqueueSnackbar('Failed to update skills.', { variant: 'warning' });
        }
      } else {
        enqueueSnackbar('Please check the box you want to update.', { variant: 'warning' });
      }
    } catch (error) {
      console.error('Error to set skills. Please try again.', error);
    }
  });

  const handleWorkExperienceChange = (experience) => (event) => {
    const uniqueIdentifier = experience._id;
    const selectedWorkExperience = getValues('selectedWorkExperience');
    if (event.target.checked) {
      setValue('selectedWorkExperience', [...selectedWorkExperience, experience]);
    } else {
      setValue(
        'selectedWorkExperience',
        selectedWorkExperience.filter((experience) => experience._id !== uniqueIdentifier)
      );
    }
  };

  const handleEducationChange = (educationItem) => (event) => {
    const uniqueIdentifier = educationItem._id;
    const selectedEducation = getValues('selectedEducation');
    if (event.target.checked) {
      setValue('selectedEducation', [...selectedEducation, educationItem]);
    } else {
      setValue(
        'selectedEducation',
        selectedEducation.filter((educationItem) => educationItem._id !== uniqueIdentifier)
      );
    }
  };

  const handleCertificateChange = (certificate) => (event) => {
    const uniqueIdentifier = certificate._id;
    const selectedCertificate = getValues('selectedCertificate');
    if (event.target.checked) {
      setValue('selectedCertificate', [...selectedCertificate, certificate]);
    } else {
      setValue(
        'selectedCertificate',
        selectedCertificate.filter((certificate) => certificate._id !== uniqueIdentifier)
      );
    }
  };

  const handleClick = (label) => {
    if (selectedChips.includes(label)) {
      setSelectedChips(selectedChips.filter((chip) => chip !== label));
      setMatchedChips(matchedSkills.filter((chip) => chip !== label));
    } else {
      if (selectedChips?.length < 1) {
        setSelectedChips([...selectedChips, label]);
        setMatchedChips([...matchedSkills, label]);
      } else {
        enqueueSnackbar('You can select only one skills at a time.', { variant: 'warning' });
      }
    }
  };

  return (
    <Box sx={{ width: 450 }} className="job-app-drawer-container update-skills-drawer-container" role="presentation">
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Box className="drawer-header">
          <Stack direction="row" alignItems={'center'} spacing={1}>
            <IconButton aria-label="delete" onClick={onClose} sx={{paddingTop: 0, paddingBottom: 0}}>
              <SvgIcon>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M6.22796 5.04477C5.90134 4.71815 5.37178 4.71815 5.04516 5.04477C4.71854 5.37139 4.71854 5.90095 5.04516 6.22757L10.8174 11.9998L5.04516 17.772C4.71854 18.0987 4.71854 18.6282 5.04516 18.9548C5.37178 19.2815 5.90134 19.2815 6.22796 18.9548L12.0002 13.1826L17.7724 18.9548C18.0991 19.2815 18.6286 19.2815 18.9552 18.9548C19.2819 18.6282 19.2819 18.0987 18.9552 17.772L13.183 11.9998L18.9552 6.22757C19.2819 5.90095 19.2819 5.37139 18.9552 5.04477C18.6286 4.71815 18.0991 4.71815 17.7724 5.04477L12.0002 10.817L6.22796 5.04477Z"
                    fill="white"
                  />
                </svg>
              </SvgIcon>
            </IconButton>

            <Typography className="title" sx={{ marginBottom: '0rem!important' }}>
              Skill Update
            </Typography>
          </Stack>
        </Box>

        <Box className="drawer-content update-skills-content">
          <Box className="skills-section" mb={2}>
            <FormControl fullWidth>
              <Controller
                name="skills"
                control={control}
                defaultValue={matchedSkills || []}
                render={({ field }) => (
                  <TypeAhead
                    {...field}
                    {...register('skills')}
                    multiple={true}
                    isReadOnly={true}
                    isCloseHide={true}
                    label="New Skill"
                    size="small"
                    variant="outlined"
                  />
                )}
              />
            </FormControl>
            <Box className="skills-section" sx={{ marginTop: 1 }}>
              <Stack direction="row" flexWrap="wrap">
                {nonMatchingSkills.map((label, index) => (
                  <Chip
                    key={index}
                    label={label}
                    onClick={() => handleClick(label)}
                    icon={
                      selectedChips.includes(label) ? (
                        <Box className="match-icon">
                          <SvgIcon>
                            <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M7.77869 1.3764L3.45821 5.69687C3.16468 5.99041 2.67851 5.99041 2.3758 5.69687L0.220152 3.54122C-0.0733838 3.24769 -0.0733838 2.76152 0.220152 2.45881C0.513687 2.16527 0.999855 2.16527 1.30256 2.45881L2.92618 4.08243L6.70545 0.303159C6.99898 0.00962395 7.48515 0.00962395 7.78786 0.303159C8.07222 0.596695 8.07222 1.08286 7.77869 1.3764Z"
                                fill="white"
                              />
                            </svg>
                          </SvgIcon>
                        </Box>
                      ) : (
                        <Box className="error-match-icon">
                          <SvgIcon>
                            <svg xmlns="http://www.w3.org/2000/svg" width="6" height="6" viewBox="0 0 6 6" fill="none">
                              <path
                                d="M0.134092 5.23008L2.36417 3L0.134092 0.769922C0.0918098 0.728272 0.0581919 0.678662 0.0351778 0.623955C0.0121637 0.569247 0.000208773 0.510524 2.71012e-06 0.451173C-0.000203353 0.391823 0.0113435 0.333018 0.0339772 0.278152C0.0566109 0.223286 0.0898834 0.173444 0.131876 0.131501C0.173868 0.0895583 0.223749 0.0563443 0.278642 0.0337753C0.333535 0.0112063 0.392353 -0.000271149 0.451703 4.86231e-06C0.511054 0.000280873 0.569763 0.0123049 0.624443 0.0353835C0.679123 0.0584621 0.728693 0.0921385 0.770294 0.13447L2.99962 2.36455L5.2297 0.13447C5.2713 0.0921385 5.32087 0.0584621 5.37555 0.0353835C5.43023 0.0123049 5.48894 0.000280873 5.54829 4.86231e-06C5.60764 -0.000271149 5.66646 0.0112063 5.72135 0.0337753C5.77624 0.0563443 5.82613 0.0895583 5.86812 0.131501C5.91011 0.173444 5.94338 0.223286 5.96602 0.278152C5.98865 0.333018 6.0002 0.391823 5.99999 0.451173C5.99978 0.510524 5.98783 0.569247 5.96482 0.623955C5.9418 0.678662 5.90818 0.728272 5.8659 0.769922L3.63507 3L5.86515 5.23008C5.90743 5.27173 5.94105 5.32134 5.96407 5.37605C5.98708 5.43075 5.99904 5.48948 5.99924 5.54883C5.99945 5.60818 5.9879 5.66698 5.96527 5.72185C5.94263 5.77671 5.90936 5.82656 5.86737 5.8685C5.82538 5.91044 5.7755 5.94366 5.7206 5.96622C5.66571 5.98879 5.60689 6.00027 5.54754 6C5.48819 5.99972 5.42948 5.9877 5.3748 5.96462C5.32012 5.94154 5.27055 5.90786 5.22895 5.86553L2.99962 3.63545L0.769545 5.86553C0.685044 5.94877 0.571075 5.99524 0.452464 5.99483C0.333852 5.99442 0.220209 5.94715 0.136288 5.86333C0.0523672 5.77951 0.00496864 5.66592 0.00441704 5.54731C0.00386544 5.4287 0.0502055 5.31468 0.133343 5.23008H0.134092Z"
                                fill="white"
                              />
                            </svg>
                          </SvgIcon>
                        </Box>
                      )
                    }
                    variant="outlined"
                    sx={{ marginRight: 1, marginBottom: 1 }}
                  />
                ))}
              </Stack>
            </Box>
          </Box>

          <Box className="skills-section" mb={2}>
            {workExperience.length > 0 && (
              <Box>
                <Typography className="title">Experience</Typography>
                <Stack>
                  {workExperience.map(
                    (experience, index) =>
                      experience.organization_name !== 'Work Gap' && (
                        <Box>
                          <FormControlLabel
                            key={index}
                            disabled={experience?.skills?.length >= 8}
                            control={<Checkbox onChange={handleWorkExperienceChange(experience)} />}
                            label={`${experience?.highest_designation || ''} at ${experience?.organization_name || ''}`}
                          />
                          {experience?.skills?.length >= 8 && (
                            <Typography
                              variant="caption"
                              display="block"
                              gutterBottom
                              className="error-text"
                              size="small"
                              component="p"
                              style={{ color: 'red' }}
                              marginLeft={'30px'}
                            >
                              {' '}
                              You've already added 8 skills to this experience, please remove other selected skills from
                              this experience to add new skills
                            </Typography>
                          )}
                        </Box>
                      )
                  )}
                </Stack>
              </Box>
            )}
          </Box>

          <Box className="skills-section" mb={2}>
            {education?.length > 0 && (
              <Box>
                <Typography className="title">Education</Typography>
                <Stack>
                  {education.map((data, index) => (
                    <Box>
                      <FormControlLabel
                        key={index}
                        disabled={data?.skills?.length >= 8}
                        control={<Checkbox onChange={handleEducationChange(data)} />}
                        label={`${data?.degree_received || ''} at ${data?.education_institute_name || ''}`}
                      />
                      {data?.skills?.length >= 8 && (
                        <Typography
                          variant="caption"
                          display="block"
                          gutterBottom
                          className="error-text"
                          size="small"
                          component="p"
                          style={{ color: 'red' }}
                          marginLeft={'30px'}
                        >
                          {' '}
                          You've already added 8 skills to this education, please remove other selected skills from
                          this education to add new skills
                        </Typography>
                      )}
                    </Box>
                  ))}
                </Stack>
              </Box>
            )}
          </Box>

          <Box className="skills-section">
            <Stack>
              {certificate?.length > 0 && (
                <Box>
                  <Typography className="title">Certification</Typography>
                  <Stack>
                    {certificate?.map((data, index) => (
                      <Box>
                        <FormControlLabel
                          key={index}
                          disabled={data?.skills?.length >= 8}
                          control={<Checkbox onChange={handleCertificateChange(data)} />}
                          label={`${data?.certificate_received || ''} at ${data?.education_institute_name || ''}`}
                        />
                        {data?.skills?.length >= 8 && (
                          <Typography
                            variant="caption"
                            display="block"
                            gutterBottom
                            className="error-text"
                            size="small"
                            component="p"
                            style={{ color: 'red' }}
                            marginLeft={'30px'}
                          >
                            {' '}
                            You've already added 8 skills to this certification, please remove other selected skills from
                            this certification to add new skills
                          </Typography>
                        )}
                      </Box>
                    ))}
                  </Stack>
                </Box>
              )}
            </Stack>
          </Box>
        </Box>

        <Box className="drawer-footer">
          <Stack direction="row" spacing={2}>
            <Button fullWidth size="large" className="cancel-btn" onClick={onClose}>
              Cancel
            </Button>
            <Button
              fullWidth
              variant="contained"
              size="large"
              disabled={isSubmitting}
              type="submit"
              className="update-btn"
            >
              Update
            </Button>
          </Stack>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default UpdateReleventSkillsDrawer;
