import React, { useState, useEffect } from "react";
import { useForm, FormProvider, Controller } from "react-hook-form";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { yupResolver } from '@hookform/resolvers/yup';
import CompanySchema from "models/CompanySchema";
import TypeAhead from 'components/TypeAhead';
import { endpoints } from 'utils/axios';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import {
  TextField,
  Button,
  Grid,
  Box,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Container,
  CircularProgress,
  Breadcrumbs,
  FormHelperText,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Paper,
} from "@mui/material";
import { useAuth } from "hooks/useAuthContext";
import { useSnackbar } from 'components/snackbar';
import { createCompany, updateCompany, getCompanyStaffList, getCompanyDetails } from "../../api/company";
import BusinessIcon from '@mui/icons-material/Business';

const CompanyMaster = () => {
  let { id } = useParams();
  const { user } = useAuth();
  const location = useLocation();
  const isRecruiter = user.user_type === 'recruiter';
  const isAdmin = user.user_type === 'admin';
  const profile = !!id;
  if (!id) {
    id = user?.id;
  }
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const fromAddCompany = location?.state?.prevPath;
  const [companyDetails, setCompanyDetails] = useState(null);
  const [staffList, setStaffList] = useState(null);
  const [loading, setLoading] = useState(true);
  const methods = useForm({
    defaultValues: {
      full_name: "",
      phone_number: "",
      email: "",
      industry_sector: "",
      type: "",
      website: "",
      incorporation_date: null,
      revenue: null,
      description: "",
      location: "",
      total_staff: null,
      company_overview: ""
    },
    mode: "onChange",
    resolver: yupResolver(CompanySchema(isRecruiter)),
  });

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    getValues,
    setError,
    control,
    formState: { errors, isDirty, isValid, dirtyFields, touched, isSubmitting },
  } = methods;

  useEffect(() => {
    if (fromAddCompany) {
      setLoading(false);
      return;
    }
    const fetchData = async () => {
      try {
        const companyResponse = await getCompanyDetails({ id });
        if (companyResponse?.success && companyResponse.data != null) {
          const { company, user } = companyResponse.data;
          if (company && user) {

            const revenueRange = company.revenue ? `${company.revenue.min}-${company.revenue.max}` : "";
            methods.setValue("revenue", revenueRange);

            const location = user.address ? user.address.city : "";
            methods.setValue("location", location);

            setCompanyDetails(companyResponse.data);
            Object.keys(company).forEach(key => methods.setValue(key, company[key]));
            Object.keys(user).forEach(key => methods.setValue(key, user[key]));
            enqueueSnackbar(companyResponse.message, { variant: 'success' });
          }
        } else {
          console.error('Error fetching company data:', companyResponse.data);
          enqueueSnackbar('Failed to fetch company data. Please try again later.', { variant: 'error' });
        }

        const staffResponse = await getCompanyStaffList({ id });

        if (staffResponse?.success && staffResponse.data != null) {
          setStaffList(staffResponse.data);
        } else {
          console.error(`Failed to fetch staff list. Status: ${staffResponse.status}`);
          enqueueSnackbar('Failed to fetch staff list. Please try again later.', { variant: 'error' });
        }
      } catch (error) {
        console.error('Error fetching company data:', error);
        enqueueSnackbar('An unexpected error occurred while fetching company data.', { variant: 'error' });
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id, enqueueSnackbar, methods]);


  const handleNavigation = (path) => {
    if (user) {
      navigate(path);
    } else {
      navigate('/login');
    }
  };

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center" style={{ height: '100vh' }}>
        <CircularProgress />
      </Grid>
    );
  }

  const handleRowClick = (staffId) => {
    handleNavigation(`/staff-details/${staffId}`);
  };


  const handleStartDateChange = date => {
    setValue('incorporation_date', date.$d);
  }

  const company_types = [
    "Micro, Small, and Medium Enterprises (MSMEs)",
    "Private Limited Company (Pvt Ltd)",
    "Public Limited Company (Ltd)",
    "Startup Company",
    "Holding Company and Subsidiary Company",
    "Joint Venture Company",
    "Government Company",
    "Foreign Company",
    "One Person Company (OPC)",
    "Limited Liability Partnership (LLP)",
    "Section 8 Company",
    "Producer Company",
  ];

  const onSubmit = async (data) => {
    try {
      let response;
      if (fromAddCompany) {
        response = await createCompany(data);
        if (response?.success && response.data != null) {
          enqueueSnackbar(response.message, { variant: 'success' });
          handleNavigation('/companies');
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
      } else {
        response = await updateCompany(data);
        if (response?.success && response.data != null) {
          enqueueSnackbar(response.message, { variant: 'success' });
          if (profile) {
            handleNavigation('/companies');
          } else {
            handleNavigation('/company-profile');
          }
        } else {
          enqueueSnackbar(response.message, { variant: 'error' });
        }
      }
    } catch (error) {
      console.error("Error:", error);
      enqueueSnackbar('An unexpected error occurred. Please try again later.', { variant: 'error' });
    }
  };

  return (
    <Box style={{ marginTop: '100px' }}>
      <Container >
        {isAdmin && <Breadcrumbs aria-label="breadcrumb" sx={{ marginTop: '1rem', ml: '1rem' }}>
          <Link underline="hover" color="inherit" to="/companies">
            Companies
          </Link>
          <Typography color="text.primary" to={fromAddCompany ? "/edit-profile" : "/company-profile"}>
            {profile ? "Edit Company" : "Add Company"}
          </Typography>
        </Breadcrumbs>}
        <Typography variant="h5" component="div" sx={{ margin: '1rem 0' }}>
          <BusinessIcon /> Company Information
          {!fromAddCompany && (
            <Grid container item xs={12} sx={{ mt: '0', justifyContent: 'flex-end' }} >
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleNavigation(`/add-staff/${id}`)} >
                Add Staff
              </Button>
            </Grid>
          )}
        </Typography>
        <FormProvider {...methods}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Grid item xs={12} sx={{ mt: '3rem' }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Controller
                    name="full_name"
                    control={control}
                    render={({ field }) => (
                      !fromAddCompany ? (
                        <TextField
                          {...field}
                          fullWidth
                          size="small"
                          label="Company Name"
                          InputProps={{ readOnly: true }}
                          value={field?.value}
                        />
                      ) : (
                        <TypeAhead
                          {...field}
                          api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.company}`}
                          multiple={false}
                          fullWidth
                          label="Company Name"
                          displayTextKey="name"
                          placeholder="Please enter your company name"
                          onChange={(e, value) => {
                            if(value){
                            field.onChange(value?.name);
                            setValue('company_overview', value?.company_overview || '');
                            setValue('description', value?.description || '');
                            }
                          }}
                          error={!!errors.full_name}
                          helperText={errors.full_name?.message}
                        />
                      )
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="phone_number"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Phone Number"
                        {...field}
                        error={!!errors.phone_number}
                        helperText={errors.phone_number?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="email"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        size="small"
                        label="Email ID"
                        error={!!errors.email}
                        helperText={errors.email?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="industry_sector"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        size="small"
                        label="Industry Sector"
                        {...field}
                        error={!!errors.industry_sector}
                        helperText={errors.industry_sector?.message}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    control={control}
                    name="location"
                    render={({ field }) => (
                      <TypeAhead
                        {...field}
                        api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.city}`}
                        multiple={false}
                        fullWidth
                        label="Select location"
                        placeholder="Select location"
                        onChange={(e, value) => {
                          field.onChange(value)
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth error={!!errors.type}>
                        <InputLabel size='small' id="type-label">Company Type</InputLabel>
                        <Select labelId="type-label" label="Company Type"{...field} size="small">
                          <MenuItem value="" disabled>Select Company Type</MenuItem>
                          {company_types.map((type) => (
                            <MenuItem key={type} value={type}>{type}</MenuItem>
                          ))}
                        </Select>
                        <FormHelperText>{errors.type?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="revenue"
                    control={control}
                    render={({ field }) => (
                      <FormControl fullWidth error={!!errors.revenue}>
                        <InputLabel id="revenue-label" size='small'>Revenue (Crs)</InputLabel>
                        <Select
                          labelId="revenue-label"
                          id="revenue"
                          value={field.value ? `${field.value?.min}-${field.value?.max}` : ""}
                          onChange={(e) => {
                            const [min, max] = e.target.value.split("-");
                            field.onChange({
                              min: isNaN(parseInt(min, 10)) ? null : parseInt(min, 10),
                              max: isNaN(parseInt(max, 10)) ? null : parseInt(max, 10),
                            });
                          }}
                          size='small'
                          label="Revenue (Crs)"
                        >
                          <MenuItem value="">Select Revenue Range</MenuItem>
                          <MenuItem value="0-5">0-5</MenuItem>
                          <MenuItem value="6-15">6-15</MenuItem>
                          <MenuItem value="16-30">16-30</MenuItem>
                          <MenuItem value="31-75">31-75</MenuItem>
                          <MenuItem value="76-200">76-200</MenuItem>
                          <MenuItem value="201-500">201-500</MenuItem>
                          <MenuItem value="501-1000">501-1000</MenuItem>
                          <MenuItem value="1000-2000">1000+</MenuItem>
                        </Select>
                        <FormHelperText>{errors.revenue?.message}</FormHelperText>
                      </FormControl>
                    )}
                  />
                </Grid>
                <Grid item xs={6}>
                  <Controller
                    name="website"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        size='small'
                        {...field}
                        error={!!errors.website}
                        helperText={errors.website && errors.website.message}
                        fullWidth
                        label="Website"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Controller
                    name="total_staff"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size='small'
                        type="number"
                        error={!!errors.total_staff}
                        helperText={errors.total_staff?.message}
                        fullWidth
                        label="Total Staff"
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Controller
                    name="incorporation_date"
                    fullWidth
                    control={control}
                    render={({ field }) => (
                      <DatePicker
                        {...field}
                        value={dayjs(field?.value)}
                        autoOk={true}
                        label='Incorporation Date'
                        closeOnSelect={true}
                        format='DD/MM/YYYY'
                        disableFuture
                        onChange={(date) => handleStartDateChange(date)}
                        slotProps={{
                          textField: {
                            size: 'small',
                            error: !!errors?.incorporation_date,
                            helperText: errors?.incorporation_date?.message,
                          },
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="company_overview"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        size='small'
                        label="Company Overview (optional)"
                        multiline
                        onKeyDown={(event) => {
                          if (event.key === "Enter" && !event.shiftKey) {
                            event.preventDefault();
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        size='small'
                        label="Description (optional)"
                        multiline
                        onKeyDown={(event) => {
                          if (event.key === "Enter" && !event.shiftKey) {
                            event.preventDefault();
                          }
                        }}
                      />
                    )}
                  />
                </Grid>
                <Grid container item xs={12} sx={{ mt: '1rem', justifyContent: 'center' }} >
                  <Button variant="contained" className="next-btn" type="submit" disabled={isSubmitting} size="medium" >
                    {isSubmitting ? "Submitting.." : (!fromAddCompany ? "Update" : "Save")}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </form>
        </FormProvider>
        {staffList && staffList.length > 0 && (
          <Grid item xs={12}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>#</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Business Function</TableCell>
                    <TableCell>Designation</TableCell>
                    <TableCell>Email ID</TableCell>
                    <TableCell>Phone No.</TableCell>
                    <TableCell>TJ Sourced?</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {staffList && staffList.map((staff, index) => (
                    <TableRow key={staff._id} onClick={() => handleRowClick(staff._id)}>
                      <TableCell>{index + 1}</TableCell>
                      <TableCell>{staff?.name || "N/A"}</TableCell>
                      <TableCell>
                        {staff?.business_function || "N/A"}
                      </TableCell>
                      <TableCell>{staff?.designation || "N/A"}</TableCell>
                      <TableCell>{staff?.email || "N/A"}</TableCell>
                      <TableCell>{staff?.phone_number || "N/A"}</TableCell>
                      <TableCell>
                        {staff.is_tj_source ? "Yes" : "No"}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        )}
      </Container>
    </Box>
  );
};

export default CompanyMaster;
