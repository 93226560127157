import React, { useRef, useEffect } from 'react';
import TextField from '@mui/material/TextField';

const DynamicTextField = ({ label, rowsMax, value, onChange, ...rest }) => {
  const inputRef = useRef(null);

  useEffect(() => {
    const textarea = inputRef.current;
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;
  }, [value]);

  const handleOnChange = (event) => {
    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <TextField
      multiline
      size='small'
      rows={1}
      rowsMax={rowsMax} 
      label={label}
      InputProps={{
        inputComponent: 'textarea', 
        inputProps: {
          ref: inputRef,
          style: { overflow: 'hidden', resize: 'none' },
          onChange: handleOnChange,
        },
      }}
      value={value}
      {...rest}
    />
  );
};

export default DynamicTextField;
