import * as yup from 'yup';


// Schema for validation
export const AddAdminSchema = yup.object().shape({
    user_type: yup.string().required('Please select user type'),
    full_name: yup.string().required('Please enter name'),
    phone_number: yup
    .string()
    .required('Please enter phone number.')
    .test(
        'len',
        'Phone number must be exactly 10 digits.',
        val => val && val.length === 10 && /^\d+$/.test(val)
    ),
    email: yup.string().email('Please enter correct email id.').required('Please enter email id.')

});

export const AddCompanySchema = yup.object().shape({
    user_type: yup.string().required('Please select user type'),
    company: yup.object().shape({
        id: yup.string().required(),
        name: yup.string().required('Company is required'),
    }),
    phone_number: yup
    .string()
    .required('Please enter phone number.')
    .test(
        'len',
        'Phone number must be exactly 10 digits.',
        val => val && val.length === 10 && /^\d+$/.test(val)
    ),
    email: yup.string().email('Please enter correct email id.').required('Please enter email id.')

});
