import {
    Box,
    Card,
    CardContent,
    Divider,
    Dialog,
    Grid,
    Skeleton,
    Stack,
    SvgIcon,
    Typography,
    ListItem,
    List,
    ListItemText, Button, Icon
  } from '@mui/material';
  import { useAuth } from 'hooks/useAuthContext';
  import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
  import { styled } from '@mui/material/styles';
  import React, { useEffect, useState } from 'react';
  import dayjs from 'dayjs';
  const resume = `${process.env.REACT_APP_HOMEPAGE}assets/resume.png`;
  import { changeNameFormat } from 'utils/Utils';

  const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        },
  }));
  
  const ResumeTemplate = ({summaryData,educationData,certificateData,workExperienceData, customSection,financeTechologiesData }) => {
    const imgLogo = `${process.env.REACT_APP_HOMEPAGE}assets/default.png`;
    
    const certificationIcon = `${process.env.REACT_APP_HOMEPAGE}assets/certification-img.png`;
    const { user, updateContext } = useAuth();
    const [educations, setEducations] = useState(educationData ?? []);
    const [workHistory, setWorkHistory] = useState(workExperienceData ?? []);
    const [certificate, setCertificate] = useState(certificateData ?? []);
    const [customSections, setCustomSection] = useState(customSection ?? []);
    const [chooseResume, setChooseResume] = React.useState(false);
  
    const [open, setOpen] = useState(false);
  
    useEffect(() => {
      setEducations(educationData);
      setWorkHistory(workExperienceData);
      setCertificate(certificateData);
      setCustomSection(customSection);
    }, [educationData, workExperienceData, certificateData, customSection]);
  
    const handleNavigation = () => {
      const data = {
        summaryData: summaryData,
        educationData: educationData?.filter(item => item?._id),
        workExperienceData: workExperienceData?.filter(item => item?._id),
        customSection: customSection,
        certificateData: certificateData?.filter(item => item?._id),
        templateType: templateType,
      };
      navigate('/resume', { state: data });
    };
  
//     const handlePreviewResume = () => {
//       setOpen(true);
//     };
  
//     const handleClose = () => {
//       setOpen(false);
//     };
    
//     const handleChangeChooseResume = () => {
//       setChooseResume((prev) => !prev);
//   };
  
    return (
        
        
          <CardContent>
            <Box>
              <Stack direction="row" spacing={1} alignItems="start">
                <Box sx={{ width: '100%' }}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Box className="resume-profile-pic">
                      <img alt="profile" width="50px" height="50px" src={user?.photo || imgLogo}
                       onError={(e) => (e.target.src = imgLogo)}
                      />
                    </Box>
                    <Box>
                      <Typography className="candidate-name" variant="p" gutterBottom>
                        {changeNameFormat(user?.name)}
                      </Typography>
                      <Typography className="candidate-email-no" variant="body2" gutterBottom>
                        {user?.email} | {user?.phone}
                      </Typography>
                    </Box>
                  </Stack>
                  {summaryData && <>
                    <Divider
                      sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '0.5rem', marginBottom: '0.5rem' }}
                    />
                    <Box>
                      <Typography className="resume-info-header-txt" variant="body2" gutterBottom>
                        Professional Summary
                      </Typography>
                      <Typography
                        className="candidate-personal-info"
                        variant="body2"
                        gutterBottom
                        style={{ marginTop: '10px' }}
                      >
                        {summaryData}
                      </Typography>
                    </Box>
                  </>
                  }
                </Box>
              </Stack>
            </Box>
            {educations?.filter(item => item._id).length !== 0 && (
              <Box>
                <Divider
                  sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '0.5rem', marginBottom: '0.5rem' }}
                />
                <Box>
                  <Typography className="resume-info-header-txt" variant="body2" gutterBottom>
                    Education
                  </Typography>
                  {educations
                    .filter(item => item._id)
                    .map((item, index) => (
                      <React.Fragment key={item._id || index}>
                        <Typography className="resume-info-header-main" variant="body2" gutterBottom>
                          {item.education_institute_name || ''}
                        </Typography>
                        <Box mb={1} className="candidate-personal-info">
                          <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                            <Box>
                              <Stack className="list-item" direction="row" spacing={2}>
                                <Box>
                                  <Typography className="degree-name" variant="p" gutterBottom>
                                    {item.degree_received || ''}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography className="degree-type" variant="p" gutterBottom>
                                    {item.field_of_study || ''}
                                  </Typography>
                                </Box>
                              </Stack>
                            </Box>
                            <Typography className="year-of-passout" variant="p" gutterBottom>
                              {' '}
                              {dayjs(item.education_from).format('YYYY')}-
                              {dayjs(item.education_to).format('YYYY') || '-'}
                            </Typography>
                          </Stack>
                        </Box>
                        <Box className="personal-edu-text">
                          <List sx={{ paddingTop: 0, paddingLeft: '1.5rem', listStyleType: 'disc' }}>
                            {item.key_accomplishments &&
                              item.key_accomplishments.map((accomplishment, index) => (
                                <ListItem sx={{ display: 'list-item' }} disablePadding key={accomplishment || index}>
                                  <Typography className="educational-info-txt" variant="body2" gutterBottom>
                                    {accomplishment}
                                  </Typography>
                                </ListItem>
                              ))}
                          </List>
                        </Box>
                      </React.Fragment>
                    ))}
                </Box>
              </Box>
            )}

            {certificate?.filter(item => item._id).length !== 0 && (
              <Box>
                <Divider
                  sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '0.5rem', marginBottom: '0.5rem' }}
                />
                <Box >
                  <Typography className="resume-info-header-txt" variant="body2" gutterBottom>
                    Certification
                  </Typography>
                  {certificate?.filter(item => item._id)
                    .map((item, index) => (
                      <React.Fragment key={item._id || index}>
                        <Typography className="resume-info-header-main" variant="body2" gutterBottom>
                          {item.education_institute_name || ''}
                        </Typography>
                        <Box className="candidate-personal-info">
                          <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                            <Box>
                              <Stack className="list-item" direction="row" spacing={2}>
                                <Box>
                                  <Typography className="degree-name" variant="p" gutterBottom>
                                    {item.certificate_received || ''}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography className="degree-type" variant="p" gutterBottom>
                                    {item.field_of_study || ''}
                                  </Typography>
                                </Box>
                              </Stack>
                            </Box>  
                            <Box>
                              <Stack direction="row" alignItems={'center'} spacing={1}>
                                <Box>
                                  <Box component="img" alt="certification" src={certificationIcon} />
                                </Box>
                                <Typography className="year-of-passout" variant="p" gutterBottom>
                                  {' '}
                                  {dayjs(item.education_to).format('YYYY') || '-'}
                                </Typography>
                              </Stack>
                            </Box>
                          </Stack>
                        </Box>
                      </React.Fragment>
                    ))}
                </Box>
              </Box>
            )}

            {workHistory?.filter(item => item._id).length !== 0 && (
              <Box>
                <Divider
                  sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '0.5rem', marginBottom: '0.5rem' }}
                />
                <Box >
                  <Typography className="resume-info-header-txt" variant="body2" gutterBottom>
                    Work Experience
                  </Typography>
                  {workHistory?.filter(item => item._id)
                    .map((data, index) => (
                      <Box key={data._id || index}>
                        {data.organization_name === 'Work Gap' ? (
                          data.show_in_resume && (
                            <Box>
                              <Typography className="resume-info-header-main" variant="body2" gutterBottom>
                                {data.highest_designation}
                              </Typography>
                              <Box mb={1} className="candidate-personal-info">
                                <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                                  <Box>
                                    <Stack className="list-item" direction="row" spacing={2}>
                                      <Typography className="company-name" variant="p" gutterBottom>
                                        {data.gap_explaination}
                                      </Typography>
                                    </Stack>
                                  </Box>
                                  <Typography className="working-year" variant="p" gutterBottom>
                                    {dayjs(data?.work_history_from).format('MMM YYYY')} -{' '}
                                    {data?.present ? 'Present' : dayjs(data?.work_history_to).format('MMM YYYY')}
                                  </Typography>
                                </Stack>
                              </Box>
                              <Box className="personal-edu-text">
                                <List sx={{ paddingTop: 0, paddingLeft: '1.5rem', listStyleType: 'disc' }}>
                                  {data.key_accomplishments &&
                                    data.key_accomplishments.map((accomplishment, keyIndex) => (
                                      <ListItem sx={{ display: 'list-item' }} disablePadding key={accomplishment || keyIndex}>
                                        <Typography className="educational-info-txt" variant="body2" gutterBottom>
                                          {accomplishment}
                                        </Typography>
                                      </ListItem>
                                    ))}
                                </List>
                              </Box>
                            </Box>
                          )
                        ) : (
                          <Box>
                            <Typography className="resume-info-header-main" variant="body2" gutterBottom>
                              {data.highest_designation}
                            </Typography>

                            <Box mb={1} className="candidate-personal-info">
                              <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                                <Box>
                                  <Stack className="list-item" direction="row" spacing={2}>
                                    <Box>
                                      <Typography className="company-name" variant="p" gutterBottom>
                                        {data.organization_name}
                                      </Typography>
                                    </Box>
                                    <Box>
                                      <Typography className="work-type" variant="p" gutterBottom>
                                        {data.job_type}
                                      </Typography>
                                    </Box>
                                  </Stack>
                                </Box>
                                <Typography className="working-year" variant="p" gutterBottom>
                                  {dayjs(data?.work_history_from).format('MMM YYYY')} -{' '}
                                  {data?.present ? 'Present' : dayjs(data?.work_history_to).format('MMM YYYY')}
                                </Typography>
                              </Stack>
                            </Box>
                            <Box className="personal-edu-text">
                              <List sx={{ paddingTop: 0, paddingLeft: '1.5rem', listStyleType: 'disc' }}>
                                {data.key_accomplishments &&
                                  data.key_accomplishments.map((accomplishment, keyIndex) => (
                                    <ListItem sx={{ display: 'list-item' }} disablePadding key={accomplishment || keyIndex}>
                                      <Typography className="educational-info-txt" variant="body2" gutterBottom>
                                        {accomplishment}
                                      </Typography>
                                    </ListItem>
                                  ))}
                              </List>
                            </Box>
                          </Box>
                        )}
                      </Box>
                    ))}
                </Box>
              </Box>
            )}

            {financeTechologiesData.length > 0 && (
              <Box>
                <Divider
                  sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '0.5rem', marginBottom: '0.5rem' }}
                />
                <Box>
                  <Typography className="resume-info-header-txt" variant="body2" gutterBottom>
                    Finance Technical Competencies
                  </Typography>
                  <Box className="personal-edu-text" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                    {financeTechologiesData.map((techSkills, techIndex) => (
                      <Typography
                        key={techIndex}
                        className="educational-info-txt"
                        variant="body2"
                        gutterBottom
                        sx={{ marginRight: '10px' }}
                      >
                        {techIndex + 1}. {techSkills}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              </Box>
            )} 
            {workHistory?.filter(item => item._id).length !== 0 && (
              <Box>
                <Divider
                  sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '0.5rem', marginBottom: '0.5rem' }}
                />
                <Box className="candidate-highlighted-skills-sec">
                  <Box mb={1.5}>
                    <Typography className="resume-info-header-txt" variant="p" gutterBottom>
                      Highlighted Skills
                    </Typography>
                  </Box>
                  <Card sx={{ minWidth: 275 }}>
                    <CardContent sx={{ padding: 0 }}>
                      <Box mb={1} className="header-block" sx={{ padding: '0 0.5rem' }}>
                        <Grid container spacing={1}>
                          <Grid item xs={2}>
                            <Typography variant="p" gutterBottom>
                              Skill
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography variant="p" gutterBottom>
                              Sub-Skill
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="p" gutterBottom>
                              Used / Learnt at
                            </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="p" gutterBottom>
                              Years Used
                            </Typography>
                          </Grid>
                        </Grid>
                      </Box>
                      {workHistory?.map((data, index) =>
                        data.organization_name !== 'Work Gap' && (
                          <Box className="text-block" sx={{ padding: '0 0.5rem' }} key={data._id || index}>
                            {index !== 0 && (
                              <Divider
                                sx={{ borderWidth: '0.01', marginTop: '0.5rem', marginBottom: '0.5rem' }}
                              />
                            )}
                            <Grid container alignItems={'center'} spacing={1}>
                              <Grid item xs={2}>
                                <Typography variant="p" gutterBottom>
                                  {data.highest_designation}
                                </Typography>
                              </Grid>
                              <Grid item xs={4}>
                                <Typography variant="p" gutterBottom>
                                  {data?.skills?.join(',')}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography variant="p" gutterBottom>
                                  {data.organization_name}
                                </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography variant="p" gutterBottom>
                                  {data.work_experience || 'Present'}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Box>
                        )
                      )}
                    </CardContent>
                  </Card>
                </Box>
              </Box>
            )}

            {customSections.length > 0 &&
              customSections.map((section, keyIndex) => (
                section?.key && section?.message && section?.message !== '<p><br></p>' ?(
                <Box key={keyIndex}>
                  <Divider
                    sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '0.5rem', marginBottom: '0.5rem' }}
                  />
                  <Typography className="resume-info-header-txt" variant="body2" gutterBottom>
                    {section.key}
                  </Typography>
                  <Box>
                    <Box className="personal-work-text">
                            <Typography className="work-info-txt" variant="body2" gutterBottom>
                            <div dangerouslySetInnerHTML={{ __html: section?.message }} />
                            </Typography>
                    </Box>
                  </Box>
                </Box>
                ) : null
              ))}
          </CardContent>
    );
  };
  
  export default ResumeTemplate;
  