import React, { useState } from 'react';
import { Box, Button, Card, CardContent, Link, IconButton, InputLabel, OutlinedInput, Stack, SvgIcon, Typography, TextField, FormControl, InputAdornment, Divider } from '@mui/material'
import { MuiOtpInput } from 'mui-one-time-password-input'


const CustomValue = ({ onClose, onCustomValue, label }) => {
    const [customValue, setCustomValue] = useState('');

    const handleValue = (e) => {
        onCustomValue(e, customValue);
    };

    return (
        <Card className="otp-card-wrapper" variant="outlined">
            <CardContent>
                <IconButton className="close-dialog-btn" aria-label="delete" color="primary" onClick={onClose}>
                    <SvgIcon sx={{ width: '20px', height: '20px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g clipPath="url(#clip0_456_20993)">
                                <path d="M7 7L17 17M17 7L7 17L17 7Z" stroke="#111827" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_456_20993">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </SvgIcon>
                </IconButton>



                <Box mb={3} sx={{ textAlign: 'center' }}>
                    <Typography mb={1} className="title" component="h6">
                        Enter {label || 'Custom Value'}
                    </Typography>
                </Box>
                <Stack direction="row" alignItems={'center'} spacing={3} marginBottom={3}>
                    <TextField
                        autoFocus
                        margin="dense"
                        size="small"
                        label={label}
                        fullWidth
                        value={customValue}
                        onChange={(e) => setCustomValue(e.target.value)}
                    />
                </Stack>

                <Stack direction="row" alignItems={'center'} spacing={3}>
                    <Button fullWidth className="otp-btn" variant="outlined" size="medium" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button fullWidth className="otp-btn" variant="contained" size="medium" onClick={handleValue}>
                        Done
                    </Button>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default CustomValue
