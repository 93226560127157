import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const useAuthDispatch = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthDispatch must be used within a AuthStateProvider');
  }
  return context;
};

export const AuthStateProvider = ({ children }) => {
  const userAccount = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : null;
  const [account, setAccount] = useState(userAccount?.authorization ? userAccount : null);

  const setLocalStorage = (key, value) => {
    localStorage.setItem(key, JSON.stringify(value))
    setAccount(JSON.parse(localStorage.getItem(key)))
  }

  const updateLocalStorage = (key, value) => {
    const updatedData = { ...account || {}, ...value };
    localStorage.setItem(key, JSON.stringify(updatedData));
    if (key === 'auth') {
      setAccount(updatedData);
    }
  };
  

  return (
    <AuthContext.Provider value={{ account, setAccount, setLocalStorage, updateLocalStorage }}>
      {children}
    </AuthContext.Provider>
  );
};