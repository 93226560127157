import * as yup from 'yup';

// Schema for validation
export const NewJobSchema =(alignment) =>yup.object().shape({
    title: yup.string().required('Title is required').matches(/^[a-zA-Z\-&\[\]\{\}\(\), ]*$/, 'Title contains invalid characters. Only letters, spaces, dashes (-), ampersands (&), square brackets ([]), curly braces ({}), and parentheses (()) are allowed.";'),
    function: yup.string().required('Function is required'),
    role: yup.string().required('Designation is required'),
    description: yup.string().required('Job description is required'),
    qualifications: yup.array().of(
        yup.object().shape({
            names: yup.array().of(yup.string()).min(1, "Please select atleast one qualification."),
            option: yup.number()
        })
    ).min(1, "Please add atleast one qualification required for this job."),
    question: yup.array().of(yup.string()).min(1, 'At least one question is required'),
    company: yup.string().required('Company name is required'),
    // certifications: yup.array().of(yup.string().required("Certification is required")),
    // key_skills: yup.array().of(yup.string()).min(5, 'At least five key skills are required'),
    min: yup.string().required('Min experience is required'),
    max:yup.string().required('Max experience is required'),
    work_mode: yup.string().required('Please select option. Job format is required'),
    company_overview: yup.string().required('Please enter company overview'),
    job_overview: yup.string().required('Please enter a brief job summary'),
    job_type: yup.string().required('Please select option. Job type is required'),
    job_shift: yup.string().required('Please select option. Job shift is required'),
    industry_sector: yup.string().required('Please enter job belong which industry sector.'),
    compensation: yup.string().required('Please select compensation structure.'),
    notice_period: yup.string().required('Please select option. Notice Period is required'),
    location: yup.string().required('Job location is required'),
    // start_date: yup.date().required('Start date is required'),
    // reporting_to: yup.string().required('Reporting To is required'),
    direct_reports: yup.string().required('Number of people reporting is required').matches(/^[0-9]+$/, 'Direct Reports must be a number'),
    salary: yup.string().required('Salary is required').matches(/^[0-9]+$/, 'Salary must be a number'),
    job_responsibilities:yup.string().required('Please enter job responsibilities'),
    external_link: alignment === 'tj-jobs'? yup.string(): yup.string().url('Invalid URL').required('Please enter job appliction link'),
    company_identification_number:yup.string().required('Please enter company Identification Number'),
    company_website:yup.string().url('Invalid URL').required('Company website is required'),
    // gender_preference: yup.string().required('Please select option. gender preference is required'),
    // disability_preference: yup.string().required('Please select option. disability preference is required'),
    is_company_name_confidential:yup.boolean('Please select option.keep company name confidentail'),
    is_compensation_confidential:yup.boolean('Please select option.keep compensation confidentail'),
});

export const NewCompanyJobSchema = (alignment)=> yup.object().shape({
    title: yup.string().required('Title is required').matches(/^[a-zA-Z\s.,]+$/, 'Title must contain only letters and spaces'),
    company: yup.object().required('Company name is required'),
    function: yup.string().required('Function is required'),
    role: yup.string().required('Designation is required'),
    description: yup.string().required('Job description is required'),
    job_overview: yup.string().required('Please enter a brief job summary'),
    qualifications: yup.array().of(
        yup.object().shape({
            names: yup.array().of(yup.string()).min(1, "Please select atleast one qualification."),
            option: yup.number()
        })
    ).min(1, "Please select atleast one qualification required for this job."),
    question: yup.array().of(yup.string()).min(1, 'At least one question is required'),
    // certifications: yup.array().of(yup.string().required("Certification is required")),
    // key_skills: yup.array().of(yup.string()).min(5, 'At least five key skills are required'),
    min: yup.string().required('Min experience is required'),
    max:yup.string().required('Max experience is required'),
    work_mode: yup.string().required('Job format is required'),
    notice_period: yup.string().required('Notice Period is required'),
    location: yup.string().required('Job location is required'),
    // start_date: yup.date().required('Start date is required'),
    // reporting_to: yup.string().required('Reporting To is required'),
    direct_reports: yup.string().required('Number of people reporting is required').matches(/^[0-9]+$/, 'Direct Reports must be a number'),
    salary: yup.string().required('Salary is required').matches(/^[0-9]+$/, 'Salary must be a number'),
    job_shift: yup.string().required('Please select option. Job shift is required'),
    job_responsibilities:yup.string().required('Please enter job responsibilities'),
    external_link: alignment === 'tj-jobs'? yup.string(): yup.string().url('Invalid URL').required('Please enter job appliction link'),
    company_identification_number:yup.string().required('Please enter company Identification Number'),
    company_website:yup.string().url('Invalid URL').required('Company website is required'),
    // gender_preference: yup.string().required('Please select option. gender preference is required'),
    // disability_preference: yup.string().required('Please select option. disability preference is required'),
    is_company_name_confidential:yup.boolean('Please select option.keep company name confidentail'),
    is_compensation_confidential:yup.boolean('Please select option.keep compensation confidentail'),
});
