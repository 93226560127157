import { Box, Checkbox, FormControlLabel, Link, Stack, Typography, Dialog } from '@mui/material'
import React, { useState } from 'react'

import { useForm, register, watch, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { updateFinanceTechnologies } from 'api/users';
import AddTechnology from './AddTechnology'; 
import { useSnackbar } from 'components/snackbar';

const FinanceTechnicalCompetencies = ({ financeTechologiesData, onFinanceDataChange }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [allFinanceTechnologiesList, setAllFinanceTechnologiesList] = useState([
        "QuickBooks",
        "Sage Intacct",
        "SAP Concur",
        "Oracle Financials",
        "Reval",
        "NetSuite",
        "Xero",
        "FreshBooks",
        "Wave Accounting",
        "Zoho Books",
        "WFl Management",
        "Kyriba",
        "BlackLine",
        "MINT",
        "Yodlee"
    ])
    const [open, setOpen] = useState(false);
    const [checkedState, setCheckedState] = useState(financeTechologiesData);

    const [bindTechnologyList, setBindTechnologyList] = useState(financeTechologiesData);
    const [selectedSoftware, setSelectedSoftware] = useState(
        financeTechologiesData.map(tech => tech)
    );

    const [showFlag, setShowFlag] = useState(false);

    const handleOpenOtpDialog = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAddMoreTechnology = async (moreTechnologies) => {
        setCheckedState(moreTechnologies);
        setSelectedSoftware(moreTechnologies);
        await onSubmit("", false, moreTechnologies, true);
    }

    const handleCheckboxChange = async (name, isChecked, index) => {
        let updatedCheckedState = !isChecked ? checkedState.filter(item => item !== name) : [...checkedState, name];

        if(updatedCheckedState && updatedCheckedState.length){
            setCheckedState(updatedCheckedState);
            setSelectedSoftware(updatedCheckedState);
        } else {
            setCheckedState([]);
            setSelectedSoftware([]);
        }
        await onSubmit(name, isChecked, [], false);
    };

    const onSubmit = (async (item, ischecked, data, isAddMore) => {
        if (selectedSoftware && selectedSoftware.length <= 12) {
            const response = await updateFinanceTechnologies({ item, ischecked, data });
            if (response?.success === true && response.data) {
                if(isAddMore) {
                    setCheckedState(response.data);
                    setSelectedSoftware(response.data);
                    setBindTechnologyList(response.data);
                }
                onFinanceDataChange();
            } else {
                enqueueSnackbar(response.message, { variant: 'error' });
            }
        } else {
            setShowFlag(true);
        }
    });

    return (
        <Box>
            <Box mb={2}>
                <Typography className="step-heading" variant="h6" gutterBottom>
                    Finance Technical Competencies (Optional)
                </Typography>
                <Typography className="step-info" variant="body2" gutterBottom>
                    Please select or enter upto 12 finance technical skills in which you have reasonable proficiency
                </Typography>
            </Box>

            <Box className="ftc-checkbox-wrapper">
                <Stack direction="row" flexWrap="wrap" mb={1}>
                    {bindTechnologyList && bindTechnologyList.length > 0 ? 
                        bindTechnologyList.map((software, index) => (
                            <FormControlLabel
                                key={index}
                                control={
                                    <Checkbox
                                        checked={checkedState.includes(software)}
                                        onChange={(e) => handleCheckboxChange(software, e.target.checked, index)}
                                    />
                                }
                                label={software}
                            />
                        )) :
                         allFinanceTechnologiesList.slice(0,12).map((software, index) => (
                                <FormControlLabel
                                    key={index}
                                    control={
                                        <Checkbox
                                            checked={checkedState.includes(software)}
                                            onChange={(e) => handleCheckboxChange(software, e.target.checked, index)}
                                        />
                                    }
                                    label={software}
                                />
                            ))
                    }
                </Stack>
                {showFlag && (
                    <Typography style={{ color: 'red', fontSize: 'small' }} mb={2}>
                        Please select upto 12 finance technical skills.
                    </Typography>
                )}
                <Link className="add-more-technology-link" underline="none" size="small" onClick={handleOpenOtpDialog}>
                    Select from complete list
                </Link>
            </Box>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="otp-dialog-wrapper"
            >
                <AddTechnology onClose={handleClose} technology={allFinanceTechnologiesList} selectedsoftware={selectedSoftware} onAddTechnology={handleAddMoreTechnology} />
            </Dialog>
        </Box>
    )
}

export default FinanceTechnicalCompetencies
