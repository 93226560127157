import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Box, Container, Paper, Checkbox, TextField, IconButton, Stack, Table, TableBody, TableCell, Grid, CircularProgress, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import TypeAhead from 'components/TypeAhead';
import { endpoints } from 'utils/axios';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import { useSnackbar } from 'components/snackbar';
import { useAuth } from 'hooks/useAuthContext';
import exportFromJSON from 'export-from-json';
import SearchIcon from '@mui/icons-material/Search';
import { getAllJobList, deleteJob } from 'api/job';

const AllJobList = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [jobs, setJobs] = useState([]);
  const [selectedJobs, setSelectedJobs] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [searchParameter, setSearchParameter] = useState(false);
  const { user } = useAuth();

  const fetchJobs = async (params = {}) => {
    try {
      const response = await getAllJobList(params);
      if (response?.success && response.data) {
        setJobs(response.data || []);
      } else {
        enqueueSnackbar('Failed to fetch jobs', { variant: 'error' });
        console.error('Failed to fetch jobs:', response?.message);
      }
    } catch (error) {
      console.error('Error fetching jobs:', error);
      enqueueSnackbar('Error fetching jobs', { variant: 'error' });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  const defaultValues = {
    jobId: "",
    jobName: "",
    skills: [],
    experience: "",
    salary: "",
    education: [],
    location: [],
    companyName: "",
    function: [],
    role: [],
  };

  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });

  const { control, reset, handleSubmit, formState: { isSubmitting }, } = methods;

  const onSubmit = handleSubmit(async (formData) => {
    await fetchJobs(formData);
  });

  const downloadSelectedJobsData = () => {
    const selectedJobsData = jobs.filter(job => selectedJobs.includes(job._id));
    const jobList = selectedJobsData.map(job => ({
      'ID': job['jobid'],
      'Title': job['title'],
      'Company Name': job['company'],
      'Location': job['location'],
      'Function': job['function'],
      'Role': job['role'],
      'Education': job['qualifications'].map(qualification => qualification.values.join(qualification.option ? ' , ' : ' | ')).join(' | ')
    }));
    const fileName = `jobList_${Date.now()}`;
    const exportType = exportFromJSON.types.csv
    exportFromJSON({ data: jobList, fileName, exportType });
  };

  const handleCheckboxChange = (event, jobId) => {
    if (event.target.checked) {
      setSelectedJobs([...selectedJobs, jobId]);
    } else {
      setSelectedJobs(selectedJobs.filter(id => id !== jobId));
    }
  };

  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      setSelectedJobs(jobs.map(job => job._id));
    } else {
      setSelectedJobs([]);
    }
  };

  const handleEditButton = (e, id) => {
    e.stopPropagation();
    navigate(`/job-form`, { state: id })
  }
  const handleSearch = () => {
    setSearchParameter(prevState => !prevState);
    if (searchParameter) {
      reset(defaultValues);
    }
  };

  const handleRowClick = (e, id) => {
    e.stopPropagation();
    navigate(`/job-applicants/${id}`)
  };

  const handleDeleteJob = async (id, event) => {
    event.stopPropagation();
    const response = await deleteJob({ id });
    if (response?.success) {
      enqueueSnackbar('Job deleted successfully', { variant: 'success' });
      await fetchJobs();
    } else {
      enqueueSnackbar('Failed to delete job', { variant: 'error' });
    }
  }

  return (
    <FormProvider {...methods}>
      <Container style={{ marginTop: '80px' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h5" component="div" sx={{ marginBottom: '1rem' }}>
            Jobs - TJ Jobs
          </Typography>
          {user.user_type !== 'user' && (
            <Button variant="contained" onClick={() => navigate('/job-form')}>Create New Job</Button>)}
        </Stack>
        {user.user_type !== 'user' && (
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
            <Button variant="contained" onClick={handleSearch}>
              {searchParameter ? "close search" : "search job"}
            </Button>
            <Button variant="contained" onClick={downloadSelectedJobsData} disabled={selectedJobs?.length === 0}>Download List</Button>
          </Stack>)}
        <Grid container spacing={2}>
          {(user.user_type !== 'user' && searchParameter) && (
            <Grid item xs={12} md={4}>
              <Paper elevation={3} sx={{ padding: '1rem' }}>
                <Typography variant="h6" sx={{ marginBottom: '0.5rem' }}>Search Parameters</Typography>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <Stack spacing={2}>
                    <Button type="submit" variant="contained" disabled={isSubmitting}> <SearchIcon /></Button>
                    <Controller
                      control={control}
                      name="jobId"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size='small'
                          fullWidth
                          label="Job ID"
                          variant="outlined"
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="jobName"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          size='small'
                          label="Job Name"
                          variant="outlined"
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="skills"
                      render={({ field }) => (
                        <TypeAhead
                          {...field}
                          api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.skills}`}
                          multiple={true}
                          fullWidth
                          displayTextKey="name"
                          label="Select Expertise/Skills"
                          placeholder="Select Expertise/Skills"
                          onChange={(e, value) => field.onChange(value)}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="experience"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          size='small'
                          fullWidth
                          label="Enter min experience"
                          variant="outlined"
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="salary"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          size='small'
                          label="Enter the min salary"
                          variant="outlined"
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="education"
                      render={({ field }) => (
                        <TypeAhead
                          {...field}
                          api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.degree}`}
                          label="Select Qualification"
                          placeholder="Select Qualification"
                          multiple={true}
                          fullWidth
                          displayTextKey='name'
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="location"
                      render={({ field }) => (
                        <TypeAhead
                          {...field}
                          api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.city}`}
                          label="Select Location"
                          placeholder="Select locations"
                          displayTextKey='name'
                          multiple={true}
                          fullWidth
                          onChange={(e, value) => field.onChange(value)}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="companyName"
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          size='small'
                          label="Company Name"
                          variant="outlined"
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="function"
                      render={({ field }) => (
                        <TypeAhead
                          {...field}
                          api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.function}`}
                          multiple={true}
                          fullWidth
                          displayTextKey="name"
                          label="Select functions"
                          placeholder="Select functions"
                          onChange={(e, value) => field.onChange(value)}
                        />
                      )}
                    />
                    <Controller
                      control={control}
                      name="role"
                      render={({ field }) => (
                        <TypeAhead
                          {...field}
                          api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.role}`}
                          label="Select Role/Desgination"
                          placeholder="Select Role/Desgination"
                          multiple={true}
                          fullWidth
                          displayTextKey='name'
                          onChange={(e, value) => field.onChange(value)}
                        />
                      )}
                    />
                  </Stack>
                </form>
              </Paper>
            </Grid>
          )}
          <Grid item xs={12} md={(user.user_type !== 'user' && searchParameter) ? 8 : 12}>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px', alignItems: 'center' }}><CircularProgress /></Box>
            ) : (
              <Paper elevation={3}>
                <TableContainer sx={{ maxHeight: '100%' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {user.user_type !== 'user' && (
                          <TableCell><Checkbox checked={selectAll} onChange={handleSelectAllChange} /> All </TableCell>)}
                        {/* <TableCell>#</TableCell> */}
                        <TableCell>Job Id</TableCell>
                        <TableCell>Job</TableCell>
                        <TableCell>Company Name</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Function</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell>Education</TableCell>
                        {user.user_type === 'user' && (<TableCell>Status</TableCell>)}
                        {user.user_type != 'user' && <TableCell>no. of applicants</TableCell>}
                        <TableCell>Edit</TableCell>
                        <TableCell>Delete</TableCell>
                      </TableRow>
                    </TableHead>
                    {/* jobs listing */}
                    <TableBody>
                      {jobs && jobs.length > 0 ? (jobs.map((job, index) => (
                        <TableRow key={job._id || index} onClick={(e) => {
                          handleRowClick(e, job?._id,);
                        }} style={{ cursor: 'pointer' }}>
                          {user.user_type !== 'user' && (
                            <TableCell onClick={(event) => event.stopPropagation()}>
                              <Checkbox checked={selectedJobs.includes(job._id)} onChange={(event) => handleCheckboxChange(event, job._id)} />
                            </TableCell>)}
                          {/* <TableCell>{index + 1}</TableCell> */}
                          <TableCell>{job.jobid || 'N/A'}</TableCell>
                          <TableCell>{job.title || 'N/A'}</TableCell>
                          <TableCell>{job.company || 'N/A'}</TableCell>
                          <TableCell>{job.location || 'N/A'}</TableCell>
                          <TableCell>{job.function || 'N/A'}</TableCell>
                          <TableCell>{job.role || 'N/A'}</TableCell>
                          <TableCell>{job?.qualifications?.map((qualification, index) => (
                            <span key={index}>
                              <label>{qualification.values.join(qualification.option ? ' , ' : ' | ')}</label>
                              <br /> {/* Add a new line after each qualification */}
                            </span>))}
                          </TableCell>
                          {user.user_type === 'user' && (<TableCell>{job.application_status || 'N/A'}</TableCell>)}
                          {user.user_type != 'user' && (<TableCell>{job.total_applicants || 0}</TableCell>)}
                          <TableCell>{job.total_applicants < 1 &&
                            <IconButton aria-label="edit" onClick={(e) => handleEditButton(e, job?._id)}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                <path
                                  d="M12.2872 16.0003H2.09789C0.941117 16.0003 0 15.0593 0 13.9024V3.71313C0 2.55635 0.941002 1.61523 2.09789 1.61523H6.76704C7.0982 1.61523 7.36641 1.88345 7.36641 2.2146C7.36641 2.54576 7.0982 2.81398 6.76704 2.81398H2.09789C1.60192 2.81398 1.19884 3.21706 1.19884 3.71303V13.9023C1.19884 14.3983 1.60192 14.8014 2.09789 14.8014H12.2872C12.7832 14.8014 13.1862 14.3983 13.1862 13.9023V9.23319C13.1862 8.90203 13.4545 8.63381 13.7856 8.63381C14.1168 8.63381 14.385 8.90203 14.385 9.23319V13.9023C14.385 15.0591 13.4441 16.0003 12.2872 16.0003Z"
                                  fill="#1F9CD7"
                                />
                                <path
                                  d="M4.44141 9.00838L10.9986 2.45117L13.5451 4.99768L6.98792 11.5549L4.44141 9.00838Z"
                                  fill="#1F9CD7"
                                />
                                <path
                                  d="M15.7098 1.43961L14.5591 0.28882C14.174 -0.0962733 13.5491 -0.0962733 13.1633 0.28882L11.8477 1.60444L14.3942 4.15095L15.7098 2.83533C16.0949 2.45024 16.0949 1.82545 15.7098 1.43961Z"
                                  fill="#1F9CD7"
                                />
                                <path
                                  d="M3.67143 9.9353L3.07881 12.523C3.02487 12.7598 3.23615 12.9711 3.4729 12.9171L6.06065 12.3245L3.67143 9.9353Z"
                                  fill="#1F9CD7"
                                />
                              </svg>
                            </IconButton>
                          }
                          </TableCell>
                          <TableCell>{job.total_applicants < 1 &&
                            < IconButton
                              className="delete-sec-btn"
                              aria-label="delete"
                              onClick={(event) => handleDeleteJob(job?._id, event)}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="12"
                                height="12"
                                viewBox="0 0 12 12"
                                fill="none"
                              >
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M11.0948 2.37734C11.0948 2.22733 11.0353 2.08301 10.9289 1.97715C10.8231 1.87074 10.6787 1.81131 10.5287 1.81131H8.70671L8.44689 0.771503C8.33369 0.317545 7.92557 -0.000564375 7.45803 7.54076e-07H4.54298C4.07543 -0.000565244 3.66732 0.317545 3.55412 0.771503L3.29431 1.81131H1.47228C1.15983 1.81131 0.90625 2.06489 0.90625 2.37734C0.90625 2.68978 1.15983 2.94337 1.47228 2.94337H1.50171C1.46096 3.10186 1.44737 3.26658 1.46096 3.42959L2.14473 10.2668C2.24096 11.2523 3.0702 12.0027 4.06023 12H7.94092C8.93091 12.0028 9.7602 11.2523 9.85642 10.2668L10.5402 3.42901C10.5538 3.266 10.5396 3.10185 10.4994 2.94335H10.5289C10.6789 2.94335 10.8232 2.88392 10.9291 2.77751C11.0355 2.67166 11.0948 2.52733 11.0948 2.37734ZM4.63071 1.13206H7.3697L7.53951 1.8113H4.46087L4.63071 1.13206ZM9.32707 3.05494C9.3916 3.1257 9.4233 3.22135 9.41311 3.31702L8.72933 10.1542C8.69028 10.5601 8.34839 10.8691 7.94085 10.868H4.06015C3.65261 10.8691 3.31072 10.5601 3.27167 10.1542L2.58789 3.3176C2.57827 3.22194 2.6094 3.12684 2.67393 3.05552C2.73846 2.9842 2.82959 2.94345 2.92582 2.94345H9.07529C9.17151 2.94288 9.26255 2.98362 9.32707 3.05494ZM5.09484 4.52832C4.94483 4.52832 4.80051 4.58775 4.69465 4.69416C4.58824 4.80001 4.52881 4.94435 4.52881 5.09434V7.81131C4.52881 8.12375 4.78239 8.37734 5.09484 8.37734C5.40728 8.37734 5.66086 8.12375 5.66086 7.81131V5.09434C5.66086 4.94434 5.60143 4.80001 5.49502 4.69416C5.38917 4.58774 5.24483 4.52832 5.09484 4.52832ZM6.50599 4.69416C6.61185 4.58775 6.75617 4.52832 6.90618 4.52832C7.05617 4.52832 7.20051 4.58774 7.30636 4.69416C7.41277 4.80001 7.4722 4.94434 7.4722 5.09434V7.81131C7.4722 8.12375 7.21862 8.37734 6.90618 8.37734C6.59373 8.37734 6.34015 8.12375 6.34015 7.81131V5.09434C6.34015 4.94435 6.39958 4.80001 6.50599 4.69416Z"
                                  fill="#FF4949"
                                />
                              </svg>
                            </IconButton>}
                          </TableCell>
                        </TableRow>
                      ))) : (
                        <TableRow><TableCell colSpan={8}>No jobs </TableCell></TableRow>)}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            )}
          </Grid>
        </Grid>
      </Container>
    </FormProvider >
  );
};

export default AllJobList;