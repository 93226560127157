import React, { useState, useEffect, useRef, forwardRef, useCallback } from 'react';
import { addCustomSection, deleteCustomSection } from 'api/users';
import { debounce } from 'lodash';
import { useSnackbar } from 'components/snackbar';
import { CardContent, Card, Box, Button, Divider, FormControl, IconButton, Stack, TextField, Typography, Collapse, Dialog, SvgIcon, Popover, List, ListItem, ListItemButton, ListItemText } from '@mui/material';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import BulletPointEditor from './BulletPointQuill';

const CustomSection = ({ customSectionData, flag, onCustomDataChange }) => {
  const modules = {
    toolbar: false,
  };

  const [customSection, setCustomSection] = useState(customSectionData || []);
  const [title, setTitle] = useState('');
  const [open, setOpen] = useState(false);
  const [customIndex, setCustomIndex] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [focusIndex, setFocusIndex] = useState(null);
  const [tempKey, setTempKey] = useState('');
  const textFieldRefs = useRef([]);
  const [textFieldFocus, setTextFieldFocus] = useState(null);
  const [needsSaving, setNeedsSaving] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const id = open ? 'simple-popover' : undefined;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const openCS = Boolean(anchorEl);

  const [customTitle, setCustomTitle] = useState(["Extra-Curricular Activities", "Awards", "Volunteer Activities", "Custom"]);
  const [selectedKeys, setSelectedKeys] = useState(customSectionData.map(item => item.key));
  const isKeyEditable = (key) => !customTitle.includes(key);
  const { enqueueSnackbar } = useSnackbar();
  const quillRef = useRef(null);
  const [deleteTempMsg, setDeleteTempMsg] = useState(null);

  const quillRefs = useRef([]);

  const handleRemove = () => {
    setIsRemoving(true);
    if (!customIndex) {
      handleDeleteCustomSection(title, 'title', deleteTempMsg);
    }else{
      handleDeleteCustomSection(customIndex, 'index', deleteTempMsg);
    }
  };

  useEffect(() => {
    if (customSection !== customSectionData) {
      debouncedHandleSubmit(customSection);
    }
  }, [customSection]);

  useEffect(() => {    
    flag && setCustomSection(customSectionData ?? []);
  }, [customSectionData]);

  useEffect(() => {
    if (textFieldRefs.current[editingIndex]) {
      textFieldRefs.current[editingIndex].focus();
    }
  }, [editingIndex]);

  // useEffect(() => {
  //   // Ensure the bullet points format is applied when the editor is first loaded
  //   customSection.forEach((_, index) => {
  //     const quillInstance = quillRefs.current[index]?.getEditor();
  //     if (quillInstance) {
  //       // Format all the content as bullet points
  //       quillInstance.format('list', 'bullet');
  //     }
  //   });
  // }, [customSection]);

  const handleSubmit = async (updatedCustomSection) => {
    try {
      if (updatedCustomSection && updatedCustomSection.length) {
        const hasEmptyFields = updatedCustomSection.some(section => {
          const message = typeof section?.message === 'string' ? section.message.trim() : '';
          return section?.key.trim() === '' || message === '';
        });
      
        if (!hasEmptyFields) {
          const response = await addCustomSection({ data: updatedCustomSection });
          if (response?.success) {            
            onCustomDataChange();
          } else {
            console.error('Failed to add custom section:', response.message);
          }
        }
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const handleCustomSectionChange = (index, field, value) => {
    const newCustomSections = [...customSection];
    newCustomSections[index] = { ...newCustomSections[index], [field]: value };
    setCustomSection(newCustomSections);
  };

  // const handleBulletPointChange = (index, content) => {
  //   const newMessages = [...tempMessages];
  //   newMessages[index] = content;
  //   setTempMessages(newMessages);
  //   handleCustomSectionChange(index, 'message', content.split('\n'));
  // };

  const handleChange = (content, delta, source, editor) => {
    const quill = quillRef?.current?.getEditor();
    const range = quill?.getSelection();
    if (range) {
      if (delta.ops[delta.ops.length - 1]?.attributes?.list === null) {
        quill.deleteText(range.index, 1);
      } else {
        quill.format('list', 'bullet');
      }
    }
  };

  const handleBulletPointChange = (index, content) => {
    const newCustomSections = [...customSection];
    newCustomSections[index] = { ...newCustomSections[index], message: content };
    setCustomSection(newCustomSections);
  };

  const debouncedHandleSubmit = useCallback(debounce(handleSubmit, 300), []);

  const handleAddCustomSection = () => {
    const newSection = { key: '', message: '' };
    const filtrData = customSection.some(item => item.key == "") || customSection.some(item => item.message == "")
    if (filtrData) {
      handleClose();
      enqueueSnackbar("Please fill the empty custom section", { variant: 'error' });
      return;
    };
    const newCustomSections = [...customSection, newSection];
    setCustomSection(newCustomSections);
    setEditingIndex(newCustomSections.length - 1);
    // setFocusIndex(newCustomSections.length - 1);
    setTempKey("");
    setAnchorEl(null);
  };

  const handleDeleteCustomSection = async ( id, type, index) => {
    try {
      if(type === "title"){        
        const responseLocal = await deleteCustomSection({ title }); 
        const updatedSections = customSection.filter(section => {
          return section.key !== id;
        });
        setCustomSection(updatedSections); 
        if (selectedKeys.includes(title) && title !== "Custom") setSelectedKeys(selectedKeys.filter(key => key !== title));
        handleCloseCSDialog();       
        onCustomDataChange();
      }else if (type === "index") {
      const response = await deleteCustomSection({ id, title });
      if (response?.success && response.data) {
        setCustomSection(response.data);
        if (selectedKeys.includes(title) && title !== "Custom") setSelectedKeys(selectedKeys.filter(key => key !== title));
        handleCloseCSDialog();
        setTitle(title);
        onCustomDataChange();
      } else {
        console.error('Failed to delete custom section:', response.message);
      }
    }
    } catch (error) {
      console.error('Error:', error);
    } finally {
      setIsRemoving(false);
    }

  };

  const handleOpenCSDialog = (id, title, index) => {
    setDeleteTempMsg(index);
    setOpen(true);
    setTitle(title);
    setCustomIndex(id);
  };

  const handleCloseCSDialog = () => {
    setOpen(false);
  };

  const handleEdit = (index) => {   
    setEditingIndex(index);
    setFocusIndex(null);  
    setTempKey(customSection[index].key);
    setTimeout(() => {
      if (textFieldRefs.current[index]) {
        textFieldRefs.current[index].focus();
      }
    }, 0);    
  };

  const handleSave = (index) => {
    setFocusIndex(index);
    if (customSection.some((section, i) => i !== index && section?.key === tempKey)) {
      enqueueSnackbar("Title must be unique", { variant: 'error' });
      return;
    }
    if (customSection[index].key === "") {
      enqueueSnackbar("Title cannot be left blank. Please provide a title", { variant: 'error' });
      return;
    }
    handleCustomSectionChange(index, 'key', tempKey);    
    setEditingIndex(null);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleAddCustSec = () => {
    setAddCustSec(true);
    setAnchorEl(null);
  }

  const handleAddCustomSectionWithTitle = (keyTitle) => {
    const filtrData = customSection.some(item => item.key == "") || customSection.some(item => item.message == "")
    if (filtrData) {
      handleClose();
      enqueueSnackbar("Please fill the empty custom section", { variant: 'error' });
      return;
    };
    
    if (customSection.some(section => section?.key === keyTitle)) {
      enqueueSnackbar("Title must be unique", { variant: 'error' });
      return;
    }

    const newSection = { key: keyTitle, message: [] };
    const newCustomSections = [...customSection, newSection];
    setSelectedKeys([...selectedKeys, keyTitle]);
    setCustomSection(newCustomSections);
    setFocusIndex(newCustomSections.length - 1);
    setAnchorEl(null);
  };

  const handleChangeTemp = (content, index) => {
    handleBulletPointChange(index, content);
  };

  return (
    <>
      <Box className="add-custom-section-wrapper">
        {customSection.map((section, index) => (
          <Box mb={3} key={section?._id || index}>
            <Stack direction="row" justifyContent="space-between" spacing={2}>
              <Stack direction="row" alignItems="center" spacing={1}>
                {editingIndex === index && isKeyEditable(section?.key) ? (
                  <TextField
                    inputRef={(el) => (textFieldRefs.current[index] = el)}
                    className="main-title"
                    variant="standard"
                    value={tempKey}
                    placeholder='Activity Name'
                    onChange={(e) => {
                      setTempKey(e.target.value);
                      customSection[index].key = e.target.value;
                    }}
                    autoFocus={true}
                  />
                ) : (
                  <Typography className="step-heading" variant="h6">
                    {section?.key || ''}
                  </Typography>
                )}
                {isKeyEditable(section?.key) &&
                  (editingIndex === index ? (
                    <IconButton aria-label="update" className="save-btn" onClick={() => handleSave(index)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M12.668 2.5646L6.18732 9.04531C5.74702 9.48561 5.01777 9.48561 4.5637 9.04531L1.33023 5.81183C0.889924 5.37153 0.889924 4.64228 1.33023 4.18822C1.77053 3.74791 2.49978 3.74791 2.95385 4.18822L5.38927 6.62364L11.0582 0.954739C11.4985 0.514436 12.2277 0.514436 12.6818 0.954739C13.1083 1.39504 13.1083 2.12429 12.668 2.5646Z"
                          fill="white"
                          stroke="#1575C6"
                        />
                      </svg>
                    </IconButton>
                  ) : (
                    <IconButton aria-label="edit" onClick={() => handleEdit(index)}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path
                          d="M12.2872 16.0003H2.09789C0.941117 16.0003 0 15.0593 0 13.9024V3.71313C0 2.55635 0.941002 1.61523 2.09789 1.61523H6.76704C7.0982 1.61523 7.36641 1.88345 7.36641 2.2146C7.36641 2.54576 7.0982 2.81398 6.76704 2.81398H2.09789C1.60192 2.81398 1.19884 3.21706 1.19884 3.71303V13.9023C1.19884 14.3983 1.60192 14.8014 2.09789 14.8014H12.2872C12.7832 14.8014 13.1862 14.3983 13.1862 13.9023V9.23319C13.1862 8.90203 13.4545 8.63381 13.7856 8.63381C14.1168 8.63381 14.385 8.90203 14.385 9.23319V13.9023C14.385 15.0591 13.4441 16.0003 12.2872 16.0003Z"
                          fill="#1F9CD7"
                        />
                        <path
                          d="M4.44141 9.00838L10.9986 2.45117L13.5451 4.99768L6.98792 11.5549L4.44141 9.00838Z"
                          fill="#1F9CD7"
                        />
                        <path
                          d="M15.7098 1.43961L14.5591 0.28882C14.174 -0.0962733 13.5491 -0.0962733 13.1633 0.28882L11.8477 1.60444L14.3942 4.15095L15.7098 2.83533C16.0949 2.45024 16.0949 1.82545 15.7098 1.43961Z"
                          fill="#1F9CD7"
                        />
                        <path
                          d="M3.67143 9.9353L3.07881 12.523C3.02487 12.7598 3.23615 12.9711 3.4729 12.9171L6.06065 12.3245L3.67143 9.9353Z"
                          fill="#1F9CD7"
                        />
                      </svg>
                    </IconButton>
                  ))
                }
              </Stack>
              <Stack direction="row" alignItems={'center'} spacing={2}>
                <IconButton
                  className="delete-sec-btn"
                  aria-label="delete"
                  onClick={() => handleOpenCSDialog(section?.id, section?.key, index)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 12 12"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.0948 2.37734C11.0948 2.22733 11.0353 2.08301 10.9289 1.97715C10.8231 1.87074 10.6787 1.81131 10.5287 1.81131H8.70671L8.44689 0.771503C8.33369 0.317545 7.92557 -0.000564375 7.45803 7.54076e-07H4.54298C4.07543 -0.000565244 3.66732 0.317545 3.55412 0.771503L3.29431 1.81131H1.47228C1.15983 1.81131 0.90625 2.06489 0.90625 2.37734C0.90625 2.68978 1.15983 2.94337 1.47228 2.94337H1.50171C1.46096 3.10186 1.44737 3.26658 1.46096 3.42959L2.14473 10.2668C2.24096 11.2523 3.0702 12.0027 4.06023 12H7.94092C8.93091 12.0028 9.7602 11.2523 9.85642 10.2668L10.5402 3.42901C10.5538 3.266 10.5396 3.10185 10.4994 2.94335H10.5289C10.6789 2.94335 10.8232 2.88392 10.9291 2.77751C11.0355 2.67166 11.0948 2.52733 11.0948 2.37734ZM4.63071 1.13206H7.3697L7.53951 1.8113H4.46087L4.63071 1.13206ZM9.32707 3.05494C9.3916 3.1257 9.4233 3.22135 9.41311 3.31702L8.72933 10.1542C8.69028 10.5601 8.34839 10.8691 7.94085 10.868H4.06015C3.65261 10.8691 3.31072 10.5601 3.27167 10.1542L2.58789 3.3176C2.57827 3.22194 2.6094 3.12684 2.67393 3.05552C2.73846 2.9842 2.82959 2.94345 2.92582 2.94345H9.07529C9.17151 2.94288 9.26255 2.98362 9.32707 3.05494ZM5.09484 4.52832C4.94483 4.52832 4.80051 4.58775 4.69465 4.69416C4.58824 4.80001 4.52881 4.94435 4.52881 5.09434V7.81131C4.52881 8.12375 4.78239 8.37734 5.09484 8.37734C5.40728 8.37734 5.66086 8.12375 5.66086 7.81131V5.09434C5.66086 4.94434 5.60143 4.80001 5.49502 4.69416C5.38917 4.58774 5.24483 4.52832 5.09484 4.52832ZM6.50599 4.69416C6.61185 4.58775 6.75617 4.52832 6.90618 4.52832C7.05617 4.52832 7.20051 4.58774 7.30636 4.69416C7.41277 4.80001 7.4722 4.94434 7.4722 5.09434V7.81131C7.4722 8.12375 7.21862 8.37734 6.90618 8.37734C6.59373 8.37734 6.34015 8.12375 6.34015 7.81131V5.09434C6.34015 4.94435 6.39958 4.80001 6.50599 4.69416Z"
                      fill="#FF4949"
                    />
                  </svg>
                </IconButton>
              </Stack>
            </Stack>
            <Box mt={2}>

              <div className={'quill-container'}>
                   <BulletPointEditor key={index} value={section?.message} onSetTemp={handleChangeTemp} index={index} title={section?.key} isFocus={ focusIndex === index }/>
              </div>
            </Box>
          </Box>
        ))}
        <Button
          className="add-cus-sec-btn"
          variant="text"
          endIcon={
            <SvgIcon sx={{ width: '20px', height: '20px' }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M19.9181 8.94995L13.3981 15.47C12.6281 16.24 11.3681 16.24 10.5981 15.47L4.07812 8.94995"
                  stroke="#1575C6"
                  strokeWidth="1.8"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </SvgIcon>
          }
          aria-describedby={id}
          onClick={handleClick}
        >
          Add Custom Section
        </Button>
      </Box >

      <Popover
        id={id}
        open={openCS}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'center',
          horizontal: 'left',
        }}
      >
        <Box className="custom-sec-list">
          <List sx={{ width: 220 }}>
            {customTitle.map((title, index) => (
              <ListItem disablePadding key={index}>
                <ListItemButton
                  disabled={selectedKeys.includes(title)}
                  onClick={(e) =>
                    title === "Custom"
                      ? handleAddCustomSection()
                      : handleAddCustomSectionWithTitle(customTitle[index])
                  }
                >
                  <ListItemText sx={{ margin: 0 }} primary={title} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </Popover>

      <Dialog
        open={open}
        onClose={handleCloseCSDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="otp-dialog-wrapper"
      >
        <Card className="otp-card-wrapper" variant="outlined">
          <CardContent>
            <IconButton
              className="close-dialog-btn"
              aria-label="delete"
              color="primary"
              onClick={handleCloseCSDialog}
            >
              <SvgIcon sx={{ width: '20px', height: '20px' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <g clipPath="url(#clip0_456_20993)">
                    <path
                      d="M7 7L17 17M17 7L7 17L17 7Z"
                      stroke="#111827"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_456_20993">
                      <rect width="24" height="24" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </SvgIcon>
            </IconButton>
            <Box mb={3} sx={{ textAlign: 'center' }}>
              <Typography mb={1} className="title" component="h6">
                Remove {title}?
              </Typography>
              <Typography sx={{ textAlign: 'center!important' }} className="otp-sent-txt" component="p">
                You sure want to remove the {title? title : 'Custom Section'}?
              </Typography>
            </Box>
            <Stack direction="row" alignItems="center" spacing={3}>
              <Button fullWidth className="otp-btn" variant="outlined" size="medium" onClick={handleCloseCSDialog}>
                Cancel
              </Button>
              <Button
                fullWidth
                className="otp-btn"
                variant="contained"
                size="medium"
                onClick={handleRemove}
                disabled={isRemoving}
              >
                {isRemoving ? 'Removing...' : 'Remove'}
              </Button>
            </Stack>
          </CardContent>
        </Card>
      </Dialog>
    </>
  );

};

export default CustomSection;
