import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  IconButton,
  SvgIcon,
  Typography,
  TextField,
  FormControl,
  Stack,
} from '@mui/material';
import { updateContact } from 'api/users';
import OneTimePassword from '../login/OneTimePassword';
import { useSnackbar } from 'components/snackbar';

const UpdateEmail = ({ onCancel, onCancelWithEmail }) => {
  const [email, setEmail] = React.useState('');
  const [showOtpInput, setShowOtpInput] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [userId, setUserId] = React.useState(null);
  const [error, setError] = React.useState('');
  const [isRemoving, setIsRemoving] = React.useState(false);

  const handleRemove = () => {
    setIsRemoving(true);
    handleSubmit();
  };


  const handleOpenOtpDialog = () => {
    setOpen(true);
  };

  const handleClose = (message) => {
    if (message === 'email') {
      onCancelWithEmail(email);
    }
    setOpen(false);
  };

  const handleSubmit = async () => {
    // Email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError('Please enter a valid email address.');
      setIsRemoving(false);
      return;
    }
    setError('');
    if (!open) {
      const response = await updateContact({ emailId: email });
      if (response?.success === true && response.data?.id) {
        setUserId(response.data.id);
        enqueueSnackbar(`OTP sent.`, { variant: 'success' });
        handleOpenOtpDialog();
      } else {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    }
    setIsRemoving(false)
  };

  return (
    <Box>
      <Card className="otp-card-wrapper" variant="outlined">
        <CardContent>
          <IconButton className="close-dialog-btn" aria-label="delete" color="primary" onClick={onCancel}>
            <SvgIcon sx={{ width: '20px', height: '20px' }}>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <g clipPath="url(#clip0_456_20993)">
                  <path
                    d="M7 7L17 17M17 7L7 17L17 7Z"
                    stroke="#111827"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_456_20993">
                    <rect width="24" height="24" fill="white" />
                  </clipPath>
                </defs>
              </svg>
            </SvgIcon>
          </IconButton>

          <Box mb={2.5} sx={{ textAlign: 'left' }}>
            <Typography mb={1} className="title" component="h6">
              Update Email
            </Typography>
            <Typography sx={{ textAlign: 'left!important' }} className="otp-sent-txt" component="p">
              This email would be used for primary communication by your recruiters
            </Typography>
          </Box>

          <Box mb={4}>
            <FormControl size="small" fullWidth
            sx={{
              display: 'inline-flex',
              flexDirection: 'column',
              position: 'relative',
              minWidth: '0px',
              padding: '0px',
              margin: '0px',
              border: '0px',
              verticalAlign: 'top',
              width: '100%',
              height: '60px',
            }}
            >
              <TextField
                name="email"
                size="small"
                label="Email ID"
                variant="outlined"
                placeholder="Enter your Email ID"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!!error}
                helperText={error}
              />
            </FormControl>
          </Box>

          <Stack direction="row" alignItems={'center'} spacing={3}>
            <Button fullWidth className="otp-btn" variant="outlined" size="medium" onClick={onCancel}>
              Cancel
            </Button>
            <Button fullWidth className="otp-btn" variant="contained" size="medium" onClick={handleRemove} disabled={isRemoving}>
              {isRemoving ? 'Submitting...' : 'Submit'}
            </Button>
          </Stack>
        </CardContent>
      </Card>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="otp-dialog-wrapper"
      >
        <OneTimePassword userId={userId} onCancelOTP={handleClose} email={email} />
      </Dialog>
    </Box>
  );
};

export default UpdateEmail;
