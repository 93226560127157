import { AccountCircle } from '@mui/icons-material';
import { DataGrid } from '@mui/x-data-grid';
import {
  Box,
  Container,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import AllApplicantsList from './AllApplicantsList';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { jobDetails } from 'api/job';
import ApplicantProfile from './ApplicantProfile';
import { SplashScreen } from 'components/loading-screen';

const dayjs = require('dayjs');

const JobApplicants = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [jobDetail, setJobDetail] = useState([]);
  const { id } = useParams();
  const [selectedApplicant, setSelectedApplicant] = useState(null);
  const [showMore, setShowMore] = useState(false);
  const showMoreButton = jobDetail?.description && jobDetail?.description.length > 300;
  const [showApplicantProfile, setShowApplicantProfile] = useState(false);

  const handleSelectApplicant = (applicant) => {
    // Toggle the visibility of the ApplicantProfile component
    setShowApplicantProfile(!showApplicantProfile);
    setSelectedApplicant(applicant);
  };

  const handleGoBack = () => {
    setShowApplicantProfile(false);
    // You can add your logic for navigating back to the previous screen here
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await jobDetails({ id });
        if (response?.success && response.data) {
          setJobDetail(response.data || []);
        } else {
          console.error('Failed to fetch job info:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching job info:', error);
      }
      setLoading(false);
    };
    fetchData();
  }, []);

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <SplashScreen />
      </Box>
    );
  }

  return (
    <Box>
      <Container style={{ marginTop: '80px' }}>
        <Box sx={{ marginBottom: '2rem' }}>
          <Container>
            {/* jobDetails */}
            <Box sx={{ marginBottom: '2rem' }}>
              <Container>
                <Paper elevation={3} sx={{ padding: '1rem' }}>
                  <Box sx={{ marginBottom: '1rem' }}>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                      <Box>
                        {/* job title & company name */}
                        <Typography variant="h6" component="div">
                          {jobDetail?.title || 'N/A'}, {jobDetail?.company || 'N/A'}
                        </Typography>
                      </Box>
                      <Box>
                        {/* job id */}
                        <Typography variant="p" component="div" sx={{ fontSize: '0.8rem' }}>
                          Job Id: {jobDetail?.jobid || 'N/A'}
                        </Typography>
                        {/* job created date */}
                        <Typography variant="p" component="div" sx={{ fontSize: '0.8rem' }}>
                          Post Date: {jobDetail?.updatedAt ? dayjs(jobDetail?.updatedAt).format('DD/MM/YYYY') : 'N/A'}
                        </Typography>
                      </Box>
                    </Stack>
                  </Box>
                  <Box>
                    <Typography variant="h6" component="div" sx={{ marginBottom: '1rem' }}>
                      Jobs Description
                    </Typography>
                    {/* job description */}
                    <div>
                      <Typography>
                        {showMore
                          ? jobDetail?.description
                          : jobDetail?.description && jobDetail?.description.substring(0, 300) + (showMoreButton ? '...' : '')}
                      </Typography>
                      {showMoreButton && (
                        <Link component="button" onClick={() => setShowMore(!showMore)}>
                          {' '}
                          {showMore ? 'show less' : 'show more'}
                        </Link>
                      )}
                    </div>
                  </Box>
                  {/* job details (qualification, work mode, location, start date) */}
                  <Box sx={{ margin: '2rem 0' }}>
                    <Typography variant="p" component="div" sx={{ marginBottom: '1rem', fontWeight: 'bold' }}>
                      Requirements
                    </Typography>
                    <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                      <TableContainer component={Paper}>
                        <Table sx={{ minWidth: 'auto' }} aria-label="simple table">
                          <TableBody>
                            <TableRow
                              key={jobDetail?.qualifications}
                              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                              <TableCell component="th" scope="row">
                                {' '}
                                Qualifications{' '}
                              </TableCell>
                              <TableCell align="left">
                                {jobDetail?.qualifications?.map((qualification, index) => (
                                  <label key={index}>
                                    {qualification.values.join(qualification.option ? ' , ' : ' | ')}
                                  </label>
                                ))}
                              </TableCell>
                            </TableRow>
                            <TableRow key={jobDetail?.work_mode} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell component="th" scope="row">
                                {' '}
                                Job Format
                              </TableCell>
                              <TableCell align="left">{jobDetail?.work_mode || 'N/A'}</TableCell>
                            </TableRow>
                            <TableRow key={jobDetail?.location} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell component="th" scope="row">
                                {' '}
                                Job Location{' '}
                              </TableCell>
                              <TableCell align="left">{jobDetail?.location || 'N/A'}</TableCell>
                            </TableRow>
                            <TableRow key={jobDetail?.start_date} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                              <TableCell component="th" scope="row">
                                {' '}
                                Desired Start Date{' '}
                              </TableCell>
                              <TableCell align="left">
                                {jobDetail?.start_date ? dayjs(jobDetail?.start_date).format('DD/MM/YYYY') : 'N/A'}{' '}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Stack>
                  </Box>
                </Paper>
              </Container>
            </Box>
            <Box sx={{ marginBottom: '1rem' }}>
              <Container>
                <Paper elevation={3} sx={{ padding: '1rem' }}>
                  <AllApplicantsList setSelectedApplicant={handleSelectApplicant} jobDetails={jobDetail} />
                </Paper>
              </Container>
            </Box>
            {showApplicantProfile && selectedApplicant && (
              <Box sx={{ marginBottom: '1rem' }}>
                <Container>
                  <Paper elevation={3} sx={{ padding: '1rem' }}>
                    <ApplicantProfile selectedApplicant={selectedApplicant} onGoBack={handleGoBack} />
                  </Paper>
                </Container>
              </Box>
            )}
          </Container>
        </Box>
      </Container>
    </Box>
  );
};

export default JobApplicants;
