import {
  AppBar,
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Menu,
  Radio,
  RadioGroup,
  TextField,
  Toolbar,
  Paper,
  Stack,
  Fade,
  Chip,
  Card,
  CardContent,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  FormHelperText
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Switch from '@mui/material/Switch';
import { useState, useEffect, useRef, forwardRef, useImperativeHandle } from 'react';
import * as React from 'react';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import {
  updateBasicProfile,
  updatePersonalDetails,
  uploadProfilePic,
  getBasicProfileDetails,
  setDefaultResume,
  deleteResume,
} from 'api/users';
import { endpoints } from 'utils/axios';
import { useAuth } from 'hooks/useAuthContext';
import { useNavigate, useLocation } from 'react-router-dom';
import { useForm, register, watch, Controller } from 'react-hook-form';
import FormProvider from 'hooks/form/form-provider';
import { useSnackbar } from 'components/snackbar';
import { CandidateBasicProfileRule } from 'models/CandidateProfileSchema';
import { yupResolver } from '@hookform/resolvers/yup';
import dayjs from 'dayjs';
import TypeAhead from 'components/TypeAhead';
import { validateDate } from '@mui/x-date-pickers/internals';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 28,
  height: 16,
  padding: 0,
  marginTop: '0.2rem!important',
  top: 5,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(9px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    '&.Mui-checked': {
      transform: 'translateX(12px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    width: 12,
    height: 12,
    borderRadius: 6,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16 / 2,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
  },
}));

const PersonalDetails = forwardRef(({ profileData }, ref) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { user, updateContext } = useAuth();
  const [isFetched, setIsFetched] = useState(false);
  const [open, setOpen] = useState(false);
  const [itemId, setItemId] = useState('');
  const [checked, setChecked] = React.useState(false);

  const defaultValues = {
    city: '',
    date_of_birth: null,
    sex: '',
    disability: '',
    name: user?.name || '',
  };

  const methods = useForm({
    defaultValues,
    resolver: yupResolver(CandidateBasicProfileRule),
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isDirty, isValid, dirtyFields, isSubmitting },
  } = methods;

  const gender = [{ label: 'Male' }, { label: 'Female' }, { label: 'Other' }];

  const disabilities = [{ label: 'Handicap' }, { label: 'Rehabilitation' }, { label: 'Other' }];

  useEffect(() => {
    const fetchData = () => {
      try {
        if (profileData) {
          const result = profileData;
          setValue('name', result?.full_name || '');
          setValue('city', result?.address?.city || '');
          setValue('date_of_birth', result?.date_of_birth || null);
          setValue('sex', result?.sex || null);
          setValue('disability', result?.disability || '');
          setChecked(result?.disability ? true : false);
        }
        setIsFetched(true);
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };
    fetchData();
  }, []);

  const handleChange = () => {
    setChecked((prev) => !prev);
    setValue('disability', '');
    handleSubmit(onSubmit)();
  };

  const handleDateChange = async (date) => {
    setValue('date_of_birth', date);
    if(!user?.is_profile_updated) {
      UpdateSingleFieldSubmit({ date_of_birth: date});
    }else {
      const isDataValid = await trigger('date_of_birth');
      if (isDataValid) {
        handleSubmit(onSubmit)();
      }
    }
  };
  
  const validateAndSubmit = async (name) => {
    const isValid = await trigger(name);
    if (isValid) {
      handleSubmit(onSubmit)();
    }
  };

  const onSubmit = async (formData) => {
    const response = await updateBasicProfile(formData);
    if (response?.success === true && response.data) {
      updateContext({ name: response.data?.full_name });
      updateContext({ sex: response.data?.sex || null });
      updateContext({ date_of_birth: response.data?.date_of_birth || null });
      updateContext({ city: response.data?.address?.city || '' });
    } else if (response?.success === false) {
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  };

  const UpdateSingleFieldSubmit = async (data) => {    
    trigger();
    const response = await updatePersonalDetails(data);
    if (response?.success === true && response.data) {
      updateContext({ name: response.data?.full_name || '' });
      updateContext({ sex: response.data?.sex || null });
      updateContext({ date_of_birth: response.data?.date_of_birth || null });
      updateContext({ city: response.data?.address?.city || '' });
    } else if (response?.success === false) {
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  }

  useImperativeHandle(ref, () => ({
    triggerValidation: async () => {
      const result = await trigger();
      return result;
    },
  }));

  return (
    <FormProvider methods={methods}>
      <Box mb={3}>
        <Typography className="step-heading" variant="h6" gutterBottom>
          Personal Details
        </Typography>
        <Typography className="step-info" variant="body2" gutterBottom>
          These details would help recruiters understand & connect with you.
        </Typography>
      </Box>
      <Box>
        <Grid container spacing={2} mb={3}>
          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" sx={{ marginTop: '8px' }}>
              <Controller
                control={control}
                name="name"
                render={({ field }) => (
                  <TextField
                    {...field}
                    {...register('name')}
                    label="Name"
                    size="small"
                    variant="outlined"
                    error={!!errors.name}
                    helperText={errors.name ? errors.name.message : ''}
                    onChange={(e) => {
                      setValue('name', e.target.value);
                      if(!user?.is_profile_updated) {
                        UpdateSingleFieldSubmit({ name: e.target.value});
                      }else {
                        validateAndSubmit('name');
                      }
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" sx={{ marginTop: '8px' }}>
            <InputLabel id="demo-simple-select-label">Gender</InputLabel>
              <Controller
                control={control}
                name="sex"
                render={({ field }) => (                  
                  <Select
                    {...field}
                    {...register('sex')}
                    value={field?.value || ''}
                    labelId="demo-simple-select-label"
                    id="sex"
                    label="Gender"
                    error={!!errors.sex}
                    onChange={(e) => {
                        field.onChange(e.target.value);
                        if(!user?.is_profile_updated) {
                          UpdateSingleFieldSubmit({ sex: e.target.value});
                        }else {
                          handleSubmit(onSubmit)();
                        }
                      }}
                  >
                    {gender.map((item) => (
                      <MenuItem key={item.label} value={item.label}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.sex && <FormHelperText error>{errors.sex?.message}</FormHelperText>} 
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" sx={{ marginTop: '8px' }}>
              <Controller
                control={control}
                name="date_of_birth"
                defaultValue={ defaultValues?.date_of_birth ? dayjs(defaultValues?.date_of_birth) : null }
                render={({ field }) => (
                  <DatePicker
                    {...field}
                    views={['year','month','day']} 
                    value={ field?.value ? dayjs(field?.value) : null }
                    format="DD MMM, YYYY"
                    inputFormat="DD MMM, YYYY"
                    label="Date of Birth"
                    placeholder ="DD MMM, YYYY"
                    disableFuture
                    tabIndex={-1}
                    closeOnSelect={true}
                    slotProps={{
                      textField: {
                        size: 'small',
                        error: !!errors?.date_of_birth,
                        helperText: errors?.date_of_birth?.message,
                        InputProps: {
                          readOnly: true,
                          onClick: (event) => event.target.blur(),
                        },
                        inputProps: {
                          tabIndex: -1,
                          placeholder: 'DD MMM, YYYY'
                        },
                      },
                    }}
                    sx={{ width: '100%' }}
                    onChange={handleDateChange}
                  />
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} md={6}>
            <FormControl fullWidth size="small" sx={{ marginTop: '8px' }}>
              <Controller
                control={control}
                name="city"
                defaultValue={[defaultValues?.city || '']}
                render={({ field }) => (
                  <TypeAhead
                    {...field}
                    {...register('city')}
                    api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.city}`}
                    label="Current Location"
                    minInputLength={2}
                    defaultText="Other"
                    onChange={(e, value) => {
                      field.onChange(value);
                      if(!user?.is_profile_updated) {
                        UpdateSingleFieldSubmit({ city: value});
                      }else {
                      handleSubmit(onSubmit)();
                      }
                    }}
                  />
                )}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
      <Box>
        <Card className="disability-card" variant="outlined">
          <CardContent>
            <Stack direction="row" spacing={1.5} alignItems="start">
              <AntSwitch inputProps={{ 'aria-label': 'ant design' }} checked={checked} onChange={handleChange} />
              <Box sx={{ width: '100%' }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Box>
                    <Typography className="heading" variant="p" sx={{ display: 'inline-flex' }}>
                      Disability Declaration
                    </Typography>
                    <Typography className="info" variant="body2" gutterBottom>
                      Select this if you have UDID Card
                    </Typography>
                  </Box>
                  <Fade in={checked}>
                    <Box>
                      <FormControl size="small" sx={{ minWidth: 200 }}>
                        <Controller
                          control={control}
                          name="disability"
                          render={({ field }) => (
                            <Autocomplete
                              size="small"
                              {...field}
                              {...register('disability')}
                              id="disability"
                              name="disability"
                              options={disabilities?.map((item) => item?.label)}
                              renderInput={(params) => <TextField {...params} label="Disability type" />}
                              onChange={(e, value) => {
                                field.onChange(value);
                                handleSubmit(onSubmit)();
                              }}
                              inputValue={field.value || ''}
                              onInputChange={(e, value) => field.onChange(value)}
                            />
                          )}
                        />
                      </FormControl>
                    </Box>
                  </Fade>
                </Stack>
              </Box>
            </Stack>
          </CardContent>
        </Card>
      </Box>
    </FormProvider>
  );
});

export default PersonalDetails;
