import SvgColor from '../../../components/svg-color';

const icon = (name) => <SvgColor src={`${process.env.REACT_APP_HOMEPAGE}assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const generateNavConfig = (userType) => {
  let navConfig = [];

  switch (userType) {
    case 'user':
      navConfig = [
        {
          title: 'Jobs',
          path: `home`,
          icon: icon('ic_analytics'),
        },
        {
          title: 'Repository',
          path: `applications`,
          icon: icon('ic_blog'),
        },
        {
          title: 'View / Edit Profile',
          path: 'profile',
          icon: icon('ic_user'),
        },
        {
          title: 'My Setting',
          path: 'settings',
          icon: icon('ic_user'),
        },
        {
          title: 'Feedback',
          path: 'Feedback',
          icon: icon('ic_user'),
        }
      ];
      break;
    case 'company':
      navConfig = [
        {
          title: 'Job & Applicants',
          path: `home`,
          icon: icon('ic_analytics'),
        },
        {
          title: 'View / Edit Profile',
          path: 'company-profile',
          icon: icon('ic_user'),
        },
        {
          title: 'Manage User',
          path: `manage-user`,
          icon: icon('ic_blog'),
        },
        {
          title: 'My Setting',
          path: 'settings',
          icon: icon('ic_user'),
        },
        {
          title: 'Feedback',
          path: 'Feedback',
          icon: icon('ic_user'),
        }

      ];
      break;
    case 'companyadmin':
      navConfig = [
        {
          title: 'Job & Applicants',
          path: `home`,
          icon: icon('ic_analytics'),
        },
        // {
        //   title: 'Company Master',
        //   path: `companies`,
        //   icon: icon('ic_blog'),
        // },
        {
          title: 'Manage User',
          path: `manage-user`,
          icon: icon('ic_blog'),
        },
        {
          title: 'My Setting',
          path: 'settings',
          icon: icon('ic_user'),
        },
        {
          title: 'Feedback',
          path: 'Feedback',
          icon: icon('ic_user'),
        }

      ];
      break;
    case 'companyadmin':
        navConfig = [
          {
            title: 'Job & Applicants',
            path: `home`,
            icon: icon('ic_analytics'),
          },
          // {
          //   title: 'Company Master',
          //   path: `companies`,
          //   icon: icon('ic_blog'),
          // },
          // {
          //   title: 'Manage User',
          //   path: `manage-user`,
          //   icon: icon('ic_blog'),
          // },
          {
            title: 'My Setting',
            path: 'settings',
            icon: icon('ic_user'),
          },
          {
            title: 'Feedback',
            path: 'Feedback',
            icon: icon('ic_user'),
          }
          
        ];
        break;
    case 'recruiter':
      navConfig = [
        {
          title: 'Job & Applicants',
          path: `home`,
          icon: icon('ic_analytics'),
        },
        {
          title: 'View / Edit Profile',
          path: 'company-profile',
          icon: icon('ic_user'),
        },
        {
          title: 'Manage User',
          path: `manage-user`,
          icon: icon('ic_blog'),
        },
        {
          title: 'My Setting',
          path: 'settings',
          icon: icon('ic_user'),
        },
        {
          title: 'Feedback',
          path: 'Feedback',
          icon: icon('ic_user'),
        }
      ];
      break;

    case 'admin':
      navConfig = [
        {
          title: 'Job & Applicants',
          path: `home`,
          icon: icon('ic_analytics'),
        },
        {
          title: 'Company Master',
          path: `companies`,
          icon: icon('ic_blog'),
        },
        {
          title: 'Candidate Master',
          path: `candidates`,
          icon: icon('ic_blog'),
        },
        {
          title: 'My Setting',
          path: 'settings',
          icon: icon('ic_user'),
        },
        {
          title: 'Feedback',
          path: 'feedback',
          icon: icon('ic_user'),
        }
      ];
      break;
    case 'superadmin':
      navConfig = [
        {
          title: 'Job & Applicants',
          path: `home`,
          icon: icon('ic_analytics'),
        },
        {
          title: 'Company Master',
          path: `companies`,
          icon: icon('ic_blog'),
        },
        {
          title: 'Candidate Master',
          path: `candidates`,
          icon: icon('ic_blog'),
        },

        {
          title: 'Manage User',
          path: 'user-manage',
          icon: icon('ic_user'),
        },
        {
          title: 'My Setting',
          path: 'settings',
          icon: icon('ic_user'),
        },
        {
          title: 'Feedback',
          path: 'feedback',
          icon: icon('ic_user'),
        }
      ];
      break;
    default:
      break;
  }

  return navConfig;
};

export default generateNavConfig;