import * as yup from 'yup';
// import parsePhoneNumber from 'libphonenumber-js';

// Schema for validation
export const LoginSchema = yup.object().shape({
    email: yup.string().email('Please enter correct email id.').required('Please enter your email id.'),
    password: yup
    .string()
    .required('Please enter your password.')
});

export const ForgotPasswordSchema = yup.object().shape({
    email: yup.string().email('Please enter correct email id.').required('Please enter your email id.'),
    otp : yup.number().min(6, "OTP should be 6 digits")
});