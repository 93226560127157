import {
  Box,
  Card,
  CardContent,
  Typography,
  IconButton,
  Button,
  Stack,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import React, { useState, useEffect } from 'react';
import EducationDetails from './AddEducation';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { deleteWorkHistory, deleteEducationHistory } from 'api/users';
import { useSnackbar } from 'components/snackbar';
import RemoveEducation from './RemoveEducationDialog';

const EducationSection = ({ educationData, onCancel, expandedSection, handleExpand, onEducationDataChange }) => {
  const [showEducation, setShowEducation] = useState(false);
  const [educations, setEducations] = useState(educationData ?? []);
  const [actionType, setActionType] = useState('');
  const [open, setOpen] = useState(false);
  const [itemId, setItemId] = useState('');
  const [title, setTitle] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [selectedCard, setSelectedCard] = useState(null);

  useEffect(() => {
    setEducations(educationData);
  }, [educationData]);
  const handleCardClick = (item, index) => {
    handleExpand('education', index);
    setSelectedCard(item);
    setShowEducation(false);
  };

  const handleDeleteEducation = async (educationId, title, event) => {
    event.stopPropagation();
    if (!educationId) {
      localStorage.removeItem("education");
      setEducations([...educations?.filter(edu => edu?._id)]);
      setSelectedCard(null);
    } else {
      setActionType('education');
      setTitle(title);
      setItemId(educationId);
      setOpen(true);
    }
  };

  const onHandleDataChange = async (isChanged) => {
    setShowEducation(false);
    if (isChanged) {
      await onEducationDataChange();
    }
  };

  const handleClose = () => {
    setOpen(false);
    setItemId('');
  };

  const handleDeleteAction = async () => {
    if (actionType === 'education') {
      const response = await deleteEducationHistory({ educationId: itemId });
      if (response && response?.success) {
        setEducations(response?.data || []);
        onEducationDataChange();
      } else {
        enqueueSnackbar(response.message, { variant: 'error' });
      }
    }
    handleClose();
  };

  const handleAddNewEdu = () => {
    setShowEducation(true);
    handleExpand("education", educations.length);
    setSelectedCard({});
  }

  return (
    <Box className="candidate-basic-det-wrapper" >
      <Box mb={3}>
        <Typography className="step-heading" variant="h6" gutterBottom>
          Your Education
        </Typography>
        <Typography className="step-info" variant="body2" gutterBottom>
          Add your highest education degree first
        </Typography>
      </Box>
      <Box mb={3}>
        {educations.length !== 0 &&
          educations.map((item, index) => (
            <React.Fragment key={item._id || index}>
              {expandedSection.section !== 'education' || expandedSection.index !== index ? (
                <Card className={`info-list-item ${item?.status !== "active" ? "incomplete-details" : ""}`} variant="outlined" key={item._id} sx={{ marginTop: 1 }} onClick={() => handleCardClick(item, index)}>
                  <CardContent>
                    <Stack direction="row" alignItems={'center'} justifyContent={'space-between'} spacing={2}>
                      <Box>
                        <Stack direction="row" alignItems={'center'} spacing={1.5}>
                          <Typography className="title-wrapper" variant="h6">
                            {item.degree_received} at {item.education_institute_name}
                          </Typography>
                          {item?.status !== "active" && <Box className="incomplete-tag">Incomplete</Box>}
                        </Stack>
                        <Typography className="info-wrapper" variant="body2" gutterBottom>
                          {item.education_from ? `${dayjs(item.education_from).format('YYYY')} - ${item.education_to ? dayjs(item.education_to).format('YYYY') : 'Present'}` : ''}
                        </Typography>
                      </Box>
                      <Stack direction="row" alignItems={'center'} spacing={2}>
                        <IconButton
                          className="delete-sec-btn"
                          aria-label="delete"
                          onClick={(event) => handleDeleteEducation(item._id, item.degree_received, event)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="12"
                            height="12"
                            viewBox="0 0 12 12"
                            fill="none"
                          >
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M11.0948 2.37734C11.0948 2.22733 11.0353 2.08301 10.9289 1.97715C10.8231 1.87074 10.6787 1.81131 10.5287 1.81131H8.70671L8.44689 0.771503C8.33369 0.317545 7.92557 -0.000564375 7.45803 7.54076e-07H4.54298C4.07543 -0.000565244 3.66732 0.317545 3.55412 0.771503L3.29431 1.81131H1.47228C1.15983 1.81131 0.90625 2.06489 0.90625 2.37734C0.90625 2.68978 1.15983 2.94337 1.47228 2.94337H1.50171C1.46096 3.10186 1.44737 3.26658 1.46096 3.42959L2.14473 10.2668C2.24096 11.2523 3.0702 12.0027 4.06023 12H7.94092C8.93091 12.0028 9.7602 11.2523 9.85642 10.2668L10.5402 3.42901C10.5538 3.266 10.5396 3.10185 10.4994 2.94335H10.5289C10.6789 2.94335 10.8232 2.88392 10.9291 2.77751C11.0355 2.67166 11.0948 2.52733 11.0948 2.37734ZM4.63071 1.13206H7.3697L7.53951 1.8113H4.46087L4.63071 1.13206ZM9.32707 3.05494C9.3916 3.1257 9.4233 3.22135 9.41311 3.31702L8.72933 10.1542C8.69028 10.5601 8.34839 10.8691 7.94085 10.868H4.06015C3.65261 10.8691 3.31072 10.5601 3.27167 10.1542L2.58789 3.3176C2.57827 3.22194 2.6094 3.12684 2.67393 3.05552C2.73846 2.9842 2.82959 2.94345 2.92582 2.94345H9.07529C9.17151 2.94288 9.26255 2.98362 9.32707 3.05494ZM5.09484 4.52832C4.94483 4.52832 4.80051 4.58775 4.69465 4.69416C4.58824 4.80001 4.52881 4.94435 4.52881 5.09434V7.81131C4.52881 8.12375 4.78239 8.37734 5.09484 8.37734C5.40728 8.37734 5.66086 8.12375 5.66086 7.81131V5.09434C5.66086 4.94434 5.60143 4.80001 5.49502 4.69416C5.38917 4.58774 5.24483 4.52832 5.09484 4.52832ZM6.50599 4.69416C6.61185 4.58775 6.75617 4.52832 6.90618 4.52832C7.05617 4.52832 7.20051 4.58774 7.30636 4.69416C7.41277 4.80001 7.4722 4.94434 7.4722 5.09434V7.81131C7.4722 8.12375 7.21862 8.37734 6.90618 8.37734C6.59373 8.37734 6.34015 8.12375 6.34015 7.81131V5.09434C6.34015 4.94435 6.39958 4.80001 6.50599 4.69416Z"
                              fill="#FF4949"
                            />
                          </svg>
                        </IconButton>

                        <KeyboardArrowDownOutlinedIcon />
                      </Stack>
                    </Stack>
                  </CardContent>
                </Card>
              ) : (
                <EducationDetails
                  key={item?._id}
                  educationData={educations.find((_, idx) => idx === expandedSection.index)}
                  id={item._id}
                  onCancel={() => {
                    setShowEducation(false);
                    setSelectedCard(null);
                    onCancel()
                  }}
                  onEducationDataChange={onHandleDataChange}
                />
              )}
            </React.Fragment>
          ))}
      </Box>
      {
        (showEducation && expandedSection.section === 'education' &&
          (<EducationDetails
            onEducationDataChange={onHandleDataChange}
            onCancel={() => {
              setShowEducation(false);
              setSelectedCard(null);
              onCancel()
            }} />
          ))}
      <Box mt={2}>
        {(!selectedCard || selectedCard?.status || expandedSection.section !== 'education') && <Button className="add-btn" startIcon={<AddIcon />}
          disabled={educations.some(edu => !edu?._id)}
          onClick={handleAddNewEdu}>
          Add Education
        </Button>}
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="otp-dialog-wrapper"
      >
        <RemoveEducation onClose={handleClose} onRemove={handleDeleteAction} title={title} />
      </Dialog>
    </Box >
  );
};

export default EducationSection;
