import { Box, Button, Stack, Typography } from '@mui/material';


import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import ResumeFullView from '../basic-profile/ResumeFullView';
import { getTimeDifferenceString } from 'utils/Utils';
import { useNavigate } from 'react-router-dom';
import { getBasicProfileDetails } from 'api/users';

const SelectResumeOptions = ({ onSelect, resumeData, selectedResume,  tab, job, index }) => {
  const [profile, setProfile] = useState({});
  const navigate = useNavigate();
  const sliderRef = useRef(null);

  // const orderedResumeData = selectedResume ? [selectedResume, ...resumeData.filter(item => item !== selectedResume)] : resumeData;
  const orderedResumeData = resumeData || [];

  useEffect(() => {  
    sliderRef.current.slickGoTo(index);
    const fetchData = async () => {
      try {
        const profileResponse = await getBasicProfileDetails();  
        let profile = {};  
        if (profileResponse?.success && profileResponse?.data?.candidate) {
          profile = profileResponse?.data?.candidate || {};
          setProfile(profile);
        }else {
          enqueueSnackbar(profileResponse?.message, { variant: 'error' });
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        enqueueSnackbar('Failed to fetch data. Please try again later.', { variant: 'error' });
      }
    };
    fetchData();
  }, []);

  const settings = {
    dots: true,
    fade: true,
    infinite: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  const handleSelect = (item, index) => {
    onSelect(item, index);
  };

  const handleNavigation = (item) => {
    const data = item;
    const totalResume = resumeData?.length;  
    navigate('/create-update-resume', { state: { data, totalResume, tab, jobData: job } });
  };

  return (
    <Box className="select-resume-options-container">
      <Box className="resume-options-slider-container">
        <Slider {...settings} ref={sliderRef}>
          {orderedResumeData &&
            orderedResumeData?.map((item, index) => (
              <Box className="resume-option-item" key={index}>
                <Box className="header-update-select-resume">
                  <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
                    <Box>
                      <Typography className="resume-title">{item?.title}</Typography>
                      <Typography className="last-updated">{item?.updatedAt ? getTimeDifferenceString(item?.updatedAt, "Updated") : getTimeDifferenceString(profile?.updatedAt, 'Updated')}</Typography>
                    </Box>
                    <Box>
                      <Stack direction="row" spacing={2}>
                        <Button variant="outlined" size="small" className="update-btn" onClick={() => handleNavigation(item)}>
                          Update
                        </Button>
                        <Button variant="contained" size="small" className="select-resume-btn" onClick={() => handleSelect(item, index)}>
                          Select Resume
                        </Button>
                      </Stack>
                    </Box>
                  </Stack>
                </Box>

                <Box className="resume-options-preview">
                <ResumeFullView
                summary={item?.summary ? item?.summary : ''}
                educationData={item?.educations ? item?.educations : []}
                certificateData={item?.certificates ? item?.certificates : []}
                workExperienceData={item?.work_history ? item?.work_history : []}
                customSection={item?.customSection ? item?.customSection : []}
                financeTechologiesData={item?.financeTechologiesData ? item?.financeTechologiesData : [] }
              />
                </Box>
              </Box>
            ))}
        </Slider>
      </Box>
    </Box>
  );
};

export default SelectResumeOptions;
