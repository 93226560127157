import * as yup from 'yup';

export const StaffSchema = yup.object().shape({
    name: yup.string().required('Name is required'),
    business_function: yup.string().required('Business function is required'),
    designation: yup.string().required('Designation is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    phone_number: yup.string().required('Phone number is required'),
    date_of_joining: yup.string().required('Date of joining is required'),
    is_tj_sourced: yup.boolean().required('TJ Sourced is required'),
  });
  