import React, {useState} from 'react'
import { Box, Button, IconButton, Stack, SvgIcon, Typography } from '@mui/material'
import ScreeningQuestionList from './ScreeningQuestionList'


const ScreeningQuestionDrawer = ({onClose, keyQuestions, setJobQuestions}) => {
    
  const [questions, setQuestions] = useState(keyQuestions || ['']);
  const [errors, setErrors] = useState([]);

  const handleQuestion = () => {
    const errorState = questions.map(question => question.trim() === '');
    setErrors(errorState); 
    const hasErrors = errorState.includes(true);
    if (!hasErrors) {
        setJobQuestions(questions);
    }
  };

  return (
    <Box sx={{ width: 450 }} className="job-app-drawer-container" role="presentation" >
        <Box className="drawer-header">
            <Stack direction="row" alignItems={'center'} spacing={1}>
                <IconButton>
                    <SvgIcon>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M21.2391 12.7064L4.71739 12.7064L9.93478 17.6385C10.2609 17.9468 10.2609 18.4606 9.93478 18.7688C9.6087 19.0771 9.06522 19.0771 8.73913 18.7688L2.21739 12.5009C2.1087 12.3982 2 12.1927 2 11.9872C2 11.7817 2.1087 11.5761 2.21739 11.4734L8.73913 5.2055C8.84783 5.10275 9.06522 5 9.28261 5C9.5 5 9.71739 5.10275 9.82609 5.2055C10.1522 5.51376 10.1522 6.02752 9.82609 6.33578L4.6087 11.2679L21.1304 11.2679C21.5652 11.2679 22 11.5761 22 12.0899C22 12.6037 21.6739 12.7064 21.2391 12.7064Z" fill="white"/>
                        </svg>
                    </SvgIcon>
                </IconButton>
                
                <Box>
                    <Typography className="title" sx={{marginBottom: "0!important"}}>Screening Questions</Typography>
                </Box>
            </Stack>
        </Box>

        <Box className="drawer-content">
            {/* screening question list  */}

            <ScreeningQuestionList questions={questions} setQuestions={setQuestions} errors={errors} setErrors={setErrors}/>
        </Box>

        <Box className="drawer-footer">
            <Stack direction="row" spacing={2}>
                <Button fullWidth size="large" className="cancel-btn" onClick={onClose}>Cancel</Button>
                <Button fullWidth variant="contained" size="large" className="update-btn" onClick={handleQuestion}>Done</Button>
            </Stack>
        </Box>
    </Box>
  )
}

export default ScreeningQuestionDrawer
