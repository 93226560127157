import { DataGrid } from '@mui/x-data-grid';
import { Button, Checkbox, Box, Container, Paper, InputAdornment, IconButton, Grid, TextField, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import exportFromJSON from 'export-from-json';
import { useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/useAuthContext';
import { getCompanyList } from 'api/company';
import { useForm, FormProvider, Controller } from 'react-hook-form';
import TypeAhead from 'components/TypeAhead';
import { endpoints } from 'utils/axios';
import { useSnackbar } from 'components/snackbar';
import SearchIcon from '@mui/icons-material/Search';

const CompanyList = () => {
  const navigate = useNavigate();
  const [companyList, setCompanyList] = useState([]);
  const [selectedCompanies, setSelectedCompanies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const [selectAll, setSelectAll] = useState(false);
  const { user } = useAuth();
  const [searchParameter, setSearchParameter] = useState(false);

  const defaultValues = {
    companyName: "",
    industrySector: "",
    types: "",
    size: "",
    employees: "",
    location: [],
  };

  const methods = useForm({
    defaultValues,
    mode: "onChange",
  });

  const { control, handleSubmit, reset, formState: { isSubmitting }, } = methods;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getCompanyList();
        if (response?.success && response.data != null) {
          const formattedData = response.data?.map((company) => ({
            id: company?._id,
            fullName: company?.user_id?.full_name || 'N/A',
            industrySector: company?.industry_sector || 'N/A',
            type: company?.type || 'N/A',
            revenueMin: `${company?.revenue?.min || '0'}`,
            revenueMax: `${company?.revenue?.max || 'N/A'}`,
            email: company?.user_id?.email || 'N/A',
            location: company?.user_id?.address?.city || 'N/A',
            ...company,
          }));
          setCompanyList(formattedData);
        } else {
          console.error(`Failed to fetch company list. Status: ${response.status}`);
          setError(`Failed to fetch company list. Error fetching data: ${error.message}`);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        setError(`Error fetching data: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [getCompanyList]);

  const handleAddCompany = () => {
    navigate("/company-profile", { state: { prevPath: location.pathname } });
  };

  const handleNavigation = (path) => {
    if (user) {
      navigate(path);
    } else {
      navigate('/login');
    }
  };

  const handleSearch = () => {
    setSearchParameter(prevState => !prevState);
    if (searchParameter) {
      reset(defaultValues);
    }
  };

  const downloadSelectedCompaniesData = () => {

    const selectedCompaniesData = companyList?.filter(company => selectedCompanies?.includes(company?.id));

    const excludedColumns = ['user_id'];
    const modifiedCompanyList = selectedCompaniesData?.map(company => {
      const modifiedCompany = { ...company };
      excludedColumns.forEach(column => delete modifiedCompany[column]);
      return modifiedCompany;
    });

    const renamedCompanyList = modifiedCompanyList.map(company => ({
      'ID': company['id'],
      'Company Name': company['fullName'],
      'Industry Sector': company['industrySector'],
      'Email': company['email'],
      'Type': company['type'],
      'Revenue': `${company['revenueMin']}-${company['revenueMax']}`,
      'Location': company['location'],
    }));

    const fileName = `companyList_${Date.now()}`;
    const exportType = exportFromJSON.types.csv
    exportFromJSON({ data: renamedCompanyList, fileName, exportType });
  }

  const handleRowClick = (userid) => {
    handleNavigation(`/company-details/${userid}`);
  };

  const handleCheckboxChange = (event, companyId) => {
    if (event.target.checked) {
      setSelectedCompanies([...selectedCompanies, companyId]);
    } else {
      setSelectedCompanies(selectedCompanies.filter(id => id !== companyId));
    }
  };

  const handleSelectAllChange = (event) => {
    setSelectAll(event.target.checked);
    if (event.target.checked) {
      setSelectedCompanies(companyList?.map(company => company.id));
    } else {
      setSelectedCompanies([]);
    }
  };

  const onSubmit = handleSubmit(async (formData) => {

    try {
      const response = await getCompanyList(formData);
      if (response?.success && response.data != null) {
        const formattedData = response.data?.map((company) => ({
          id: company._id,
          fullName: company?.user_id?.full_name || 'N/A',
          industrySector: company?.industry_sector || 'N/A',
          type: company?.type || 'N/A',
          revenueMin: `${company?.revenue?.min || '0'}`,
          revenueMax: `${company?.revenue?.max || 'N/A'}`,
          email: company?.user_id?.email || 'N/A',
          location: company?.user_id?.address?.city || 'N/A',
          ...company,
        }));
        setCompanyList(formattedData);
      } else {
        console.error(`Failed to fetch company list. Status: ${response.status}`);
        setError(`Failed to fetch company list. Error fetching data: ${error.message}`);
      }
    } catch (error) {
      console.error('Error fetching Companys:', error);
      enqueueSnackbar('Failed to fetch Companys', { variant: 'error' });
    }
  });

  return (
    <FormProvider {...methods}>
      <Container style={{ marginTop: '80px' }}>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Typography variant="h5" component="div" sx={{ marginBottom: '1rem' }}>
            Company List
          </Typography>
          <Button variant="contained" onClick={handleAddCompany}>
            Add New Company
          </Button>
        </Stack>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2}>
          <Button variant="contained" onClick={handleSearch}>
            {searchParameter ? "close search" : "search company"}
          </Button>
          <Button variant="contained"
            onClick={downloadSelectedCompaniesData}
            disabled={selectedCompanies.length === 0}
          >Download List</Button>
        </Stack>
        <Grid container spacing={2}>
          {searchParameter && <Grid item xs={12} md={4}>
            <Paper elevation={3} sx={{ padding: '1rem' }}>
              <Typography variant="h6" sx={{ marginBottom: '0.5rem' }}>Search Parameters</Typography>
              <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={2}>
                  <Button type="submit" variant="contained" disabled={isSubmitting}> <SearchIcon /></Button>
                  <Controller
                    control={control}
                    name="companyName"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size='small'
                        fullWidth
                        label="Company Name"
                        variant="outlined"
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="industrySector"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size='small'
                        fullWidth
                        label="Industry Sector Name"
                        variant="outlined"
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="types"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        size='small'
                        fullWidth
                        label="Types of company"
                        variant="outlined"
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="size"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        size='small'
                        label="Company size (in CRs)"
                        variant="outlined"
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="employees"
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        size='small'
                        label="Company employees"
                        variant="outlined"
                      />
                    )}
                  />
                  <Controller
                    control={control}
                    name="location"
                    render={({ field }) => (
                      <TypeAhead
                        {...field}
                        api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.city}`}
                        label="Location"
                        placeholder="Select locations"
                        displayTextKey='name'
                        multiple={true}
                        fullWidth
                        onChange={(e, value) => field.onChange(value)}
                      />
                    )}
                  />
                </Stack>
              </form>
            </Paper>
          </Grid>}
          <Grid item xs={12} md={searchParameter ? 8 : 12}>
            {loading ? (
              <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: '30px', alignItems: 'center' }}>
                <CircularProgress />
              </Box>
            ) : (
              <Paper elevation={3}>
                <TableContainer sx={{ maxHeight: '100%' }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          <Checkbox
                            checked={selectAll}
                            onChange={handleSelectAllChange}
                          />All
                        </TableCell>
                        <TableCell>#</TableCell>
                        <TableCell>Full Name</TableCell>
                        <TableCell>Location</TableCell>
                        <TableCell>Industry Sector</TableCell>
                        <TableCell>Type</TableCell>
                        <TableCell>Revenue </TableCell>
                        <TableCell>Email</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {companyList.length === 0 ? (
                        <TableRow>
                          <TableCell colSpan={7}>No companies available.</TableCell>
                        </TableRow>
                      ) : (
                        companyList?.map((company, index) => (
                          <TableRow key={company.id} onClick={() => handleRowClick(company?.user_id?.id)}>
                            <TableCell onClick={(event) => event.stopPropagation()}>
                              <Checkbox
                                checked={selectedCompanies?.includes(company.id)}
                                onChange={(event) => handleCheckboxChange(event, company.id)}
                              />
                            </TableCell>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{company?.fullName}</TableCell>
                            <TableCell>{company?.location}</TableCell>
                            <TableCell>{company?.industrySector}</TableCell>
                            <TableCell>{company?.type}</TableCell>
                            <TableCell>{company?.revenueMin}-{company?.revenueMax}</TableCell>
                            <TableCell>{company?.email}</TableCell>
                          </TableRow>
                        ))
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>)}
          </Grid>
        </Grid>
      </Container>
    </FormProvider>
  );
};

export default CompanyList;
