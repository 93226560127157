export function blobToURL(blob) {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onloadend = function () {
      const base64data = reader.result;
      resolve(base64data);
    };
  });
}

export async function base64ToBlob(base64data, contentType = 'application/pdf') {
  const base64Response = await fetch(`data:${contentType};base64,${base64data}`);
  const fileBlob = await base64Response.blob();
  return fileBlob;
}

export function blobToDataURL(blob) {
  return URL.createObjectURL(blob);
}

export function downloadBlobFile(blob, fileName) {
  const fileURL = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = fileURL;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function downloadFromURI(uri, fileName) {
  const link = document.createElement('a');
  link.href = uri;
  link.download = fileName;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export async function fileToBlob(file) {
  return new Promise((resolve) => {
    const { content, size } = file;
    let chunks = [];
    let i = 0;
    const totalCount = Math.round(size / 250000);

    for (const chunk of content) {
      chunks.push(chunk);
      i++;
    }
    // eslint-disable-next-line no-undef
    return resolve(new Blob(chunks));
  });
};

export function copyToClipboard(text) {
  // Create a text area element
  const textArea = document.createElement('textarea');

  // Set the value of the text area to the text you want to copy
  textArea.value = text;

  // Append the text area to the document
  document.body.appendChild(textArea);

  // Select the text in the text area
  textArea.select();

  try {
    // Execute the 'copy' command to copy the selected text to the clipboard
    document.execCommand('copy');
    console.log('Text copied to clipboard:', text);
  } catch (err) {
    console.error('Unable to copy text to clipboard:', err);
  } finally {
    // Remove the text area from the document
    document.body.removeChild(textArea);
  }
}

export function getTimeDifferenceString(pastDate, type) {
  const currentDate = new Date();
  const timeDifference = Math.abs(currentDate - new Date(pastDate));
  const secondsDifference = Math.floor(timeDifference / 1000);
  const minutesDifference = Math.floor(secondsDifference / 60);
  const hoursDifference = Math.floor(minutesDifference / 60);
  const daysDifference = Math.floor(hoursDifference / 24);
  const monthsDifference = Math.floor(daysDifference / 30);
  const yearsDifference = Math.floor(monthsDifference / 12);
  if (yearsDifference > 0) {
    return `${type} ${yearsDifference} ${yearsDifference === 1 ? 'year' : 'years'} ago`;
  } else if (monthsDifference > 0) {
    return `${type} ${monthsDifference} ${monthsDifference === 1 ? 'month' : 'months'} ago`;
  } else if (daysDifference > 0) {
    return `${type} ${daysDifference} ${daysDifference === 1 ? 'day' : 'days'} ago`;
  } else if (hoursDifference > 0) {
    return `${type} ${hoursDifference} ${hoursDifference === 1 ? 'hr' : 'hrs'} ago`;
  } else if (minutesDifference > 0) {
    return `${type} ${minutesDifference} ${minutesDifference === 1 ? 'min' : 'mins'} ago`;
  } else {
    return `${type} just now`;
  }
};

export function changeNameFormat(fullName) {
  let words = fullName.split(' ');
  let name = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  return name;
};

export function formatNumber(num) {
  if (num >= 1e12) {
    return ` ${(num / 1e12).toFixed(1)} L Cr PA `;
  } else if (num >= 1e10) {
    return ` ${(num / 1e10).toFixed(1)} K Cr PA `;
  } else if (num >= 1e7) {
    return ` ${(num / 1e7).toFixed(1)} Cr PA `;
  } else if (num >= 1e5) {
    return ` ${(num / 1e5).toFixed(1)} LPA `;
  } else if (num >= 1e3) {
    return ` ${(num / 1e3).toFixed(1)} K PA `;
  }
  return num ? num.toString() : "Not Disclosed";
}

export function formatCurrency(value) {
  if (value === undefined || value === null || isNaN(value)) return '';
  return Number(value).toLocaleString("en-IN");
};