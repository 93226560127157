// utils
import { fetcher, poster, endpoints } from '../utils/axios';

export async function createJob(payload) {
    const URL = endpoints.job.createJob;
    return await poster([URL, payload])
}

export async function jobUpdated(payload) {
    const URL = endpoints.job.jobUpdated;
    return await poster([URL, payload])
}

export async function getJobList(payload) {
    const URL = endpoints.job.getJobList;
    return await fetcher([URL, { params: payload }])
}

export async function getAllJobList(payload) {
    const URL = endpoints.job.getAllJobList;
    return await fetcher([URL, { params: payload }])
}

export async function deleteJob(payload) {
    const URL = endpoints.job.deleteJob;
    return await fetcher([URL, { params: payload }])
}

export async function jobDetails(payload) {
    const URL = endpoints.job.jobDetails;
    return await fetcher([URL, { params: payload }])
}

export async function getCompanyDetails(payload) {
    const URL = endpoints.job.getCompanyDetails;
    return await fetcher([URL, { params: payload }])
}

export async function setHideJob(payload) {
    const URL = endpoints.job.setHideJob;
    return await poster([URL, payload]);
}
