import * as yup from 'yup';

export const CompanySchema = (isRecruiter) => yup.object().shape({
  full_name: yup.string()
    .required('Please enter the Company name')
    .min(3, 'String must be at least 3 characters long'),
  phone_number: yup.string()
    .required('Please enter your phone number.')
    .matches(/^\d{10}$/, 'Phone number must be exactly 10 digits'),
  email: yup.string()
    .required('Email is required')
    .email('Invalid email format')
    .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, 'Email must contain @ and .'),
  industry_sector: yup.string().required('Industry Sector is required.'),
  incorporation_date: yup.date().nullable().required('Incorporation date is required'),
  type: yup.string().required('Company Type is required.'),
  revenue: isRecruiter ? null : yup.object().required('Revenue field is required'),
  website: yup.string().url('Invalid URL').required('URL is required'),
  location: yup.string().required('locationfield is required'),
  description: yup.string().optional(),
  total_staff: yup.number()
    .required('Total Staff is required')
    .typeError('Total Staff must be a number')
    .positive('Total Staff must be a positive number')
    .integer('Total Staff must be an integer')
});

export default CompanySchema;
