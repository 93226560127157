import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Grid, Divider, Card, Stack, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem, InputAdornment } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useSnackbar } from '../../components/snackbar';
import FormProvider from 'hooks/form/form-provider';
import { useForm, Controller } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { saveB2BProfile } from 'api/users';
import {useAuthDispatch} from '../../components/AuthStateProvider'

function B2BProfile({ data, isAdmin, onSubmitDone }) {
  const { enqueueSnackbar } = useSnackbar(); 
  const { updateLocalStorage } = useAuthDispatch();


    const indianStates = [
        'Andhra Pradesh',
        'Arunachal Pradesh',
        'Assam',
        'Bihar',
        'Chhattisgarh',
        'Goa',
        'Gujarat',
        'Haryana',
        'Himachal Pradesh',
        'Jharkhand',
        'Karnataka',
        'Kerala',
        'Madhya Pradesh',
        'Maharashtra',
        'Manipur',
        'Meghalaya',
        'Mizoram',
        'Nagaland',
        'Odisha',
        'Punjab',
        'Rajasthan',
        'Sikkim',
        'Tamil Nadu',
        'Telangana',
        'Tripura',
        'Uttar Pradesh',
        'Uttarakhand',
        'West Bengal',
        'Andaman and Nicobar Islands',
        'Chandigarh',
        'Dadra and Nagar Haveli',
        'Daman and Diu',
        'Delhi',
        'Lakshadweep',
        'Puducherry',
    ];
      
    const defaultValues = data ? {
        ...{ ...data, ...data?.company_data }
    } : {
        phone_number: '',
        name: '',
        email: '',
        business_name: '',
        gst_number: '',
        billing_address: '',
        state: '',
    };
    
    const methods = useForm({
        defaultValues,
        mode: "onChange",
    });
    
    const {
        control,
        register,
        handleSubmit,
        setValue,
        formState: { errors, isSubmitting },
    } = methods;
    
    const onSubmit = handleSubmit(async (formData) => {
        try {
            if (!isAdmin) delete formData?.charges;
            if (!data?.phone_number) formData.phone_number = `+91${formData?.phone_number}`;
            const response = await saveB2BProfile(formData)
            if (response?.success === true && response.status == 200) {
                if (!isAdmin) {
                    // const updatedValues={...data, name: formData?.name, displayName: formData?.name, email: formData?.email, company_data: response?.data?.company_data }
                    // localStorage.setItem('TokenDetails',JSON.stringify(updatedValues))

                    const updatedValues = { name: formData?.name, displayName: formData?.name, email: formData?.email,company_data: response?.data?.company_data };
                    updateLocalStorage('TokenDetails', updatedValues);
                }
                enqueueSnackbar(response.message, { variant: 'success' })
                if (onSubmitDone) onSubmitDone(true)
            } else if (response?.success === false) {
                enqueueSnackbar("Unable to save the data.", { variant: 'error' })
                if (onSubmitDone) onSubmitDone(null, true)
            }
        } catch (e) {
            console.log(e)
            enqueueSnackbar("Unable to save the data.", { variant: 'error' })
            if (onSubmitDone) onSubmitDone(null, true)
        }
    })

    return (
        <>
            <Card sx={{ py: 2, px: 3 }}>
                <FormProvider methods={methods} onSubmit={onSubmit}>
                    <Grid container spacing={1} justifyContent='center'> 
                        <Grid item md={6}>
                            <Controller
                                name="phone_number"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        sx={{ minWidth: 300 }}
                                        label="Phone number"
                                        fullWidth
                                        disabled={ data?.phone_number }
                                        placeholder='9999999999'
                                        margin="normal"
                                        InputProps={ !data?.phone_number ? {
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                +91
                                            </InputAdornment>
                                            ),
                                        } : null }
                                    />
                                )} />
                        </Grid>
                        <Grid item md={6}>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field} 
                                        sx={{ minWidth: 300 }}
                                        label="User Name"
                                        fullWidth
                                        margin="normal"
                                    />
                                )} />
                        </Grid>
                        <Grid item md={6}>
                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        sx={{ minWidth: 300 }}
                                        label="Email"
                                        fullWidth
                                        margin="normal"
                                    />
                                )} />
                        </Grid>
                        <Grid item md={6}>
                            <Controller
                                name="business_name"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        sx={{ minWidth: 300 }}
                                        label="Business/Company Name"
                                        fullWidth
                                        margin="normal"
                                    />
                                )} />
                        </Grid>
                        <Grid item md={6}>
                            <Controller
                                name="gst_number"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        value={ (field?.value || '')?.toUpperCase()}
                                        sx={{ minWidth: 300 }}
                                        label="GST Number"
                                        fullWidth
                                        margin="normal"
                                    />
                                )} />
                        </Grid>
                        { isAdmin && <Grid item md={6}>
                            <Controller
                                name="charges"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        sx={{ minWidth: 300 }}
                                        label="Interpretation charge"
                                        fullWidth
                                        margin="normal"
                                    />
                                )} />
                        </Grid> }
                        <Grid item md={6}>
                            <Controller
                                name="billing_address"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        sx={{ minWidth: 300 }}
                                        label="Billing Address"
                                        fullWidth
                                        margin="normal"
                                    />
                                )} />
                        </Grid>

                        <Grid item md={6}>
                            <Controller
                                name="state"
                                control={control}
                                defaultValue=""
                                render={({ field }) => (
                                    <Autocomplete
                                        {...field}
                                        sx={{ minWidth: 300 }}
                                        options={indianStates}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Select State" margin="normal" />
                                        )}
                                        onChange={(event, value) => {
                                            field.onChange(value);
                                        }}
                                    />
                                )} />
                        </Grid>

                        <Grid item xs={12} textAlign='center'>
                            <Divider sx={{ my: 2 }} />
                            <LoadingButton
                                sx={{ minWidth: 250 }}
                                size="large"
                                type="submit"
                                variant="contained"
                                loading={isSubmitting}
                            >
                                Save
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </FormProvider>
            </Card>
        </>
    )
}

export default B2BProfile;