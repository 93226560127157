// utils
import { fetcher, endpoints, poster, patcher } from '../utils/axios';

export async function createUser(payload){
    const URL = endpoints.profile.createUser
    return await poster([URL,payload])
}
export async function removeUser(payload){
    const URL = endpoints.profile.removeUser
    return await poster([URL,payload])
}

export async function resendOTP(payload) {
    const URL = endpoints.profile.resendOTP;
    return await fetcher([URL, {params:payload}])
}