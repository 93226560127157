import { Box, Button, Card, CardContent, CardHeader, ButtonBase, Checkbox, Chip, Container, Divider, FormControl, FormControlLabel, Grid, Icon, IconButton, InputAdornment, InputLabel, List, ListItem, MenuItem, OutlinedInput, Radio, RadioGroup, Select, Stack, SvgIcon, TextField, Typography } from '@mui/material'
import Header from '../header/Header'
import Accent from './templates/Accent';
import Classic from './templates/Classic';
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/useAuthContext';
import dayjs from 'dayjs';
import { setTemplate, getTemplate } from 'api/users';

const ChooseResumeTemplate = () => {
    const location = useLocation();
    const { loading, user } = useAuth();
    const navigate = useNavigate();
    const data = location.state;
    // const { educations, work_history, template, summary, customSection, data?.certificates } = data;
    const [template, setTemplateType] = useState(data?.template || 'Accent');
    const backIcon = `${process.env.REACT_APP_HOMEPAGE}assets/arrow_back.svg`;
    const certificationIcon = `${process.env.REACT_APP_HOMEPAGE}assets/certification-img.png`;

    const linkedinIcon = (
        <Icon className="extLoginIcon">
            <img alt="edit" width="24px" height="24px" src={backIcon} />
        </Icon>
    );

    const changeNameFormat = (fullName) => {
        let words = fullName.split(' ');
        let name = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        return name;
    };

    const handleTemplateChange = async (data) => {
        setTemplateType(data);
        const response = await setTemplate({ data })
        if (response?.success === true && response.data) {

        } else if (response?.success === false) {
            enqueueSnackbar(response.message, { variant: 'error' })
        }
    };


    return (
        <Box sx={{ overflow: 'hidden', height: '100vh', paddingBottom: 0, backgroundColor: '#f1f5f7', paddingTop: '60px' }}>
            <Container maxWidth="xl">
                <Box>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={5}>
                            <Box className="choose-template-section">
                                <Box my={2}>
                                    <Button size="small" className="back-btn" startIcon={linkedinIcon} onClick={() => navigate('/job-application' ,{ state: { tab: '4' } })}>Back</Button>
                                </Box>
                                <Box mb={2}>
                                    <Typography className="step-heading" variant="h6" gutterBottom>
                                        Select template for your resume
                                    </Typography>
                                </Box>
                                <Box className="choose-template-wrapper">
                                    <FormControl component="fieldset">
                                        <Box className="template-card selected-classic" onClick={() => handleTemplateChange('Classic')}>
                                            <Card>
                                                <CardContent sx={{ padding: '0!important' }}>
                                                    <Box className="resume-type-header">
                                                        <FormControlLabel
                                                            value="Classic"
                                                            control={<Radio />}
                                                            label="Classic"
                                                            checked={template === 'Classic'}
                                                            onChange={(e) => handleTemplateChange(e.target.value)}
                                                        />
                                                    </Box>
                                                    <Box className="resume-content-info">
                                                        <Box className="resume-content-header">
                                                            <Typography className="name" variant="p" gutterBottom>{changeNameFormat(user.name)}</Typography>
                                                            <Stack direction="row" spacing={1}>
                                                                <Typography className="email" variant="p" gutterBottom>{user.email}</Typography>
                                                                <Divider orientation="vertical" variant="middle" flexItem />
                                                                <Typography className="mobile-no" variant="p" gutterBottom>{user.phone}</Typography>
                                                            </Stack>
                                                        </Box>
                                                        <Box className="resume-inner-info">
                                                            <Typography className="info-txt" component="p" variant="p" gutterBottom>{data?.summary}</Typography>
                                                            <Divider sx={{ width: '100%', borderStyle: 'dashed', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} />
                                                            <Box className="resume-info-row">
                                                                <Box>
                                                                    <Typography className="main-title" component="p" variant="p" gutterBottom>Education</Typography>
                                                                    <Typography className="sub-title" component="p" variant="p" gutterBottom>{data?.educations[0]?.education_institute_name || 'degree data'}</Typography>
                                                                </Box>
                                                                <Box mb={1} className="candidate-personal-info">
                                                                    <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                                                                        <Box>
                                                                            <Stack className="list-item" direction="row" spacing={2}>
                                                                                <Box>
                                                                                    <Typography className="degree-name" variant="p" gutterBottom>{data?.educations[0]?.degree_received || 'degree data'}</Typography>
                                                                                </Box>
                                                                                <Box>
                                                                                    <Typography className="degree-type" variant="p" gutterBottom>{data?.educations[0]?.field_of_study || 'education data'}</Typography>
                                                                                </Box>
                                                                            </Stack>
                                                                        </Box>
                                                                        <Typography className="year-of-passout" variant="p" gutterBottom>{dayjs(data?.educations[0]?.education_from).format('YYYY') || '2XXX'} - {dayjs(data?.educations[0]?.education_to).format('YYYY') || '2XXX'}</Typography>
                                                                    </Stack>
                                                                </Box>
                                                                <Divider sx={{ width: '100%', borderStyle: 'dashed', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} />
                                                            </Box>
                                                            <Box className="resume-info-row">
                                                                <Box>
                                                                    <Typography className="main-title" component="p" variant="p" gutterBottom>Certification</Typography>
                                                                    <Typography className="sub-title" component="p" variant="p" gutterBottom>{data?.certificates[0]?.education_institute_name || "certificate institute"}</Typography>
                                                                </Box>
                                                                <Box className="candidate-personal-info">
                                                                    <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                                                                        <Box>
                                                                            <Stack className="list-item" direction="row" spacing={2}>
                                                                                <Box>
                                                                                    <Typography className="degree-name" variant="p" gutterBottom>
                                                                                        {data?.certificates[0]?.certificate_received || 'certificate title'}
                                                                                    </Typography>
                                                                                </Box>
                                                                                <Box>
                                                                                    <Typography className="degree-type" variant="p" gutterBottom>
                                                                                        {data?.certificates[0]?.field_of_study || "cerificate data"}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Stack>
                                                                        </Box>
                                                                        <Box>
                                                                            <Stack direction="row" alignItems={'center'} spacing={1}>
                                                                                <Box>
                                                                                    <Box
                                                                                        component="img"
                                                                                        alt="certification"
                                                                                        src={certificationIcon}
                                                                                    />
                                                                                </Box>
                                                                                <Typography className="year-of-passout" variant="p" gutterBottom>{dayjs(data?.certificates[0]?.education_to).format('YYYY') || '2XXX'}</Typography>
                                                                            </Stack>
                                                                        </Box>
                                                                    </Stack>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Box>
                                        <Box className="template-card selected-accent" onClick={() => handleTemplateChange('Accent')}>
                                            <Card>
                                                <CardContent sx={{ padding: '0!important' }}>
                                                    <Box className="resume-type-header">
                                                        <FormControlLabel
                                                            value="Accent"
                                                            control={<Radio />}
                                                            label="Accent"
                                                            checked={template === 'Accent'}
                                                            onChange={(e) => handleTemplateChange(e.target.value)}
                                                        />
                                                    </Box>
                                                    <Box className="resume-content-info">
                                                        <Box className="resume-content-header">
                                                            <Typography className="name" variant="p" gutterBottom>{changeNameFormat(user.name)}</Typography>
                                                            <Stack direction="row" spacing={1}>
                                                                <Typography className="email" variant="p" gutterBottom>{user.email}</Typography>
                                                                <Divider orientation="vertical" variant="middle" flexItem />
                                                                <Typography className="mobile-no" variant="p" gutterBottom>{user.phone}</Typography>
                                                            </Stack>
                                                        </Box>
                                                        <Box className="resume-inner-info">
                                                            <Typography className="info-txt" component="p" variant="p" gutterBottom>{data?.summary}</Typography>
                                                            <Divider sx={{ width: '100%', borderStyle: 'dashed', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} />
                                                            <Box className="resume-info-row">
                                                                <Box>
                                                                    <Typography className="main-title" component="p" variant="p" gutterBottom>Education</Typography>
                                                                    <Typography className="sub-title" component="p" variant="p" gutterBottom>{data?.educations[0]?.education_institute_name || 'degree data'}</Typography>
                                                                </Box>
                                                                <Box mb={1} className="candidate-personal-info">
                                                                    <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                                                                        <Box>
                                                                            <Stack className="list-item" direction="row" spacing={2}>
                                                                                <Box>
                                                                                    <Typography className="degree-name" variant="p" gutterBottom>{data?.educations[0]?.degree_received || 'degree data'}</Typography>
                                                                                </Box>
                                                                                <Box>
                                                                                    <Typography className="degree-type" variant="p" gutterBottom>{data?.educations[0]?.field_of_study || 'education data'}</Typography>
                                                                                </Box>
                                                                            </Stack>
                                                                        </Box>
                                                                        <Typography className="year-of-passout" variant="p" gutterBottom>{dayjs(data?.educations[0]?.education_from).format('YYYY') || '2XXX'} - {dayjs(data?.educations[0]?.education_to).format('YYYY') || '2XXX'}</Typography>
                                                                    </Stack>
                                                                </Box>
                                                                <Divider sx={{ width: '100%', borderStyle: 'dashed', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} />
                                                            </Box>
                                                            <Box className="resume-info-row">
                                                                <Box>
                                                                    <Typography className="main-title" component="p" variant="p" gutterBottom>Certification</Typography>
                                                                    <Typography className="sub-title" component="p" variant="p" gutterBottom>{data?.certificates[0]?.education_institute_name || "certificate institute"}</Typography>
                                                                </Box>
                                                                <Box className="candidate-personal-info">
                                                                    <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                                                                        <Box>
                                                                            <Stack className="list-item" direction="row" spacing={2}>
                                                                                <Box>
                                                                                    <Typography className="degree-name" variant="p" gutterBottom>
                                                                                        {data?.certificates[0]?.certificate_received || 'certificate title'}
                                                                                    </Typography>
                                                                                </Box>
                                                                                <Box>
                                                                                    <Typography className="degree-type" variant="p" gutterBottom>
                                                                                        {data?.certificates[0]?.field_of_study || "cerificate data"}
                                                                                    </Typography>
                                                                                </Box>
                                                                            </Stack>
                                                                        </Box>

                                                                        <Box>
                                                                            <Stack direction="row" alignItems={'center'} spacing={1}>
                                                                                <Box>
                                                                                    <Box
                                                                                        component="img"
                                                                                        alt="certification"
                                                                                        src={certificationIcon}
                                                                                    />
                                                                                </Box>
                                                                                <Typography className="year-of-passout" variant="p" gutterBottom>{dayjs(data?.certificates[0]?.education_to).format('YYYY') || '2XXX'}</Typography>
                                                                            </Stack>
                                                                        </Box>
                                                                    </Stack>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </CardContent>
                                            </Card>
                                        </Box>
                                    </FormControl>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={7}>
                            {template === 'Accent' && <Accent data={data} />}
                            {template === 'Classic' && <Classic data={data} />}
                        </Grid>
                    </Grid>
                </Box >
            </Container >
        </Box >
    )
}

export default ChooseResumeTemplate
