import React, { useState, useEffect } from 'react';
import {
  Avatar,
  Box,
  Button,
  Link,
  Slider,
  Chip,
  Grid,
  IconButton,
  Stack,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  ListItemIcon,
  SvgIcon,
  Popover,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
} from '@mui/material';
import SlickSlider from 'react-slick';
import { useAuth } from 'hooks/useAuthContext';
import { useNavigate } from 'react-router-dom';
import { getJobList } from 'api/job';
import { getTimeDifferenceString, changeNameFormat, formatNumber } from 'utils/Utils';
import dayjs from 'dayjs';
import { SplashScreen } from 'components/loading-screen';
import ExternalJob from './ExternalJob';

const JobNotMatchingIcon = `${process.env.REACT_APP_HOMEPAGE}assets/job-not-matching-icon.png`;
const ModSuitableIcon = `${process.env.REACT_APP_HOMEPAGE}assets/moderately-suitable-icon.png`;
const suitableIcon = `${process.env.REACT_APP_HOMEPAGE}assets/highly-suitable-icon.png`;
const likeIcon = `${process.env.REACT_APP_HOMEPAGE}assets/like-icon.png`;
const WavingHand = `${process.env.REACT_APP_HOMEPAGE}assets/waving-hand-icon.png`;

const Dashboard = ({ onSelectTab }) => {
  const { user, updateContext } = useAuth();
  const [allApplications, setAllApplications] = useState([]);
  const [savedApplications, setSavedApplications] = useState([]);
  const [inprogressApplications, setInprogressApplications] = useState([]);
  const [updatedAt, setUpdatedAt] = useState(null);
  const [stages, setStages] = useState(0);
  const [jobs, setJobs] = useState([]);
  const [visible, setVisible] = useState(true);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [applicationStage, setApplicationStage] = useState([
    'Application Sent',
    'Application Reviewed',
    'Interview Scheduled',
    'Final Offer',
  ]);

  const settings = {
    dots: false,
    infinite: false,
    draggable: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 2,
  };

  const [savedHideArrows, setSavedHideArrows] = useState();
  const [recommendedHideArrows, setRecommendedHideArrows] = useState();
  const [alignment, setAlignment] = useState('jobs-saved');
  const fetchJobs = async () => {
    try {
      const response = await getJobList();
      if (response?.success && response?.data) {
        if (response?.data && response?.data?.jobs) {
          const nonAppliedJobs = response?.data?.jobs?.filter((job) => job?.application_status === 'Not Applied');
          setJobs(nonAppliedJobs);
          setRecommendedHideArrows(nonAppliedJobs?.length <= settings.slidesToShow);
        }
        if (response?.data && response?.data?.candidate) {
          setUpdatedAt(response?.data?.candidate?.updatedAt);
          setStages(response?.data?.candidate?.stages);
        }
        if (response?.data && response?.data?.application) {
          const savedApps = response?.data?.application?.filter((application) => application?.status === 'saved');
          setSavedApplications(savedApps);
          if (!savedApps || savedApps?.length===0){
            setAlignment('jobs-recommended');
          }
          setSavedHideArrows(savedApps?.length <= settings.slidesToShow);
          const nonSavedApps = response?.data?.application?.filter((application) => application?.status !== 'saved');
          setAllApplications(nonSavedApps);
          const inprogressApplications = response?.data?.application?.filter(
            (application) => application?.status === 'in-progress'
          );
          setInprogressApplications(inprogressApplications);
        }
      } else {
        console.error('Failed to fetch jobs:', response?.message);
      }
    } catch (error) {
      console.error('Error fetching jobs:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobs();
  }, []);

  const handleToggleChange = (event, newAlignment) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const handleCategoryRecommendedChange = (job) => {
    onSelectTab('2', job);
  };
  const handleRecommendedViewAllClick = () => {
    onSelectTab('2', jobs[null]);
  };

  const handleApplicationViewAllClick = () => {
    onSelectTab('3', null, 'applied-jobs', true);
};

  const handleAppliedViewAllClick = () => {
    onSelectTab('3', allApplications[0], 'applied-jobs');
  };

  const handleCategorySavedChange = (job) => {
    onSelectTab('3', job, 'saved-jobs');
  };
  const handleSavedViewAllClick = () => {
    onSelectTab('3', savedApplications[null], 'saved-jobs');
  };
  const handleCategoryAppliedChange = (job) => {
    onSelectTab('3', job, 'applied-jobs');
  };
  const [open, setOpenPopover] = React.useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handlePopoverOpen = (event, index) => {
    setAnchorEl(event.currentTarget);
    setOpenPopover(index);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setOpenPopover(null);
  };

  const updateProfile = (profile) => {
    if (profile == 4) {
      return 'Your profile is 100% complete.';
    } else if (profile == 3) {
      return 'Your profile is 75% complete.';
    } else if (profile == 2) {
      return 'Your profile is 50% complete.';
    } else if (profile == 1) {
      return 'Your profile is 25% complete.';
    } else {
      return 'Your profile is 0% complete.';
    }
  };

  const recommendedChip = (score) => {
    if (score > 3) {
      return (
        <Chip
          className="chip-job-matching chip-suitable"
          avatar={<Avatar alt="Natacha" src={suitableIcon} />}
          label="Highly Suitable Job For You"
          size="small"
        />
      );
    } else if (score < 2) {
      return (
        <Chip
          className="chip-job-matching chip-not-matching"
          avatar={<Avatar alt="Natacha" src={JobNotMatchingIcon} />}
          label="Job Not Matching Your Profile"
          size="small"
        />
      );
    } else {
      return (
        <Chip
          className="chip-job-matching chip-mod-suitable"
          avatar={<Avatar alt="Natacha" src={ModSuitableIcon} />}
          label="Moderately Suitable For You"
          size="small"
        />
      );
    }
  };

  const updateBeforeSixMonth = (updatedAt = null) => {
    if (!updatedAt) return false;
    const sixMonthsBeforeNow = dayjs().subtract(6, 'months');
    return dayjs(updatedAt).isBefore(sixMonthsBeforeNow);
  };
  const calculateStage = (status) => {
    switch (status) {
      case 'applied':
        return 1;
      case 'shortlist':
        return 2;
      case 'Final Offer':
        return 3;
      default:
        return 0;
    }
  };
  const handleApplicationStageList = (status, index) => {
    let stage = calculateStage(status);
    if (stage === index) {
      return 'process-in-review';
    } else if (stage > index) {
      return 'process-completed';
    } else {
      return null;
    }
  };
  const handleApplicationStageStartIcon = (status, index) => {
    let stage = calculateStage(status);
    if (stage <= index) {
      return <Typography variant="p">{index + 1}</Typography>;
    } else {
      return (
        <SvgIcon sx={{ width: '14px', height: '14px' }}>
          <svg xmlns="http://www.w3.org/2000/svg" width="8" height="6" viewBox="0 0 8 6" fill="none">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.40072 1.53905L3.51229 5.42748C3.24811 5.69166 2.81056 5.69166 2.53812 5.42748L0.598039 3.48739C0.333857 3.22321 0.333857 2.78566 0.598039 2.51322C0.862221 2.24904 1.29977 2.24904 1.57221 2.51322L3.03347 3.97448L6.43481 0.573136C6.69899 0.308955 7.13654 0.308955 7.40898 0.573136C7.6649 0.837318 7.6649 1.27487 7.40072 1.53905Z"
              fill="#0E7819"
              stroke="#E7FFEA"
              strokeWidth="0.6"
            />
          </svg>
        </SvgIcon>
      );
    }
  };
  const calculateSliderValue = (status) => {
    switch (status) {
      case 'applied':
        return 33.3333;
      case 'shortlist':
        return 66.6666;
      case 'Final Offer':
        return 100;
      default:
        return 0;
    }
  };

  if (loading) {
    return (
      <Grid
        container
        justifyContent="center"
        sx={{ position: 'relative', overflow: 'hidden', width: '100%', height: 'calc(100vh - 110px)' }}
        alignItems="center"
      >
        <SplashScreen />
      </Grid>
    );
  }

  return (
    <Box className="dashboard-page-wrapper" sx={{backgroundColor: '#ffffff'}}>   
      <Box
        className="dashboard-content-wrapper"
        sx={{
          position: 'relative',
          overflow: 'hidden',
          width: '100%',
          height: 'calc(100vh - 110px)',
        }}
      >
         <Box className="dashboard-candi-title">
                <Stack direction="row" alignItems={'center'} spacing={1}>
                    <img alt="name" width="36px" height="36px" src={WavingHand} />
                    <Typography className="candidate-name">Welcome {changeNameFormat(user?.name)}</Typography>     
                </Stack>   
          </Box> 
          {allApplications && allApplications?.length > 0 && (        
        <Box className="track-job-applications" mt={4}> 
          <Box className="track-app-card-block">
            <Box mb={3}>
              {inprogressApplications && inprogressApplications?.length ? (
                <Box className="application-pending" mb={3}>
                  <Typography className="title">Application Pending</Typography>
                  <Typography className="info-text">
                    {`We noticed you were applying to ${inprogressApplications?.length} external job ${
                      inprogressApplications?.length > 1 ? 'applications' : 'application'
                    }, you can view & update their status for better tracking.`}
                     <Link href="#" underline="none" className="view-all" ml={1} onClick={handleApplicationViewAllClick}>
                                    View All <SvgIcon sx={{width: '14px', height: '14px'}}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M5.9668 2.7193L10.3135 7.06596C10.8268 7.5793 10.8268 8.4193 10.3135 8.93263L5.9668 13.2793" stroke="#1575C6" stroke-width="1.33333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
                                            </SvgIcon>
                                </Link>
                  </Typography>
                </Box>
              ) : null}

              {allApplications && allApplications?.length > 0 && (
                <>
                  <Typography className="text-main" mb={0.5}>
                    Track your recent job applications
                  </Typography>
                  <Typography className="text-sub">
                    Showing applications you applied for job in last 30 days
                    <Link href="#" underline="none" className="view-all" ml={1} display={'inline-flex'} onClick={handleAppliedViewAllClick} >
                      View All{' '}
                      <SvgIcon sx={{ width: '14px', height: '14px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                          <path
                            d="M5.9668 2.7193L10.3135 7.06596C10.8268 7.5793 10.8268 8.4193 10.3135 8.93263L5.9668 13.2793"
                            stroke="#1575C6"
                            stroke-width="1.33333"
                            stroke-miterlimit="10"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </SvgIcon>
                    </Link>
                  </Typography>
                </>
              )}
            </Box>

            <Box className="dashboard-card-row">
              {allApplications &&
                allApplications?.map((item, index) => (
                  <Box className="card-wrapper" key={index}>
                    <Box className="card-content-top" onClick={() => handleCategoryAppliedChange(item)}>
                      <Stack
                        direction="row"
                        alignItems={'center'}
                        justifyContent={'space-between'}
                        sx={{ width: '100%' }}
                      >
                        <Typography className="job-title">{item?.job_id?.title || ''}</Typography>
                        <IconButton className="arrow-btn" sx={{ padding: 0 }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                          >
                            <path
                              d="M1.84066 8.9703L12.8552 8.9703L9.37689 12.2584C9.1595 12.4639 9.1595 12.8064 9.37689 13.0119C9.59428 13.2174 9.9566 13.2174 10.174 13.0119L14.5218 8.83329C14.5943 8.76479 14.6667 8.62779 14.6667 8.49079C14.6667 8.35378 14.5943 8.21678 14.5218 8.14828L10.174 3.96968C10.1015 3.90118 9.9566 3.83268 9.81168 3.83268C9.66675 3.83268 9.52182 3.90118 9.44936 3.96968C9.23197 4.17519 9.23197 4.5177 9.44936 4.7232L12.9276 8.01128L1.91313 8.01128C1.62327 8.01128 1.33341 8.21678 1.33341 8.55929C1.33341 8.9018 1.55081 8.9703 1.84066 8.9703Z"
                              fill="#111827"
                            />
                          </svg>
                        </IconButton>
                      </Stack>
                      <Typography className="job-company" mb={1.5}>
                        {item?.job_id?.company || ''}
                      </Typography>
                      <Typography className="applied-days">
                        {item?.applied_at
                          ? getTimeDifferenceString(item?.applied_at, 'Applied')
                          : 'Application Pending'}
                      </Typography>
                    </Box>
                    {/* {item?.status == 'applied' ? ( */}
                    <Box
                      className="card-content-bottom track-status-block custom-card-bottom-margin"
                      aria-owns={open ? 'track-job-status' : undefined}
                      aria-haspopup="true"
                      onMouseEnter={(event) => handlePopoverOpen(event, index)}
                      onMouseLeave={handlePopoverClose}
                    >
                      <Stack
                        direction="row"
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        spacing={4}
                        sx={{ width: '100%' }}
                      >
                        <Chip className="current-track-status-chip" label={item?.status} />
                        <Box sx={{ width: '80px' }}>
                          <Slider
                            key={index}
                            disabled
                            className="track-status-slider"
                            aria-label="Custom marks"
                            defaultValue={calculateSliderValue(item?.status)}
                            step={33.3333}
                            marks
                            max={100}
                          />
                        </Box>
                      </Stack>
                      <Popover
                        elevation={3}
                        id={`track-job-status-${index}`}
                        className="job-track-menu-list"
                        sx={{ pointerEvents: 'none' }}
                        open={open === index}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'center',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                      >
                        <Box className="track-list">
                          <List sx={{ width: 220 }}>
                            {applicationStage?.map((stageName, stageIndex) => (
                              <ListItem
                                disablePadding
                                key={stageIndex}
                                className={handleApplicationStageList(item?.status, stageIndex)}
                              >
                                <ListItemButton>
                                  <ListItemIcon>
                                    {handleApplicationStageStartIcon(item?.status, stageIndex)}
                                  </ListItemIcon>
                                  <ListItemText sx={{ margin: 0 }} primary={stageName} />
                                </ListItemButton>
                              </ListItem>
                            ))}
                          </List>
                        </Box>
                      </Popover>
                    </Box>
                  </Box>
                ))}
            </Box>
          </Box>

          {updateBeforeSixMonth(updatedAt || null) && visible && (
            <Box className="update-profile-section">
              <Typography className="title">Update Your Profile</Typography>
              <Typography className="info-text">{updateProfile(stages)}</Typography>
              <Typography className="info-text">
                It has been more than {getTimeDifferenceString(updatedAt, 'Updated')} 😳 since you updated your profile.
              </Typography>
              <Box className="resume-update-msg" mb={5}>
                Updating resume every 6 months has{' '}
                <Typography variant="p" sx={{ fontWeight: 700 }}>
                  {' '}
                  helped 70%
                </Typography>{' '}
                of our candidates
              </Box>
              <Stack justifyContent={'center'} spacing={3} sx={{ width: '100%' }}>
                <Button
                  variant="contained"
                  size="large"
                  className="update-now-btn"
                  onClick={() => navigate('/profile')}
                  fullWidth
                >
                  Update Now
                </Button>
                <Link
                  onClick={() => setVisible(false)}
                  underline="none"
                  className="cancel-update-link"
                  component="button"
                >
                  No Updates Required
                </Link>
              </Stack>
            </Box>
          )}
        </Box>
        )}

        <Box className="explore-new-opp">
          <Box className={`explore-new-opp-container ${allApplications?.length > 0 && 'bg-light-blue'}`}>
            <Box>
              <Typography className="title" mb={3}>
                Explore New Opportunities
              </Typography>

              <Box mb={2}>
                <ToggleButtonGroup
                  className="jobs-toggle-btn"
                  color="primary"
                  value={alignment}
                  exclusive
                  onChange={handleToggleChange}
                  aria-label="Platform"
                >
                  <ToggleButton value="jobs-saved">Saved Jobs</ToggleButton>
                  <ToggleButton value="jobs-recommended">Recommended Jobs</ToggleButton>
                </ToggleButtonGroup>
              </Box>

              {alignment === 'jobs-saved' && (
                <Stack direction="row" alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }}>
                  <Box>
                    {/* <Typography className="text-1">Saved Jobs</Typography> */}
                    <Typography className="text-1">
                      You have saved {savedApplications?.length} {savedApplications?.length <= 1 ? 'job' : 'jobs'} for
                      apply
                      <Link
                        href="#"
                        underline="none"
                        className="view-all"
                        ml={1}
                        display={'inline-flex'}
                        onClick={handleSavedViewAllClick}
                      >
                        View All{' '}
                        <SvgIcon sx={{ width: '14px', height: '14px' }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M5.9668 2.7193L10.3135 7.06596C10.8268 7.5793 10.8268 8.4193 10.3135 8.93263L5.9668 13.2793"
                              stroke="#1575C6"
                              stroke-width="1.33333"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </SvgIcon>
                      </Link>
                    </Typography>
                  </Box>
                  {/* <Button variant="outlined" className="view-all-btn">
                    View All
                  </Button> */}
                </Stack>
              )}
              {alignment === 'jobs-recommended' && (
                <Stack direction="row" alignItems={'center'} justifyContent={'space-between'} sx={{ width: '100%' }}>
                  <Box>
                    {/* <Typography className="text-1">Jobs</Typography> */}
                    <Typography className="text-1">
                      You have {jobs?.length} {jobs?.length <= 1 ? 'job' : 'recommended jobs'} for apply
                      <Link
                        href="#"
                        underline="none"
                        className="view-all"
                        ml={1}
                        display={'inline-flex'}
                        onClick={handleRecommendedViewAllClick}
                      >
                        View All{' '}
                        <SvgIcon sx={{ width: '14px', height: '14px' }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M5.9668 2.7193L10.3135 7.06596C10.8268 7.5793 10.8268 8.4193 10.3135 8.93263L5.9668 13.2793"
                              stroke="#1575C6"
                              stroke-width="1.33333"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </SvgIcon>
                      </Link>
                    </Typography>
                  </Box>
                  {/* <Button variant="outlined" className="view-all-btn">
                    View All
                  </Button> */}
                </Stack>
              )}
            </Box>
            {alignment === 'jobs-saved' && savedApplications && savedApplications?.length > 0 && (
              <SlickSlider {...settings} arrows={!savedHideArrows}>
                {savedApplications &&
                  savedApplications?.map((item, index) => (
                    <Box className="card-wrapper" key={index}>
                      <Box className="card-content-top" onClick={() => handleCategorySavedChange(item)}>
                        {recommendedChip(item?.tj_score)}
                        <Stack
                          direction="row"
                          alignItems={'center'}
                          justifyContent={'space-between'}
                          sx={{ width: '100%' }}
                        >
                          <Typography className="job-title">{item?.job_id?.title || ''}</Typography>
                          <IconButton className="arrow-btn" sx={{ padding: 0 }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                              fill="none"
                            >
                              <path
                                d="M1.84066 8.9703L12.8552 8.9703L9.37689 12.2584C9.1595 12.4639 9.1595 12.8064 9.37689 13.0119C9.59428 13.2174 9.9566 13.2174 10.174 13.0119L14.5218 8.83329C14.5943 8.76479 14.6667 8.62779 14.6667 8.49079C14.6667 8.35378 14.5943 8.21678 14.5218 8.14828L10.174 3.96968C10.1015 3.90118 9.9566 3.83268 9.81168 3.83268C9.66675 3.83268 9.52182 3.90118 9.44936 3.96968C9.23197 4.17519 9.23197 4.5177 9.44936 4.7232L12.9276 8.01128L1.91313 8.01128C1.62327 8.01128 1.33341 8.21678 1.33341 8.55929C1.33341 8.9018 1.55081 8.9703 1.84066 8.9703Z"
                                fill="#111827"
                              />
                            </svg>
                          </IconButton>
                        </Stack>

                        <Typography className="job-company">{item?.job_id?.company || ''}</Typography>
                        <Box mb={1} className="work-type">
                          <Stack direction="row" alignItems={'center'} spacing={0.5}>
                            <Typography variant="p">{item?.job_id?.location}</Typography>
                            <Typography variant="body1">•</Typography>
                            <Typography variant="p">{item?.job_id?.job_type}</Typography>
                            <Typography variant="body1">•</Typography>
                            <Typography variant="p">{item?.job_id?.work_mode}</Typography>
                          </Stack>
                        </Box>

                        <Box mb={2}>
                          <Stack direction="row" spacing={1}>
                            <Stack spacing={0.5}>
                              <Typography variant="p" className="package">
                                ₹{formatNumber(item?.job_id?.salary)}
                              </Typography>
                              <Typography variant="body1" className="package-type">
                                {item?.job_id?.compensation}
                              </Typography>
                            </Stack>
                            <Box></Box>
                          </Stack>
                        </Box>
                        <Typography className="posted-date">
                          {item?.job_id?.updatedAt ? getTimeDifferenceString(item?.job_id?.updatedAt, 'Posted') : 'N/A'}
                        </Typography>
                      </Box>

                      <Box className="card-content-bottom" onClick={() => handleCategorySavedChange(item)}>
                        <Button
                          endIcon={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M13.0331 0.644197C13.1728 0.702374 13.2891 0.818726 13.3473 0.95835H13.3531C13.3822 1.02816 13.3997 1.10379 13.3997 1.17942V4.62928C13.3997 4.94925 13.1379 5.21104 12.8179 5.21104C12.4979 5.21104 12.2361 4.94925 12.2361 4.62928V2.58147L7.41331 7.40429C7.29696 7.51482 7.15152 7.573 7.00026 7.573C6.849 7.573 6.70356 7.52064 6.58721 7.40429C6.36032 7.1774 6.36032 6.81089 6.58721 6.584L11.41 1.76118H9.36222C9.04225 1.76118 8.78046 1.49939 8.78046 1.17942C8.78046 0.85945 9.04225 0.597656 9.36222 0.597656H12.8121C12.8877 0.597656 12.9633 0.615109 13.0331 0.644197ZM12.23 7.001C12.23 6.68103 12.4918 6.41924 12.8118 6.41924C13.1318 6.41924 13.3936 6.68103 13.3936 7.001V11.0443C13.3936 12.3416 12.3348 13.4004 11.0374 13.4004H2.95673C1.6594 13.4004 0.600586 12.3416 0.600586 11.0443V2.96356C0.600586 1.66623 1.6594 0.607422 2.95673 0.607422H6.99999C7.31996 0.607422 7.58175 0.869215 7.58175 1.18919C7.58175 1.50916 7.31996 1.77095 6.99999 1.77095H2.95673C2.29934 1.77095 1.76411 2.30617 1.76411 2.96356V11.0443C1.76411 11.7017 2.29934 12.2369 2.95673 12.2369H11.0374C11.6948 12.2369 12.23 11.7017 12.23 11.0443V7.001Z"
                                fill="white"
                              />
                            </svg>
                          }
                        >
                          Apply Now
                        </Button>
                      </Box>
                    </Box>
                  ))}
              </SlickSlider>
            )}
            {alignment === 'jobs-recommended' && jobs && jobs?.length > 0 && (
              <SlickSlider {...settings} arrows={!recommendedHideArrows}>
                {jobs &&
                  jobs?.map((item, index) => (
                    <Box className="card-wrapper" key={index}>
                      <Box className="card-content-top" onClick={() => handleCategoryRecommendedChange(item)}>
                        {recommendedChip(item?.tj_score)}
                        <Stack
                          direction="row"
                          alignItems={'center'}
                          justifyContent={'space-between'}
                          sx={{ width: '100%' }}
                        >
                          <Typography className="job-title">{item?.title || ''}</Typography>
                          <IconButton className="arrow-btn" sx={{ padding: 0 }}>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                              fill="none"
                            >
                              <path
                                d="M1.84066 8.9703L12.8552 8.9703L9.37689 12.2584C9.1595 12.4639 9.1595 12.8064 9.37689 13.0119C9.59428 13.2174 9.9566 13.2174 10.174 13.0119L14.5218 8.83329C14.5943 8.76479 14.6667 8.62779 14.6667 8.49079C14.6667 8.35378 14.5943 8.21678 14.5218 8.14828L10.174 3.96968C10.1015 3.90118 9.9566 3.83268 9.81168 3.83268C9.66675 3.83268 9.52182 3.90118 9.44936 3.96968C9.23197 4.17519 9.23197 4.5177 9.44936 4.7232L12.9276 8.01128L1.91313 8.01128C1.62327 8.01128 1.33341 8.21678 1.33341 8.55929C1.33341 8.9018 1.55081 8.9703 1.84066 8.9703Z"
                                fill="#111827"
                              />
                            </svg>
                          </IconButton>
                        </Stack>

                        <Typography className="job-company">{item?.company || ''}</Typography>
                        <Box mb={1} className="work-type">
                          <Stack direction="row" alignItems={'center'} spacing={0.5}>
                            <Typography variant="p">{item?.location}</Typography>
                            <Typography variant="body1">•</Typography>
                            <Typography variant="p">{item?.job_type}</Typography>
                            <Typography variant="body1">•</Typography>
                            <Typography variant="p">{item?.work_mode}</Typography>
                          </Stack>
                        </Box>

                        <Box mb={2}>
                          <Stack direction="row" spacing={1}>
                            <Stack spacing={0.5}>
                              <Typography variant="p" className="package">
                                ₹ {formatNumber(item?.salary)}
                              </Typography>
                              <Typography variant="body1" className="package-type">
                                {item?.compensation}
                              </Typography>
                            </Stack>
                            <Box>
                              {/* <Typography variant="p" className="bim-tag">
                              Best in Market
                            </Typography> */}
                            </Box>
                          </Stack>
                        </Box>
                        <Typography className="posted-date">
                          {item?.updatedAt ? getTimeDifferenceString(item?.updatedAt, 'Posted') : 'N/A'}
                        </Typography>
                      </Box>

                      <Box className="card-content-bottom">
                        <Button
                          endIcon={
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="14"
                              height="14"
                              viewBox="0 0 14 14"
                              fill="none"
                            >
                              <path
                                fillRule="evenodd"
                                clipRule="evenodd"
                                d="M13.0331 0.644197C13.1728 0.702374 13.2891 0.818726 13.3473 0.95835H13.3531C13.3822 1.02816 13.3997 1.10379 13.3997 1.17942V4.62928C13.3997 4.94925 13.1379 5.21104 12.8179 5.21104C12.4979 5.21104 12.2361 4.94925 12.2361 4.62928V2.58147L7.41331 7.40429C7.29696 7.51482 7.15152 7.573 7.00026 7.573C6.849 7.573 6.70356 7.52064 6.58721 7.40429C6.36032 7.1774 6.36032 6.81089 6.58721 6.584L11.41 1.76118H9.36222C9.04225 1.76118 8.78046 1.49939 8.78046 1.17942C8.78046 0.85945 9.04225 0.597656 9.36222 0.597656H12.8121C12.8877 0.597656 12.9633 0.615109 13.0331 0.644197ZM12.23 7.001C12.23 6.68103 12.4918 6.41924 12.8118 6.41924C13.1318 6.41924 13.3936 6.68103 13.3936 7.001V11.0443C13.3936 12.3416 12.3348 13.4004 11.0374 13.4004H2.95673C1.6594 13.4004 0.600586 12.3416 0.600586 11.0443V2.96356C0.600586 1.66623 1.6594 0.607422 2.95673 0.607422H6.99999C7.31996 0.607422 7.58175 0.869215 7.58175 1.18919C7.58175 1.50916 7.31996 1.77095 6.99999 1.77095H2.95673C2.29934 1.77095 1.76411 2.30617 1.76411 2.96356V11.0443C1.76411 11.7017 2.29934 12.2369 2.95673 12.2369H11.0374C11.6948 12.2369 12.23 11.7017 12.23 11.0443V7.001Z"
                                fill="white"
                              />
                            </svg>
                          }
                        >
                          Apply Now
                        </Button>
                      </Box>
                    </Box>
                  ))}
              </SlickSlider>
            )}
          </Box>
        </Box>

        <Box className="supercharge-your-career" >
          <Box className="supercharge-career-container bg-light-blue">
            <Box mb={3}>
              <Typography className="title">Steps to Your Next Career Progression</Typography>
              {/* <Typography className="info-text">We bring you the best finance specific content from around the world for continued career success</Typography>   */}
            </Box>

            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Box className="career-card">
                  <Box className="card-head">
                    <Typography className="title">Courses For You</Typography>
                    <Typography className="info-text">
                      These courses can help you progress to your next career milestone
                    </Typography>
                  </Box>

                  <Box className="card-body">
                    <Stack spacing={3}>
                      <Box>
                        <Stack direction="row" spacing={2}>
                          <Box>
                            <Box
                              sx={{
                                width: '72px',
                                height: '72px',
                                borderRadius: '12px',
                                backgroundColor: '#D9D9D9',
                              }}
                            ></Box>
                          </Box>

                          <Box>
                            <Typography className="category-title">
                              The Complete Financial Analyst Training & Investing Course
                            </Typography>
                            <Typography className="category-info">
                              Succeed as a Financial Analyst &Investor by Award Winning MBA Prof who worked @Goldman, in
                              Hedge Funds & Venture Capital
                            </Typography>

                            <Box mt={1} className="category-type">
                              <Stack direction="row" alignItems={'center'} spacing={0.7}>
                                <Typography variant="p">Video</Typography>
                                <Typography variant="body1">•</Typography>
                                <Typography variant="p">3-4 minutes</Typography>
                                <Typography variant="body1">•</Typography>
                                <Typography variant="p" className="likes">
                                  <img alt="name" width="12px" height="12px" src={likeIcon} /> 5.6K
                                </Typography>
                              </Stack>
                            </Box>
                          </Box>
                        </Stack>
                      </Box>

                      <Box>
                        <Stack direction="row" spacing={2}>
                          <Box>
                            <Box
                              sx={{
                                width: '72px',
                                height: '72px',
                                borderRadius: '12px',
                                backgroundColor: '#D9D9D9',
                              }}
                            ></Box>
                          </Box>

                          <Box>
                            <Typography className="category-title">
                              Chief Financial Officer Leadership Program
                            </Typography>
                            <Typography className="category-info">
                              An ever expanding program of courses helping financial professionals aspire to the top
                              levels of financial leadership
                            </Typography>

                            <Box mt={1} className="category-type">
                              <Stack direction="row" alignItems={'center'} spacing={0.7}>
                                <Typography variant="p">Video</Typography>
                                <Typography variant="body1">•</Typography>
                                <Typography variant="p">3-4 minutes</Typography>
                                <Typography variant="body1">•</Typography>
                                <Typography variant="p" className="likes">
                                  <img alt="name" width="12px" height="12px" src={likeIcon} /> 5.6K
                                </Typography>
                              </Stack>
                            </Box>
                          </Box>
                        </Stack>
                      </Box>

                      <Box>
                        <Stack direction="row" spacing={2}>
                          <Box>
                            <Box
                              sx={{
                                width: '72px',
                                height: '72px',
                                borderRadius: '12px',
                                backgroundColor: '#D9D9D9',
                              }}
                            ></Box>
                          </Box>

                          <Box>
                            <Typography className="category-title">QMS Auditor / Lead Auditor Course</Typography>
                            <Typography className="category-info">
                              Plan and Confidently Conduct Quality Management System Audits - Auditing Explained in
                              Plain and Simple Language.
                            </Typography>

                            <Box mt={1} className="category-type">
                              <Stack direction="row" alignItems={'center'} spacing={0.7}>
                                <Typography variant="p">Blog</Typography>
                                <Typography variant="body1">•</Typography>
                                <Typography variant="p">3-4 minutes</Typography>
                                <Typography variant="body1">•</Typography>
                                <Typography variant="p" className="likes">
                                  <img alt="name" width="12px" height="12px" src={likeIcon} /> 5.6K
                                </Typography>
                              </Stack>
                            </Box>
                          </Box>
                        </Stack>
                      </Box>

                      <Box>
                        <Stack direction="row" spacing={2}>
                          <Box>
                            <Box
                              sx={{
                                width: '72px',
                                height: '72px',
                                borderRadius: '12px',
                                backgroundColor: '#D9D9D9',
                              }}
                            ></Box>
                          </Box>

                          <Box>
                            <Typography className="category-title">
                              The Complete Financial Analyst Training & Investing Course
                            </Typography>
                            <Typography className="category-info">
                              Succeed as a Financial Analyst &Investor by Award Winning MBA Prof who worked @Goldman, in
                              Hedge Funds & Venture Capital
                            </Typography>

                            <Box mt={1} className="category-type">
                              <Stack direction="row" alignItems={'center'} spacing={0.7}>
                                <Typography variant="p">Article</Typography>
                                <Typography variant="body1">•</Typography>
                                <Typography variant="p">3-4 minutes</Typography>
                                <Typography variant="body1">•</Typography>
                                <Typography variant="p" className="likes">
                                  <img alt="name" width="12px" height="12px" src={likeIcon} /> 5.6K
                                </Typography>
                              </Stack>
                            </Box>
                          </Box>
                        </Stack>
                      </Box>
                    </Stack>

                    <Box sx={{ textAlign: 'center', width: '100%' }}>
                      <Button
                        className="view-all-btn"
                        endIcon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M5.96667 2.7193L10.3133 7.06596C10.8267 7.5793 10.8267 8.4193 10.3133 8.93263L5.96667 13.2793"
                              stroke="#1575C6"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        }
                      >
                        View all (43)
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box className="career-card">
                  <Box className="card-head">
                    <Typography className="title">Events Around You</Typography>
                    <Typography className="info-text">
                      These events would help you connect with people of similar interests and help you grow
                    </Typography>
                  </Box>

                  <Box className="card-body">
                    <Stack spacing={3}>
                      <Box>
                        <Stack direction="row" spacing={2}>
                          <Box>
                            <Box
                              sx={{
                                width: '72px',
                                height: '72px',
                                borderRadius: '12px',
                                backgroundColor: '#D9D9D9',
                              }}
                            ></Box>
                          </Box>

                          <Box>
                            <Typography className="category-title">Successful Engagement Workshop</Typography>
                            <Typography className="category-info">Some explainer</Typography>

                            <Box mt={1} className="category-type">
                              <Stack direction="row" alignItems={'center'} spacing={0.7}>
                                <Typography variant="p">Webinar</Typography>
                                <Typography variant="body1">•</Typography>
                                <Typography variant="p">90 minutes</Typography>
                                <Typography variant="body1">•</Typography>
                                <Typography variant="p" className="likes">
                                  <img alt="name" width="12px" height="12px" src={likeIcon} /> 5.6K
                                </Typography>
                              </Stack>
                            </Box>
                          </Box>
                        </Stack>
                      </Box>

                      <Box>
                        <Stack direction="row" spacing={2}>
                          <Box>
                            <Box
                              sx={{
                                width: '72px',
                                height: '72px',
                                borderRadius: '12px',
                                backgroundColor: '#D9D9D9',
                              }}
                            ></Box>
                          </Box>

                          <Box>
                            <Typography className="category-title">
                              Chief Financial Officer Leadership Program
                            </Typography>
                            <Typography className="category-info">
                              An ever expanding program of courses helping financial professionals aspire to the top
                              levels of financial leadership
                            </Typography>

                            <Box mt={1} className="category-type">
                              <Stack direction="row" alignItems={'center'} spacing={0.7}>
                                <Typography variant="p">Industry Event</Typography>
                                <Typography variant="body1">•</Typography>
                                <Typography variant="p">90 minutes</Typography>
                                <Typography variant="body1">•</Typography>
                                <Typography variant="p" className="likes">
                                  <img alt="name" width="12px" height="12px" src={likeIcon} /> 5.6K
                                </Typography>
                              </Stack>
                            </Box>
                          </Box>
                        </Stack>
                      </Box>

                      <Box>
                        <Stack direction="row" spacing={2}>
                          <Box>
                            <Box
                              sx={{
                                width: '72px',
                                height: '72px',
                                borderRadius: '12px',
                                backgroundColor: '#D9D9D9',
                              }}
                            ></Box>
                          </Box>

                          <Box>
                            <Typography className="category-title">QMS Auditor / Lead Auditor Course</Typography>
                            <Typography className="category-info">
                              Plan and Confidently Conduct Quality Management System Audits - Auditing Explained in
                              Plain and Simple Language.
                            </Typography>

                            <Box mt={1} className="category-type">
                              <Stack direction="row" alignItems={'center'} spacing={0.7}>
                                <Typography variant="p">Job Fair</Typography>
                                <Typography variant="body1">•</Typography>
                                <Typography variant="p">90 minutes</Typography>
                                <Typography variant="body1">•</Typography>
                                <Typography variant="p" className="likes">
                                  <img alt="name" width="12px" height="12px" src={likeIcon} /> 5.6K
                                </Typography>
                              </Stack>
                            </Box>
                          </Box>
                        </Stack>
                      </Box>

                      <Box>
                        <Stack direction="row" spacing={2}>
                          <Box>
                            <Box
                              sx={{
                                width: '72px',
                                height: '72px',
                                borderRadius: '12px',
                                backgroundColor: '#D9D9D9',
                              }}
                            ></Box>
                          </Box>

                          <Box>
                            <Typography className="category-title">
                              The Complete Financial Analyst Training & Investing Course
                            </Typography>
                            <Typography className="category-info">
                              Succeed as a Financial Analyst &Investor by Award Winning MBA Prof who worked @Goldman, in
                              Hedge Funds & Venture Capital
                            </Typography>

                            <Box mt={1} className="category-type">
                              <Stack direction="row" alignItems={'center'} spacing={0.7}>
                                <Typography variant="p">Webinar</Typography>
                                <Typography variant="body1">•</Typography>
                                <Typography variant="p">90 minutes</Typography>
                                <Typography variant="body1">•</Typography>
                                <Typography variant="p" className="likes">
                                  <img alt="name" width="12px" height="12px" src={likeIcon} /> 5.6K
                                </Typography>
                              </Stack>
                            </Box>
                          </Box>
                        </Stack>
                      </Box>
                    </Stack>

                    <Box sx={{ textAlign: 'center', width: '100%' }}>
                      <Button
                        className="view-all-btn"
                        endIcon={
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                          >
                            <path
                              d="M5.96667 2.7193L10.3133 7.06596C10.8267 7.5793 10.8267 8.4193 10.3133 8.93263L5.96667 13.2793"
                              stroke="#1575C6"
                              stroke-width="1.5"
                              stroke-miterlimit="10"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        }
                      >
                        View all (12)
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Dashboard;
