import * as yup from 'yup';


// Schema for validation
export const PasswordResetSchema = yup.object().shape({
  password: yup
  .string()
  .min(7, 'The password must be at least 6+ characters')
  .max(12, 'The password must be between 7 and 12 characters long')
  .matches(/^\S*$/, 'The password must not contain spaces') 
  .required('Please enter the password.'),
  confirm_password: yup
    .string()
    .required()
    .oneOf([yup.ref("password"), null], "Passwords must match")
});