import React from 'react'
import { Box, Button, Stack, SvgIcon, Container, Avatar, Typography, IconButton, Menu, MenuItem, ListItemIcon, Divider } from '@mui/material'
import { Link as RouterLink } from "react-router-dom";
import { styled } from '@mui/material/styles';
import Badge from '@mui/material/Badge';
import { useNavigate, useLocation } from 'react-router-dom';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAuth } from 'hooks/useAuthContext';

const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      animation: 'ripple 1.2s infinite ease-in-out',
      border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}));


const Header = () => {
  const Logo = `${process.env.REACT_APP_HOMEPAGE}assets/TJ-Logo-Black 1.svg`;
  const Default = `${process.env.REACT_APP_HOMEPAGE}assets/default.png`;
  const { loading, user, logout } = useAuth();
  const navigate = useNavigate();
  const handleLogOut = async (event) => {
    logout();
    // localStorage.clear()
  }
  const getInitial = (name) => {
    return name ? name.charAt(0).toUpperCase() : '';
  };

  const logoutIcon = (
    <SvgIcon>
      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
        <path d="M16 8C16 8.21236 15.9029 8.41406 15.734 8.5522L13.0673 10.734C12.9237 10.8516 12.7474 10.9091 12.5718 10.9091C12.3571 10.9091 12.1436 10.8228 11.9929 10.6552C11.7191 10.3501 11.756 9.89098 12.0755 9.62962L13.1784 8.72727H6.85714C6.43638 8.72727 6.09524 8.40163 6.09524 8C6.09524 7.59837 6.43638 7.27273 6.85714 7.27273H13.1784L12.0755 6.37038C11.756 6.10902 11.7191 5.64986 11.9929 5.34482C12.2671 5.04013 12.7485 5.00497 13.0673 5.26598L15.734 7.4478C15.9029 7.58594 16 7.78764 16 8ZM9.14286 13.0909C9.14286 13.8931 8.45945 14.5455 7.61905 14.5455H3.04762C2.2074 14.5455 1.52381 13.8931 1.52381 13.0909V2.90909C1.52381 2.10689 2.2074 1.45455 3.04762 1.45455H7.61905C8.45945 1.45455 9.14286 2.10689 9.14286 2.90909V6.18182H10.6667V2.90909C10.6667 1.30504 9.29948 0 7.61905 0H3.04762C1.36719 0 0 1.30504 0 2.90909V13.0909C0 14.695 1.36719 16 3.04762 16H7.61905C9.29948 16 10.6667 14.695 10.6667 13.0909V9.81818H9.14286V13.0909Z" fill="white" />
      </svg>
    </SvgIcon>
  );

  // resume action menu
  const [resumeActionAnchorEl, setResumeActionAnchorEl] = React.useState(null);
  const openActionMenu = Boolean(resumeActionAnchorEl);

  const openResumeActionMenu = (event) => {
    setResumeActionAnchorEl(event.currentTarget);
  };
  const closeResumeActionMenu = () => {
    setResumeActionAnchorEl(null);
  };
  const handleNavigate = (path) => {
    setResumeActionAnchorEl(null);
    navigate(path);
  }

  return (
    <Box className="header">
      <Container maxWidth="xl">
        <Box className="header-top">
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{ width: '100%' }}
          >
            <Box
              component="img"
              alt="Talent Junction"
              src={Logo}
              onClick={() => navigate('/')}
              sx={{ cursor: 'pointer' }}
            />
            {user?.user_type === "user" ?
              <Stack direction="row" alignItems={'center'} spacing={1.5}>
                <StyledBadge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  variant="dot"
                >
                  <Avatar alt={getInitial(user?.name)} src={user?.photo || Default} />
                </StyledBadge>
                <Typography className="candidate-name" onClick={openResumeActionMenu}>{user?.name}</Typography >
                <IconButton
                  aria-label="more"
                  aria-controls={openActionMenu ? 'resume-action-menu' : undefined}
                  aria-expanded={openActionMenu ? 'true' : undefined}
                  aria-haspopup="true"
                  onClick={openResumeActionMenu}
                  className="profile-dp-btn"
                >
                  {/* <KeyboardArrowDownIcon /> */}
                  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <path d="M3.4001 7.4248L8.83343 12.8581C9.4751 13.4998 10.5251 13.4998 11.1668 12.8581L16.6001 7.4248" stroke="white" stroke-width="1.3" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>
                </IconButton>

                <Menu
                  id="resume-action-menu"
                  className="profile-menu"
                  anchorEl={resumeActionAnchorEl}
                  open={openActionMenu}
                  onClose={closeResumeActionMenu}
                  sx={{ marginTop: 2 }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <MenuItem onClick={() => handleNavigate('/profile')}>
                    Basic Profile
                  </MenuItem>
                  <MenuItem onClick={() => handleNavigate('/job-preferences')}>
                    Job Preferences
                  </MenuItem>
                  <MenuItem onClick={closeResumeActionMenu}>
                    Help
                  </MenuItem>
                  <MenuItem onClick={handleLogOut}>
                    Log out
                  </MenuItem>
                </Menu>
              </Stack>
              : <Button className="logout-btn" variant="outlined" startIcon={logoutIcon} onClick={handleLogOut} >
                LogOut
              </Button>}
          </Stack>
        </Box>
        {/* <Box className="header-bottom-space"></Box> */}
      </Container>
    </Box>
  )
}

export default Header

