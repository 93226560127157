import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
// @mui
import { styled } from '@mui/system';
import {
  FormHelperText,
  Icon,
  Checkbox,
  Dialog,
  FormControlLabel,
  SvgIcon,
  OutlinedInput,
  FormControl,
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Box,
  Divider,
  Typography,
  Button,
  Link,
} from '@mui/material';
// components
import FormProvider from 'hooks/form/form-provider';
import { useSnackbar } from 'components/snackbar';
// import { useAuth } from 'hooks/useAuthContext';
// Validation
import ForgotPasswordDialog from '../../../components/login/ForgotPasswordDialog';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoginSchema } from 'models/LoginSchema';

// import { Link } from 'react-router-dom';
// APIs
import { useAuth } from 'hooks/useAuthContext';
// import PhoneInput from 'components/country-code-picker';

// ----------------------------------------------------------------------
const DisabledLink = styled(Link)(({ theme, isDisabled }) => ({
  '&:disabled': {
    color: theme.palette.text.disabled,
    pointerEvents: 'none',
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.text.disabled,
    },
  },
}));

export default function LoginForm() {
  // const { setLocalStorage } = useAuth();
  const { loading, login, user } = useAuth();

  /*----------------SnackBar Defination--------------------------------*/
  const { enqueueSnackbar } = useSnackbar();
  /*----------------SnackBar Defination--------------------------------*/

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [autoSaveDialogOpen, setAutoSetDialogOpen] = useState(false);

  const autoSaveJobAppDialog = () => {
    setAutoSetDialogOpen((prev) => !prev);
  };

  const defaultValues = {
    email: '',
    password: '',
  };

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(LoginSchema),
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    trigger,
    formState: { errors, isDirty, isValid, dirtyFields, isSubmitting },
  } = methods;

  const onSubmit = async (formData) => {
    const { success, error } = await login({ email: formData?.email, password: formData?.password });
    if (success?.success) {
      localStorage.setItem('prevPath', '/login');
    } else {
      enqueueSnackbar(success.message, { variant: 'error' });
    }
  };

  const linkedinIcon = (
    <Icon className="extLoginIcon">
      <img alt="edit" width="24px" height="24px" src={`${process.env.REACT_APP_HOMEPAGE}assets/linkedIn.png`} />
    </Icon>
  );

  const googleIconPng = (
    <Icon className="extLoginIcon">
      <img alt="edit" width="24px" height="24px" src={`${process.env.REACT_APP_HOMEPAGE}assets/google.png`} />
    </Icon>
  );
  return (
    <Box>
      <Box>
        <Stack spacing={1} mb={3}>
          <Typography className="title">Login</Typography>
          <Typography className="info-text">Please login using registered Email Id</Typography>
        </Stack>
      </Box>
      <FormProvider sx={{ height: '60px' }} methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Stack spacing={2.5}>
          <FormControl sx={{
                display: 'inline-flex',
                flexDirection: 'column',
                position: 'relative',
                minWidth: '0px',
                padding: '0px',
                margin: '0px',
                border: '0px',
                verticalAlign: 'top',
                width: '100%',
                height: '67px',
            }} size="small" fullWidth>
          <Controller
          name="email"
          label = "Email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              type="email"
              autoComplete="email"
              autoFocus
              label="Email Id"
              placeholder='Email Id'
              error={!!errors.email}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SvgIcon sx={{ width: '16px', height: '16px' }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="12" viewBox="0 0 16 12" fill="none">
                        <path
                          d="M14 -0.000244141H2.00001C1.09796 -0.000244141 0.363647 0.734069 0.363647 1.63612V10.3634C0.363647 11.2654 1.09796 11.9998 2.00001 11.9998H14C14.9021 11.9998 15.6364 11.2654 15.6364 10.3634V1.63612C15.6364 0.734069 14.9021 -0.000244141 14 -0.000244141ZM13.6366 1.09066L8.32798 5.0724C8.13502 5.21694 7.86572 5.21694 7.67276 5.0724L2.36343 1.09066H13.6366ZM14 10.9088H2.00001C1.69864 10.9088 1.45456 10.6641 1.45456 10.3634V1.77244L7.01819 5.94512C7.30659 6.16194 7.65364 6.27035 8.00001 6.27035C8.34638 6.27035 8.69343 6.16195 8.98183 5.94512L14.5455 1.77244V10.3634C14.5455 10.6641 14.3014 10.9088 14 10.9088Z"
                          fill="#6B7280"
                        />
                      </svg>
                    </SvgIcon>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
        <FormHelperText error={!!errors.email}>{errors.email?.message}
        </FormHelperText>
      </FormControl>
          <FormControl sx={{
                display: 'inline-flex',
                flexDirection: 'column',
                position: 'relative',
                minWidth: '0px',
                padding: '0px',
                margin: '0px',
                border: '0px',
                verticalAlign: 'top',
                width: '100%',
                height: '67px',
            }} size="small" fullWidth>
            <TextField
              name="password"
              label="Password"
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              {...register('password')}
              error={!!errors.password}
              InputProps={{
              startAdornment:(
                <InputAdornment position="start">
                  <SvgIcon sx={{ width: '16px', height: '16px' }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="16" viewBox="0 0 14 16" fill="none">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.96303 0.516724C4.85522 0.516724 3.14501 2.22313 3.14501 4.32787V6.36885H2.58619C1.71626 6.36885 1 7.08116 1 7.95074V13.9009C1 14.7705 1.71626 15.4828 2.58619 15.4828H11.4173C12.2872 15.4828 13 14.7705 13 13.9009V7.95074C13 7.08116 12.2872 6.36885 11.4173 6.36885H10.7836V4.32787C10.7836 2.22313 9.07085 0.516724 6.96303 0.516724ZM6.96303 1.39708C8.59939 1.39708 9.90328 2.69667 9.90328 4.32787V6.36885H4.02536V4.32787C4.02536 2.69667 5.32666 1.39708 6.96303 1.39708ZM2.58619 7.24921H3.58346H10.3417H11.4173C11.8157 7.24921 12.1196 7.55469 12.1196 7.95074V13.9009C12.1196 14.2969 11.8157 14.6024 11.4173 14.6024H2.58619C2.18771 14.6024 1.88035 14.2969 1.88035 13.9009V7.95074C1.88035 7.55469 2.18771 7.24921 2.58619 7.24921ZM7.00172 8.80874C6.15965 8.80874 5.46454 9.5007 5.46454 10.3425C5.46454 11.0311 5.92961 11.617 6.55982 11.8075V12.6044C6.56005 12.6622 6.57166 12.7194 6.59399 12.7727C6.61631 12.8261 6.64893 12.8745 6.68996 12.9152C6.731 12.9559 6.77965 12.9881 6.83314 13.01C6.88663 13.0319 6.94391 13.0431 7.00172 13.0429C7.11787 13.0424 7.22913 12.9961 7.31126 12.9139C7.39338 12.8318 7.43972 12.7206 7.44018 12.6044V11.8083C8.07092 11.6186 8.53547 11.0321 8.53546 10.3425C8.53546 9.5007 7.84379 8.80874 7.00172 8.80874ZM7.00172 9.6891C7.36906 9.6891 7.65511 9.97753 7.65511 10.3425C7.65511 10.7074 7.36906 10.9924 7.00172 10.9924C6.63438 10.9924 6.34489 10.7074 6.34489 10.3425C6.34489 9.97753 6.63438 9.6891 7.00172 9.6891Z"
                        fill="#6B7280"
                        stroke="#6B7280"
                        strokeWidth="0.5"
                      />
                    </svg>
                  </SvgIcon>
                </InputAdornment>
              ),
           
              endAdornment :(
                <InputAdornment position="end" onClick={() => setShowPassword(!showPassword)}>
                  <IconButton aria-label="toggle password visibility" edge="end" tabIndex={-1}>
                    <SvgIcon sx={{ width: '16px', height: '16px' }}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="10" viewBox="0 0 16 10" fill="none">
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M8 0.0604248C11.3236 0.0604248 14.1512 2.44413 15.5411 3.86595C16.153 4.48987 16.153 5.50774 15.5411 6.13366C14.1512 7.55549 11.3236 9.93919 8 9.93919C4.67641 9.93919 1.84877 7.55549 0.458942 6.13366C-0.152981 5.50974 -0.152981 4.49187 0.458942 3.86595C1.84877 2.44413 4.67641 0.0604248 8 0.0604248ZM8.002 9.07529C11.0076 9.07529 13.6293 6.85357 14.9231 5.52974C15.2091 5.23778 15.2091 4.76184 14.9231 4.46987C13.6293 3.14604 11.0076 0.924317 8.002 0.924317C4.99637 0.924317 2.3747 3.14604 1.08086 4.46987C0.7949 4.76384 0.7949 5.23778 1.08086 5.52974C2.3747 6.85357 4.99637 9.07529 8.002 9.07529ZM7.99995 1.42029C6.02619 1.42029 4.42039 3.02609 4.42039 4.99984C4.42039 6.97359 6.02619 8.57939 7.99995 8.57939C9.9737 8.57939 11.5795 6.97359 11.5795 4.99984C11.5795 3.02609 9.9737 1.42029 7.99995 1.42029ZM7.99995 7.7135C6.50413 7.7135 5.28629 6.49565 5.28629 4.99984C5.28629 3.50403 6.50413 2.28618 7.99995 2.28618C9.49576 2.28618 10.7136 3.50403 10.7136 4.99984C10.7136 6.49565 9.49576 7.7135 7.99995 7.7135ZM7.99994 3.36404C7.09605 3.36404 6.36414 4.09595 6.36414 4.99983C6.36414 5.90372 7.09605 6.63563 7.99994 6.63563C8.90383 6.63563 9.63573 5.90372 9.63573 4.99983C9.63573 4.09595 8.90383 3.36404 7.99994 3.36404Z"
                          fill="#6B7280"
                        />
                      </svg>
                    </SvgIcon>
                  </IconButton>
                </InputAdornment>
              )
            }}
              placeholder="Password"
            />
            <FormHelperText error={!!errors.password}>{errors.password?.message}</FormHelperText>
          </FormControl>

          <Stack direction="row" alignItems={'center'} justifyContent={'space-between'}>
            <Box>
              <FormControl className="remember-me-chk" size="small">
                <FormControlLabel control={<Checkbox defaultChecked />} label="Remember Me" />
              </FormControl>
            </Box>
            <Box>
              <Link
                className="fogot-pass-link"
                underline="none"
                onClick={autoSaveJobAppDialog}
                style={{ cursor: 'pointer' }}
              >
                Forgot Password?
              </Link>
            </Box>
          </Stack>
          <Button type="submit" variant="contained" size="large" className="login-btn" disabled={isSubmitting}>
            Login
            {/* className={`${isValid ? "login-btn" : 'disable-login-btn'}`}*/}
          </Button>

          <Divider sx={{ fontSize: '0.8rem', fontWeight: 600 }}>OR</Divider>

          <Stack direction="row" alignItems={'center'} spacing={3}>
            <Button
              variant="outlined"
              size="large"
              className="ext-login-btn"
              fullWidth
              startIcon={linkedinIcon}
              onClick={(e) => {
                window.open(`${process.env.REACT_APP_END_POINT}/auth/linkedin`, '_self');
              }}
            >
              Login with LinkedIn
            </Button>

            <Button
              variant="outlined"
              size="large"
              className="ext-login-btn"
              fullWidth
              startIcon={googleIconPng}
              onClick={(e) => {
                window.open(`${process.env.REACT_APP_END_POINT}/auth/google`, '_self');
              }}
            >
              Login with Google
            </Button>
          </Stack>

          <Box>
            <Typography className="register-link" component="p">
              Haven’t registered?&nbsp;
              <Link onClick={() => navigate('/register')} sx={{ cursor: 'pointer' }} underline="none">
                Register Here
              </Link>
            </Typography>
          </Box>
        </Stack>
      </FormProvider>
      {/* auto save job app dialog */}
      <Dialog
        open={autoSaveDialogOpen}
        onClose={autoSaveJobAppDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="forgot-password-dialog"
      >
        <ForgotPasswordDialog onChange={autoSaveJobAppDialog} />
      </Dialog>
    </Box>
  );
}
