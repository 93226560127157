import {
  Box,
  Card,
  CardContent,
  Divider,
  Dialog,
  Grid,
  Skeleton,
  Stack,
  SvgIcon,
  Typography,
  ListItem,
  List,
  ListItemText, Button, Icon
} from '@mui/material';
import { useAuth } from 'hooks/useAuthContext';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import Zoom from '@mui/material/Zoom';
import Collapse from '@mui/material/Collapse';
import { Link, useNavigate } from 'react-router-dom';
import ResumeFullView from './ResumeFullView';
const resume = `${process.env.REACT_APP_HOMEPAGE}assets/resume.png`;
import { changeNameFormat } from 'utils/Utils';
import ResumeTemplate from 'components/resume-formats/ResumeTemplate';
import ResumeFormatVersionOne from 'components/resume-formats/ResumeFormatVersionOne';
import ResumeFormatViewResume from 'components/resume-formats/ResumeFormatViewResume';

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
      [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
      },
}));

const CandidateResume = ({
  updating,
  educationData,
  templateType,
  summaryData,
  workExperienceData,
  customSection,
  certificateData,
  financeTechologiesData,
}) => {
  const imgLogo = `${process.env.REACT_APP_HOMEPAGE}assets/default.png`;
  const navigate = useNavigate();
  const certificationIcon = `${process.env.REACT_APP_HOMEPAGE}assets/certification-img.png`;
  const { user, updateContext } = useAuth();
  const [educations, setEducations] = useState(educationData ?? []);
  const [workHistory, setWorkHistory] = useState(workExperienceData ?? []);
  const [certificate, setCertificate] = useState(certificateData ?? []);
  const [customSections, setCustomSection] = useState(customSection ?? []);
  const [chooseResume, setChooseResume] = React.useState(false);
  const [showMasterResumeFormat, setShowMasterResumeFormat] = useState(true);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    setEducations(educationData);
    setWorkHistory(workExperienceData);
    setCertificate(certificateData);
    setCustomSection(customSection);
  }, [educationData, workExperienceData, certificateData, customSection]);

  const handleNavigation = () => {
    const data = {
      summaryData: summaryData,
      educationData: educationData?.filter(item => item?._id),
      workExperienceData: workExperienceData?.filter(item => item?._id),
      customSection: customSection,
      certificateData: certificateData?.filter(item => item?._id),
      templateType: templateType,
    };
    navigate('/resume', { state: data });
  };

  const handlePreviewResume = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const handleChangeChooseResume = () => {
    setChooseResume((prev) => !prev);
  };

  const handleShowMasterResume = () => {
    setShowMasterResumeFormat(true);
  };

  return (
    <Box className="candidate-resume-wrapper">
      <Box className="resume-content">          
        <Box mb={1.5} mt={2} className="header-sec">
        <Stack direction="row" mb={1} spacing={1} justifyContent={'space-between'} alignItems="center"> 
            <Typography className="title" variant="p">
              {updating ? "loading..." : "Your Master Resume"}
            </Typography>
            <Box>
              <Stack direction="row" spacing={1} justifyContent={'space-between'} alignItems="center">
                {/* <Link href="#" className="choose-template" variant="body2" 
                // onClick={handleChangeChooseResume}
                >Choose Template</Link>  */}
                <Divider orientation="vertical" variant="middle" flexItem />
                <Link href="#" onClick={handlePreviewResume} sx={{display: 'flex'}}>
                <SvgIcon>      
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <path d="M0.484841 6.56051C0.594415 6.56051 1.59076 5.66961 2.72844 4.59981L4.64793 6.5193C4.83726 6.70863 5.08525 6.80341 5.33349 6.80341C5.58173 6.80341 5.82972 6.70863 6.0193 6.5193C6.39796 6.14064 6.39796 5.52659 6.0193 5.14817L4.1306 3.25947C5.30222 2.12082 6.30293 1.09854 6.30293 0.984841C6.30293 0.717209 6.08572 0.5 5.81809 0.5H0.484841C0.217209 0.5 0 0.717209 0 0.984841V6.07567C0 6.3433 0.217209 6.56051 0.484841 6.56051Z" fill="#1575C6"/>
                        <path d="M0.484841 16.5003H5.81809C6.08572 16.5003 6.30293 16.283 6.30293 16.0154C6.30293 15.9017 5.30222 14.8794 4.1306 13.741L6.01905 11.8526C6.39772 11.4739 6.39772 10.8599 6.01905 10.4814C5.64015 10.1025 5.02634 10.1025 4.64768 10.4814L2.72868 12.4004C1.59076 11.3306 0.594415 10.4397 0.484841 10.4397C0.217209 10.4397 0 10.657 0 10.9246V16.0154C0 16.283 0.217209 16.5003 0.484841 16.5003Z" fill="#1575C6"/>
                        <path d="M16.0002 16.0154V10.9246C16.0002 10.657 15.783 10.4397 15.5154 10.4397C15.4058 10.4397 14.4094 11.3306 13.2718 12.4004L11.3528 10.4814C10.9739 10.1025 10.36 10.1025 9.98138 10.4814C9.60272 10.8601 9.60272 11.4742 9.98138 11.8526L11.8698 13.741C10.698 14.8794 9.69727 15.9017 9.69727 16.0154C9.69727 16.283 9.91447 16.5003 10.1821 16.5003H15.5154C15.783 16.5003 16.0002 16.283 16.0002 16.0154Z" fill="#1575C6"/>
                        <path d="M16.0003 0.984841C16.0003 0.717209 15.7831 0.5 15.5155 0.5H10.1822C9.9146 0.5 9.69739 0.717209 9.69739 0.984841C9.69739 1.09854 10.6981 2.12082 11.8697 3.25923L9.98126 5.14768C9.6026 5.52634 9.6026 6.14039 9.98126 6.51881C10.1708 6.70814 10.4188 6.80293 10.6671 6.80293C10.9153 6.80293 11.1633 6.70814 11.3529 6.51881L13.2719 4.59981C14.4096 5.66961 15.4059 6.56051 15.5155 6.56051C15.7831 6.56051 16.0003 6.3433 16.0003 6.07567V0.984841Z" fill="#1575C6"/>
                    </svg>
                </SvgIcon>   
                </Link>  
              </Stack>
            </Box>
          </Stack>
          <Collapse in={chooseResume}>
          <Box className="choose-template-options" mt={1} mb={1}>
                  <Stack direction="row" justifyContent={'center'} spacing={1.5} alignItems="center"> 
                      <LightTooltip TransitionComponent={Zoom} title="Resume 1">
                          <Button
                              component="label"
                              variant="contained"
                              disableRipple
                              className="choose-template-btn activated-template-resume"   
                              onClick={(e) => setShowMasterResumeFormat(true)}              
                          >
                              {/* <Icon>
                                  <img alt="profile" width="50px" height="50px" src={resume} />
                              </Icon> */}
                              <Icon sx={{
                                  display: 'inline-flex',
                                  width: '50px',
                                  height: '50px',
                                  backgroundColor: '#D9D9D9'
                              }}>                                            
                              </Icon>
                          </Button>   
                      </LightTooltip>

                      <LightTooltip TransitionComponent={Zoom} title="Resume 2">
                          <Button
                              component="label"
                              variant="contained"
                              disableRipple
                              className="choose-template-btn"   
                              onClick={(e) => setShowMasterResumeFormat(false)}                   
                          >
                              {/* <Icon>
                                  <img alt="profile" width="50px" height="50px" src={resume} />
                              </Icon> */}
                              <Icon sx={{
                                  display: 'inline-flex',
                                  width: '50px',
                                  height: '50px',
                                  backgroundColor: '#F0C6FF'
                              }}>                                            
                              </Icon>
                          </Button>   
                      </LightTooltip>

                      <LightTooltip TransitionComponent={Zoom} title="Resume 3">
                          <Button
                              component="label"
                              variant="contained"
                              disableRipple
                              className="choose-template-btn"                                  
                              onClick={(e) => setShowMasterResumeFormat(false)}              
                          >
                              {/* <Icon>
                                  <img alt="profile" width="50px" height="50px" src={resume} />
                              </Icon> */}
                              <Icon sx={{
                                  display: 'inline-flex',
                                  width: '50px',
                                  height: '50px',
                                  backgroundColor: '#4B260C'
                              }}>                                            
                              </Icon>
                          </Button>   
                      </LightTooltip>
                                  
                  </Stack>
              </Box>
          </Collapse>
        </Box>
        <Box className="resume-body">
        <Box className="resume-container">
        <Card className="resume-card" variant="outlined">         

        {showMasterResumeFormat ? <ResumeFormatVersionOne
        educationData={educationData}
        summaryData={summaryData}
        workExperienceData={workExperienceData}
        customSection={customSection}
        certificateData={certificateData}
        financeTechologiesData={financeTechologiesData}/> : 
        <ResumeTemplate
             summaryData={summaryData}
             educationData={educationData}
             certificateData={certificateData}
             workExperienceData={workExperienceData}
             customSection={customSection}
             financeTechologiesData={financeTechologiesData}
         />}

        
</Card>
</Box>
        </Box>
      </Box>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="full-view-resume-dialog"
      >
        {/* <ResumeFullView
          summary={summaryData}
          educationData={educationData}
          certificateData={certificateData}
          workExperienceData={workExperienceData}
          customSection={customSection}
          financeTechologiesData={financeTechologiesData}

        /> */}        
      <Box className="resume-container">
      <Card className="resume-card" variant="outlined" sx={{ marginBottom: '1rem' }}>
        <ResumeFormatViewResume
        educationData={educationData}
        summaryData={summaryData}
        workExperienceData={workExperienceData}
        customSection={customSection}
        certificateData={certificateData}
        financeTechologiesData={financeTechologiesData}
        />
        </Card>
        </Box>
      </Dialog>
    </Box>
  );
};

export default CandidateResume;
