import * as yup from 'yup';
import dayjs from 'dayjs';

export const CandidateSummarySchema = yup.object().shape({
  summary: yup.string().required('write your summary'),
});

// Schema for validation
export const CandidateBasicProfileRule = yup.object().shape({
  name: yup
    .string()
    .required('Please enter your name')
    .matches(/^[A-Z a-z]+$/, 'Name can only contain alphabetic characters'),
  city: yup.string().required('Please select your city'),
  sex: yup.string().required('Please select your gender'),
  date_of_birth: yup
    .date()
    .required('Please provide your date of birth')
    .max(dayjs().endOf('day').toDate(), 'Provide correct date of birth'),
});

export const CandidateEducationSchema = (data) =>
  yup.object().shape({
    education_from: yup
      .date()
      .required('Please select from date.')
      .max(dayjs().endOf('day').toDate(), 'Start date cannot be in the future.')
      .test('is-before', 'Start date cannot be later than end date', function (value) {
        const { education_to } = this.parent;
        return !education_to || !value || dayjs(value).isBefore(dayjs(education_to));
      }),
    education_to: yup
      .date()
      .required('Please select to date.')
      .min(yup.ref('education_from'), 'End date cannot be earlier than start date.')
      .max(dayjs().endOf('day').toDate(), 'End date cannot be in the future.'),
    education_institute_name: yup.string().required('Please enter institution name.'),
    learning_format: yup.string().required('Please select learning format.'),
    [`${data}_received`]: yup.string().required(`Please select your ${data} name.`),
    field_of_study: yup.string().required('Please enter your specialization .'),
  });

export const CandidateWorkHistorySchema = (explanation, present) =>
  yup.object().shape({
    work_history_from: yup
      .date()
      .required('Please select from date.')
      .max(dayjs().endOf('day').toDate(), 'Start date cannot be in the future.')
      .test('is-before', 'Start date cannot be later than end date', function (value) {
        const { work_history_to } = this.parent;
        return !work_history_to || !value || dayjs(value).isBefore(dayjs(work_history_to));
      }),
    work_history_to: present
      ? yup.date().nullable()
      : yup
          .date()
          .required('Please select to date.')
          .min(yup.ref('work_history_from'), 'End date cannot be earlier than start date.')
          .max(dayjs().endOf('day').toDate(), 'End date cannot be in the future.'),
    // present: yup.boolean().required('Please specify whether you have an explanation'),
    work_history_location: yup.string().required('Please select work location.'),
    organization_name: yup.string().required('Please enter company name.'),
    last_job_function: yup.string().required('Please enter your job function.'),
    highest_designation: yup.string().required('Please enter your designation.'),
    job_type: yup.string().required('Please enter job type.'),
    team_size_managed: yup
      .number()
      .typeError('Team size must be a number.')
      .required('Please enter team size.')
      .min(0, 'Team size cannot be negative.')
      .max(100000, 'Team size cannot exceed 10,0000.')
      .integer('Team size must be an integer.'),
    // gap_explaination: explanation ? yup.string().required('Mention your employment gap') : yup.string().nullable(),
    // work_oriented_skills: yup.array().min(1, 'Please select at least one skill').required('Please select your skill'),
  });

export const CandidateWorkHistoryForGapSchema = (present) =>
  yup.object().shape({
    work_history_from: yup
      .date()
      .required('Please select from date.')
      .max(dayjs().endOf('day').toDate(), 'Start date cannot be in the future.')
      .test('is-before', 'Start date cannot be later than end date', function (value) {
        const { work_history_to } = this.parent;
        return !work_history_to || !value || dayjs(value).isBefore(dayjs(work_history_to));
      }),
    work_history_to: present
      ? yup.date().nullable()
      : yup
          .date()
          .required('Please select to date.')
          .min(yup.ref('work_history_from'), 'End date cannot be earlier than start date.')
          .max(dayjs().endOf('day').toDate(), 'End date cannot be in the future.'),
    gap_explaination: yup.string().required('Mention your reason of employment gap'),
    gap_title: yup.string().required('Mention your title of employment gap'),
  });

export const ResumeSchema = yup.object().shape({
  title: yup.string().required('Please enter your resume title.'),
  resume: yup
    .mixed()
    .test('fileSize', 'File size is too large.', (value) => {
      return value?.length && value[0].size <= 1024 * 1024 * 5; // 5MB
    })
    .test('fileType', 'Please select pdf/word file.', (value) => {
      return (
        value?.length &&
        ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'].includes(
          value[0].type
        )
      );
    })
    .required('Please upload your resume.'),
});

export const CandidateJobRelatedPreferencesRule = yup.object().shape({
  preferd_job_format: yup.string().required('Please enter your prefered job format.'),
  prefered_location: yup.string().required('Please select your prefered location.'),
  notice_period: yup.string().required('Please select your notice period.'),
  reason_for_change: yup.string().required('Please enter reason for change '),
  current_ctc: yup
    .number()
    .typeError('Please enter a valid number')
    .min(0, 'CTC must be above zero')
    .required('Please enter your current ctc.'),
  variable_ctc: yup
    .number()
    .typeError('Please enter a valid number ')
    .min(0, 'CTC must be above zero')
    .required('Please enter your variable ctc.'),
  total_ctc: yup.number().typeError('Please enter a valid number ').required('failed to calculate total ctc.'),
  expected_ctc: yup
    .number()
    .typeError('Please enter a valid number ')
    .min(0, 'CTC must be above zero')
    .required('Please enter your expected ctc.'),
  linkedin_profile: yup.string().url('Invalid URL').required('URL is required'),
});

export const CandidateExpertiseSkillsRule = yup.object().shape({
  domain_experties: yup.string().required('Please select your functional expertise.'),
  area_of_expertise: yup.string().required('Please select your area of expertise.'),
  skills: yup
    .array()
    .of(yup.string())
    .required('Please select your skills.')
    .min(5, 'Please select at least five skills.'),
  responsbility_type: yup.string().required('Please enter your Responsibility.'),
  self_assessment: yup.string().required('Please select your self assessment.'),
  practice_experience: yup.string().required('Please enter your practice experiance.'),
  experties_source: yup.string().required('Please select your experiance source.'),
});

export const CandidateJobPreferencesRule = (values, isBuyout) =>
  yup.object().shape({
    expected_ctc: yup
      .number()
      .typeError('Please enter expected compansation.')
      .min(0, 'CTC must be above zero')
      .required('Please enter your expected compensation.'),
    // total_ctc: yup.number().typeError('Please enter a valid number').required('Failed to calculate total CTC.'),
    // compensation: yup.string().required('Please select compensation structure.'),
    notice_period: yup.string().required('Please select notice period.'),
    buyout_period: isBuyout ? yup.string().required('Please enter buyout period.') : yup.string().nullable(),
    fixed_ctc: yup.number().typeError('Please enter a valid number').required('Please enter fixed compensation.'),
    // variable_ctc: (values === 2 || values === 3) ? yup.number().typeError('Please enter a valid number').required('Please enter variable compensation.') : yup.number().nullable(),
    // other_ctc: (values === 3) ? yup.string().required('Please enter other perks (ESOPs/RSUs etc.).') : yup.string().nullable(),
    work_format: yup.array().of(yup.string()).min(1, 'Please select at least one work format.'),
    job_type: yup.array().of(yup.string()).min(1, 'Please select at least one job type.'),
    preferred_job_shifts: yup.array().of(yup.string()).min(1, 'Please select at least one job shift.'),
    prefered_location: yup.array().of(yup.string()).min(1, 'Please select at least one location.'),
  });

  export const CandidateBasicContactRule = yup.object().shape({
    email: yup.string().required('Please update email id for contact.'),
    phone_number: yup.string().required('Please update phone number.'),
    linkedinprofile: yup.string().required('Please update linkedin profile url.'),
  });
  