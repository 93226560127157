import React, { useEffect, useState, useRef } from 'react';
import { Link as RouterLink } from "react-router-dom";
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Card, CardContent, Chip, Container, Dialog, Divider, Drawer, FormControl, Grid, Icon, IconButton, Stack, TextField, Typography } from '@mui/material'
import Paper from '@mui/material/Paper';
import Header from '../header/Header';
const backIcon = `${process.env.REACT_APP_HOMEPAGE}assets/arrow_back.svg`;
import CandidateResume from '../basic-profile/CandidateResume';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import CreateUpdateEducation from './CreateUpdateEducation';
import AddSkills from '../basic-profile/AddSkills';
import CreateUpdateCertificate from './CreateUpdateCertificate';
import CreateUpdateWorkExperience from './CreateUpdateWorkExperience';
// import CandidateResume from '../../components/basic-profile/CandidateResume';
import dayjs from 'dayjs';
import { useSnackbar } from 'components/snackbar';
import { getBasicProfileDetails, addSummary, getResumesData, setResumesData, getAllCertificationDetails, deleteWorkHistory, deleteEducationHistory, deleteCertificateHistory, updateCandidateEducation, updateCandidateWorkExperience, updateCandidateCertificate } from 'api/users';
import { SplashScreen } from 'components/loading-screen';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from 'hooks/useAuthContext';
import RemoveCard from './RemoveCard';

const DemoPaper = styled(Paper)(({ theme }) => ({
    width: 'auto',
    height: 'auto',
    padding: theme.spacing(2),
    ...theme.typography.body2,
    textAlign: 'center',
}));

const CreateUpdateResume = () => {
    const { user } = useAuth();
    const location = useLocation();
    const { data, totalResume, tab, jobData, createNewResume } = location.state || { data: null, totalResume: 0, tab: '4', jobData: null, createNewResume: false };
    const [isMasterResume, setIsMasterResume] = useState(data?._id === data?.original_id || false)
    const navigate = useNavigate();
    const [customSection, setCustomSection] = useState(data?.profile?.custom_section ?? []);
    const [profile, setProfile] = useState(data?.profile ?? {});
    const [educations, setEducations] = useState(data?.educations ?? []);
    const [workHistory, setWorkHistory] = useState(data?.work_history ?? []);
    const [certificates, setCertificates] = useState(data?.certificates ?? []);
    const [loading, setLoading] = useState(true);
    const [summary, setSummary] = useState(data?.summary || data?.profile?.summary || '');
    const [templateType, setTemplateType] = useState(data?.profile?.template ?? '');
    const [financeTechologiesData, setfinanceTechologiesData] = useState(data?.profile?.finance_technologies ?? []);
    const [resumeId, setResumeId] = useState(data?._id ?? '');
    const [resumeTitleText, setResumeTitleText] = useState(data ? (data?.title || '') : `Main Resume ${totalResume} - Draft`);
    const [openAddSkills, setOpenAddSkills] = React.useState(false);
    const [openRemoveCard, setOpenRemoveCard] = useState(false);
    const [titleData, setTitle] = useState('');
    const [sectionType, setSectionType] = useState('');
    const [actionType, setActionType] = useState('');
    const [expandedSection, setExpandedSection] = useState({ section: null, index: null });
    const { enqueueSnackbar } = useSnackbar();
    const [workExperienceGap, setGap] = useState([]);
    const [selectedData, setSelectedData] = useState({});
    const [flag, setFlag] = useState(true);
    const [expanded, setExpanded] = useState(false);
    const [isEditResumeTitle, setIsEditResumeTitle] = useState(false);
    const [isEditTitle, setIsEditTitle] = useState(true);
    const [openCUEducDrawer, setOpenCUEducDrawer] = useState(false);
    const [openCUWorkExpDrawer, setOpenCUWorkExpDrawer] = useState(false);
    const [openCUCertiDrawer, setOpenCUCertiDrawer] = useState(false);
    const [currentItemId, setCurrentItemId] = useState(null);
    const [currentType, setCurrentType] = useState(null);
    const [itemId, setItemId] = useState('');
    const [selectedChips, setSelectedChips] = useState([]);
    const [addMoreSkills, setAddMoreSkills] = useState([
        'Analytical Skills',
        'Finance strategy',
        'Financial Acumen',
        'Adaptability and Resilience',
        'Leadership',
        'Strategic Thinking',
        'Strategic',
        'Thinking',
    ]);
    const [isClicked, setIsClicked] = useState(false)

    const fetchData = async (id = null) => {
        try {
            const profileResponse = await getBasicProfileDetails();
            const resumeResponse = id ? await setResumesData({ id }) : await setResumesData();

            if (resumeResponse?.success && resumeResponse.data) {
                const id = resumeResponse.data._id;
                const detailedResumeResponse = await getResumesData({ id });
                setIsMasterResume(false);

                if (detailedResumeResponse?.success && detailedResumeResponse.data) {
                    const { data } = detailedResumeResponse;
                    setEducations(data.educations || []);
                    setWorkHistory(data.work_history || []);
                    setCertificates(data.certificates || []);
                    setSummary(data.summary || '');
                    setResumeId(id);
                    setIsEditResumeTitle(false);
                    setIsEditTitle(true);
                } else {
                    enqueueSnackbar('Failed to fetch detailed resume data', { variant: 'error' });
                }
            } else {
                enqueueSnackbar(resumeResponse?.message, { variant: 'error' });
                navigate('/job-application', { state: { tab: tab || '4', data: jobData || null } });
            }

            if (profileResponse?.success && profileResponse.data?.candidate) {
                const { candidate } = profileResponse.data;
                setProfile(candidate);
                setTemplateType(candidate.template || '');
                setfinanceTechologiesData(candidate.finance_technologies || []);
                setCustomSection(candidate.custom_section || []);
            } else {
                enqueueSnackbar('Failed to fetch basic profile details', { variant: 'error' });
            }

        } catch (error) {
            console.error('Error fetching data:', error);
            enqueueSnackbar('Error fetching data', { variant: 'error' });
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        if (data) {
            setLoading(false);
            return;
        }
        if (!data && totalResume === 5) {
            setLoading(false);
            navigate('/job-application', { state: { tab: tab || '4', data: jobData || null } });
            return;
        }
        if (createNewResume)
            fetchData();
        else
            setLoading(false);
    }, []);

    const handleDataChange = async () => {
        setOpenCUEducDrawer(false);
        setOpenCUCertiDrawer(false);
        setOpenCUWorkExpDrawer(false);
        await fetchData(resumeId);
    };


    if (loading) {
        return (
            <Grid container justifyContent="center" alignItems="center">
                <SplashScreen />
            </Grid>
        );
    }

    const bankInIcon = (
        <Icon className="extLoginIcon">
            <img className='back-button' alt="edit" width="24px" height="24px" src={backIcon} />
        </Icon>
    );


    const handleChange = (panel) => (event, isExpanded) => {
        !isExpanded && setSelectedData({})
        setExpanded(isExpanded ? panel : false);
    };

    // chip delete
    const handleDelete = (item) => {
        const uniqueSkills = new Set([...item.skills, ...addMoreSkills,]);
        setAddMoreSkills([...uniqueSkills]);
        setSelectedChips(item?.skills || [])
        handleOpenAddSkillsDialog();
    };

    // handle edit custom section;


    const handleEditResumeTitle = () => {
        setIsEditResumeTitle(true);
        setIsEditTitle(false);
    };


    const handleAddSkills = (updatedSkills) => {
        switch (currentType) {
            case 'work':
                handleUpdate('work', currentItemId, { skills: updatedSkills });
                break;
            case 'education':
                handleUpdate('education', currentItemId, { skills: updatedSkills });
                break;
            case 'certificate':
                handleUpdate('certificate', currentItemId, { skills: updatedSkills });
                break;
            default:
                break;
        }
        handleCloseAddSkillsDialog();
    };


    const onSubmit = async (data) => {
        try {
            if (data?.onlySummary) {
                const { summary, ...others } = data;
                const response = await addSummary({ summary, resumeId });
                if (response?.success === true && response.data) {
                    handleDataChange();
                } else if (response?.success === false) {
                    enqueueSnackbar(response.message, { variant: 'error' });
                }
            } else {
                data.id = resumeId;
                const response = await setResumesData(data);

                if (response?.success && response.data) {
                    setIsEditResumeTitle(false);
                    setIsEditTitle(true);
                    setResumeId(response.data._id || '')
                    // fetchData();
                } else {
                    enqueueSnackbar('Failed to update resume', { variant: 'error' });
                }
            }
        } catch (error) {
            enqueueSnackbar('Error updating resume', { variant: 'error' });
        }
    };

    // open create update education drawer



    const createUpdateEduDrawer = (newOpen) => () => {
        setOpenCUEducDrawer(newOpen);
    };

    // open create update work exp drawer



    const createUpdateWorkExpDrawer = (newOpen) => () => {
        setOpenCUWorkExpDrawer(newOpen);
    };


    const createUpdateCertiDrawer = (newOpen) => () => {
        setOpenCUCertiDrawer(newOpen);
    };

    // add more skills dialog


    const handleOpenAddSkillsDialog = (itemId, type, title) => {
        setCurrentItemId(itemId);
        setTitle(title);
        setCurrentType(type);
        setOpenAddSkills(true);
    };

    const handleCloseAddSkillsDialog = () => {
        setOpenAddSkills(false);
        setCurrentItemId(null);
        setCurrentType(null);
        setTitle(null);
    };
    const handleDeleteSection = async (id, title, type, event) => {
        event.stopPropagation();
        setTitle(title);
        setSectionType(type);
        setItemId(id);
        setOpenRemoveCard(true);
    };

    const handleDeleteAction = async () => {
        if (actionType === 'education') {
            const response = await deleteEducationHistory({ educationId: itemId });
            if (response && response?.success) {
                setOpenRemoveCard(false);
                setEducations(response?.data || []);
                handleDataChange();
            } else {
                enqueueSnackbar(response.message, { variant: 'error' });
            }
        } else if (actionType === 'certificate') {
            const response = await deleteCertificateHistory({ certificateId: itemId });
            if (response && response?.success) {
                setOpenRemoveCard(false);
                setEducations(response?.data || []);
                handleDataChange();
            } else {
                enqueueSnackbar(response.message, { variant: 'error' });
            }
        } else if (actionType === 'workExperience') {
            const response = await deleteWorkHistory({ workId: itemId });
            if (response && response?.success) {
                // setOpen(false);
                setOpenRemoveCard(false);
                setWorkHistory(response?.data || []);
                handleDataChange();
            } else {
                enqueueSnackbar(response.message, { variant: 'error' });
            }
        }
        handleRemoveCard();
    };

    const handleRemoveCard = () => {
        setOpenRemoveCard(false);
        // setItemId('');
    };

    const handleUpdate = async (type, itemId, updatedFields) => {
        let updatedItem;

        const setStateFunction = {
            education: setEducations,
            work: setWorkHistory,
            certificate: setCertificates,
        }[type];

        const apiFunction = {
            education: updateCandidateEducation,
            work: updateCandidateWorkExperience,
            certificate: updateCandidateCertificate,
        }[type];

        setStateFunction((prevItems) => {
            return prevItems.map((item) => {
                if (item._id === itemId) {
                    updatedItem = {
                        ...item,
                        ...updatedFields,
                    };
                    return updatedItem;
                }
                return item;
            });
        });

        if (updatedItem) {
            updatedItem[`${type}Id`] = itemId;
            const response = await apiFunction(updatedItem);
            if (response?.success === true && response.data) {
                handleDataChange();
            } else {
                enqueueSnackbar(response.message || "Unable to update", { variant: 'error' });
            }
        }
    };

    const handleDeleteEducationAccomplishment = async (educationId, accomplishment, item) => {
        const updatedFields = item.key_accomplishments.filter(acc => acc !== accomplishment);
        await handleUpdate('education', educationId, { key_accomplishments: updatedFields });
    };

    const handleDeleteWorkAccomplishment = async (workId, accomplishment, item) => {
        const updatedFields = item.key_accomplishments.filter(acc => acc !== accomplishment);
        await handleUpdate('work', workId, { key_accomplishments: updatedFields });
    };






    return (
        <Box>
            <Box className="create-update-resume-wrapper">
                <Container maxWidth="xl">
                    <Grid container spacing={2}>
                        <Grid item xs={8}>
                            <Box sx={{ padding: '5rem 1.5rem' }}>
                                <Box mb={3}>
                                    <Button
                                        size="small"
                                        className="back-btn"
                                        startIcon={bankInIcon}
                                        onClick={() => navigate('/job-application', { state: { tab: tab || '4', data: jobData || null } })}>
                                        Back
                                    </Button>
                                </Box>
                                <Box className="header-section" mb={3}>
                                    <Stack direction="row" alignItems={'center'} spacing={1}>
                                        <Box className="editCustomSectionTitle">
                                            {isEditResumeTitle ? (
                                                <TextField
                                                    type="text"
                                                    variant="standard"
                                                    autoFocus
                                                    value={resumeTitleText}
                                                    className="titleInput"
                                                    onChange={(e) => setResumeTitleText(e.target.value)}
                                                />
                                            ) : (
                                                <Typography className="title">
                                                    {resumeTitleText}
                                                </Typography>
                                            )}
                                        </Box>
                                        {isEditTitle ? (
                                            <IconButton aria-label="edit" disabled={isMasterResume} onClick={() => {
                                                setIsClicked(false)
                                                handleEditResumeTitle()
                                            }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M12.2872 16.0003H2.09789C0.941117 16.0003 0 15.0593 0 13.9024V3.71313C0 2.55635 0.941002 1.61523 2.09789 1.61523H6.76704C7.0982 1.61523 7.36641 1.88345 7.36641 2.2146C7.36641 2.54576 7.0982 2.81398 6.76704 2.81398H2.09789C1.60192 2.81398 1.19884 3.21706 1.19884 3.71303V13.9023C1.19884 14.3983 1.60192 14.8014 2.09789 14.8014H12.2872C12.7832 14.8014 13.1862 14.3983 13.1862 13.9023V9.23319C13.1862 8.90203 13.4545 8.63381 13.7856 8.63381C14.1168 8.63381 14.385 8.90203 14.385 9.23319V13.9023C14.385 15.0591 13.4441 16.0003 12.2872 16.0003Z" fill="#1F9CD7" />
                                                    <path d="M4.44141 9.00838L10.9986 2.45117L13.5451 4.99768L6.98792 11.5549L4.44141 9.00838Z" fill="#1F9CD7" />
                                                    <path d="M15.7098 1.43961L14.5591 0.28882C14.174 -0.0962733 13.5491 -0.0962733 13.1633 0.28882L11.8477 1.60444L14.3942 4.15095L15.7098 2.83533C16.0949 2.45024 16.0949 1.82545 15.7098 1.43961Z" fill="#1F9CD7" />
                                                    <path d="M3.67143 9.9353L3.07881 12.523C3.02487 12.7598 3.23615 12.9711 3.4729 12.9171L6.06065 12.3245L3.67143 9.9353Z" fill="#1F9CD7" />
                                                </svg>
                                            </IconButton>
                                        ) : (
                                            <IconButton aria-label="update" className="save-btn" disable={isClicked} onClick={() => {
                                                setIsClicked(true);
                                                onSubmit({ title: resumeTitleText });
                                            }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="10" viewBox="0 0 14 10" fill="none">
                                                    <path fillRule="evenodd" clipRule="evenodd" d="M12.668 2.5646L6.18732 9.04531C5.74702 9.48561 5.01777 9.48561 4.5637 9.04531L1.33023 5.81183C0.889924 5.37153 0.889924 4.64228 1.33023 4.18822C1.77053 3.74791 2.49978 3.74791 2.95385 4.18822L5.38927 6.62364L11.0582 0.954739C11.4985 0.514436 12.2277 0.514436 12.6818 0.954739C13.1083 1.39504 13.1083 2.12429 12.668 2.5646Z" fill="white" stroke="#1575C6" />
                                                </svg>
                                            </IconButton>
                                        )}
                                    </Stack>
                                    <Typography className="info-text">Any changes made in this resume except for “Education” would not be reflecting in your other resume(s).</Typography>
                                </Box>
                                < Box >
                                    <Card className="create-update-resume-card">
                                        <CardContent>
                                            <Box className="scroll-container">
                                                <Box className="scroll-wrapper">
                                                    <Box className="candidate-personal-info" sx={{ marginBottom: '1.5rem' }}>
                                                        <Stack direction="row" spacing={1.5} alignItems="center" mb={2.5}>
                                                            <Box>
                                                                <Typography className="candidate-name">{user.name}</Typography>
                                                                <Typography className="candidate-email-contact">{user.email} | {user.phone}</Typography>
                                                            </Box>
                                                        </Stack>
                                                        <FormControl fullWidth>
                                                            <TextField
                                                                label="Professional Summary"
                                                                value={summary}
                                                                onChange={(event) => {
                                                                    setSummary(event.target.value)
                                                                    onSubmit({ summary: event.target.value, onlySummary: true })
                                                                }}
                                                                multiline
                                                                size="small"
                                                                variant="outlined"
                                                            />
                                                        </FormControl>
                                                    </Box>

                                                    <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '0.5rem', marginBottom: '0.5rem' }} />

                                                    <Box className="resume-details-row education-sec">
                                                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel2bh-content"
                                                                id="panel2bh-header"
                                                                m={0}
                                                            >
                                                                <Typography className="title" sx={{ width: '90%', flexShrink: 0 }}>Education</Typography>
                                                                <button className="choose-template" variant="body2" onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setOpenCUEducDrawer(true);
                                                                }}>
                                                                    Add New
                                                                </button>
                                                            </AccordionSummary>

                                                            <AccordionDetails>
                                                                {/* <Box mb={2}>
                                                                    <Chip className="info-chip" label="Changes made to this section would be updated in all other resumes too" size="small" />
                                                                </Box> */}

                                                                {educations?.length > 0 &&
                                                                    educations.map((item, index) =>
                                                                        <Box mb={3} key={item._id || index}>
                                                                            <Box>
                                                                                <Stack direction="row" alignItems={'center'} spacing={1.5}>
                                                                                    <Typography className="block-title">{item.education_institute_name || "N/A"}</Typography>
                                                                                    <Link href="#" className="update-link" underline="none" onClick={() => {
                                                                                        setSelectedData(item)
                                                                                        setOpenCUEducDrawer(true)
                                                                                    }}>Update</Link>
                                                                                    <IconButton className="delete-btn-icon" aria-label="delete" onClick={(event) => {
                                                                                        setActionType('education');
                                                                                        handleDeleteSection(item._id, item.degree_received, "Education", event);
                                                                                    }}>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                                                            <path fillRule="evenodd" clipRule="evenodd" d="M11.0948 2.37734C11.0948 2.22733 11.0353 2.08301 10.9289 1.97715C10.8231 1.87074 10.6787 1.81131 10.5287 1.81131H8.70671L8.44689 0.771503C8.33369 0.317545 7.92557 -0.000564375 7.45803 7.54076e-07H4.54298C4.07543 -0.000565244 3.66732 0.317545 3.55412 0.771503L3.29431 1.81131H1.47228C1.15983 1.81131 0.90625 2.06489 0.90625 2.37734C0.90625 2.68978 1.15983 2.94337 1.47228 2.94337H1.50171C1.46096 3.10186 1.44737 3.26658 1.46096 3.42959L2.14473 10.2668C2.24096 11.2523 3.0702 12.0027 4.06023 12H7.94092C8.93091 12.0028 9.7602 11.2523 9.85642 10.2668L10.5402 3.42901C10.5538 3.266 10.5396 3.10185 10.4994 2.94335H10.5289C10.6789 2.94335 10.8232 2.88392 10.9291 2.77751C11.0355 2.67166 11.0948 2.52733 11.0948 2.37734ZM4.63071 1.13206H7.3697L7.53951 1.8113H4.46087L4.63071 1.13206ZM9.32707 3.05494C9.3916 3.1257 9.4233 3.22135 9.41311 3.31702L8.72933 10.1542C8.69028 10.5601 8.34839 10.8691 7.94085 10.868H4.06015C3.65261 10.8691 3.31072 10.5601 3.27167 10.1542L2.58789 3.3176C2.57827 3.22194 2.6094 3.12684 2.67393 3.05552C2.73846 2.9842 2.82959 2.94345 2.92582 2.94345H9.07529C9.17151 2.94288 9.26255 2.98362 9.32707 3.05494ZM5.09484 4.52832C4.94483 4.52832 4.80051 4.58775 4.69465 4.69416C4.58824 4.80001 4.52881 4.94435 4.52881 5.09434V7.81131C4.52881 8.12375 4.78239 8.37734 5.09484 8.37734C5.40728 8.37734 5.66086 8.12375 5.66086 7.81131V5.09434C5.66086 4.94434 5.60143 4.80001 5.49502 4.69416C5.38917 4.58774 5.24483 4.52832 5.09484 4.52832ZM6.50599 4.69416C6.61185 4.58775 6.75617 4.52832 6.90618 4.52832C7.05617 4.52832 7.20051 4.58774 7.30636 4.69416C7.41277 4.80001 7.4722 4.94434 7.4722 5.09434V7.81131C7.4722 8.12375 7.21862 8.37734 6.90618 8.37734C6.59373 8.37734 6.34015 8.12375 6.34015 7.81131V5.09434C6.34015 4.94435 6.39958 4.80001 6.50599 4.69416Z" fill="#FF4949" />
                                                                                        </svg>
                                                                                    </IconButton>
                                                                                </Stack>
                                                                            </Box>

                                                                            <Box mb={1} className="candidate-short-info-list">
                                                                                <Stack direction="row" alignItems={'center'} spacing={0.5}>
                                                                                    <Typography variant="p">{item.degree_received || "N/A"}</Typography>
                                                                                    <Typography variant="body1">•</Typography>
                                                                                    <Typography variant="p">{item.field_of_study || "N/A"}</Typography>
                                                                                    <Typography variant="body1">•</Typography>
                                                                                    <Typography variant="p"> {item.education_from ? `${dayjs(item.education_from).format('YYYY')} - ${item.education_to ? dayjs(item.education_to).format('YYYY') : 'Present'}` : ''}</Typography>
                                                                                </Stack>
                                                                            </Box>

                                                                            <Box className="skills-block">
                                                                                <Stack direction="row" alignItems={'center'} flexWrap="wrap" mb={2}>
                                                                                    {item.skills?.length > 0 && item.skills.map(data => < Chip key={data} label={data} size="small"
                                                                                        onDelete={() => handleDelete(item)} />)}
                                                                                           <Box sx={{marginTop: '-10px'}}>
                                                                                    <Link className='add-more-skills-link' href="#" underline="none" alignItems={'center'}
                                                                                        onClick={() => {
                                                                                            setSelectedChips(item?.skills || [])
                                                                                            handleOpenAddSkillsDialog(item._id, 'education', item.degree_received,);
                                                                                        }}>Add More Skills</Link></Box>
                                                                                </Stack>
                                                                            </Box>

                                                                            <Box className="block-info-text">
                                                                                {item.key_accomplishments?.length > 0 && item.key_accomplishments.map(data =>
                                                                                    <Stack direction="row" alignItems={'center'} spacing={1} mb={1} key={data}>
                                                                                        <DemoPaper variant="outlined">{data}</DemoPaper>
                                                                                        <IconButton className="delete-btn-icon" aria-label="delete" onClick={() => handleDeleteEducationAccomplishment(item._id, data, item)}>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                                                                <path fillRule="evenodd" clipRule="evenodd" d="M11.0948 2.37734C11.0948 2.22733 11.0353 2.08301 10.9289 1.97715C10.8231 1.87074 10.6787 1.81131 10.5287 1.81131H8.70671L8.44689 0.771503C8.33369 0.317545 7.92557 -0.000564375 7.45803 7.54076e-07H4.54298C4.07543 -0.000565244 3.66732 0.317545 3.55412 0.771503L3.29431 1.81131H1.47228C1.15983 1.81131 0.90625 2.06489 0.90625 2.37734C0.90625 2.68978 1.15983 2.94337 1.47228 2.94337H1.50171C1.46096 3.10186 1.44737 3.26658 1.46096 3.42959L2.14473 10.2668C2.24096 11.2523 3.0702 12.0027 4.06023 12H7.94092C8.93091 12.0028 9.7602 11.2523 9.85642 10.2668L10.5402 3.42901C10.5538 3.266 10.5396 3.10185 10.4994 2.94335H10.5289C10.6789 2.94335 10.8232 2.88392 10.9291 2.77751C11.0355 2.67166 11.0948 2.52733 11.0948 2.37734ZM4.63071 1.13206H7.3697L7.53951 1.8113H4.46087L4.63071 1.13206ZM9.32707 3.05494C9.3916 3.1257 9.4233 3.22135 9.41311 3.31702L8.72933 10.1542C8.69028 10.5601 8.34839 10.8691 7.94085 10.868H4.06015C3.65261 10.8691 3.31072 10.5601 3.27167 10.1542L2.58789 3.3176C2.57827 3.22194 2.6094 3.12684 2.67393 3.05552C2.73846 2.9842 2.82959 2.94345 2.92582 2.94345H9.07529C9.17151 2.94288 9.26255 2.98362 9.32707 3.05494ZM5.09484 4.52832C4.94483 4.52832 4.80051 4.58775 4.69465 4.69416C4.58824 4.80001 4.52881 4.94435 4.52881 5.09434V7.81131C4.52881 8.12375 4.78239 8.37734 5.09484 8.37734C5.40728 8.37734 5.66086 8.12375 5.66086 7.81131V5.09434C5.66086 4.94434 5.60143 4.80001 5.49502 4.69416C5.38917 4.58774 5.24483 4.52832 5.09484 4.52832ZM6.50599 4.69416C6.61185 4.58775 6.75617 4.52832 6.90618 4.52832C7.05617 4.52832 7.20051 4.58774 7.30636 4.69416C7.41277 4.80001 7.4722 4.94434 7.4722 5.09434V7.81131C7.4722 8.12375 7.21862 8.37734 6.90618 8.37734C6.59373 8.37734 6.34015 8.12375 6.34015 7.81131V5.09434C6.34015 4.94435 6.39958 4.80001 6.50599 4.69416Z" fill="#FF4949" />
                                                                                            </svg>
                                                                                        </IconButton>
                                                                                    </Stack>
                                                                                )}
                                                                            </Box>
                                                                        </Box>)
                                                                }
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </Box>

                                                    <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '0.5rem', marginBottom: '0.5rem' }} />

                                                    <Box className="resume-details-row">
                                                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel2bh-content"
                                                                id="panel2bh-header"
                                                                m={0}
                                                            >
                                                                <Typography className="title" sx={{ width: '90%', flexShrink: 0 }}>Certification</Typography>
                                                                <button className="choose-template" variant="body2" onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setOpenCUCertiDrawer(true);
                                                                }}>
                                                                    Add New
                                                                </button>
                                                            </AccordionSummary>

                                                            <AccordionDetails>
                                                                {certificates?.length > 0 &&
                                                                    certificates.map((item, index) =>
                                                                        <Box mb={3} key={item._id || index}>
                                                                            <Box>
                                                                                <Stack direction="row" alignItems={'center'} spacing={1.5}>
                                                                                    <Typography className="block-title">{item.education_institute_name || "N/A"}</Typography>
                                                                                    <Link href="#" className="update-link" underline="none" onClick={() => {
                                                                                        setSelectedData(item)
                                                                                        setOpenCUCertiDrawer(true)
                                                                                    }}>Update</Link>
                                                                                    <IconButton className="delete-btn-icon" aria-label="delete" onClick={(event) => {
                                                                                        setActionType('certificate');
                                                                                        handleDeleteSection(item._id, item.certificate_received, "Certification", event);
                                                                                    }}>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                                                            <path fillRule="evenodd" clipRule="evenodd" d="M11.0948 2.37734C11.0948 2.22733 11.0353 2.08301 10.9289 1.97715C10.8231 1.87074 10.6787 1.81131 10.5287 1.81131H8.70671L8.44689 0.771503C8.33369 0.317545 7.92557 -0.000564375 7.45803 7.54076e-07H4.54298C4.07543 -0.000565244 3.66732 0.317545 3.55412 0.771503L3.29431 1.81131H1.47228C1.15983 1.81131 0.90625 2.06489 0.90625 2.37734C0.90625 2.68978 1.15983 2.94337 1.47228 2.94337H1.50171C1.46096 3.10186 1.44737 3.26658 1.46096 3.42959L2.14473 10.2668C2.24096 11.2523 3.0702 12.0027 4.06023 12H7.94092C8.93091 12.0028 9.7602 11.2523 9.85642 10.2668L10.5402 3.42901C10.5538 3.266 10.5396 3.10185 10.4994 2.94335H10.5289C10.6789 2.94335 10.8232 2.88392 10.9291 2.77751C11.0355 2.67166 11.0948 2.52733 11.0948 2.37734ZM4.63071 1.13206H7.3697L7.53951 1.8113H4.46087L4.63071 1.13206ZM9.32707 3.05494C9.3916 3.1257 9.4233 3.22135 9.41311 3.31702L8.72933 10.1542C8.69028 10.5601 8.34839 10.8691 7.94085 10.868H4.06015C3.65261 10.8691 3.31072 10.5601 3.27167 10.1542L2.58789 3.3176C2.57827 3.22194 2.6094 3.12684 2.67393 3.05552C2.73846 2.9842 2.82959 2.94345 2.92582 2.94345H9.07529C9.17151 2.94288 9.26255 2.98362 9.32707 3.05494ZM5.09484 4.52832C4.94483 4.52832 4.80051 4.58775 4.69465 4.69416C4.58824 4.80001 4.52881 4.94435 4.52881 5.09434V7.81131C4.52881 8.12375 4.78239 8.37734 5.09484 8.37734C5.40728 8.37734 5.66086 8.12375 5.66086 7.81131V5.09434C5.66086 4.94434 5.60143 4.80001 5.49502 4.69416C5.38917 4.58774 5.24483 4.52832 5.09484 4.52832ZM6.50599 4.69416C6.61185 4.58775 6.75617 4.52832 6.90618 4.52832C7.05617 4.52832 7.20051 4.58774 7.30636 4.69416C7.41277 4.80001 7.4722 4.94434 7.4722 5.09434V7.81131C7.4722 8.12375 7.21862 8.37734 6.90618 8.37734C6.59373 8.37734 6.34015 8.12375 6.34015 7.81131V5.09434C6.34015 4.94435 6.39958 4.80001 6.50599 4.69416Z" fill="#FF4949" />
                                                                                        </svg>
                                                                                    </IconButton>
                                                                                </Stack>
                                                                            </Box>

                                                                            <Box mb={1} className="candidate-short-info-list">
                                                                                <Stack direction="row" alignItems={'center'} spacing={0.5}>
                                                                                    <Typography variant="p">{item.certificate_received || "N/A"}</Typography>
                                                                                    <Typography variant="body1">•</Typography>
                                                                                    <Typography variant="p">{item.field_of_study || "N/A"}</Typography>
                                                                                    <Typography variant="body1">•</Typography>
                                                                                    <Typography variant="p"> {item.education_to ? `${dayjs(item.education_to).format('YYYY')}` : ''}</Typography>
                                                                                </Stack>
                                                                            </Box>

                                                                            <Box className="skills-block">
                                                                                <Stack direction="row" alignItems={'center'} flexWrap="wrap" mb={2}>
                                                                                    {item.skills?.length > 0 && item.skills.map(data => < Chip key={data} label={data} size="small" onDelete={() => handleDelete(item)} />)}
                                                                                    <Box sx={{marginTop: '-10px'}}>
                                                                                        <Link className='add-more-skills-link' href="#" underline="none"
                                                                                        onClick={() => {
                                                                                            setSelectedChips(item?.skills || [])
                                                                                            handleOpenAddSkillsDialog(item._id, 'certificate', item.certificate_received);
                                                                                        }}>Add More Skills</Link></Box>
                                                                                </Stack>
                                                                            </Box>
                                                                        </Box>
                                                                    )}

                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </Box>

                                                    <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '0.5rem', marginBottom: '0.5rem' }} />

                                                    <Box className="resume-details-row">
                                                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                                                            <AccordionSummary
                                                                expandIcon={<ExpandMoreIcon />}
                                                                aria-controls="panel2bh-content"
                                                                id="panel2bh-header"
                                                                m={0}
                                                            >
                                                                <Typography className="title" sx={{ width: '90%', flexShrink: 0 }}>Work Experience</Typography>
                                                                <button className="choose-template" variant="body2" onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setOpenCUWorkExpDrawer(true);
                                                                }}>
                                                                    Add New
                                                                </button>
                                                            </AccordionSummary>

                                                            <AccordionDetails>
                                                                {workHistory?.length > 0 &&
                                                                    workHistory.map((item, index) =>
                                                                        <Box mb={3}>
                                                                            <Box>
                                                                                <Stack direction="row" alignItems={'center'} spacing={1.5}>
                                                                                    <Typography className="block-title">{item.highest_designation}</Typography>
                                                                                    <Link href="#" className="update-link" underline="none" onClick={() => {
                                                                                        setSelectedData(item)
                                                                                        setOpenCUWorkExpDrawer(true)
                                                                                    }}>Update</Link>
                                                                                    <IconButton className="delete-btn-icon" aria-label="delete" onClick={(event) => {
                                                                                        setActionType('workExperience');
                                                                                        handleDeleteSection(item._id, item.highest_designation, "Work Experience", event);
                                                                                    }}>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                                                            <path fillRule="evenodd" clipRule="evenodd" d="M11.0948 2.37734C11.0948 2.22733 11.0353 2.08301 10.9289 1.97715C10.8231 1.87074 10.6787 1.81131 10.5287 1.81131H8.70671L8.44689 0.771503C8.33369 0.317545 7.92557 -0.000564375 7.45803 7.54076e-07H4.54298C4.07543 -0.000565244 3.66732 0.317545 3.55412 0.771503L3.29431 1.81131H1.47228C1.15983 1.81131 0.90625 2.06489 0.90625 2.37734C0.90625 2.68978 1.15983 2.94337 1.47228 2.94337H1.50171C1.46096 3.10186 1.44737 3.26658 1.46096 3.42959L2.14473 10.2668C2.24096 11.2523 3.0702 12.0027 4.06023 12H7.94092C8.93091 12.0028 9.7602 11.2523 9.85642 10.2668L10.5402 3.42901C10.5538 3.266 10.5396 3.10185 10.4994 2.94335H10.5289C10.6789 2.94335 10.8232 2.88392 10.9291 2.77751C11.0355 2.67166 11.0948 2.52733 11.0948 2.37734ZM4.63071 1.13206H7.3697L7.53951 1.8113H4.46087L4.63071 1.13206ZM9.32707 3.05494C9.3916 3.1257 9.4233 3.22135 9.41311 3.31702L8.72933 10.1542C8.69028 10.5601 8.34839 10.8691 7.94085 10.868H4.06015C3.65261 10.8691 3.31072 10.5601 3.27167 10.1542L2.58789 3.3176C2.57827 3.22194 2.6094 3.12684 2.67393 3.05552C2.73846 2.9842 2.82959 2.94345 2.92582 2.94345H9.07529C9.17151 2.94288 9.26255 2.98362 9.32707 3.05494ZM5.09484 4.52832C4.94483 4.52832 4.80051 4.58775 4.69465 4.69416C4.58824 4.80001 4.52881 4.94435 4.52881 5.09434V7.81131C4.52881 8.12375 4.78239 8.37734 5.09484 8.37734C5.40728 8.37734 5.66086 8.12375 5.66086 7.81131V5.09434C5.66086 4.94434 5.60143 4.80001 5.49502 4.69416C5.38917 4.58774 5.24483 4.52832 5.09484 4.52832ZM6.50599 4.69416C6.61185 4.58775 6.75617 4.52832 6.90618 4.52832C7.05617 4.52832 7.20051 4.58774 7.30636 4.69416C7.41277 4.80001 7.4722 4.94434 7.4722 5.09434V7.81131C7.4722 8.12375 7.21862 8.37734 6.90618 8.37734C6.59373 8.37734 6.34015 8.12375 6.34015 7.81131V5.09434C6.34015 4.94435 6.39958 4.80001 6.50599 4.69416Z" fill="#FF4949" />
                                                                                        </svg>
                                                                                    </IconButton>
                                                                                </Stack>
                                                                            </Box>

                                                                            <Box mb={1} className="candidate-short-info-list">
                                                                                <Stack direction="row" alignItems={'center'} spacing={0.5}>
                                                                                    <Typography variant="p">{item.organization_name || ''}</Typography>
                                                                                    <Typography variant="body1">{item.job_type && "•"}</Typography>
                                                                                    <Typography variant="p">{item.job_type || ''}</Typography>
                                                                                    <Typography variant="body1">•</Typography>
                                                                                    <Typography variant="p"> {item?.work_history_from ? `${dayjs(item.work_history_from).format('MMM YYYY')} - ${item.present ? 'Present' : item?.work_history_to && dayjs(item.work_history_to).format('MMM YYYY')}` : ''}</Typography>
                                                                                </Stack>
                                                                            </Box>

                                                                            {item?.organization_name !== "Work Gap" &&
                                                                                <Box className="skills-block">
                                                                                    <Stack direction="row" alignItems={'center'} flexWrap="wrap" mb={2}>
                                                                                        {item.skills?.length > 0 && item.skills.map(data => < Chip key={data} label={data} size="small" onDelete={() => () => handleDelete(item)} />)}
                                                                                        <Box sx={{marginTop: '-10px'}}>
                                                                                            <Link className='add-more-skills-link' href="#" underline="none"
                                                                                            onClick={() => {
                                                                                                setSelectedChips(item?.skills || [])
                                                                                                handleOpenAddSkillsDialog(item._id, 'work', item.highest_designation);
                                                                                            }}>Add More Skills</Link></Box>
                                                                                    </Stack>
                                                                                </Box>
                                                                            }

                                                                            <Box className="block-info-text">
                                                                                {item.key_accomplishments?.length > 0 && item.key_accomplishments.map(data =>
                                                                                    <Stack direction="row" alignItems={'center'} spacing={1} mb={1} key={data}>
                                                                                        <DemoPaper variant="outlined">{data}</DemoPaper>
                                                                                        <IconButton className="delete-btn-icon" aria-label="delete" onClick={() => handleDeleteWorkAccomplishment(item._id, data, item)}>
                                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                                                                <path fillRule="evenodd" clipRule="evenodd" d="M11.0948 2.37734C11.0948 2.22733 11.0353 2.08301 10.9289 1.97715C10.8231 1.87074 10.6787 1.81131 10.5287 1.81131H8.70671L8.44689 0.771503C8.33369 0.317545 7.92557 -0.000564375 7.45803 7.54076e-07H4.54298C4.07543 -0.000565244 3.66732 0.317545 3.55412 0.771503L3.29431 1.81131H1.47228C1.15983 1.81131 0.90625 2.06489 0.90625 2.37734C0.90625 2.68978 1.15983 2.94337 1.47228 2.94337H1.50171C1.46096 3.10186 1.44737 3.26658 1.46096 3.42959L2.14473 10.2668C2.24096 11.2523 3.0702 12.0027 4.06023 12H7.94092C8.93091 12.0028 9.7602 11.2523 9.85642 10.2668L10.5402 3.42901C10.5538 3.266 10.5396 3.10185 10.4994 2.94335H10.5289C10.6789 2.94335 10.8232 2.88392 10.9291 2.77751C11.0355 2.67166 11.0948 2.52733 11.0948 2.37734ZM4.63071 1.13206H7.3697L7.53951 1.8113H4.46087L4.63071 1.13206ZM9.32707 3.05494C9.3916 3.1257 9.4233 3.22135 9.41311 3.31702L8.72933 10.1542C8.69028 10.5601 8.34839 10.8691 7.94085 10.868H4.06015C3.65261 10.8691 3.31072 10.5601 3.27167 10.1542L2.58789 3.3176C2.57827 3.22194 2.6094 3.12684 2.67393 3.05552C2.73846 2.9842 2.82959 2.94345 2.92582 2.94345H9.07529C9.17151 2.94288 9.26255 2.98362 9.32707 3.05494ZM5.09484 4.52832C4.94483 4.52832 4.80051 4.58775 4.69465 4.69416C4.58824 4.80001 4.52881 4.94435 4.52881 5.09434V7.81131C4.52881 8.12375 4.78239 8.37734 5.09484 8.37734C5.40728 8.37734 5.66086 8.12375 5.66086 7.81131V5.09434C5.66086 4.94434 5.60143 4.80001 5.49502 4.69416C5.38917 4.58774 5.24483 4.52832 5.09484 4.52832ZM6.50599 4.69416C6.61185 4.58775 6.75617 4.52832 6.90618 4.52832C7.05617 4.52832 7.20051 4.58774 7.30636 4.69416C7.41277 4.80001 7.4722 4.94434 7.4722 5.09434V7.81131C7.4722 8.12375 7.21862 8.37734 6.90618 8.37734C6.59373 8.37734 6.34015 8.12375 6.34015 7.81131V5.09434C6.34015 4.94435 6.39958 4.80001 6.50599 4.69416Z" fill="#FF4949" />
                                                                                            </svg>
                                                                                        </IconButton>
                                                                                    </Stack>
                                                                                )}
                                                                            </Box>
                                                                        </Box>)}
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        </CardContent>
                                    </Card>
                                </Box >
                            </Box>
                        </Grid>

                        <Grid item xs={4} style={{ marginTop: '40px' }}>
                            <CandidateResume
                                updating={loading}
                                templateType={templateType}
                                summaryData={summary}
                                educationData={educations}
                                workExperienceData={workHistory}
                                certificateData={certificates}
                                financeTechologiesData={financeTechologiesData}
                                customSection={customSection}
                            />
                        </Grid>
                    </Grid>
                </Container>
            </Box>

            {/* create update education drawer */}

            <Drawer open={openCUEducDrawer} anchor={'right'} onClose={createUpdateEduDrawer(false)}>
                <CreateUpdateEducation educationData={selectedData} closeDialog={createUpdateEduDrawer(false)} onDataChange={handleDataChange} />
            </Drawer>

            <Drawer open={openCUWorkExpDrawer} anchor={'right'} onClose={createUpdateWorkExpDrawer(false)}>
                <CreateUpdateWorkExperience workExperienceData={selectedData} closeDialog={createUpdateWorkExpDrawer(false)} onDataChange={handleDataChange} />
            </Drawer>
            <Drawer open={openCUCertiDrawer} anchor={'right'} onClose={createUpdateCertiDrawer(false)}>
                <CreateUpdateCertificate certificateData={selectedData} closeDialog={createUpdateCertiDrawer(false)} onDataChange={handleDataChange} />
            </Drawer>
            <Dialog
                open={openAddSkills}
                onClose={handleCloseAddSkillsDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="otp-dialog-wrapper"
            >
                <AddSkills onClose={handleCloseAddSkillsDialog} skills={addMoreSkills} selectedskills={selectedChips} onAddSkills={handleAddSkills} title={titleData} />
            </Dialog>
            <Dialog
                open={openRemoveCard}
                onClose={handleRemoveCard}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="otp-dialog-wrapper"
            >
                <RemoveCard onClose={handleRemoveCard} onRemove={handleDeleteAction} title={titleData} section={sectionType} />
            </Dialog>
        </Box >

    )
}

export default CreateUpdateResume;
