import React, { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';

function TypeAheadPlain({ value, api, displayTextKey, multiple, onInputChange, onChange, ...rest }) {
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchData = async (inputValue) => {
    setLoading(true);
    try {
      // Fetch data from API based on the input value
      const response = await fetch(`${api}?q=${inputValue}`);
      const data = await response.json();
      setOptions(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Autocomplete
      {...rest}
      size='small'
      disablePortal
      multiple={multiple} // Set multiple based on selectType
      options={options}
      getOptionLabel={(option) => typeof option === 'object' ? option[displayTextKey] : option}
      value={multiple ? value || [] : value}
      loading={loading}
      onInputChange={(event, newInputValue) => {
        fetchData(newInputValue);
        if (onInputChange) onInputChange(event, newInputValue);
      }}
      onChange={(_, newValue) => {
        if (onChange) return onChange(_, newValue);
      }}
      renderOption={(props, option) => {
        return (
          <li {...props} key={props?.id}>
            {typeof option === 'object' ? option[displayTextKey] : option}
          </li>
        );
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label={rest?.label}
          error={rest?.error}
          helperText={rest?.helperText}
        />
      )}
    />
  );
}

export default TypeAheadPlain;
