import { Box, Dialog, FormControl, Stack, Divider, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, SvgIcon, Typography, TextField, FormHelperText } from '@mui/material'
import { useAuth } from 'hooks/useAuthContext';
import UpdateWhatsapp from './UpdateWhatsapp';
import React, { useEffect, useState } from 'react';
import UpdateEmail from './UpdateEmail';
import { useSnackbar } from 'components/snackbar';
import { CandidateBasicContactRule } from 'models/CandidateProfileSchema';
// import Profile from 'pages/temp/Profile';
// import LinkIcon from '@mui/icons-material/Link';
// import LinkedInProfileDialog from './LinkedInProfileDialog'
import {
    addLinkedInProfile
} from 'api/users';
const ContactDetails = ({contactDetailsError}) => {
    const { enqueueSnackbar } = useSnackbar();
    const { user, updateContext } = useAuth();
    const [open, setOpen] = useState(false);
    const [contact, setContact] = useState('');
    const [error, setErrors] = useState('');
    const [openLinkedInDialog, setOpenLinkedInDialog] = React.useState(false);
    const [linkedinProfile, setLinkedinProfile] = useState(user?.linkedinprofile || '');
    const [phoneNumberError, setPhoneNumberError] = useState(user?.phone || '');

    useEffect(() => {
        if (contactDetailsError){
            if (!user?.phone) {
                setPhoneNumberError(true);
            }else{
                setPhoneNumberError(false);
            }
        }else{
            setPhoneNumberError(false);
        }
    }, [contactDetailsError, user])

    // const defaultValues = {
    //     email: user?.email || '',
    //     phone_number: user?.email || '',
    //     linkedinprofile: user?.linkedinprofile || '',
    //   };

    //   const methods = useForm({
    //     defaultValues,
    //     resolver: yupResolver(CandidateBasicProfileRule),
    //   });

    //   const {
    //     control,
    //     register,
    //     handleSubmit,
    //     setValue,
    //     trigger,
    //     formState: { errors, isDirty, isValid, dirtyFields, isSubmitting },
    //   } = methods;

    const handleOpenContactInfo = () => {
        setOpen(true);
        setContact('email');
    };

    const handleOpenWhatsappContact = () => {
        setOpen(true);
        setContact('phone');
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseWithNumber = (phoneNumber) => {
        if (phoneNumber) {
            updateContext({ 'phone': phoneNumber });
        }
        setOpen(false);
    };

    const handleCloseWithEmail = (email) => {
        if (email) {
            updateContext({ 'email': email });
        }
        setOpen(false);
    };

    const validateLikedInProfile = (linkedinProfile) => {
        const linkedInPattern = /^https:\/\/(www\.)?linkedin\.com\/in\/[A-Za-z0-9_-]+\/?$/;
        return linkedInPattern.test(linkedinProfile);
    }
    const handleLinkedInProfile = async (event) => {
        const linkedInProfile = event.target.value;
        if (!linkedInProfile) return false;
        setLinkedinProfile(linkedInProfile);
        if(!validateLikedInProfile(linkedInProfile)) {
            setErrors('Please enter a valid LinkedIn profile URL.');
            return false;
        }       
        setErrors('');
        const response = await addLinkedInProfile({ 'linkedInProfile': linkedInProfile });
        if (response?.success === true && response?.data) {
            updateContext({ 'linkedinprofile': linkedInProfile });
            // enqueueSnackbar(response?.message, { variant: 'success' });
        } else if (response?.success === false) {
            enqueueSnackbar(response.message, { variant: 'error' });
        }

    };

    const handleCloseLinkedInDialog = () => {
        setOpenLinkedInDialog(false);
    };

    return (
        <Box>
            <Typography className="step-heading" variant="h6" gutterBottom>
                Contact Details
            </Typography>
            <Typography className="step-info" variant="body2" gutterBottom>
                These details would help us reach out to you directly
            </Typography>
            <Box component="form" noValidate autoComplete="off" mt={2}>
                <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 2 }} mb={3}>
                    <Grid item xs={6}>
                        <FormControl size="small" className="wh-number-input" fullWidth>
                            <InputLabel htmlFor="outlined-adornment-password">WhatsApp Number</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                            onClick={handleOpenWhatsappContact}
                                        >
                                            <SvgIcon sx={{ width: '16px', height: '16px' }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M12.2907 16.0003H2.10143C0.944657 16.0003 0.00354004 15.0593 0.00354004 13.9024V3.71313C0.00354004 2.55635 0.944542 1.61523 2.10143 1.61523H6.77058C7.10174 1.61523 7.36995 1.88345 7.36995 2.2146C7.36995 2.54576 7.10174 2.81398 6.77058 2.81398H2.10143C1.60546 2.81398 1.20238 3.21706 1.20238 3.71303V13.9023C1.20238 14.3983 1.60546 14.8014 2.10143 14.8014H12.2907C12.7867 14.8014 13.1898 14.3983 13.1898 13.9023V9.23319C13.1898 8.90203 13.458 8.63381 13.7892 8.63381C14.1203 8.63381 14.3885 8.90203 14.3885 9.23319V13.9023C14.3885 15.0591 13.4476 16.0003 12.2907 16.0003Z" fill="#6B7280" />
                                                    <path d="M4.44409 9.00838L11.0013 2.45117L13.5478 4.99768L6.9906 11.5549L4.44409 9.00838Z" fill="#6B7280" />
                                                    <path d="M15.7117 1.43961L14.5609 0.28882C14.1758 -0.0962733 13.551 -0.0962733 13.1651 0.28882L11.8495 1.60444L14.396 4.15095L15.7116 2.83533C16.0967 2.45024 16.0968 1.82545 15.7117 1.43961Z" fill="#6B7280" />
                                                    <path d="M3.6757 9.9353L3.08308 12.523C3.02914 12.7598 3.24042 12.9711 3.47717 12.9171L6.06492 12.3245L3.6757 9.9353Z" fill="#6B7280" />
                                                </svg>
                                            </SvgIcon>
                                        </IconButton>
                                    </InputAdornment>
                                }
                                disabled
                                label="WhatsApp Number"
                                value={user?.phone}
                            />
                            {user?.phone &&
                                <Box className="iti-flag-container" sx={{ marginTop: "-2px;" }}>
                                    <Stack direction="row" alignItems={'center'} spacing={1}>
                                        <Box className="iti-flag"></Box>
                                        <Box className="selected-dial-code">+91</Box>
                                        <Divider orientation="vertical" variant="middle" flexItem />
                                    </Stack>
                                </Box>
                            }
                    {phoneNumberError && <FormHelperText error>Please add your WhatsApp number</FormHelperText>}
                        </FormControl>
                    </Grid>
                    <Grid item xs={6}>
                        <FormControl size="small" fullWidth>
                            <InputLabel htmlFor="outlined-adornment-password">Email Id</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-confirm-password"
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            edge="end"
                                            onClick={handleOpenContactInfo}
                                        >
                                            <SvgIcon sx={{ width: '16px', height: '16px' }}>
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                                    <path d="M12.2907 16.0003H2.10143C0.944657 16.0003 0.00354004 15.0593 0.00354004 13.9024V3.71313C0.00354004 2.55635 0.944542 1.61523 2.10143 1.61523H6.77058C7.10174 1.61523 7.36995 1.88345 7.36995 2.2146C7.36995 2.54576 7.10174 2.81398 6.77058 2.81398H2.10143C1.60546 2.81398 1.20238 3.21706 1.20238 3.71303V13.9023C1.20238 14.3983 1.60546 14.8014 2.10143 14.8014H12.2907C12.7867 14.8014 13.1898 14.3983 13.1898 13.9023V9.23319C13.1898 8.90203 13.458 8.63381 13.7892 8.63381C14.1203 8.63381 14.3885 8.90203 14.3885 9.23319V13.9023C14.3885 15.0591 13.4476 16.0003 12.2907 16.0003Z" fill="#6B7280" />
                                                    <path d="M4.44409 9.00838L11.0013 2.45117L13.5478 4.99768L6.9906 11.5549L4.44409 9.00838Z" fill="#6B7280" />
                                                    <path d="M15.7117 1.43961L14.5609 0.28882C14.1758 -0.0962733 13.551 -0.0962733 13.1651 0.28882L11.8495 1.60444L14.396 4.15095L15.7116 2.83533C16.0967 2.45024 16.0968 1.82545 15.7117 1.43961Z" fill="#6B7280" />
                                                    <path d="M3.6757 9.9353L3.08308 12.523C3.02914 12.7598 3.24042 12.9711 3.47717 12.9171L6.06492 12.3245L3.6757 9.9353Z" fill="#6B7280" />
                                                </svg>
                                            </SvgIcon>
                                        </IconButton>
                                    </InputAdornment>
                                }
                                disabled
                                label="Email Id"
                                value={user?.email}
                            />
                        </FormControl>

                    </Grid>
                </Grid>
                <FormControl size="small" fullWidth>
                    <InputLabel htmlFor="linked-in-profile">LinkedIn Profile URL (Optional)</InputLabel>
                    <OutlinedInput
                        id="linked-in-profile"
                        onBlur={handleLinkedInProfile}
                        onChange={handleLinkedInProfile}
                        label="LinkedIn Profile (Optional)"
                        value={linkedinProfile}
                    />
                    {error && <FormHelperText error>{error}</FormHelperText>}
                </FormControl>
            </Box>

            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className="otp-dialog-wrapper"
            >
                {contact === 'phone' ? (<UpdateWhatsapp onCancel={handleClose} onCancelWithNumber={handleCloseWithNumber} />) : (

                    <UpdateEmail onCancel={handleClose} onCancelWithEmail={handleCloseWithEmail} />

                )}
            </Dialog>
            {/* Linked in dialog */}
            {/* <Dialog
                open={openLinkedInDialog}
                onClose={handleCloseLinkedInDialog}
                className="action-dialog-card lined-in-dialog"
            >
                <LinkedInProfileDialog onCloseDialog={handleCloseLinkedInDialog}/>
                
            </Dialog> */}
        </Box>
    )
}

export default ContactDetails
