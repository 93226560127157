import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  SvgIcon,
  TextField,
  Typography,
  FormControlLabel,
  Checkbox,
  FormHelperText
} from '@mui/material';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { CandidateJobPreferencesRule } from 'models/CandidateProfileSchema';
import FormProvider from 'hooks/form/form-provider';
import TypeAhead from 'components/TypeAhead';
import { endpoints } from 'utils/axios';
const dayjs = require('dayjs');
import { addJobPreferance } from 'api/users';
import { formatCurrency } from 'utils/Utils';

const JopApplicationDrawer = ({ onClose, data, onUpdatePreference }) => {
  const [values, setValues] = useState(0);

  const [openWorkFormat, setOpenWorkFormat] = useState(false);
  const [openJobType, setOpenJobType] = useState(false);
  const [openJobShifts, setOpenJobShifts] = useState(false);
  const [isBuyout, setIsBuyout] = useState(false);

  const workModelFormat = ['Work From Home', 'Office Environment', 'Hybrid'];
  const jobType = ['Full Time', 'Part Time', 'Self-Employed', 'Trainee', 'Freelancer', 'Internship'];
  const preferredJobShifts = ['First-Shift', 'Second-Shift', 'Third-Shift', 'Split-Shift'];
  const preferredCompanyType = ['Private Companies', 'Public Companies', 'Associate Companies', 'One Person Companies (OPC)'];

  const defaultValues = {
    notice_period: '',
    buyout_period: '',
    is_negotiable: false,
    total_ctc: null,
    compensation: '',
    fixed_ctc: null,
    variable_ctc: null,
    other_ctc: '',
    expected_ctc: '',
    work_format: [],
    job_type: [],
    preferred_job_shifts: [],
    prefered_location: [],
  };

  const methods = useForm({
    defaultValues,
    mode: 'onChange',
    resolver: yupResolver(CandidateJobPreferencesRule(values, isBuyout)),
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { touchedFields, errors, isDirty, isValid, dirtyFields, isSubmitting },
  } = methods;

  // const compensationValue = watch('compensation');
  const fixed = watch('fixed_ctc');
  const variable = watch('variable_ctc');
  // const other_ctc = watch('other_ctc');


  useEffect(() => {
    fetchjobs();
  }, []);

  useEffect(() => {
    // if (compensationValue) {
    //   setValues(Number(compensationValue));
    let total = 0;
    // if (compensationValue === '1') {
    //   total = parseInt(fixed_ctc || 0);
    //   setValue('variable_ctc', 0);
    //   setValue('other_ctc', '');
    // } else if (compensationValue === '2') {
    //   total = parseInt(fixed_ctc || 0) + parseInt(variable_ctc || 0);
    // setValue('other_ctc', '');
    // } else if (compensationValue === '3') {
    total = parseInt(fixed || 0) + parseInt(variable || 0);
    // }
    setValue('total_ctc', total);
    // }
  }, [fixed, variable, setValue]);

  const fetchjobs = () => {
    try {
      if (data) {
        Object.keys(defaultValues).forEach((field) => {
          setValue(field, data?.[field]);
        });
      }
      setIsBuyout(data?.is_negotiable || false);
    } catch (error) {
      console.error('Error fetching jobs:', error);
    }
  };

  const onSubmit = handleSubmit(async (formData) => {
    const response = await addJobPreferance(formData);
    if (response?.success === true && response?.data) {
      onUpdatePreference(response.data?.job_preferences);
      onClose();
    } else if (response?.success === false) {
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  });

  return (
    <Box sx={{ width: 450 }} className="job-app-drawer-container" role="presentation">
      <Box className="drawer-header">
        <Stack direction="row" alignItems={'flex-start'} spacing={1}>
          <IconButton aria-label="delete" onClick={onClose} sx={{ paddingTop: 0, paddingBottom: 0 }}>
            <SvgIcon>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path
                  d="M6.22796 5.04477C5.90134 4.71815 5.37178 4.71815 5.04516 5.04477C4.71854 5.37139 4.71854 5.90095 5.04516 6.22757L10.8174 11.9998L5.04516 17.772C4.71854 18.0987 4.71854 18.6282 5.04516 18.9548C5.37178 19.2815 5.90134 19.2815 6.22796 18.9548L12.0002 13.1826L17.7724 18.9548C18.0991 19.2815 18.6286 19.2815 18.9552 18.9548C19.2819 18.6282 19.2819 18.0987 18.9552 17.772L13.183 11.9998L18.9552 6.22757C19.2819 5.90095 19.2819 5.37139 18.9552 5.04477C18.6286 4.71815 18.0991 4.71815 17.7724 5.04477L12.0002 10.817L6.22796 5.04477Z"
                  fill="white"
                />
              </svg>
            </SvgIcon>
          </IconButton>
          <Box>
            <Typography className="title">Current Job Details</Typography>
            <Typography className="sub-text">
              These details would help recruiters understand & connect with you.
            </Typography>
          </Box>
        </Stack>
      </Box>
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Box className="drawer-content">
          <Stack spacing={2}>
            <FormControl fullWidth size="small" error={!!errors.notice_period}>
              <InputLabel id="notice_period">Notice Period</InputLabel>
              <Controller
                control={control}
                name="notice_period"
                defaultValue={defaultValues?.buyout_period || ''}
                render={({ field }) => (
                  <Select
                    {...field}
                    {...register('notice_period')}
                    labelId="notice_period"
                    label="Notice Period"
                    variant="outlined"
                    fullWidth
                  >
                    <MenuItem value="15">15 Days</MenuItem>
                    <MenuItem value="30">30 Days</MenuItem>
                    <MenuItem value="45">45 Days</MenuItem>
                    <MenuItem value="60">60 Days</MenuItem>
                  </Select>
                )}
              />
              {errors.notice_period && <FormHelperText error>{errors.notice_period?.message}</FormHelperText>}
            </FormControl>
            <FormControl fullWidth
              className="job-drawer">
              <Controller
                control={control}
                name="buyout_period"
                defaultValue={defaultValues?.buyout_period || ''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="buyout_period"
                    name="buyout_period"
                    placeholder="If notice period is negotiable, please state how much."
                    disabled={!isBuyout}
                    variant="outlined"
                    size="small"
                    error={!!errors.buyout_period}
                    helperText={errors.buyout_period?.message}
                    className="job-drawer"
                  />
                )}
              />


            </FormControl>

            {/* <FormControl fullWidth>
              <Controller
                control={control}
                name="buyout_period"
                defaultValue={defaultValues?.buyout_period || ''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="buyout_period"
                    name="buyout_period"
                    placeholder='If notice period is negotiable, please state how much.'
                    disabled={!isBuyout}
                    variant="outlined"
                    size="small"
                    defaultValue={defaultValues?.buyout_period || ''}
                    error={!!errors.buyout_period}
                    helperText={errors.buyout_period?.message}
                  />
                )}
              />
            </FormControl> */}
            <FormControl error={!!errors.is_negotiable} className="neogtiable-chk" size="small">
              <Controller
                name="is_negotiable"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    control={
                      <Checkbox
                        {...field}
                        {...register('is_negotiable')}
                        id="is_negotiable"
                        name="is_negotiable"
                        checked={isBuyout}
                        onChange={(event) => {
                          if (!event.target.checked) {
                            setValue('buyout_period', '');
                          }
                          field.onChange(event.target.checked);
                          setIsBuyout(event.target.checked);
                        }}
                      />
                    }
                    label="Notice period is negotiable"
                    id="is_negotiable"
                  />
                )}
              />
              {errors.is_negotiable && (
                <Typography variant="caption" color="error">
                  {errors.is_negotiable.message}
                </Typography>
              )}
            </FormControl>
          </Stack>
          <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginBottom: '1rem' }} />

          <Box className="annual-compensation" mb={3}>
            <Box mb={3}>
              <Typography className="step-heading" variant="h6">
                Annual Compensation
              </Typography>
              <Typography className="step-info" variant="body2">
                Please tell us your annual compensation with current company
              </Typography>
            </Box>
            <Box>
              <Stack spacing={2}>
                <FormControl fullWidth error={!!errors?.fixed_ctc} >
                  <Controller
                    control={control}
                    name="fixed_ctc"
                    defaultValue={defaultValues?.fixed_ctc || ''}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="fixed_ctc"
                        name="fixed_ctc"
                        label="Fixed Component"
                        variant="outlined"
                        size="small"
                        value={field.value ? formatCurrency(field.value) : ''}
                        error={!!errors.fixed_ctc}
                        onChange={(e) => {
                          const rawValue = parseInt(e.target.value.replace(/,/g, '')) || null;
                          field.onChange(rawValue);
                        }}
                      />
                    )}
                  />
                  {errors?.fixed_ctc && <FormHelperText error>{errors?.fixed_ctc?.message}</FormHelperText>}
                </FormControl>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="variable_ctc"
                    defaultValue={defaultValues?.variable_ctc || ''}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="variable_ctc"
                        name="variable_ctc"
                        label="Variable Component"
                        variant="outlined"
                        size="small"
                        value={field.value ? formatCurrency(field.value) : ''}
                        error={!!errors.variable_ctc}
                        helperText={errors.variable_ctc?.message}
                        onChange={(e) => {
                          const rawValue = parseInt(e.target.value.replace(/,/g, '')) || null;
                          field.onChange(rawValue);
                        }}
                      />
                    )}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <Controller
                    control={control}
                    name="other_ctc"
                    defaultValue={defaultValues?.other_ctc || ''}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        id="other_ctc"
                        name="other_ctc"
                        label="Other Perks"
                        variant="outlined"
                        size="small"
                        defaultValue={defaultValues?.other_ctc || ''}
                        error={!!errors.other_ctc}
                        helperText={errors.other_ctc?.message}
                        onChange={(e) => field.onChange(e.target.value || '')}
                      />
                    )}
                  />
                </FormControl>
                <Box className="annual-compensation compensation-det-block">
                {watch('total_ctc') && watch('total_ctc') > '0' ? (
                    <Box className="after-comp-amt">
                      <Typography className="comp-text">Your Total Annual Compensation</Typography>
                      <Typography className="comp-amt" mt={2}>
                      ₹{watch('total_ctc') && `${new Intl.NumberFormat('en-IN').format(watch('total_ctc'))}`}
                      </Typography>
                      <Typography className="comp-text">
                        {watch('other_ctc') && `Along with ${watch('other_ctc')}`}
                      </Typography>
                    </Box>
                  ) : (
                    <Box className="before-comp-amt">
                      <Typography className="comp-text">
                        Please enter your compensation details
                      </Typography>
                    </Box>
                  )}
                </Box>

              </Stack>
            </Box>
          </Box>

          <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} />

          <Box className="current-job-details">
            <Typography className="title">Job Application</Typography>
            <Typography className="sub-text">
              Please help us with answers to few questions to complete your application
            </Typography>

            <Stack spacing={2} mt={2}>
              <FormControl fullWidth size="small" error={!!errors.expected_ctc}>
                <Controller
                  control={control}
                  name="expected_ctc"
                  defaultValue={defaultValues?.expected_ctc || ''}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="expected_ctc"
                      type="number"
                      label="Expected Compensation"
                      variant="outlined"
                      size="small"
                      defaultValue={defaultValues?.expected_ctc || ''}
                      error={!!errors.expected_ctc}
                      helperText={errors.expected_ctc?.message}
                      onChange={(e) => field.onChange(e.target.value || '')}
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth size="small">
                <Controller
                  control={control}
                  name="work_format"
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      size="small"
                      options={workModelFormat}
                      getOptionLabel={(option) => option}
                      disableCloseOnSelect
                      open={openWorkFormat}
                      onOpen={() => setOpenWorkFormat(true)}
                      onClose={(event, reason) => {
                        if (reason != 'selectOption') {
                          setOpenWorkFormat(false);
                        }
                      }}
                      onChange={(event, value) => {
                        field.onChange(value);
                        setOpenWorkFormat(true);
                      }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Preferred Work Format"
                          error={!!errors.work_format}
                          helperText={errors.work_format?.message}
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth size="small">
                <Controller
                  control={control}
                  name="job_type"
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      size="small"
                      options={jobType}
                      getOptionLabel={(option) => option}
                      disableCloseOnSelect
                      open={openJobType}
                      onOpen={() => setOpenJobType(true)}
                      onClose={(event, reason) => {
                        if (reason != 'selectOption') {
                          setOpenJobType(false);
                        }
                      }}
                      onChange={(event, value) => {
                        field.onChange(value);
                        setOpenJobType(true);
                      }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Preferred Job Type"
                          error={!!errors.job_type}
                          helperText={errors.job_type?.message}
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth size="small">
                <Controller
                  control={control}
                  name="preferred_job_shifts"
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      multiple
                      size="small"
                      options={preferredJobShifts}
                      getOptionLabel={(option) => option}
                      disableCloseOnSelect
                      open={openJobShifts}
                      onOpen={() => setOpenJobShifts(true)}
                      onClose={(event, reason) => {
                        if (reason !== 'selectOption') {
                          setOpenJobShifts(false);
                        }
                      }}
                      onChange={(event, value) => {
                        field.onChange(value);
                        setOpenJobShifts(true);
                      }}
                      renderOption={(props, option, { selected }) => (
                        <li {...props}>
                          <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            checked={selected}
                          />
                          {option}
                        </li>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Preferred Job Shifts"
                          variant="outlined"
                          error={!!errors.preferred_job_shifts}
                          helperText={errors.preferred_job_shifts?.message}
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth >
                <Controller
                  control={control}
                  name='prefered_location'
                  defaultValue={defaultValues?.prefered_location || []}
                  render={({ field }) =>
                    <TypeAhead
                      {...field}
                      {...register('prefered_location')}
                      multiple={true}
                      selectedAsCheckbox={true}
                      api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.city}`}
                      label="Preferred Locations"
                      disableCloseOnSelect={true}
                      onChange={(e, value) => field.onChange(value)}
                      error={!!errors.prefered_location}
                      helperText={errors.prefered_location ? errors.prefered_location.message : ''}
                    />
                  }
                />
              </FormControl>
            </Stack>
          </Box>
        </Box>

        <Box className="drawer-footer">
          <Stack direction="row" spacing={2}>
            <Button fullWidth size="large" className="cancel-btn" onClick={onClose}>
              Cancel
            </Button>
            <Button fullWidth variant="contained" size="large" className="update-btn" onClick={onSubmit} disabled={isSubmitting}>
              Update
            </Button>
          </Stack>
        </Box>
      </FormProvider>
    </Box>
  );
};

export default JopApplicationDrawer;
