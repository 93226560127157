import React from 'react'
import {
    Box,
    Dialog,
    Card,
    CardContent,
    Link,
    Chip,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SvgIcon,
    Stack,
    TextField,
    Typography,
    Autocomplete,
    Button,
    Container,
    IconButton,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useNavigate } from 'react-router-dom';
import TypeAhead from 'components/TypeAhead';
import { useAuth } from 'hooks/useAuthContext';
import { useSnackbar } from 'components/snackbar';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, register, watch, Controller } from 'react-hook-form';
import FormProvider from 'hooks/form/form-provider';
import { CandidateEducationSchema } from 'models/CandidateProfileSchema';
import { updateCandidateEducation } from 'api/users';
import dayjs from 'dayjs';
import DynamicTextField from 'components/DynamicTextField'
import { endpoints } from 'utils/axios';
import { useState, useEffect, useRef } from 'react';
import AddSkills from '../basic-profile/AddSkills';
import { updateCandidateCertificate } from 'api/users';

const CreateUpdateCertificate = ({ certificateData, closeDialog, onDataChange }) => {
    const { enqueueSnackbar } = useSnackbar();
    const isEditMode = !!certificateData._id;
    const navigate = useNavigate();
    const { user, updateUnsaved } = useAuth();
    const [showFlag, setShowFlag] = useState(false);
    const [educationFrom, setEducationFrom] = useState(dayjs(certificateData?.education_from) || null);
    const [selectedChips, setSelectedChips] = useState(certificateData?.skills || []);
    const [open, setOpen] = React.useState(false);
    const [addMoreSkills, setAddMoreSkills] = useState([
        'Analytical Skills',
        'Finance strategy',
        'Financial Acumen',
        'Adaptability and Resilience',
        'Leadership',
        'Strategic Thinking',
        'Strategic',
        'Thinking',
    ]);

    const [chipData, setChipData] = useState(certificateData?.skills || [
        "Analytical Skills",
        "Finance strategy",
        "Financial Acumen",
        "Adaptability and Resilience",
        "Leadership",
        "Strategic Thinking",
        "Finance"
    ]);

    const defaultValues = {
        education_from: null,
        education_to: null,
        education_institute_name: '',
        certificate_received: '',
        learning_format: '',
        field_of_study: '',
        ...certificateData
    }
    const methods = useForm({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(CandidateEducationSchema('certificate')),
    });

    const {
        register,
        handleSubmit,
        control,
        getValues,
        setValue,
        reset,
        formState: { errors, isDirty, isValid, dirtyFields, isSubmitting },
        watch,
    } = methods;

    const handleEducationFromChange = (date) => {
        setEducationFrom(date);
        setValue('education_from', date.$d);
    };

    const handleEducationtoChange = (date) => {
        setValue('education_to', date.$d);
    };

    const skills = [{ label: 'Tax' }, { label: 'Accounting' }, { label: 'Audit' }];

    const format = [{ label: 'Online' }, { label: 'Offline' }];

    const onSubmit = handleSubmit(async (formData) => {
        if (isEditMode) {
            formData.certificationId = certificateData._id;
        }
        if (selectedChips && selectedChips.length >= 3) {
            setShowFlag(false);
            formData.skills = selectedChips;
        } else {
            setShowFlag(true);
            return;
        }
        const response = await updateCandidateCertificate(formData);
        if (response?.success === true && response.data) {
            onDataChange();
        } else if (response?.success === false) {
            enqueueSnackbar(response.message, { variant: 'error' });
        }
    });

    const handleClick = (label) => {
        if (selectedChips.includes(label)) {
            setSelectedChips(selectedChips.filter((chip) => chip !== label));
        } else {
            setSelectedChips([...selectedChips, label]);
        }
    };

    const handleOpenOtpDialog = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAddMoreSkills = (moreSkills) => {
        setSelectedChips(moreSkills);
    }

    const handleCancel = () => {
        reset();
    };
    return (
        <Box sx={{ width: 450 }} className="job-app-drawer-container" role="presentation" >
            <FormProvider methods={methods} onSubmit={onSubmit}>
                <Box className="drawer-header">
                    <Stack direction="row" alignItems={'center'} spacing={1}>
                        <IconButton aria-label="delete" onClick={closeDialog}>
                            <SvgIcon>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M6.22796 5.04477C5.90134 4.71815 5.37178 4.71815 5.04516 5.04477C4.71854 5.37139 4.71854 5.90095 5.04516 6.22757L10.8174 11.9998L5.04516 17.772C4.71854 18.0987 4.71854 18.6282 5.04516 18.9548C5.37178 19.2815 5.90134 19.2815 6.22796 18.9548L12.0002 13.1826L17.7724 18.9548C18.0991 19.2815 18.6286 19.2815 18.9552 18.9548C19.2819 18.6282 19.2819 18.0987 18.9552 17.772L13.183 11.9998L18.9552 6.22757C19.2819 5.90095 19.2819 5.37139 18.9552 5.04477C18.6286 4.71815 18.0991 4.71815 17.7724 5.04477L12.0002 10.817L6.22796 5.04477Z" fill="white" />
                                </svg>
                            </SvgIcon>
                        </IconButton>

                        <Box>
                            <Typography className="title" sx={{ marginBottom: "0!important" }}>
                                {watch('certificate_received') ? `${watch('certificate_received')} at ` : ''}
                                {watch('education_institute_name') ? watch('education_institute_name') : 'Certificate'}
                            </Typography>
                        </Box>
                    </Stack>
                </Box>
                <Box className="drawer-content">
                    <Stack spacing={2}>
                        <FormControl fullWidth size="small">
                        <Controller
                        control={control}
                        name="education_institute_name"
                        render={({ field }) => (
                            <TypeAhead
                            {...field}
                            api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.institute}`}
                            multiple={false}
                            fullWidth
                            label="Institution"
                            displayTextKey="Institution Name"
                            placeholder="Institution Name"
                            onChange={(e, value) => field.onChange(value)}
                            />
                            )}
                        />
                        </FormControl>
                        <FormControl fullWidth size="small">
                            <Controller
                                control={control}
                                name="certificate_received"
                                defaultValue={defaultValues?.degree_received || ''}
                                render={({ field }) => (
                                    <TypeAhead
                                        {...field}
                                        {...register('certificate_received')}
                                        api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.certificate}`}
                                        label="Certificate"
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                variant="outlined"
                                                error={!!errors.certificate_received}
                                                helperText={errors.certificate_received ? errors.certificate_received.message : ''}
                                            />
                                        )}
                                        onChange={(e, value) => {
                                            if (value) {
                                                field.onChange(value?.name);
                                                setChipData(value?.skills);
                                                setAddMoreSkills(value?.skills);
                                            }
                                        }}
                                    />
                                )}
                            />
                        </FormControl>

                        <FormControl fullWidth size="small">
                            <Controller
                                control={control}
                                name="field_of_study"
                                defaultValue={defaultValues?.field_of_study || []}
                                render={({ field }) => (
                                    <Autocomplete
                                        size="small"
                                        {...field}
                                        disablePortal
                                        {...register('field_of_study')}
                                        id="field_of_study"
                                        name="field_of_study"
                                        options={skills?.map((item) => item?.label)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                size="small"
                                                label="Field of Study"
                                                error={!!errors.field_of_study}
                                                helperText={errors.field_of_study ? errors.field_of_study.message : ''}
                                            />
                                        )}
                                        onChange={(e, value) => field.onChange(value)}
                                    />
                                )}
                            />
                        </FormControl>


                        <FormControl fullWidth size="small">
                            <Controller
                                control={control}
                                name="learning_format"
                                defaultValue={defaultValues?.learning_format || []}
                                render={({ field }) => (
                                    <Autocomplete
                                        size="small"
                                        {...field}
                                        disablePortal
                                        {...register('learning_format')}
                                        id="learning_format"
                                        name="learning_format"
                                        options={format?.map((item) => item?.label)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                size="small"
                                                label="Learning Format"
                                                error={!!errors.learning_format}
                                                helperText={errors.learning_format ? errors.learning_format.message : ''}
                                            />
                                        )}
                                        onChange={(e, value) => field.onChange(value)}
                                    />
                                )}
                            />
                        </FormControl>


                        <FormControl fullWidth>
                            <Controller
                                control={control}
                                name="education_from"
                                defaultValue={defaultValues?.education_from}
                                render={({ field }) => (
                                    <DatePicker
                                        {...field}
                                        value={dayjs(field?.value)}
                                        autoOk={true}
                                        format="MM/YYYY"
                                        views={['month', 'year']}
                                        label="Start Date"
                                        disableFuture
                                        closeOnSelect={true}
                                        sx={{ width: '100%' }}
                                        onChange={(date) => handleEducationFromChange(date)}
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                error: !!errors?.education_from,
                                                helperText: errors?.education_from?.message,
                                            },
                                        }}
                                    />
                                )}
                            />
                        </FormControl>

                        <FormControl fullWidth>
                            <Controller
                                control={control}
                                name="education_to"
                                defaultValue={defaultValues?.education_to}
                                render={({ field }) => (
                                    <DatePicker
                                        {...field}
                                        value={dayjs(field?.value)}
                                        autoOk={true}
                                        format="MM/YYYY"
                                        views={['month', 'year']}
                                        label="End Date"
                                        onChange={(date) => handleEducationtoChange(date)}
                                        slotProps={{
                                            textField: {
                                                size: 'small',
                                                error: !!errors?.education_to,
                                                helperText: errors?.education_to?.message,
                                            },
                                        }}
                                        disableFuture
                                        minDate={educationFrom}
                                        closeOnSelect={true}
                                    />
                                )}
                            />
                        </FormControl>
                    </Stack>
                    <Stack spacing={2} mt={2}>
                        <Box className="besic-det-skills-wrapper">
                            <Box mb={2}>
                                <Typography className="title" variant="body2" gutterBottom>
                                    Skills
                                </Typography>
                                <Typography className="info-txt" variant="body2" gutterBottom>
                                    3 to 8 skills can be selected
                                </Typography>
                            </Box>
                            <Stack direction="row" flexWrap="wrap">
                                {chipData.map((label, index) => (
                                    <Chip
                                        key={index}
                                        label={label}
                                        size="small"
                                        clickable
                                        variant={selectedChips.includes(label) ? 'filled' : 'outlined'}
                                        style={{
                                            color: selectedChips.includes(label) ? 'white' : 'black',
                                            backgroundColor: selectedChips.includes(label) ? '#ade9ab' : 'transparent',
                                            border: selectedChips.includes(label) ? 'none' : '1px solid rgba(0, 0, 0, 0.23)',
                                        }}
                                        onClick={() => handleClick(label)}
                                    />
                                ))}
                            </Stack>
                            {showFlag && (
                                <Typography style={{ color: 'red', fontSize: 'small' }} mb={2}>
                                    Please select atleast 3 skills.
                                </Typography>
                            )}
                            <Link className="add-more-skills-link" underline="none" size="small" onClick={handleOpenOtpDialog}>
                                Add More Skills
                            </Link>
                        </Box>
                    </Stack>
                </Box>
                <Box className="drawer-footer">
                    <Stack direction="row" spacing={2}>
                        <Button fullWidth size="large" className="cancel-btn" onClick={closeDialog}>Cancel</Button>
                        <Button fullWidth className="update-btn" variant="contained" disabled={isSubmitting} type="submit" size="large" >
                            {isEditMode ? 'Update' : 'Done'}
                        </Button>
                    </Stack>
                </Box>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="otp-dialog-wrapper"
                >
                    <AddSkills onClose={handleClose} skills={addMoreSkills} selectedskills={selectedChips} onAddSkills={handleAddMoreSkills} />
                </Dialog>
            </FormProvider >
        </Box >
    )
}

export default CreateUpdateCertificate;
