import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Container, Grid, Divider, Card, Stack, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem, InputAdornment } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { useSnackbar } from '../../components/snackbar';
import FormProvider from 'hooks/form/form-provider';
import { useForm, Controller } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { saveInternalUsers } from 'api/users';
import {useAuthDispatch} from '../../components/AuthStateProvider'

function UserProfile({ data, userType, isAdmin, isEditing, onSubmitDome }) {
    const { enqueueSnackbar } = useSnackbar(); 
    const { updateLocalStorage } = useAuthDispatch();
    const indianStates = [
        'Andhra Pradesh',
        'Arunachal Pradesh',
        'Assam',
        'Bihar',
        'Chhattisgarh',
        'Goa',
        'Gujarat',
        'Haryana',
        'Himachal Pradesh',
        'Jharkhand',
        'Karnataka',
        'Kerala',
        'Madhya Pradesh',
        'Maharashtra',
        'Manipur',
        'Meghalaya',
        'Mizoram',
        'Nagaland',
        'Odisha',
        'Punjab',
        'Rajasthan',
        'Sikkim',
        'Tamil Nadu',
        'Telangana',
        'Tripura',
        'Uttar Pradesh',
        'Uttarakhand',
        'West Bengal',
        'Andaman and Nicobar Islands',
        'Chandigarh',
        'Dadra and Nagar Haveli',
        'Daman and Diu',
        'Delhi',
        'Lakshadweep',
        'Puducherry',
    ];

    const defaultValues = data ? {
        ...data,
    } : {
        user_type: userType || '',
        phone_number: '',
        name: '',
        email: '',
    };
    
    const methods = useForm({
        defaultValues,
        mode: "onChange",
    });
    
    const {
        control,
        handleSubmit,
        formState: { errors, isSubmitting },
    } = methods;
    
    const onSubmit = handleSubmit(async (formData) => {
        try {
            if (userType) formData.user_type = userType;
            if (!data?.phone_number) formData.phone_number = `+91${formData?.phone_number}`;
            const response = await saveInternalUsers(formData)
            if (response?.success === true && response.status == 200) {
                if (!isAdmin) {
                    // const updatedValues={...data, name: formData?.name, displayName: formData?.name, email: formData?.email }
                    // localStorage.setItem('TokenDetails',JSON.stringify(updatedValues))
                    const updatedValues = { name: formData?.name, displayName: formData?.name, email: formData?.email };
                    updateLocalStorage('TokenDetails', updatedValues);
                }
                enqueueSnackbar(response.message, { variant: 'success' })
                if (onSubmitDome) onSubmitDome(true)
            } else if (response?.success === false) {
                enqueueSnackbar("Unable to save the data.", { variant: 'error' })
                if (onSubmitDome) onSubmitDome(null, true)
            }
        } catch (e) {
            console.log(e)
            enqueueSnackbar("Unable to save the data.", { variant: 'error' })
            if (onSubmitDome) onSubmitDome(null, true)
        }
    })

    return (
        <>
            <Card sx={{ py: 2, px: 3 }}>
                <FormProvider methods={methods} onSubmit={onSubmit}>
                    <Grid container spacing={1} justifyContent='center'> 
                    {isAdmin && <Grid item md={6}>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel id="user-type-select-label">User Type</InputLabel>
                            <Controller
                                name="user_type"
                                control={control}
                                render={({ field }) => (
                                    <Select
                                        {...field}
                                        disabled={ !isEditing }
                                        labelId="user-type-select-label"
                                        label="User Type">
                                        <MenuItem key="user_type_admin" value="admin">Admin</MenuItem>
                                        <MenuItem key="user_type_expert" value="expert">Expert</MenuItem>
                                    </Select>
                                )} />
                            </FormControl>
                        </Grid> }
                        <Grid item md={6}>
                            <Controller
                                name="phone_number"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        disabled={ data?.phone_number }
                                        sx={{ minWidth: 300 }}
                                        label="Phone number"
                                        fullWidth
                                        placeholder='9999999999'
                                        margin="normal"
                                        InputProps={ !data?.phone_number ? {
                                            startAdornment: (
                                            <InputAdornment position="start">
                                                +91
                                            </InputAdornment>
                                            ),
                                        } : null }
                                    />
                                )} />
                        </Grid>
                        <Grid item md={6}>
                            <Controller
                                name="name"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field} 
                                        sx={{ minWidth: 300 }}
                                        label="Name"
                                        fullWidth
                                        margin="normal"
                                    />
                            )} />
                        </Grid>
                        <Grid item md={6}>
                            <Controller
                                name="email"
                                control={control}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        sx={{ minWidth: 300 }}
                                        label="Email"
                                        fullWidth
                                        margin="normal"
                                    />
                                )} />
                        </Grid>


                        <Grid item xs={12} textAlign='center'>
                            <Divider sx={{ my: 2 }} />
                            <LoadingButton
                                sx={{ minWidth: 250 }}
                                size="large"
                                type="submit"
                                variant="contained"
                                loading={isSubmitting}
                            >
                                Save
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </FormProvider>
            </Card>
        </>
    )
}

export default UserProfile;