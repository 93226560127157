
import { fetcher, endpoints, poster } from '../utils/axios';

export async function addAdminAndCompanyUsers(payload) {
    const URL = endpoints.admin.addAdminAndCompanyUsers;
    return await poster([URL, payload])
}

export async function getAllAdminsAndCompanies(payload) {
    const URL = endpoints.admin.getAllAdminsAndCompanies;
    return await fetcher([URL, {params:payload}])
}

export async function deleteUser(payload) {
    const URL = endpoints.admin.deleteUser;
    return await poster([URL, payload])
}

export async function deleteWorkHistory(payload) {
    const URL = endpoints.admin.deleteWorkHistory;
    return await poster([URL, payload])
}
  