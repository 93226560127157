import React, { forwardRef, useLayoutEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  IconButton,
  InputLabel,
  OutlinedInput,
  Stack,
  SvgIcon,
  Typography,
  FormControl,
  TextField,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import { endpoints } from 'utils/axios';

const AddSkills = ({ onClose, skills, selectedskills, onAddSkills, title, type }) => {
  const [selectedSkills, setSelectedSkills] = useState(selectedskills || []);
  const [searchTerm, setSearchTerm] = useState('');
  const [error, setError] = useState('');
  const [filteredSkills, setFilteredSkills] = useState(skills || []);
  const focusRef = useRef(null);

  useLayoutEffect(() => {
    focusRef.current?.focus();
  }, [])

  const handleDoneClick = () => {
    if (selectedSkills.length > 8 && type !== 'jobType') {
      setError('Cannot add more than 8 skills');
    } else {
      onAddSkills(selectedSkills);
      onClose();
    }
  };

  const handleCheckboxChange = (event) => {
    const skillName = event.target.name;
    if (event.target.checked) {
      if (selectedSkills.length >= 8 && type !== 'jobType') {
        setError('Cannot add more than 8 skills');
        return;
      }
      setSelectedSkills((prevSelectedSkills) => [...prevSelectedSkills, skillName]);
    } else {
      setSelectedSkills((prevSelectedSkills) => prevSelectedSkills.filter((skill) => skill !== skillName));
      setError('');
    }
  };

  // const handleJobSkills = (value) => {
  //   setSelectedSkills((prevSelectedSkills) =>[...prevSelectedSkills, value[0]]);
  // };

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  // const filteredSkills = skills.filter((skill) => skill.toLowerCase().includes(searchTerm.toLowerCase()));

  const fetchData = async (inputValue) => {
    if (!inputValue) return; 
    try {
      const response = await fetch(`${process.env.REACT_APP_END_POINT}${endpoints?.services?.skills}?q=${inputValue}`);
      if (!response.ok) {
        throw new Error('Failed to fetch data');
      }
      const resultData = await response.json();
      setFilteredSkills(resultData);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const handleInputChange = (event) => {
    const newInputValue = event.target.value;
    fetchData(newInputValue);
  };

  return (
    <Card className="add-skills-dialog" variant="outlined">
      <CardContent>
        <IconButton className="close-dialog-btn" aria-label="delete" color="primary" onClick={onClose}>
          <SvgIcon sx={{ width: '20px', height: '20px' }}>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
              <g clipPath="url(#clip0_456_20993)">
                <path
                  d="M7 7L17 17M17 7L7 17L17 7Z"
                  stroke="#111827"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_456_20993">
                  <rect width="24" height="24" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </SvgIcon>
        </IconButton>

        <Box mb={2.5}>
          <Typography className="title" component="h6">
            {`Add Skills${title ? ` in ${title}` : ''}`}
          </Typography>
          <Typography className="sub-text" component="p">
            Please select relevant skills from the list below
          </Typography>
        </Box>
        
        <Box >
          <FormControl fullWidth>
            <TextField
              label="Skill Name"
              inputRef={focusRef}
              onChange={handleInputChange}
              size="small"
              variant="outlined"
            />
          </FormControl>
        </Box>

        {error && (
          <Typography className="error-text" component="p" style={{ color: 'red', fontSize: '12px' }}>
            {error}
          </Typography>
        )}

        <Box className="add-skills-dialog-content" mb={3.5}>
          <Box className="content-inner">
            <FormGroup>
              {filteredSkills.map((label, index) => (
                <FormControlLabel
                  control={
                    <Checkbox checked={selectedSkills.includes(label)} onChange={handleCheckboxChange} name={label} />
                  }
                  key={index}
                  label={label}
                />
              ))}
            </FormGroup>
          </Box>
        </Box>

        <Box className="skills-dialog-actions">
          <Stack direction="row" alignItems={'center'} spacing={3}>
            <Button fullWidth className="otp-btn" variant="outlined" size="medium" onClick={onClose} tabIndex={-1}>
              Cancel
            </Button>
            <Button fullWidth className="otp-btn" variant="contained" size="medium" onClick={handleDoneClick}>
              Done
            </Button>
          </Stack>
        </Box>
      </CardContent>
    </Card>
  );
};

export default AddSkills;
