// utils
import { fetcher, endpoints } from '../utils/axios';
export async function getFeedbackList(payload) {
    const URL = endpoints.feedback.getFeedbackList;
    return await fetcher([URL, { params: payload }])
}

export async function getApplicationFeedback(payload) {
    const URL = endpoints.feedback.getApplicationFeedback;
    return await fetcher([URL, { params: payload }])
}