import React, { useState } from 'react';
import { Box, Button, Card, CardContent, Link, IconButton, InputLabel, OutlinedInput, Stack, SvgIcon, Typography, TextField, FormControl, InputAdornment, Divider } from '@mui/material';
import { setApplication } from 'api/application';
import { useSnackbar } from 'components/snackbar';


const ExternalJobStatus = ({ onClose, tab, job }) => {
  const { enqueueSnackbar } = useSnackbar();  
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handleJobApplied = async () => {
      try {
        setIsSubmitting(true);
        if (job) {
          const jobId = tab === '2' ? job?._id : job?.job_id?._id;
          const response = await setApplication({jobId: jobId, type: 'externalJobApplied', status: "applied" });
          if (response?.success && response?.data != null) {
            const company = response?.data?.job_id?.company;
            const title = response?.data?.job_id?.title;
            enqueueSnackbar(`Successfully applied to ${company} for ${title}`, { variant: 'success' });
            if (tab === '3') {
              job.status = response?.data?.status;
              job.applied_at = response?.data?.applied_at;
            } else {
              job.application_status = response?.data?.status;
            }
            onClose('applied');
          } else {
            enqueueSnackbar('Failed to submit the application. Please try again.', { variant: 'error' });
          }
        } else {
          enqueueSnackbar('Failed to submit the application. Please try again.', { variant: 'error' });
        }
      } catch (error) {
        console.error('Error submitting application:', error);
      } finally {
        setIsSubmitting(false);
      }
    };      

    return (
        <Card className="otp-card-wrapper" variant="outlined">
            <CardContent>
                <IconButton className="close-dialog-btn" aria-label="delete" color="primary" onClick={onClose}>
                    <SvgIcon sx={{ width: '20px', height: '20px' }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g clipPath="url(#clip0_456_20993)">
                                <path d="M7 7L17 17M17 7L7 17L17 7Z" stroke="#111827" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
                            </g>
                            <defs>
                                <clipPath id="clip0_456_20993">
                                    <rect width="24" height="24" fill="white" />
                                </clipPath>
                            </defs>
                        </svg>
                    </SvgIcon>
                </IconButton>

                <Box mb={3} sx={{ textAlign: 'center' }}>
                    <Typography mb={1} className="title" component="h6">
                       Applied For Job
                    </Typography>
                    <Typography sx={{ textAlign: 'center!important' }} className="otp-sent-txt" component="p">
                        Were you able to apply for the job?
                    </Typography>
                </Box>

                <Stack className="external-job" direction="row" alignItems={'center'} spacing={3}>
                    <Button fullWidth className="otp-btn" variant="contained" size="medium" onClick={onClose}>
                        Couldn't Apply
                    </Button>
                    <Button fullWidth className="otp-btn" variant="contained" size="medium" onClick={handleJobApplied} disabled={isSubmitting}>
                        {isSubmitting ? 'Applied...' : 'Yes Applied Successfully'}
                    </Button>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default ExternalJobStatus;
