import React, { useState, useEffect } from 'react';
import { Autocomplete, Box, Button, Container, InputLabel, FormControl, Grid, Stack, TextField, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom';
import TypeAhead from 'components/TypeAhead';

import { useAuth } from 'hooks/useAuthContext';
import { useSnackbar } from 'components/snackbar';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, register, watch, Controller } from 'react-hook-form';
import FormProvider from 'hooks/form/form-provider';
import { AddAdminSchema, AddCompanySchema } from 'models/AdminAndCompanySchema';
import { addAdminAndCompanyUsers } from 'api/addadminandcompany';
import { endpoints } from 'utils/axios';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const AddUsersComponent = ({ onSaveData, initialData, userOption }) => {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const { user } = useAuth();
    const [formData, setFormData] = useState(initialData || {});
    const [isCompanySelect, setIsCompanySelect] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };

    const defaultValues = {
        user_type: formData?.user_type || '',
        full_name: formData?.full_name || '',
        company: '',
        phone_number: formData?.phone_number || '',
        email: formData?.email || '',
        user_id: formData?.id || '',
    }

    const userType = userOption;

    const methods = useForm({
        defaultValues,
        mode: "onChange",
        resolver: yupResolver(isCompanySelect ? AddCompanySchema : AddAdminSchema)
    });

    const {
        register,
        handleSubmit,
        control,
        setValue,
        formState: { errors, isDirty, isValid, dirtyFields, isSubmitting }, watch
    } = methods;

    const onCancel = () => {
        onSaveData();
    }

    const onSubmit = handleSubmit(async (formData) => {
        if (isCompanySelect) {
            formData["full_name"] = formData?.company?.name;
        }
        const response = await addAdminAndCompanyUsers(formData)
        if (response?.success && response.data) {
            enqueueSnackbar(response.message, { variant: 'success' })
            onSaveData(response.data);
        } else {
            enqueueSnackbar(response.message, { variant: 'error' })
        }
    })

    return (
        <Box>
            <Container>
                <FormProvider methods={methods} onSubmit={onSubmit}>
                    <Typography textAlign={'center'} variant="button" display="block" gutterBottom>
                        Add User
                    </Typography>
                    <Grid container rowSpacing={2} columnSpacing={{ xs: 1, sm: 2, md: 3 }} sx={{ marginTop: '2rem' }}>
                        <Grid item xs={12} lg={6}>
                            <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
                                <FormControl fullWidth >
                                    <Controller
                                        control={control}
                                        name='user_type'
                                        defaultValue={defaultValues?.user_type || ''}
                                        render={({ field }) =>
                                            <Autocomplete
                                                {...field}
                                                {...register('user_type')}
                                                options={userType?.map(item => item?.label)}
                                                renderInput={(params) => <TextField {...params} label="Select user type" variant="outlined"
                                                    error={!!errors.user_type}
                                                    helperText={errors.user_type ? errors.user_type.message : ''} />}
                                                onChange={(e, value) => {
                                                    field.onChange(value)
                                                    if (value === 'company' || value === 'recruiter') {
                                                        setIsCompanySelect(true);
                                                    } else {
                                                        setValue('company', '');
                                                        setValue('phone_number', '');
                                                        setValue('email', '');
                                                        setIsCompanySelect(false);
                                                    }
                                                }}
                                            />
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
                                <FormControl fullWidth><TextField
                                    name='phone_number'
                                    label="Phone Number"
                                    variant="outlined"
                                    placeholder='Please enter email'
                                    fullWidth
                                    {...register('phone_number')}
                                    InputProps={{ readOnly: isCompanySelect }}
                                    InputLabelProps={{ shrink: Boolean(watch('phone_number')) }}
                                    error={!!errors.phone_number}
                                    helperText={errors.phone_number?.message}
                                />
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} lg={6}>
                            <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
                                <FormControl fullWidth >
                                    {!isCompanySelect ?
                                        <TextField
                                            name='full_name'
                                            label="Name"
                                            variant="outlined"
                                            autoComplete="full_name"
                                            placeholder='Please enter name'
                                            fullWidth
                                            {...register('full_name')}
                                            error={!!errors.full_name}
                                            helperText={errors.full_name?.message}
                                        /> : <Controller
                                            control={control}
                                            name='company'
                                            defaultValue={defaultValues?.company || {}}
                                            render={({ field }) =>
                                                <TypeAhead
                                                    {...field}
                                                    api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.registeredCompany}`}
                                                    multiple={false}
                                                    fullWidth
                                                    label="Company Name"
                                                    placeholder="Please select company"
                                                    onChange={(e, selectedCompany) => {
                                                        if (selectedCompany) {
                                                            setValue('company', selectedCompany);
                                                            setValue('phone_number', selectedCompany?.phone_number || '');
                                                            setValue('email', selectedCompany?.email);
                                                        } else {
                                                            setValue('company', '');
                                                            setValue('phone_number', '');
                                                            setValue('email', '');
                                                        }
                                                    }}
                                                />

                                            }
                                        />}
                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sx={{ marginBottom: '1rem' }}>
                                <FormControl fullWidth>
                                    <TextField
                                        name='email'
                                        label="Email"
                                        variant="outlined"
                                        placeholder='Please enter email'
                                        fullWidth
                                        {...register('email')}
                                        InputProps={{ readOnly: isCompanySelect }}
                                        InputLabelProps={{ shrink: Boolean(watch('email')) }}
                                        error={!!errors.email}
                                        helperText={errors.email?.message}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box textAlign={'center'} flexDirection="row" justifyContent="flex-end" sx={{ marginBottom: '1rem' }}>
                        <Button variant="contained" type="submit" sx={{ marginRight: 2 }}>{!defaultValues?.user_id ? 'Save' : 'Update'}</Button>
                        <Button variant="contained" onClick={() => onCancel()}>Cancel</Button>
                    </Box>
                </FormProvider>
            </Container>
        </Box>
    )
}

export default AddUsersComponent
