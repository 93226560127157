import PropTypes from 'prop-types';
import { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box, Link } from '@mui/material';
// ----------------------------------------------------------------------

const Logo = forwardRef(({ disabledLink = false, title, sx, ...other }, ref) => {
  const logoImg = `${process.env.REACT_APP_HOMEPAGE}assets/logo.webp`;
  const theme = useTheme();

  const PRIMARY_LIGHT = theme.palette.primary.light;

  const PRIMARY_MAIN = theme.palette.primary.main;

  const PRIMARY_DARK = theme.palette.primary.dark;

  // OR using local (public folder)
  // -------------------------------------------------------
  // const logo = (
  //   <Box
  //     component="img"
  //     src="/logo/logo_single.svg" => your path
  //     sx={{ width: 40, height: 40, cursor: 'pointer', ...sx }}
  //   />
  // );

  const logo = (
    <Box
      ref={ref}
      component="div"
      sx={{
        ...sx,
      }}
      {...other}
    >
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <img src={logoImg} alt="react logo" style={{
          width: sx?.width || 40,
          height: sx?.height || 40,
        }} />
        {!disabledLink ? <b style={{ paddingLeft: '10px'}}>{ title || `${ process.env.REACT_APP_DISPLAY_NAME }` }</b> : null}
      </Box>
    </Box>
  );

  if (disabledLink) {
    return <>{logo}</>;
  }

  return (
    <Link to={ process.env.REACT_APP_HOMEPAGE } component={RouterLink} sx={{ display: 'contents' }}>
      {logo}
    </Link>
  );
});

Logo.propTypes = {
  title: PropTypes.string,
  sx: PropTypes.object,
  disabledLink: PropTypes.bool,
};

export default Logo;
