import { Box, Dialog, SvgIcon, Typography } from '@mui/material';
import React, { useState } from 'react';
import Slider from 'react-slick';
import SelectResumeOptions from './SelectResumeOptions';
const resumeImg = `${process.env.REACT_APP_HOMEPAGE}assets/resume.png`;

const ChatResumeOptions = ({ onSet, resumeData, tab, job }) => {
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
  };

  // choose resume options dialog
  const [open, setOpen] = useState(false);
  const [selectedResume, setSelectedResume] = useState([]);
  const [resumeIndex, setIndex] = useState(0);

  const handleOpenOtpDialog = (item, index) => {
    setSelectedResume(item);
    setIndex(index);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelect = (resume, index) => {
    setOpen(false);
    onSet(resume, index);
  };

  return (
    <Box className="select-resume-option-container">
      <Slider {...settings} arrows={!(resumeData?.length <= settings?.slidesToShow)}>
        {resumeData &&
          resumeData?.map((item, index) => (
            <Box key={index} className="resume-option" onClick={() => handleOpenOtpDialog(item, index)}>
              <img alt="resume" src={resumeImg} />
              <Box className="select-resume-action">
                <SvgIcon className="jn-chat-icon">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.09274 6.14966C2.38619 7.75435 0.891638 9.09068 0.727274 9.09068C0.325819 9.09068 0 8.76487 0 8.36342V0.727254C0 0.32581 0.325819 0 0.727274 0H8.72729C9.12875 0 9.45456 0.32581 9.45456 0.727254C9.45456 0.897795 7.95347 2.43121 6.19601 4.13917L9.02911 6.97219C9.59711 7.53981 9.59711 8.46087 9.02911 9.02886C8.74475 9.31285 8.37274 9.45503 8.00038 9.45503C7.62802 9.45503 7.25601 9.31285 6.97201 9.02886L4.09274 6.14966ZM8.72729 24.0001H0.727273C0.325818 24.0001 0 23.6743 0 23.2728V15.6367C0 15.2352 0.325818 14.9094 0.727273 14.9094C0.891637 14.9094 2.38619 16.2457 4.0931 17.8504L6.97165 14.972C7.53965 14.4036 8.46038 14.4036 9.02875 14.972C9.59675 15.5396 9.59675 16.4606 9.02875 17.0286L6.19601 19.8613C7.95347 21.5689 9.45457 23.1023 9.45457 23.2728C9.45457 23.6743 9.12875 24.0001 8.72729 24.0001ZM23.9994 23.2728V15.6367C23.9994 15.2352 23.6736 14.9094 23.2721 14.9094C23.1078 14.9094 21.6132 16.2457 19.9067 17.8504L17.0281 14.972C16.4598 14.4036 15.539 14.4036 14.971 14.972C14.403 15.5399 14.403 16.461 14.971 17.0286L17.8038 19.8613C16.0459 21.5689 14.5448 23.1023 14.5448 23.2728C14.5448 23.6743 14.8707 24.0001 15.2721 24.0001H23.2721C23.6736 24.0001 23.9994 23.6743 23.9994 23.2728ZM23.2727 0C23.6742 0 24 0.32581 24 0.727254V8.36342C24 8.76487 23.6742 9.09068 23.2727 9.09068C23.1084 9.09068 21.6138 7.75435 19.9073 6.14966L17.0287 9.02813C16.7443 9.31213 16.3723 9.4543 16 9.4543C15.6276 9.4543 15.2556 9.31213 14.9713 9.02813C14.4033 8.46051 14.4033 7.53944 14.9713 6.97146L17.804 4.1388C16.0465 2.43121 14.5454 0.897795 14.5454 0.727254C14.5454 0.32581 14.8713 0 15.2727 0H23.2727Z"
                      fill="white"
                    />
                  </svg>
                </SvgIcon>
              </Box>
              <Box className="resume-title">
                <Typography>{item?.title || 'NA'}</Typography>
              </Box>
            </Box>
          ))}
      </Slider>

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="select-resumes-dialog"
      >
        <SelectResumeOptions
          onSelect={handleSelect}
          resumeData={resumeData}
          selectedResume={selectedResume}
          tab={tab}
          job={job}
          index={resumeIndex}
        />
      </Dialog>
    </Box>
  );
};

export default ChatResumeOptions;
