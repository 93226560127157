import React, { useState } from 'react';
import TypeAheadPlain from './TypeAheadPlain';
import { endpoints } from 'utils/axios';
import { TextField, Stack, Box, Button, Grid } from '@mui/material';

const QualificationSelector = ({ onAdd, ...rest }) => {
  const [values, setValues] = useState([]);
  const [option, setOption] = useState(0);

  const onAddClick = (data) => {
    onAdd({ values: data, option });
    // setValues([])
    // setOption(0)
  };

  return (
    <>
       {/* <Grid item xs={7} lg={6}> */}
        <TypeAheadPlain
          value={values}
          onChange={(e, data) => {
            // setValues(data);
            onAddClick(data);
          }
          }
          api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.degree}`}
          multiple={true}
          fullWidth
          label="Minimum Educational Qualification (Any of the below can apply)"
          placeholder="Minimum Educational Qualification (Any of the below can apply)"
          {...rest}
        />
       {/* </Grid> */}
      {/* <Grid item xs={3} lg={3}>
        <TextField
          size='small'
          fullWidth
          select
          label="Required"
          SelectProps={{
            native: true,
          }}
          onChange={(e) => setOption(e.target.value)}
          error={rest?.error}
          helperText={rest?.error && "select any one"}
        >
          <option key="option_0" value="0">
            All
          </option>
          <option key="option_1" value="1" selected>
            Any one
          </option>
        </TextField>
      </Grid>
      <Grid item xs={2} lg={3} alignItems='center'>
        <Button variant='contained' type="button" size='medium' disabled={!values?.length} onClick={onAddClick}>
          Add
        </Button>
      </Grid> */}
    </>
   );
};

export default QualificationSelector;
