import { Box, Card, CardContent, Divider, Grid, List, ListItem, Stack, Typography } from '@mui/material';
import React from 'react';
import { useAuth } from 'hooks/useAuthContext';
import dayjs from 'dayjs';
import { changeNameFormat } from 'utils/Utils';

const ResumeFullView = ({
  email,
  phone,
  summary,
  educationData,
  certificateData,
  workExperienceData,
  customSection,
  financeTechologiesData,
}) => {
  const { user, updateContext } = useAuth();
  const certificationIcon = `${process.env.REACT_APP_HOMEPAGE}assets/certification-img.png`;
  const imgLogo = `${process.env.REACT_APP_HOMEPAGE}assets/default.png`;

  return (
    <Box>
      <Box className="resume-container">
        {/* Resume Block */}
        <Card className="resume-card" variant="outlined" sx={{ marginBottom: '1rem' }}>
          <CardContent>
            {/* Candidate mail contact no */}
            <Box>
              <Stack direction="row" spacing={1} alignItems="start" mb={1}>
                <Box sx={{ width: '100%' }}>
                  <Stack direction="row" spacing={1.5} alignItems="center">
                    <Box className="resume-profile-pic">
                      <img alt="profile" width="50px" height="50px" src={user?.photo || imgLogo}
                      onError={(e) => (e.target.src = imgLogo)}
                      />
                    </Box>

                    <Box>
                      <Typography className="candidate-name" variant="p" gutterBottom>
                        {changeNameFormat(user?.name)}
                      </Typography>
                      <Typography className="candidate-email-no" variant="body2" gutterBottom>
                        {user?.email} | {user?.phone}
                      </Typography>
                    </Box>
                  </Stack>
                </Box>
              </Stack>

              <Typography className="candidate-personal-info" variant="body2" gutterBottom>
                {summary}
              </Typography>
            </Box>

            {educationData && educationData?.length > 0 && (
              <>
                <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} />

                {/* Candidate education block */}

                <Box>
                  <Typography className="resume-info-header-txt" variant="body2" gutterBottom mb={1.5}>
                    Education
                  </Typography>
                  {
                    educationData?.map((item, index) => (
                      <Box key={index}>
                        <Typography className="resume-info-header-main" variant="body2" gutterBottom>
                          {item?.education_institute_name || ''}
                        </Typography>
                        <Box mb={1} className="candidate-personal-info">
                          <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                            <Box>
                              <Stack className="list-item" direction="row" spacing={2}>
                                <Box>
                                  <Typography className="degree-name" variant="p" gutterBottom>
                                    {item?.degree_received || ''}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography className="degree-type" variant="p" gutterBottom>
                                    {item?.field_of_study || ''}
                                  </Typography>
                                </Box>
                              </Stack>
                            </Box>
                            <Typography className="year-of-passout" variant="p" gutterBottom>
                              {dayjs(item?.education_from).format('YYYY')}-
                              {dayjs(item?.education_to).format('YYYY') || '-'}
                            </Typography>
                          </Stack>
                        </Box>
                        <Box className="personal-edu-text">
                          <List sx={{ paddingTop: 0, paddingLeft: '1.5rem', listStyleType: 'disc' }}>
                            {item?.key_accomplishments &&
                              item?.key_accomplishments?.map((accomplishment, index) => (
                                <ListItem sx={{ display: 'list-item' }} disablePadding>
                                  <Typography className="educational-info-txt" variant="body2" gutterBottom>
                                    {accomplishment}
                                  </Typography>
                                </ListItem>
                              ))}
                          </List>
                        </Box>
                      </Box>
                    ))}
                </Box>
              </>
            )}

            {certificateData?.length > 0 && (
              <>
                <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} />

                {/* Candidate certification block */}

                <Box>
                  <Typography className="resume-info-header-txt" variant="body2" gutterBottom mb={1.5}>
                    Certification
                  </Typography>
                  {certificateData?.length > 0 &&
                    certificateData?.map((item, index) => (
                      <Box>
                        <Typography className="resume-info-header-main" variant="body2" gutterBottom>
                          {item?.education_institute_name || ''}
                        </Typography>
                        <Box className="candidate-personal-info">
                          <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                            <Box>
                              <Stack className="list-item" direction="row" spacing={2}>
                                <Box>
                                  <Typography className="degree-name" variant="p" gutterBottom>
                                    {item?.certificate_received || ''}
                                  </Typography>
                                </Box>
                                <Box>
                                  <Typography className="degree-type" variant="p" gutterBottom>
                                    {item?.field_of_study || ''}
                                  </Typography>
                                </Box>
                              </Stack>
                            </Box>

                            <Box>
                              <Stack direction="row" alignItems={'center'} spacing={1}>
                                <Box>
                                  <Box component="img" alt="certification" src={certificationIcon} />
                                </Box>
                                <Typography className="year-of-passout" variant="p" gutterBottom>
                                  {dayjs(item?.education_to).format('YYYY') || '-'}
                                </Typography>
                              </Stack>
                            </Box>
                          </Stack>
                        </Box>
                      </Box>
                    ))}
                </Box>
              </>
            )}

            {workExperienceData?.length > 0 && (
              <>
                <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }} />

                {/* Candidate work experience block */}

                <Box>
                  <Typography className="resume-info-header-txt" variant="body2" gutterBottom mb={1.5}>
                    Work Experience
                  </Typography>
                  {workExperienceData?.map((data, index) => (
                    <Box key={index}>
                      {data?.organization_name === 'Work Gap' ? (
                        data?.show_in_resume && (
                          <Box>
                            <Typography className="resume-info-header-main" variant="body2" gutterBottom>
                              {data?.highest_designation}
                            </Typography>
                            <Box mb={1} className="candidate-personal-info">
                              <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                                <Box>
                                  <Stack className="list-item" direction="row" spacing={2}>
                                  <Typography className="company-name" variant="p" gutterBottom>
                                            {data?.gap_explaination}
                                          </Typography>
                                  
                                  </Stack>
                                </Box>
                               
                              </Stack>
                            </Box>
                          </Box>
                        )
                      ) : (
                        <Box>
                          <Typography className="resume-info-header-main" variant="body2" gutterBottom>
                            {data?.highest_designation}
                          </Typography>
                          <Box mb={1} className="candidate-personal-info">
                            <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                              <Box>
                                <Stack className="list-item" direction="row" spacing={2}>
                                  <Box>
                                    <Typography className="company-name" variant="p" gutterBottom>
                                      {data?.organization_name}
                                    </Typography>
                                  </Box>
                                  <Box>
                                    <Typography className="work-type" variant="p" gutterBottom>
                                      {data?.job_type}
                                    </Typography>
                                  </Box>
                                </Stack>
                              </Box>
                              <Typography className="working-year" variant="p" gutterBottom>
                                {dayjs(data?.work_history_from).format('MMM YYYY')} -{' '}
                                {data?.present ? 'Present' : dayjs(data?.work_history_to).format('MMM YYYY')}
                              </Typography>
                            </Stack>
                          </Box>
                          <Box className="personal-work-text">
                            <List sx={{ paddingTop: 0, paddingLeft: '1.5rem', listStyleType: 'disc' }}>
                              {data?.key_accomplishments &&
                                data?.key_accomplishments.map((accomplishment, index) => (
                                  <ListItem sx={{ display: 'list-item' }} disablePadding>
                                    <Typography className="work-info-txt" variant="body2" gutterBottom>
                                      {accomplishment}
                                    </Typography>
                                  </ListItem>
                                ))}
                            </List>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  ))}
                </Box>
              </>
            )}
          </CardContent>
        </Card>

        

        {(financeTechologiesData?.length > 0 || workExperienceData?.length > 0 || customSection?.length > 0) && (
          <Card className="resume-card" variant="outlined">
            <CardContent>
            {financeTechologiesData?.length > 0 && (
          <Box>
            <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '0.5rem', marginBottom: '0.5rem' }} />
            <Box>
              <Typography className="resume-info-header-txt" variant="body2" gutterBottom>
                Finance Technical Competencies
              </Typography>
              <Box className="personal-edu-text" sx={{ display: 'flex', flexWrap: 'wrap' }}>
                {financeTechologiesData?.map((techSkills, techIndex) => (
                  <Typography
                    key={techIndex}
                    className="educational-info-txt"
                    variant="body2"
                    gutterBottom
                    sx={{ marginRight: '10px' }}
                  >
                    {techIndex + 1}. {techSkills}
                  </Typography>
                ))}
              </Box>
            </Box>
          </Box>
        )}

              {workExperienceData?.length > 0 && (
                <>
                  <Divider
                    sx={{ borderStyle: 'dashed', borderWidth: '1px', marginTop: '1rem', marginBottom: '1rem' }}
                  />

                  {/* Candidate Highlighted Skills */}
                  <Box className="candidate-highlighted-skills-sec" mb={3}>
                    <Box mb={1.5}>
                      <Typography className="resume-info-header-txt" variant="p" gutterBottom>
                        Highlighted Skills
                      </Typography>
                    </Box>

                    <Card variant="outlined" sx={{ borderRadius: '12px' }}>
                      <CardContent sx={{ padding: 0 }}>
                        <Box mb={1} className="header-block" sx={{ padding: '0 0.5rem' }}>
                          <Grid container spacing={1}>
                            <Grid item xs={2}>
                              <Typography variant="p" gutterBottom>
                                Skill
                              </Typography>
                            </Grid>
                            <Grid item xs={4}>
                              <Typography variant="p" gutterBottom>
                                Sub-Skill
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography variant="p" gutterBottom>
                                Used / Learnt at
                              </Typography>
                            </Grid>
                            <Grid item xs={3}>
                              <Typography variant="p" gutterBottom>
                                Years Used
                              </Typography>
                            </Grid>
                          </Grid>
                        </Box>
                        {workExperienceData?.map(
                          (data, index) =>
                            data?.organization_name !== 'Work Gap' && (
                              <Box>
                                <Box className="text-block" sx={{ padding: '0 0.5rem' }} key={index}>
                                  {index !== 0 && (
                                    <Divider
                                      sx={{ borderWidth: '0.01', marginTop: '0.5rem', marginBottom: '0.5rem' }}
                                    />
                                  )}
                                  <Grid container alignItems={'center'} spacing={1}>
                                    <Grid item xs={2}>
                                      <Typography variant="p" gutterBottom>
                                        {data?.highest_designation}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={4}>
                                      <Typography variant="p" gutterBottom>
                                        {data?.skills?.join(',')}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Typography variant="p" gutterBottom>
                                        {data?.organization_name}
                                      </Typography>
                                    </Grid>
                                    <Grid item xs={3}>
                                      <Typography variant="p" gutterBottom>
                                        {data?.work_experience || 'Present'}
                                      </Typography>
                                    </Grid>
                                  </Grid>
                                </Box>
                              </Box>
                            )
                        )}
                      </CardContent>
                    </Card>
                  </Box>
                </>
              )}

              {/* Candidate work experience block */}

              <Box mb={10}>
                {customSection?.map((section) => (
                  <Box>
                    <Typography className="resume-info-header-txt" variant="body2" mb={1}>
                      {section?.key}
                    </Typography>

                    <Box className="personal-work-text">
                      <Typography className="work-info-txt" variant="body2" gutterBottom>
                        <div dangerouslySetInnerHTML={{ __html: section?.message }} />
                      </Typography>
                    </Box>
                  </Box>
                ))}
              </Box>
            </CardContent>
          </Card>
        )}
      </Box>
    </Box>
  );
};

export default ResumeFullView;
