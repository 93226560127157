import { Box, Button, Card, CardContent, CardHeader, Checkbox, Chip, Container, Divider, FormControl, FormControlLabel, Grid, Icon, IconButton, InputAdornment, InputLabel, List, ListItem, MenuItem, OutlinedInput, Radio, RadioGroup, Select, Stack, SvgIcon, TextField, Typography } from '@mui/material'
import { useAuth } from 'hooks/useAuthContext';
import React, { useEffect, useState, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';

const Classic = ({ data }) => {
    // const { data?.educations, data?.work_history, data?.summary, customSection, data?.certificates } = data;
    const location = useLocation();
    const { loading, user } = useAuth();
    const navigate = useNavigate();
    const backIcon = `${process.env.REACT_APP_HOMEPAGE}assets/arrow_back.svg`;
    const certificationIcon = `${process.env.REACT_APP_HOMEPAGE}assets/certification-img.png`;

    const changeNameFormat = (fullName) => {
        let words = fullName.split(' ');
        let name = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
        return name;
    };

    return (
        <Box className="template-resume-display selected-view-classic" mt={2.5}>
            <Box className="header-block">
                <Stack direction="row" justifyContent={'space-between'} spacing={1}>
                    <Box>
                        <Typography className="title" variant="h6">Classic</Typography>
                        <Typography className="text" variant="p" gutterBottom>Some explainer text</Typography>
                    </Box>
                    {/* <Box>
                        <Button className="select-temp-btn" variant="contained">Select Template</Button>
                    </Box> */}
                </Stack>
            </Box>
            <Card className="resume-display-container" variant="outlined" sx={{ borderRadius: '12px' }}>
                <CardContent sx={{ padding: 0 }}>
                    <Box>
                        <Box className="display-resume-header">
                            <Typography className="name" variant="p" gutterBottom>{`${changeNameFormat(user.name)}` || "no data"}</Typography>

                            <Stack direction="row" spacing={1}>
                                <Typography className="email" variant="p" gutterBottom>
                                    {user.email || "no data"}
                                </Typography>
                                <Divider orientation="vertical" variant="middle" flexItem />
                                <Typography className="mobile-no" variant="p" gutterBottom>
                                    {user.phone || "no data"}
                                </Typography>
                            </Stack>
                        </Box>

                        <Box className="display-resume-info" sx={{ padding: '10px 20px' }}>
                            <Typography className="info-txt" variant="p" gutterBottom>
                                {data?.summary || "no data"}
                            </Typography>

                            <Divider sx={{ width: '100%', borderStyle: 'dashed', borderWidth: '1px', marginTop: '0.7rem', marginBottom: '0.7rem' }} />

                            {data?.educations?.length !== 0 && <>
                                <Box className="resume-info-row">
                                    <Box>
                                        <Typography className="main-title" component="p" variant="p" gutterBottom>Education</Typography>
                                    </Box>
                                    {data?.educations?.length !== 0 &&
                                        data?.educations?.map((item, index) => (
                                            <React.Fragment key={item._id || index}>
                                                <Box>
                                                    <Typography className="sub-title" component="p" variant="p">{item.education_institute_name || "no data"}</Typography>
                                                </Box>
                                                <Box mb={1} className="candidate-personal-info">
                                                    <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                                                        <Box>
                                                            <Stack className="list-item" direction="row" spacing={2}>
                                                                <Box>
                                                                    <Typography className="degree-name" variant="p" gutterBottom>{item.degree_received || "no data"}</Typography>
                                                                </Box>
                                                                <Box>
                                                                    <Typography className="degree-type" variant="p" gutterBottom>{item.field_of_study || "no data"}</Typography>
                                                                </Box>
                                                            </Stack>
                                                        </Box>
                                                        <Typography className="year-of-passout" variant="p" gutterBottom>
                                                            {(item.education_from && item.education_to) ? `${dayjs(item.education_from).format('YYYY')} - ${dayjs(item.education_to).format('YYYY')}` : '-'}
                                                        </Typography>
                                                    </Stack>
                                                </Box>
                                                {item.key_accomplishments.length !== 0 &&
                                                    item.key_accomplishments?.map((data, accomplishmentIndex) => (
                                                        <List sx={{ paddingTop: 0, paddingLeft: '1.5rem', listStyleType: 'disc' }} key={accomplishmentIndex}>
                                                            <ListItem sx={{ display: 'list-item' }} disablePadding>
                                                                <Typography className="info-list-txt" variant="body2" gutterBottom>
                                                                    {data}
                                                                </Typography>
                                                            </ListItem>
                                                        </List>
                                                    ))}
                                            </React.Fragment>
                                        ))}
                                </Box>
                                <Divider sx={{ width: '100%', borderStyle: 'dashed', borderWidth: '1px', marginBottom: '0.7rem' }} />
                            </>}

                            {data?.certificates.length !== 0 && <>
                                <Box className="resume-info-row">
                                    <Box>
                                        <Typography className="main-title" component="p" variant="p" gutterBottom>Certification</Typography>
                                    </Box>
                                    {data?.certificates.length !== 0 &&
                                        data?.certificates.map((item, index) => (
                                            <React.Fragment key={item._id || index}>
                                                <Box>
                                                    <Typography className="sub-title" component="p" variant="p" gutterBottom>{item.education_institute_name || "no data"}</Typography>
                                                </Box>
                                                <Box className="candidate-personal-info">
                                                    <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                                                        <Box>
                                                            <Stack className="list-item" direction="row" spacing={2}>
                                                                <Box>
                                                                    <Typography className="degree-name" variant="p" gutterBottom>{item.certificate_received || "no data"}</Typography>
                                                                </Box>
                                                                <Box>
                                                                    <Typography className="degree-type" variant="p" gutterBottom>{item.field_of_study || "no data"}</Typography>
                                                                </Box>
                                                            </Stack>
                                                        </Box>
                                                        <Box>
                                                            <Stack direction="row" alignItems={'center'} spacing={1}>
                                                                <Box>
                                                                    <Box
                                                                        component="img"
                                                                        alt="certification"
                                                                        src={certificationIcon}
                                                                    />
                                                                </Box>
                                                                <Typography className="year-of-passout" variant="p" gutterBottom>{dayjs(item.education_to).format('YYYY') || '-'}</Typography>
                                                            </Stack>
                                                        </Box>
                                                    </Stack>
                                                </Box>
                                            </React.Fragment>
                                        ))}
                                </Box>
                                <Divider sx={{ width: '100%', borderStyle: 'dashed', borderWidth: '1px', marginTop: '0.7rem', marginBottom: '0.7rem' }} />
                            </>}

                            {data?.work_history.length !== 0 && <>
                                <Box className="resume-info-row">
                                    <Box>
                                        <Typography className="main-title" component="p" variant="p" gutterBottom>Work Experience</Typography>
                                    </Box>
                                    {data?.work_history.length !== 0 &&
                                        data?.work_history.map((item, index) => (
                                            <React.Fragment key={item._id || index}>
                                                <Box>
                                                    <Typography className="sub-title" component="p" variant="p">{item.highest_designation || "no data"}</Typography>
                                                </Box>
                                                <Box mb={1} className="candidate-personal-info">
                                                    <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
                                                        <Box>
                                                            <Stack className="list-item" direction="row" spacing={2}>
                                                                <Box>
                                                                    <Typography className="degree-name" variant="p" gutterBottom>{item.organization_name || "no data"}</Typography>
                                                                </Box>
                                                                <Box>
                                                                    <Typography className="degree-type" variant="p" gutterBottom>{item.job_type || "no data"}</Typography>
                                                                </Box>
                                                            </Stack>
                                                        </Box>
                                                        <Typography className="year-of-passout" variant="p" gutterBottom>
                                                            {dayjs(item?.work_history_from).format('MMM YYYY')} - {item?.present ? 'Present' : dayjs(item?.work_history_to).format('MMM YYYY')}
                                                        </Typography>
                                                    </Stack>
                                                </Box>
                                                {item.key_accomplishments.length !== 0 &&
                                                    item.key_accomplishments.map((data, accomplishmentIndex) => (
                                                        <List sx={{ paddingTop: 0, paddingLeft: '1.5rem', listStyleType: 'disc' }} key={accomplishmentIndex}>
                                                            <ListItem sx={{ display: 'list-item' }} disablePadding>
                                                                <Typography className="info-list-txt" variant="body2" gutterBottom>
                                                                    {data}
                                                                </Typography>
                                                            </ListItem>
                                                        </List>
                                                    ))}
                                            </React.Fragment>
                                        ))}
                                </Box>
                                <Divider sx={{ width: '100%', borderStyle: 'dashed', borderWidth: '1px', marginTop: '0.7rem', marginBottom: '0.7rem' }} />
                            </>
                            }

                            {data?.work_history.length != 0 && (
                                <Box className="high-skills">
                                    <Box mb={1}>
                                        <Typography className="main-title" component="p" variant="p" gutterBottom>Highlighted Skills</Typography>

                                    </Box>
                                    {data?.work_history.map((item, index) => (
                                        <React.Fragment key={item._id || index}>
                                            <Box>
                                                <Typography className="sub-title" component="p" variant="p">{item.highest_designation || "no data"}</Typography>
                                            </Box>
                                            <Stack direction="row" flexWrap="wrap" mb={2}>
                                                {item.skills.map((skill, skillIndex) =>
                                                    <Chip label={skill} key={skillIndex} size="small" />)}
                                            </Stack>
                                        </React.Fragment>
                                    ))}
                                </Box>
                            )}
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </Box>);
}
export default Classic;