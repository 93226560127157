import {
  Box,
  Button,
  Chip,
  Container,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom';
import { jobDetails } from 'api/job';
import CircularProgress from '@mui/material/CircularProgress';
import { useAuth } from 'hooks/useAuthContext';

const dayjs = require('dayjs');

const JobDescription = () => {
  const [auth, setAuth] = React.useState(true);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(true);
  const [showMore, setShowMore] = useState(false);
  const [applicationStatus, setApplicationStatus] = useState('');
  const containsApply = location?.pathname?.includes('jobs');
  const { user } = useAuth();
  const userId = user?.id;

  const containsDetail = location?.pathname?.includes('job-application-description');
  const handleNavigation = (jobId) => {
    navigate(`/review-details/${jobId}`);
  }

  const { id } = useParams();
  const [jobs, setjobs] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await jobDetails({ id : id, userId : userId });
        if (response && response?.success && response?.data) {
          if(response.data && response?.data?.job){
            setjobs(response?.data?.job);
          }
          if(response.data && response?.data?.jobIdInCandidate) {
            setApplicationStatus(response?.data?.jobIdInCandidate?.status);
          }
        } else {
          console.error('Failed to fetch job info:', response.data.message);
        }
      } catch (error) {
        console.error('Error fetching job info:', error);
      }finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id]);
  
  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <CircularProgress />
      </Grid>
    );
  }

  const showMoreButton = jobs.description && jobs.description.length > 300;

  return (
    <Container>
      <Paper elevation={3} sx={{ padding: '1rem' }}>
        {!containsDetail && (
          <Typography variant="h4" component="div" sx={{ marginBottom: '1rem' }}>Jobs Details</Typography>
        )}
        <Box sx={{ marginBottom: '1rem' }}>
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Box>
              <Typography variant="h6" component="div">{jobs.company || 'N/A'}</Typography>
              <Typography variant="h6" component="div">{jobs.title || 'N/A'}</Typography>
            </Box>
            <Box>
              {containsApply && (
                <>
                  {applicationStatus=='saved' || !applicationStatus ? (
                    <Stack >
                      <Stack direction="row">
                      <Button variant="contained" sx={{ marginBottom: '0.5rem' }} onClick={() => handleNavigation(jobs._id)}>Apply</Button>
                      </Stack>
                      {applicationStatus=='saved' ? (
                        <Stack direction="row" >
                        <Chip label="saved" sx={{ backgroundColor: '#ffc107' }} />
                        </Stack>
                      ):(<></>)} 
                    </Stack>
                  ) : (<Stack><Stack direction="row">
                    <Button variant="contained" sx={{ marginBottom: '0.5rem' }} onClick={() => navigate(`/job-application-description/${jobs._id}`)}>View</Button>
                    </Stack>
                    <Stack direction="row">
                      <Chip label="applied" sx={{ margin: '0.5rem' }} style={{ color: 'black', background: '#8bc34a' }}></Chip>
                  </Stack>
                  </Stack>
                  )}
                </>
              )}
              <Typography variant="p" component="div" sx={{ fontSize: '0.8rem', textAlign: 'right' }}>Job Id: {jobs.jobid || 'N/A'}</Typography>
              <Typography variant="p" component="div" sx={{ fontSize: '0.8rem', textAlign: 'right' }}>Post Date: {jobs.updatedAt ? dayjs(jobs.updatedAt).format('DD/MM/YYYY') : 'N/A'}</Typography>
            </Box>
          </Stack>
        </Box>
        <Box>
          <Typography variant="h6" component="div" sx={{ marginBottom: '1rem' }}>
            Jobs Description
          </Typography>
          <div>
            <Typography>
              {showMore
                ? jobs.description
                : jobs.description && jobs.description.substring(0, 300) + (showMoreButton ? '...' : '')}
            </Typography>
            {showMoreButton && (
              <Link component="button" onClick={() => setShowMore(!showMore)}>
                {showMore ? 'show less' : 'show more'}
              </Link>
            )}
          </div>
        </Box>
        <Box sx={{ margin: '2rem 0' }}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography variant="p" component="div" sx={{ marginBottom: '1rem', fontWeight: 'bold' }}>
                Requirements
              </Typography>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 'auto' }} aria-label="simple table">
                  <TableBody>
                    <TableRow key={jobs.qualifications} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        Qualifications
                      </TableCell>
                      <TableCell align="right">
                        {jobs?.qualifications?.map((qualification, index) => (
                          <label key={index}>{qualification.values.join(qualification.option ? ' , ' : ' | ')}</label>
                        ))}
                      </TableCell>
                    </TableRow>
                    <TableRow key={jobs.experience} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        Yrs of Experience (Range)
                      </TableCell>
                      <TableCell align="right">{jobs.experience || 'N/A'}</TableCell>
                    </TableRow>
                    <TableRow key={jobs.work_mode} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        Job Format
                      </TableCell>
                      <TableCell align="right">{jobs.work_mode || 'N/A'}</TableCell>
                    </TableRow>
                    <TableRow key={jobs.location} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        Job Location
                      </TableCell>
                      <TableCell align="right">{jobs.location || 'N/A'}</TableCell>
                    </TableRow>
                    <TableRow key={jobs.start_date} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        Desired Start Date
                      </TableCell>
                      <TableCell align="right">
                        {jobs.start_date ? dayjs(jobs.start_date).format('DD/MM/YYYY') : 'N/A'}
                      </TableCell>
                    </TableRow>
                    <TableRow key={jobs.salary} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        Salary Range
                      </TableCell>
                      <TableCell align="right">{jobs.salary || 'N/A'}</TableCell>
                    </TableRow>
                    <TableRow key={jobs.notice_period} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                      <TableCell component="th" scope="row">
                        Notice Period (preference)
                      </TableCell>
                      <TableCell align="right">{jobs.notice_period || 'N/A'}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="p" component="div" sx={{ marginBottom: '0.5rem', fontWeight: 'bold' }}>
                Required Expertise/ Skills
              </Typography>
              <Typography variant="p" component="div" sx={{ marginBottom: '1rem', fontSize: '0.8rem' }}>
                {!containsApply ? (
                  <>Required skills are highlighted</>
                ) : (
                  <>
                    Highlighted skills show a match. Please <Link to="/experties-and-skills">update your skills</Link>,
                    if needed.
                  </>
                )}
              </Typography>
              <Box sx={{ marginTop: '2rem' }}>
                <Stack direction="row" sx={{ flexWrap: 'wrap' }}>
                  {jobs.key_skills &&
                    Array.isArray(jobs.key_skills) &&
                    jobs.key_skills.map((item, index) => (
                      <Chip sx={{ margin: '0.5rem' }} label={item} key={index} className="chip" />
                    ))}
                </Stack>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
};

export default JobDescription;