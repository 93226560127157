import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import dayjs from 'dayjs';
import { Download, Share, ContentCopy, TaskAltOutlined, ScheduleOutlined } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import Label from 'components/label/Label';
import CircularProgress from '@mui/material/CircularProgress';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { getAllReports, revokeInterpretationRequest } from 'api/reports';
import { copyToClipboard, downloadFromURI } from 'utils/Utils';
import { getEcgData } from 'api/reports';
import LoadingButton from '@mui/lab/LoadingButton';
import { Box, Chip, FormControl, InputLabel, Select, MenuItem, Alert, Card, Grid, Avatar, Table, Stack, Container, Typography, TableContainer, TablePagination, Tooltip, Button, TableRow, TableCell, TableHead, TableBody, TableFooter, Snackbar } from '@mui/material';
import useResponsive from '../../hooks/useResponsive';
import { refreshSocket } from '../../utils/socket'
import { useSnackbar } from '../../components/snackbar'
import { darken, lighten, styled } from '@mui/material/styles';

//------------------------------------Data Grid-------------------------//

import {
  DataGrid,
  GridToolbarExport,
  GridToolbarContainer,
  GridToolbarQuickFilter,
  GridToolbarFilterButton,
  GridToolbarColumnsButton,
} from '@mui/x-data-grid';
//import { DataGrid, GridToolbar } from '@mui/x-data-grid';


import Scrollbar from 'components/scrollbar/Scrollbar';
import { result } from 'lodash';
//-----------------------------------------------------------------------
const OverflowTypography = styled(Typography)((maxWidth) => ({
  maxWidth: `${maxWidth}px`, // percentage also works
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis"
}));

export default function AllReports() {
  const { enqueueSnackbar } = useSnackbar();
  const isSmall = useResponsive('', 'xs');
  const isMd = useResponsive('between', 'xs', 'md');
  const account = localStorage.getItem('TokenDetails') && JSON.parse(localStorage.getItem('TokenDetails'))?.authorization
    ? JSON.parse(localStorage.getItem('TokenDetails'))
    : {};
  const { searchStatus, searchStDate, searchEnDate, searchResult } = JSON.parse(window.sessionStorage.getItem("searchDetails") || '{}');
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([]);
  const [status, setstatus] = useState(searchStatus || 'requested');
  const [loading, setLoading] = useState(true);
  const [startDate, setStartDate] = useState(searchStDate ? dayjs(searchStDate) : dayjs());
  const [endDate, setEndDate] = useState(searchEnDate ? dayjs(searchEnDate) : dayjs());
  const [interpretationStatusFilter, setInterpretationStatusFilter] = useState('all');
  const [rowLoading, setRowLoading] = useState(null);
  const [socket, setSocket] = useState();

  async function getSocketDetails() {
    setSocket(await refreshSocket());
  }

  const navigate = useNavigate();



  const TABLE_HEAD = [
    { field: 'searchname', headerName: 'Name', width: status === 'completed' ? 180 : 200, align: 'left', renderCell: (params) => params.row.name, filterable: true },
    { field: 'searchreportType', headerName: 'Report Type', width: status === 'completed' ? 160 : 160, align: 'left', renderCell: (params) => params.row.reportType, filterable: true },
    { field: 'searchtakenAt', headerName: 'Taken At', width: status === 'completed' ? 160 : 200, align: 'left', filterable: true },
    { field: 'searchrequestedAt', headerName: status === 'completed' ? 'Completed At' : 'Requested At', width: status === 'completed' ? 180 : 200, align: 'left', renderCell: (params) => params.row.requestedAt, filterable: true },
    ...(status === 'completed' || status === '' ? [{ field: 'searchfindings', headerName: 'Findings', width: status === 'completed' ? 180 : 200, align: 'left', renderCell: (params) => params.row.findings_status }] : []),
    { field: 'searchecg_status', headerName: 'Status', width: status === 'completed' ? 160 : 200, align: 'left', renderCell: (params) => params.row.ecg_status },
    { field: 'action', headerName: 'Action', width: status === 'completed' ? 180 : 200, align: 'left', renderCell: (params) => params.row.action },
  ];
  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }

  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }


  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0)
        return order;
      return a[1] - b[1];
    });
    if (query) {
      return array.filter((_user) =>
        _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
    return stabilizedThis.map((el) => el[0]);
  }

  const formatDate = (date) => {
    return date ? dayjs(date).format('DD MMM, YYYY') : null;
  };

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setLoading(true);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setLoading(true);
  };

  useEffect(() => {
    if (socket) {
      socket.on('addReport', (result) => {
        if (result && result?.length) {
          const filteredData = result.filter(item => !data.some(dt => item?.id === dt?.id))
          setData(prevData => [...filteredData.map(item => { return { ...item, is_new: true } }), ...prevData])
        }
      })

      socket.on('InterpretationInProgress', (resultData) => {

        if (resultData) setData((prevItems) =>
          prevItems.map((item) =>
            item.id === resultData?.id ? { ...item, ...resultData, selected: true } : item
          )
        );

      })
    } else {
      getSocketDetails();
    }
  }, [socket])



  useEffect(() => {
    fetchData();
  }, [status, startDate, endDate]);

  const fetchData = async () => {
    try {
      const response = await getAllReports({
        startDate: formatDate(startDate),
        endDate: formatDate(endDate),
        status: status
      });

      setLoading(false);
      if (response && response?.success) {
        const filteredData = response?.data?.filter(row =>
          !row.interpretation_request_status ||
          row.interpretation_request_status === "requested" ||
          row.interpretation_request_status === "in progress" ||
          row.interpretation_request_status === "completed"
        );
        setData(filteredData || []);
        const searchDetails = {
          searchStatus: status,
          searchStDate: startDate,
          searchEnDate: endDate,
          searchResult: filteredData
        };
        window.sessionStorage.setItem("searchDetails", JSON.stringify(searchDetails));
      } else {
        if (response) {
          enqueueSnackbar(response.message, { variant: 'error' })
        }
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };
  //--------------------------------------------//
  let filteredUsers;
  if (status === "completed") {
    filteredUsers = applySortFilter(
      data.filter(row => {
        if (interpretationStatusFilter === 'all') {
          return true;
        } else {
          return row?.interpretation_report_status === interpretationStatusFilter;
        }
      }),
      getComparator(order, orderBy),
      filterName
    );
  }
  else {
    filteredUsers = applySortFilter(data, getComparator(order, orderBy), filterName);
  }


  //--------------------------------------------//



  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleAction = async (event, row, type) => {
    if (row?.interpretation_request_status === "requested") {
      socket.emit('onClickInterpret', row?.id, account?.authorization)
    }
    navigate(`${process.env.REACT_APP_HOMEPAGE}custominterpretation/${row?.id}`);
  };

  const downloadPDF = async (event, row) => {
    const id = row?.id;
    setRowLoading(id);
    try {
      const reportresponse = await getEcgData({ id });
      const reportdata = reportresponse?.data;
      console.log("pdf response data",reportdata)
      const fileName = `${reportdata?.user_data?.first_name}-${reportdata?.user_data?.last_name}-${dayjs(reportdata?.report_timestamp).format('DD-MM-YYYY-HH-mm')}${reportdata?.interpretation_data?.report_status ? `-${reportdata?.interpretation_data?.report_status}` : ''}`;
      downloadFromURI(`data:application/pdf;base64,${reportdata?.ReportPdf}`, fileName)
      setRowLoading(null);
      enqueueSnackbar("Pdf download suceessfully", { variant: 'success' })

    } catch (error) {
      enqueueSnackbar(error, { variant: 'error' })
    }
  };

  const handleStatus = (newStatus) => {
    if (status === newStatus) {
      setstatus('');
      setLoading(true);
    } else {
      setstatus(newStatus);
      setLoading(true);
    }
  };

  const onCopyClick = (text) => (e) => {
    copyToClipboard(text);
    enqueueSnackbar("Copied to clipboard.", { variant: 'success' })
  };



  const handleChange = (event) => {
    setLoading(true);
    setInterpretationStatusFilter(event.target.value);
    setLoading(false);
  };



  const handleRevokeRequest = (id) => async (e) => {
    setRowLoading(id);
    const response = await revokeInterpretationRequest({ ids: [id] })
    if (response?.success) {
      setRowLoading(null);
      setData([...data.filter(item => item?.id !== id)])
      enqueueSnackbar(response.message, { variant: 'success' })
    } else {
      enqueueSnackbar(response.message, { variant: 'error' })
      setRowLoading(null);
    }
  };

  const StyledDataGrid = styled(DataGrid)({
    '.super-app-theme--Open': {
      backgroundColor: '#FFEEBA',
    },
  });
  function NoRowsOverlay() {
    return (
      <Stack height="100%" alignItems="center" justifyContent="center">
        No rows in DataGrid
        <pre>(rows=&#123;[]&#125;)</pre>
      </Stack>
    );
  }

  const rows = filteredUsers.map((row, index) => {
    return {
      id: `${row?.id}`,
      searchname: `${row?.user_data?.first_name} ${row?.user_data?.last_name}, ${row?.user_data?.phone_number}`,
      searchreportType: `${row?.report_type?.replace(/REPORT|_/g, ' ')}`,
      searchfindings:row?.interpretation_report_status,
      searchecg_status: row?.interpretation_request_status,
      searchtakenAt: row?.report_timestamp ? dayjs(parseInt(row?.report_timestamp)).format('DD MMM, YYYY hh:mm A') : '',
      searchrequestedAt: `${row?.interpretation_completed_at? dayjs(parseInt(row?.interpretation_completed_at)).format('DD MMM hh:mm A')
          : '--' }, ${row?.interpretation_requested_at? dayjs(parseInt(row?.interpretation_requested_at)).format('DD MMM hh:mm A'): '--'
      }`,
      name: <TableCell component="th" scope="row" padding="none">
      <Stack direction="row" alignItems="center" spacing={2} >
        <Tooltip title={row?.user_data?.phone_number}>
          <OverflowTypography maxWidth={150} variant="subtitle2" noWrap>
            {row?.user_data?.first_name} {row?.user_data?.last_name}
          </OverflowTypography>
        </Tooltip>
        <ContentCopy fontSize='small' sx={{ cursor: "pointer" }} onClick={onCopyClick(row?.user_data?.phone_number)} />
      </Stack>
    </TableCell>,
      reportType: <OverflowTypography maxWidth={150} variant="subtitle2" noWrap>
        {row?.report_type?.replace(/REPORT|_/g, ' ')}
      </OverflowTypography>,
      takenAt: row?.report_timestamp ? dayjs(parseInt(row?.report_timestamp)).format('DD MMM, YYYY hh:mm A') : '',
      requestedAt: <Tooltip title={(row?.interpretation_completed_at ? dayjs(parseInt(row?.interpretation_completed_at)).format('DD MMM, YYYY hh:mm A') : (row?.interpretation_requested_at ? dayjs(parseInt(row?.interpretation_requested_at)).format('DD MMM, YYYY hh:mm A') : '--'))}>
        <span>{(row?.interpretation_completed_at ? dayjs(parseInt(row?.interpretation_completed_at)).format('DD MMM hh:mm A') : (row?.interpretation_requested_at ? dayjs(parseInt(row?.interpretation_requested_at)).format('DD MMM hh:mm A') : '--'))}</span>
      </Tooltip>,
      findings_status: status === "completed" || !status ? (
        <Typography
          color={
            row?.interpretation_report_status === 'normal'
              ? 'success.main'
              : row?.interpretation_report_status === 'abnormal'
                ? 'warning.main'
                : row?.interpretation_report_status === 'retake'
                  ? 'error.main'
                  : 'default'
          }
        >
          {row?.interpretation_report_status === 'normal'
            ? 'Normal'
            : row?.interpretation_report_status === 'abnormal'
              ? 'Abnormal'
              : row?.interpretation_report_status === 'retake'
                ? 'Retake'
                : ' '}
        </Typography>
      ) : null,
      ecg_status: row?.selected || row?.interpretation_request_status === "in progress" ?
        <Tooltip color='warning' title="In progress"><Label color="warning"><ScheduleOutlined sx={{ mr: 1 }} fontSize='small' color='warning' severity="warning" /> <OverflowTypography maxWidth={100} variant="subtitle2" noWrap>{row?.interpreter_details?.name ? `By: ${row?.interpreter_details?.name}` : 'In progress'}</OverflowTypography></Label></Tooltip> :
        (row?.interpretation_request_status === "completed" ?
          <Tooltip color='warning' title="Interpreted"><Label color="success"><TaskAltOutlined sx={{ mr: 1 }} fontSize='small' color="success" /> <OverflowTypography maxWidth={100} variant="subtitle2" noWrap>{`By: ${row?.interpreter_details?.name}`}</OverflowTypography></Label></Tooltip> :
          <Tooltip color='warning' title="New request"><Label color='primary'>
            {row?.interpretation_request_status}
          </Label></Tooltip>)
      ,
      action: row?.interpretation_request_status === "in progress" ? (
        row?.interpreter_phone_number === account?.phoneNumber ? (
          <>
            <Button
              loading={(row?.id === rowLoading).toString()}
              aria-describedby="adadsa"
              variant="contained"
              color='warning'
              onClick={handleRevokeRequest(row?.id)}
            >
              Revoke
            </Button>
            <Button
              sx={{ ml: 2 }}
              aria-describedby="adadsa"
              variant="contained"
              onClick={(event) => handleAction(event, row, 'delete')}
            >
              Edit
            </Button>
          </>
        ) : "--"
      ) : row?.interpretation_request_status === "completed" ? (
        <>
          <Tooltip title="Download" arrow>
            <LoadingButton
              variant="contained"
              onClick={(event) => downloadPDF(event, row, 'download')}
            >
              <Download />
            </LoadingButton>
          </Tooltip>
          <Button
            sx={{ ml: 2 }}
            aria-describedby="adadsa"
            variant="contained"
            onClick={(event) => handleAction(event, row, 'delete')}
          >
            Edit
          </Button>
        </>
      ) : (
        <Button
          aria-describedby="adadsa"
          variant="contained"
          onClick={(event) => handleAction(event, row, 'delete')}
          disabled={row?.selected}
        >
          {row?.interpretation_request_status === "completed" ? "Edit" : "Interpret"}
        </Button>
      ),
      report_status: row?.interpretation_request_status,
      phone_number: row?.interpreter_phone_number,
      user_data: row?.user_data,
      interpreter_details: row?.interpreter_details,
      selected: row?.selected,
    };
  });
  
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;
  const isNotFound = !filteredUsers.length && !!filterName;

  return (
    <>
      <Box >
        <Grid sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginY: '10px',
          marginX: '20px',
          flexDirection: (isSmall ? 'column-reverse' : 'row'),
          gap: (isSmall ? '1rem' : 0)
        }}>
          <Grid item xs={12} md={6}>
            <Stack direction="row" spacing={1}>
              <Chip
                label="Requested"
                onClick={() => handleStatus('requested')}
                color="primary"
                variant={status === 'requested' ? "filled" : "outlined"}
              />
              <Chip
                label="Completed"
                onClick={() => handleStatus('completed')}
                color="success"
                variant={status === 'completed' ? "filled" : "outlined"}
              />
              <Chip
                label="In Progress"
                onClick={() => handleStatus('in progress')}
                color="warning"
                variant={status === 'in progress' ? "filled" : "outlined"}
              />
            </Stack>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-end",
              }} >
              <Stack direction="row" spacing={1}>
                {status === "completed" && (<FormControl>
                  <InputLabel id="report_findings">Findings</InputLabel>
                  <Select
                    labelId="report-findings-label"
                    id="report-status-select"
                    value={interpretationStatusFilter}
                    label="Findings"
                    onChange={handleChange}
                    sx={{ width: (isSmall ? '80px' : (isMd ? '100px' : '120px')) }}
                  >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value="normal">Normal</MenuItem>
                    <MenuItem value="abnormal">Abnormal</MenuItem>
                    <MenuItem value="retake">Retake</MenuItem>
                  </Select>
                </FormControl>)}
                <DatePicker
                  sx={{ width: (isSmall ? '120px' : (isMd ? '120px' : '150px')) }}
                  label="Start Date"
                  value={startDate}
                  format='DD/MM/YYYY'
                  maxDate={dayjs(endDate)}
                  onChange={(date) => handleStartDateChange(date)}
                />
                <DatePicker
                  sx={{ width: (isSmall ? '120px' : (isMd ? '120px' : '150px')) }}
                  label="End Date"
                  value={endDate}
                  format='DD/MM/YYYY'
                  minDate={dayjs(startDate)}
                  maxDate={dayjs(startDate).add(10, 'day')}
                  onChange={(date) => handleEndDateChange(date)}
                />
              </Stack>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'end', marginTop: '30px' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={data ? { height: 600, width: '100%' } : { height: 100, ...width}}>
          <StyledDataGrid
            sx={{ m: 3, p: 1 }}
            rows={rows}
            filterMode="client"
            columns={TABLE_HEAD}
            disableVirtualization={true}
            getRowId={(row) => row?.id}
            getRowClassName={(params) => {
              const isSelected = params.row.selected;
              return isSelected ? 'super-app-theme--Open' : ' ';
            }}
            localeText={{ noRowsLabel: 'No interpretation request found.' }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 15,
                },
              },

            }}
            pageSizeOptions={[5, 10, 25]}
            slots={{
              toolbar: () => (
                <>
                  <GridToolbarContainer>
                    <GridToolbarQuickFilter />
                    <Stack
                      spacing={3}
                      flexGrow={1}
                      direction="row"
                      alignItems="center"
                      justifyContent="flex-end"
                    >
                      <GridToolbarColumnsButton />
                      <GridToolbarFilterButton />
                      <GridToolbarExport />
                    </Stack>
                  </GridToolbarContainer>
                </>
              ),
            }}
          />
        </Box>
      )}
    </>
  )
}