import React from 'react'
import {
    Box,
    Dialog,
    Card,
    CardContent,
    Link,
    Chip,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SvgIcon,
    Stack,
    TextField,
    Typography,
    Autocomplete,
    Button,
    Container,
    IconButton,
    FormControlLabel,
    Switch,
    styled,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import { useNavigate } from 'react-router-dom';
import TypeAhead from 'components/TypeAhead';
import { useAuth } from 'hooks/useAuthContext';
import { useSnackbar } from 'components/snackbar';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, register, watch, Controller } from 'react-hook-form';
import FormProvider from 'hooks/form/form-provider';
import { CandidateWorkHistorySchema, CandidateWorkHistoryForGapSchema } from 'models/CandidateProfileSchema';
import { updateCandidateWorkExperience } from 'api/users';
import dayjs from 'dayjs';
import DynamicTextField from 'components/DynamicTextField'
import { endpoints } from 'utils/axios';
import { useState, useEffect, useRef } from 'react';
import AddSkills from '../basic-profile/AddSkills';

const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    top: 5,
    display: 'flex',
    '&:active': {
        '& .MuiSwitch-thumb': {
            width: 15,
        },
        '& .MuiSwitch-switchBase.Mui-checked': {
            transform: 'translateX(9px)',
        },
    },
    '& .MuiSwitch-switchBase': {
        padding: 2,
        '&.Mui-checked': {
            transform: 'translateX(12px)',
            color: '#fff',
            '& + .MuiSwitch-track': {
                opacity: 1,
                backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#1890ff',
            },
        },
    },
    '& .MuiSwitch-thumb': {
        boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
        width: 12,
        height: 12,
        borderRadius: 6,
        transition: theme.transitions.create(['width'], {
            duration: 200,
        }),
    },
    '& .MuiSwitch-track': {
        borderRadius: 16 / 2,
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? 'rgba(255,255,255,.35)' : 'rgba(0,0,0,.25)',
        boxSizing: 'border-box',
    },
}));

const CreateUpdateWorkExperience = ({ workExperienceData, currentCompany, onDataChange, closeDialog }) => {
    const { enqueueSnackbar } = useSnackbar();
    const isEditMode = !!workExperienceData._id;
    const navigate = useNavigate();
    const { user, updateUnsaved } = useAuth();
    const [error, setError] = useState(false);
    const [showFlag, setShowFlag] = useState(false);
    const [educationFrom, setEducationFrom] = useState(dayjs(workExperienceData?.education_from) || null);
    const [selectedChips, setSelectedChips] = useState(workExperienceData?.skills || []);
    const [keyAccomplishments, setKeyAccomplishments] = useState(
        workExperienceData?.key_accomplishments?.length > 0 ? [...workExperienceData.key_accomplishments, ''] : ['']
    );
    const [explaination, setExplaination] = useState(workExperienceData?.gap_explaination ? true : false);
    const [from, setFrom] = useState(dayjs(workExperienceData?.work_history_from) || null);
    const [present, setPresent] = useState(false);
    const [open, setOpen] = React.useState(false);
    const [addMoreSkills, setAddMoreSkills] = useState([
        'Analytical Skills',
        'Finance strategy',
        'Financial Acumen',
        'Adaptability and Resilience',
        'Leadership',
        'Strategic Thinking',
        'Strategic',
        'Thinking',
    ]);

    const [chipData, setChipData] = useState(workExperienceData?.skills || [
        "Analytical Skills",
        "Finance strategy",
        "Financial Acumen",
        "Adaptability and Resilience",
        "Leadership",
        "Strategic Thinking",
        "Finance"
    ]);

    const defaultValues = {
        work_history_from: null,
        work_history_to: null,
        work_history_location: '',
        organization_name: '',
        present: false,
        last_job_function: '',
        highest_designation: '',
        job_type: '',
        team_size_managed: '',
        work_oriented_skills: [],
        gap_explaination: '',
        key_accomplishments: [],
        ...workExperienceData
    }
    const methods = useForm({
        defaultValues,
        mode: 'onChange',
        resolver: yupResolver(explaination ? CandidateWorkHistoryForGapSchema() : CandidateWorkHistorySchema(explaination, present)),
    });

    const {
        register,
        handleSubmit,
        control,
        getValues,
        setValue,
        reset,
        formState: { errors, isDirty, isValid, dirtyFields, isSubmitting },
        watch,
    } = methods;

    const updateWorkToDate = (date) => {
        setValue('work_history_to', date.$d);
    };
    const updateWorkFromDate = async (date) => {
        setFrom(date);
        setValue('work_history_from', date.$d);
    };

    const handleAccomplishmentChange = (index, value) => {
        const newAccomplishments = [...keyAccomplishments];
        newAccomplishments[index] = value;
        setKeyAccomplishments(newAccomplishments);

        if (index === keyAccomplishments.length - 1 && value.trim() !== '') {
            setKeyAccomplishments([...newAccomplishments, '']);
        } else if (value.trim() === '') {
            newAccomplishments.splice(index, 1);
        }
    };

    const handlePresentDateChange = (event) => {
        const isChecked = event.target.checked;
        if (currentCompany && (isEdit ? !workExperienceData.present : true)) {
            setError(true);
        } else {
            setPresent(isChecked);
            setValue('present', isChecked);
            if (isChecked) {
                setValue('work_history_to', null);
            }
        }
    };


    const onSubmit = handleSubmit(async (formData) => {
        if (isEditMode) {
            formData.workId = workExperienceData._id;
        }
        if (selectedChips && selectedChips.length >= 3) {
            setShowFlag(false);
            formData.skills = selectedChips;
        } else {
            if (!explaination) {
                setShowFlag(true);
                return;
            }
        }
        formData.key_accomplishments = keyAccomplishments.filter((accomplishment) => accomplishment.trim() !== '');
        const response = await updateCandidateWorkExperience(formData);
        if (response?.success === true && response.data) {
            onDataChange();
        } else if (response?.success === false) {
            enqueueSnackbar(response.message, { variant: 'error' });
        }
    });

    const handleClick = (label) => {
        if (selectedChips.includes(label)) {
            setSelectedChips(selectedChips.filter((chip) => chip !== label));
        } else {
            setSelectedChips([...selectedChips, label]);
        }
    };

    const handleOpenOtpDialog = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAddMoreSkills = (moreSkills) => {
        setSelectedChips(moreSkills);
    }

    const handleCancel = () => {
        reset();
    };
    return (
        <Box sx={{ width: 450 }} className="job-app-drawer-container" role="presentation" >
            <FormProvider methods={methods} onSubmit={onSubmit}>
                <Box className="drawer-header">
                    <Stack direction="row" alignItems={'center'} spacing={1}>
                        <IconButton aria-label="delete" onClick={closeDialog}>
                            <SvgIcon>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M6.22796 5.04477C5.90134 4.71815 5.37178 4.71815 5.04516 5.04477C4.71854 5.37139 4.71854 5.90095 5.04516 6.22757L10.8174 11.9998L5.04516 17.772C4.71854 18.0987 4.71854 18.6282 5.04516 18.9548C5.37178 19.2815 5.90134 19.2815 6.22796 18.9548L12.0002 13.1826L17.7724 18.9548C18.0991 19.2815 18.6286 19.2815 18.9552 18.9548C19.2819 18.6282 19.2819 18.0987 18.9552 17.772L13.183 11.9998L18.9552 6.22757C19.2819 5.90095 19.2819 5.37139 18.9552 5.04477C18.6286 4.71815 18.0991 4.71815 17.7724 5.04477L12.0002 10.817L6.22796 5.04477Z" fill="white" />
                                </svg>
                            </SvgIcon>
                        </IconButton>

                        <Box>
                            <Typography className="title" sx={{ marginBottom: "0!important" }}>
                                {watch('highest_designation') ? `${watch('highest_designation')} at ` : ''}
                                {watch('organization_name') ? `${watch('organization_name')}` : 'Work Experience'}
                            </Typography>
                        </Box>
                    </Stack>
                </Box>
                <Box className="drawer-content">
                    {workExperienceData?.organization_name == "Work Gap" ? (
                        <Stack spacing={2}>
                            <Typography className="info-wrapper" variant="body2" gutterBottom mb={3}>
                                {workExperienceData.work_history_from ? `${dayjs(workExperienceData.work_history_from).format('MMM YYYY')} - ${workExperienceData.work_history_to && dayjs(workExperienceData.work_history_to).format('MMM YYYY')}` : ''}
                            </Typography>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name="gap_title"
                                    defaultValue={defaultValues?.gap_title || ''}
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            size='small'
                                            label="Explain gap in employment"
                                            error={!!errors.gap_title}
                                            helperText={errors.gap_title ? errors.gap_title.message : ''}
                                        />
                                    )}
                                />
                            </FormControl>
                            {/* </Grid>
                                <Grid item xs={12}> */}
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name="gap_explaination"
                                    defaultValue={defaultValues?.gap_explaination || ''}
                                    render={({ field }) => (
                                        <TextField
                                            multiline
                                            rows={2}
                                            {...field}
                                            size='small'
                                            label="Explain gap in employment"
                                            error={!!errors.gap_explaination}
                                            helperText={errors.gap_explaination ? errors.gap_explaination.message : ''}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Stack>
                    ) : <>
                        <Stack spacing={2}>

                            <FormControl fullWidth size="small">
                                <Controller
                                    name="organization_name"
                                    control={control}
                                    render={({ field }) => (
                                        <TypeAhead
                                            {...field}
                                            api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.company}`}
                                            multiple={false}
                                            fullWidth
                                            size="small"
                                            label="Company"
                                            freeSolo={true}
                                            placeholder="Enter your Company Name"
                                            onChange={(e, value) => {
                                                if (value){                              
                                                    field.onChange(value.name);
                                                } 
                                            }}
                                            onInputChange={(e, value) => {
                                                setValue("organization_name", value, { shouldDirty: true });
                                            }}
                                            error={!!errors.organization_name}
                                            helperText={errors.organization_name?.message}
                                        />
                                    )}
                                />
                            </FormControl>
                            <FormControl fullWidth size="small">
                            <Controller
                      control={control}
                      name="highest_designation"
                      defaultValue={defaultValues?.highest_designation || ''}
                      render={({ field }) => (
                        <TypeAhead
                          {...field}
                          {...register('highest_designation')}
                          api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.roles}`}
                          label="Designation"
                          id="highest_designation"
                          name="highest_designation"
                          size="small"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              error={!!errors.highest_designation}
                              helperText={errors.highest_designation ? errors.highest_designation.message : ''}
                            />
                          )}
                          onChange={(e, value) => {                            
                          if (value) {
                            field.onChange(value?.name);
                            setChipData(value?.skills);
                            setAddMoreSkills(value?.skills);
                          }
                          }}
                        />
                      )}
                    />
                            </FormControl>

                            <FormControl fullWidth size="small">
                            <Controller
                      control={control}
                      name="last_job_function"
                      defaultValue={defaultValues?.last_job_function || ''}
                      render={({ field }) => (
                        <TypeAhead
                          {...field}
                          {...register('last_job_function')}
                          api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.roles}`}
                          label="Job Function"
                          id="last_job_function"
                          name="last_job_function"
                          size="small"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              error={!!errors.last_job_function}
                              helperText={errors.last_job_function ? errors.last_job_function.message : ''}
                            />
                          )}
                          onChange={(e, value) => {                            
                          if (value) {
                            field.onChange(value?.name);
                          }
                          }}
                        />
                      )}
                    />
                            </FormControl>
                            <FormControl fullWidth size="small">
                                <InputLabel id="job_type">Job Type</InputLabel>
                                <Controller
                                    control={control}
                                    name="job_type"
                                    defaultValue={defaultValues?.job_type || ''}
                                    render={({ field }) => (
                                        <Select
                                            {...field}
                                            {...register('job_type')}
                                            labelId="job_type"
                                            error={!!errors.job_type}
                                            helperText={errors.job_type?.message}
                                            label="Job Type"
                                            variant="outlined"
                                            fullWidth
                                        >
                                            <MenuItem value="Full Time">Full Time</MenuItem>
                                            <MenuItem value="Part-Time">Part Time</MenuItem>
                                            <MenuItem value="Self-Employed">Self-Employed</MenuItem>
                                            <MenuItem value="Trainee">Trainee</MenuItem>
                                            <MenuItem value="Freelancer">Freelancer</MenuItem>
                                            <MenuItem value="Intership">Intership</MenuItem>
                                        </Select>
                                    )}
                                />
                                <Typography variant="caption" color="error">
                                    {errors.job_type?.message}
                                </Typography>
                            </FormControl>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name="work_history_location"
                                    defaultValue={defaultValues?.work_history_location || ''}
                                    render={({ field }) => (
                                        <TypeAhead
                                            {...field}
                                            {...register('work_history_location')}
                                            api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.city}`}
                                            label="Location"
                                            id="work_history_location"
                                            name="work_history_location"
                                            size="small"
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    error={!!errors.work_history_location}
                                                    helperText={errors.work_history_location ? errors.work_history_location.message : ''}
                                                />
                                            )}
                                            onChange={(e, value) => field.onChange(value)}
                                        />
                                    )}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <TextField
                                    id="team_size_managed"
                                    name="team_size_managed"
                                    label="People Managed (Direct Reportees)"
                                    type="number"
                                    variant="outlined"
                                    size="small"
                                    placeholder="People Managed"
                                    fullWidth
                                    {...register('team_size_managed')}
                                    error={!!errors.team_size_managed}
                                    helperText={errors.team_size_managed?.message}
                                />
                            </FormControl>
                            <Box mb={2}>
                                <Card className="disability-card" variant="outlined">
                                    <CardContent>
                                        <FormControlLabel
                                            control={
                                                <Stack direction="row" spacing={1} alignItems="start">
                                                    <AntSwitch
                                                        checked={present}
                                                        onChange={handlePresentDateChange}
                                                        inputProps={{ 'aria-label': 'ant design' }}
                                                    />

                                                    <Box>
                                                        <Typography className="heading" variant="p" gutterBottom>
                                                            You are currently working with {watch('organization_name')} as{' '}
                                                            {watch('highest_designation')}
                                                        </Typography>
                                                        <Typography className="info" variant="body2" gutterBottom>
                                                            Please unselect that role if you wish to set this as your current role & company
                                                        </Typography>
                                                    </Box>
                                                </Stack>
                                            }
                                        />
                                    </CardContent>
                                </Card>
                                {error && (
                                    <Typography className="error-text" component="p" style={{ color: 'red' }}>
                                        You are currently working with {currentCompany}.
                                    </Typography>
                                )}
                            </Box>
                            {/* <Grid item xs={12} md={6}> */}
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name="work_history_from"
                                    defaultValue={defaultValues?.work_history_from}
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            id="work_history_from"
                                            format="MM/YYYY"
                                            views={['month', 'year']}
                                            disableFuture
                                            label="Start Date"
                                            sx={{ width: '100%' }}
                                            value={dayjs(field?.value)}
                                            onChange={(date) => updateWorkFromDate(date)}
                                            slotProps={{
                                                textField: {
                                                    size: 'small',
                                                    error: !!errors?.work_history_from,
                                                    helperText: errors?.work_history_from?.message,
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>
                            <FormControl fullWidth>
                                <Controller
                                    control={control}
                                    name="work_history_to"
                                    defaultValue={defaultValues?.work_history_to}
                                    render={({ field }) => (
                                        <DatePicker
                                            {...field}
                                            id="work_history_to"
                                            format="MM/YYYY"
                                            views={['month', 'year']}
                                            disableFuture
                                            sx={{ width: '100%' }}
                                            disabled={present}
                                            minDate={from}
                                            label="End Date"
                                            value={dayjs(field?.value)}
                                            onChange={(date) => updateWorkToDate(date)}
                                            slotProps={{
                                                textField: {
                                                    size: 'small',
                                                    error: !!errors?.work_history_to,
                                                    helperText: errors?.work_history_to?.message,
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </FormControl>
                        </Stack>
                        <Stack spacing={2} mt={2}>
                            <Box className="besic-det-skills-wrapper">
                                <Box mb={2}>
                                    <Typography className="title" variant="body2" gutterBottom>
                                        Skills
                                    </Typography>
                                    <Typography className="info-txt" variant="body2" gutterBottom>
                                        3 to 8 skills can be selected
                                    </Typography>
                                </Box>
                                <Stack direction="row" flexWrap="wrap">
                                    {chipData.map((label, index) => (
                                        <Chip
                                            key={index}
                                            label={label}
                                            size="small"
                                            clickable
                                            variant={selectedChips.includes(label) ? 'filled' : 'outlined'}
                                            style={{
                                                color: selectedChips.includes(label) ? 'white' : 'black',
                                                backgroundColor: selectedChips.includes(label) ? '#ade9ab' : 'transparent',
                                                border: selectedChips.includes(label) ? 'none' : '1px solid rgba(0, 0, 0, 0.23)',
                                            }}
                                            onClick={() => handleClick(label)}
                                        />
                                    ))}
                                </Stack>
                                {showFlag && (
                                    <Typography style={{ color: 'red', fontSize: 'small' }} mb={2}>
                                        Please select atleast 3 skills.
                                    </Typography>
                                )}
                                <Link className="add-more-skills-link" underline="none" size="small" onClick={handleOpenOtpDialog}>
                                    Add More Skills
                                </Link>
                            </Box>
                            <Box className="accomplishments" mt={3} mb={2}>
                                <Typography className="title" variant="body2" gutterBottom>
                                    Accomplishments
                                </Typography>
                                <Typography className="info-txt" variant="body2" gutterBottom>
                                    Well structured accomplishments help recruiters get visibility on your strengths
                                </Typography>
                                {keyAccomplishments.map((accomplishment, index) => (
                                    <Box key={index} mt={2}>
                                        <FormControl fullWidth>
                                            <DynamicTextField
                                                label="Key Accomplishments"
                                                rowsMax={10}
                                                value={accomplishment}
                                                onChange={(value) => handleAccomplishmentChange(index, value)}
                                            />
                                        </FormControl>
                                    </Box>
                                ))}
                            </Box>
                        </Stack>

                    </>}
                </Box>
                <Box className="drawer-footer">
                    <Stack direction="row" spacing={2}>
                        <Button fullWidth size="large" className="cancel-btn" onClick={closeDialog}>Cancel</Button>
                        <Button fullWidth className="update-btn" variant="contained" disabled={isSubmitting} type="submit" size="large" >
                            {isEditMode ? 'Update' : 'Done'}
                        </Button>
                    </Stack>
                </Box>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    className="otp-dialog-wrapper"
                >
                    <AddSkills onClose={handleClose} skills={addMoreSkills} selectedskills={selectedChips} onAddSkills={handleAddMoreSkills} />
                </Dialog>
            </FormProvider >
        </Box >
    )
}

export default CreateUpdateWorkExperience;
