import {
  Button,
  Box,
  Autocomplete,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  Icon,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Stack,
  Select,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState, useEffect } from 'react';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { useForm, register, watch, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from 'hooks/form/form-provider';
import { useSnackbar } from 'components/snackbar';
import { CandidateJobPreferencesRule } from 'models/CandidateProfileSchema';
import TypeAhead from 'components/TypeAhead';
import { endpoints } from 'utils/axios';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
const dayjs = require('dayjs');
import { addJobPreferance, getJobPreferanceDetails } from 'api/users';
import { useNavigate } from 'react-router-dom';
import { SplashScreen } from 'components/loading-screen';
import { formatCurrency } from 'utils/Utils';

const JobPreferences = () => {
  const { enqueueSnackbar } = useSnackbar();
  const backIcon = `${process.env.REACT_APP_HOMEPAGE}assets/arrow_back.svg`;
  const navigate = useNavigate();
  const [values, setValues] = useState(0);
  const [loading, setLoading] = useState(true);
  const [isBuyout, setIsBuyout] = useState(false);
  const [openWorkFormat, setOpenWorkFormat] = useState(false);
  const [openJobType, setOpenJobType] = useState(false);
  const [openJobShifts, setOpenJobShifts] = useState(false);

  const workModelFormat = ['Work From Home', 'Office Environment', 'Hybrid'];
  const jobType = ['Full Time', 'Part Time', 'Self-Employed', 'Trainee', 'Freelancer', 'Internship'];
  const preferredJobShifts = ['First-Shift', 'Second-Shift', 'Third-Shift', 'Split-Shift'];
  const preferredCompanyType = [
    'Private Companies',
    'Public Companies',
    'Associate Companies',
    'One Person Companies (OPC)',
  ];

  const defaultValues = {
    notice_period: '',
    buyout_period: '',
    is_negotiable: false,
    total_ctc: null,
    compensation: '',
    fixed_ctc: null,
    variable_ctc: null,
    other_ctc: '',
    expected_ctc: '',
    work_format: [],
    job_type: [],
    preferred_job_shifts: [],
    prefered_location: [],
  };

  const methods = useForm({
    defaultValues,
    mode: 'onSubmit',
    resolver: yupResolver(CandidateJobPreferencesRule(values, isBuyout)),
  });

  const {
    control,
    register,
    handleSubmit,
    setValue,
    watch,
    getValues,
    formState: { touchedFields, errors, isDirty, isValid, dirtyFields, isSubmitting },
  } = methods;

  // const compensationValue = watch('compensation');
  const fixed = watch('fixed_ctc');
  const variable = watch('variable_ctc');
  // const other_ctc = watch('other_ctc');

  useEffect(() => {
    const fetchjobs = async () => {
      try {
        const response = await getJobPreferanceDetails();
        if (response?.success && response.data) {
          Object.keys(defaultValues).forEach((field) => {
            setValue(field, response.data[field]);
          });
          setIsBuyout(response?.data?.is_negotiable);
        }
      } catch (error) {
        console.error('Error fetching jobs:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchjobs();
  }, []);

  useEffect(() => {
    // if (compensationValue) {
    //   setValues(Number(compensationValue));
      let total = 0;
      // if (compensationValue === '1') {
      //   total = parseInt(fixed_ctc || 0);
      //   setValue('variable_ctc', 0);
      //   setValue('other_ctc', '');
      // } else if (compensationValue === '2') {
      //   total = parseInt(fixed_ctc || 0) + parseInt(variable_ctc || 0);
        // setValue('other_ctc', '');
      // } else if (compensationValue === '3') {
        total = parseInt(fixed || 0) + parseInt(variable || 0);
      // }
      setValue('total_ctc', total);
    // }
  }, [ fixed, variable, setValue]);

  const onSubmit = handleSubmit(async (formData) => {
    const response = await addJobPreferance(formData);
    if (response?.success === true && response.data) {
      navigate('/job-application');
      enqueueSnackbar(response.message, { variant: 'success' });
    } else if (response?.success === false) {
      enqueueSnackbar(response.message, { variant: 'error' });
    }
  });

  const linkedinIcon = (
    <Icon className="extLoginIcon">
      <img alt="edit" width="24px" height="24px" className="back-button" src={backIcon} />
    </Icon>
  );

  if (loading) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <SplashScreen />
      </Grid>
    );
  }

  return (
    <Box className="page-content">
      <FormProvider methods={methods} onSubmit={onSubmit}>
        <Container maxWidth="xl">
          <Box className="job-preferences-page">
            <Box className="job-preferences-content-wrapper">
              <Box className="current-job-det" mb={1}>
                <Box mb={3}>
                  <Typography className="step-heading" variant="h6">
                    Current Job Details
                  </Typography>
                  <Typography className="step-info" variant="body2">
                    These details would help recruiters understand & connect with you
                  </Typography>
                </Box>

                <Grid container spacing={2}>
                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth size="small" error={!!errors.notice_period}>
                      <InputLabel id="notice_period">Notice Period</InputLabel>
                      <Controller
                        control={control}
                        name="notice_period"
                        defaultValue={defaultValues?.buyout_period || ''}
                        render={({ field }) => (
                          <Select
                            {...field}
                            {...register('notice_period')}
                            labelId="notice_period"
                            label="Notice Period"
                            variant="outlined"
                            fullWidth      
                          >
                            <MenuItem value="15">15 Days</MenuItem>
                            <MenuItem value="30">30 Days</MenuItem>
                            <MenuItem value="45">45 Days</MenuItem>
                            <MenuItem value="60">60 Days</MenuItem>
                          </Select>
                        )}
                      />    
                      {errors.notice_period && <FormHelperText error>{errors.notice_period?.message}</FormHelperText>}                
                    </FormControl>
                    <FormControl error={!!errors.is_negotiable} className="neogtiable-chk" size="small">
                      <Controller
                        name="is_negotiable"
                        control={control}
                        render={({ field }) => (
                          <FormControlLabel
                            control={
                              <Checkbox
                                {...field}
                                {...register('is_negotiable')}
                                id="is_negotiable"
                                name="is_negotiable"
                                checked={isBuyout}
                                onChange={(event) => {
                                  if (!event.target.checked) {
                                    setValue('buyout_period', '');
                                  }
                                  field.onChange(event.target.checked);
                                  setIsBuyout(event.target.checked);
                                }}
                              />
                            }
                            label="Notice period is negotiable"
                            id="is_negotiable"
                          />
                        )}
                      />
                      {errors.is_negotiable && (
                        <Typography variant="caption" color="error">
                          {errors.is_negotiable.message}
                        </Typography>
                      )}
                    </FormControl>
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <FormControl fullWidth>
                      <Controller
                        control={control}
                        name="buyout_period"
                        defaultValue={defaultValues?.buyout_period || ''}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            id="buyout_period"
                            label="If notice period is negotiable, please state how much."
                            name="buyout_period"
                            disabled={!isBuyout}
                            variant="outlined"
                            size="small"
                            error={isBuyout && !!errors.buyout_period}
                            helperText={isBuyout && errors.buyout_period?.message}
                          />
                        )}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Box>

              <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginBottom: '1rem' }} />

              <Box className="annual-compensation" mb={3}>
                <Box mb={3}>
                  <Typography className="step-heading" variant="h6">
                    Annual Compensation
                  </Typography>
                  <Typography className="step-info" variant="body2">
                    Please tell us your annual compensation with current company
                  </Typography>
                </Box>
                <Box>
                  <Grid container spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Grid container spacing={2} mb={2}>
                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth error={!!errors?.fixed_ctc} >
                            <Controller
                              control={control}
                              name="fixed_ctc"
                              defaultValue={defaultValues?.fixed_ctc || ''}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  id="fixed_ctc"
                                  name="fixed_ctc"
                                  label="Fixed Component"
                                  variant="outlined"
                                  size="small"
                                  value={field.value ? formatCurrency(field.value) : ''}
                                  error={!!errors.fixed_ctc}
                                  onChange={(e) => {
                                    const rawValue = parseInt(e.target.value.replace(/,/g, '')) || null;
                                    field.onChange(rawValue);
                                  }}                                 
                                />
                              )}
                            /> 
                            {errors?.fixed_ctc && <FormHelperText error>{errors?.fixed_ctc?.message}</FormHelperText>}                
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormControl fullWidth>
                            <Controller
                              control={control}
                              name="variable_ctc"
                              defaultValue={defaultValues?.variable_ctc || ''}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  id="variable_ctc"
                                  name="variable_ctc"
                                  label="Variable Component"
                                  variant="outlined"
                                  size="small"
                                  value={field.value ? formatCurrency(field.value) : ''}
                                  error={!!errors.variable_ctc}
                                  helperText={errors.variable_ctc?.message}
                                  onChange={(e) => {
                                    const rawValue = parseInt(e.target.value.replace(/,/g, '')) || null;
                                    field.onChange(rawValue);
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                      <FormControl fullWidth>
                        <Controller
                          control={control}
                          name="other_ctc"
                          defaultValue={defaultValues?.other_ctc || ''}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              id="other_ctc"
                              name="other_ctc"
                              label="Other Perks"
                              variant="outlined"
                              size="small"
                              defaultValue={defaultValues?.other_ctc || ''}
                              error={!!errors.other_ctc}
                              helperText={errors.other_ctc?.message}
                              onChange={(e) => field.onChange(e.target.value || '')}
                            />
                          )}
                        />
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <Box className="compensation-det-block">
                        {watch('total_ctc') && watch('total_ctc') > '0' ? 
                        <Box className="after-comp-amt">
                          <Typography className="comp-text">Your Total Annual Compensation</Typography>
                          <Typography className="comp-amt" mt={2}>
                            ₹{watch('total_ctc') && `${new Intl.NumberFormat('en-IN').format(watch('total_ctc'))}`}

                          </Typography>
                          <Typography className="comp-text">{watch('other_ctc') && `Along with ${watch('other_ctc')}`}</Typography>
                        </Box>                        
                        :
                        <Box className="before-comp-amt" >
                        <Typography className="comp-text">
                          Please enter your compensation details on the left
                        </Typography>
                      </Box>}
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginBottom: '1.5rem' }} />

              <Box className="job-preferences">
                <Box mb={3}>
                  <Typography className="step-heading" variant="h6">
                    Job Preferences
                  </Typography>
                  <Typography className="step-info" variant="body2">
                    This would help us identify the best suited jobs for you
                  </Typography>
                </Box>

                <Box>
                  <Grid container spacing={2} mb={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth size="small" error={!!errors. expected_ctc}>
                        <Controller
                          control={control}
                          name="expected_ctc"
                          defaultValue={defaultValues?. expected_ctc|| ''}
                          render={({ field }) => (
                            <TextField
                            {...field}
                            id="expected_ctc"
                            name="expected_ctc"
                            label="Expected Compensation"
                            variant="outlined"
                            size="small"
                            value={field.value ? formatCurrency(field.value) : ''}
                            error={!!errors.expected_ctc}
                            helperText={errors.expected_ctc?.message}
                            onChange={(e) => {
                              const rawValue = parseInt(e.target.value.replace(/,/g, '')) || null;
                              field.onChange(rawValue);
                              }}
                            />
                          )}
                      />
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} mb={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth size="small">
                        <Controller
                          control={control}
                          name="work_format"
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              multiple
                              size="small"
                              options={workModelFormat}
                              getOptionLabel={(option) => option}
                              disableCloseOnSelect
                              open={openWorkFormat}
                              onOpen={() => setOpenWorkFormat(true)}
                              onClose={(event, reason) => {
                                if (reason != 'selectOption') {
                                  setOpenWorkFormat(false);
                                }
                              }}
                              onChange={(event, value) => {
                                field.onChange(value);
                                setOpenWorkFormat(true);
                              }}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    checked={selected}
                                  />
                                  {option}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Preferred Work Format"
                                  error={!!errors.work_format}
                                  helperText={errors.work_format?.message}
                                />
                              )}
                            />
                          )}
                        />
                        <FormHelperText>Select one or more options</FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth size="small">
                        <Controller
                          control={control}
                          name="job_type"
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              multiple
                              size="small"
                              options={jobType}
                              getOptionLabel={(option) => option}
                              disableCloseOnSelect
                              open={openJobType}
                              onOpen={() => setOpenJobType(true)}
                              onClose={(event, reason) => {
                                if (reason != 'selectOption') {
                                  setOpenJobType(false);
                                }
                              }}
                              onChange={(event, value) => {
                                field.onChange(value);
                                setOpenJobType(true);
                              }}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    checked={selected}
                                  />
                                  {option}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Preferred Job Type"
                                  error={!!errors.job_type}
                                  helperText={errors.job_type?.message}
                                />
                              )}
                            />
                          )}
                        />
                        <FormHelperText>Select one or more options</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>

                  <Grid container spacing={2} mb={2}>
                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth size="small">
                        <Controller
                          control={control}
                          name="preferred_job_shifts"
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              multiple
                              size="small"
                              options={preferredJobShifts}
                              getOptionLabel={(option) => option}
                              disableCloseOnSelect
                              open={openJobShifts}
                              onOpen={() => setOpenJobShifts(true)}
                              onClose={(event, reason) => {
                                if (reason !== 'selectOption') {
                                  setOpenJobShifts(false);
                                }
                              }}
                              onChange={(event, value) => {
                                field.onChange(value);
                                setOpenJobShifts(true);
                              }}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox
                                    icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                                    checked={selected}
                                  />
                                  {option}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Preferred Job Shifts"
                                  variant="outlined"
                                  error={!!errors.preferred_job_shifts}
                                  helperText={errors.preferred_job_shifts?.message}
                                />
                              )}
                            />
                          )}
                        /><FormHelperText>Select one or more options</FormHelperText>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6}>
                      <FormControl fullWidth size="small">
                        <Controller
                          control={control}
                          name="prefered_location"
                          // defaultValue={defaultValues?.prefered_location || []}
                          render={({ field }) => (
                            <TypeAhead
                              {...field}
                              {...register('prefered_location')}
                              multiple={true}
                              selectedAsCheckbox={true}
                              api={`${process.env.REACT_APP_END_POINT}${endpoints?.services?.city}`}
                              label="Preferred Locations"
                              minInputLength={2}
                              disableCloseOnSelect={true}
                              onChange={(e, value) => field.onChange(value)}
                              error={!!errors.prefered_location}
                              helperText={errors.prefered_location ? errors.prefered_location.message : ''}
                            />
                          )}
                        /><FormHelperText>Select one or more options</FormHelperText>
                      </FormControl>
                    </Grid>
                  </Grid>
                </Box>
              </Box>

              <Divider sx={{ borderStyle: 'dashed', borderWidth: '1px', marginBottom: '1.5rem' }} />

              <Button
                className="next-btn"
                variant="contained"
                size="large"
                sx={{ backgroundColor: '#1575C6!important' }}
                onClick={onSubmit}
                disabled={isSubmitting}
              >
                Next
              </Button>
            </Box>
          </Box>
        </Container>
      </FormProvider>
    </Box>
  );
};

export default JobPreferences;
