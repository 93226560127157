// utils
import { fetcher, endpoints, poster } from '../utils/axios';

export async function getCompanyDetails(payload) {
    const URL = endpoints.company.getCompanyDetails;
    return await fetcher([URL,{params:payload}]);
}

export async function getCompanyStaffList(payload){
    const URL = endpoints.company.getCompanyStaffList;
    return await fetcher([URL, {params:payload}])
}

export async function getCompanyStaffDetails(payload){
    const URL = endpoints.company.getCompanyStaffDetails;
    return await fetcher([URL, {params:payload}])
}

export async function getCompanyList(payload){
    const URL = endpoints.company.getCompanyList;
    return await fetcher([URL, {params:payload}])
}

export async function createCompany(payload){
    const URL = endpoints.company.createCompany;
    return await poster([URL,payload])
}

export async function addStaff(payload){
    const URL = endpoints.company.addStaff;
    return await poster([URL,payload])
}

export async function updateCompany(payload){
    const URL = endpoints.company.updateCompany;
    return await poster([URL, payload]);
}
export async function updateCompanyStaff(payload){
    const URL = endpoints.company.updateCompanyStaff;
    return await poster([URL, payload]);
}
